import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {
  getAllInterfaceSettings = () => request({
    url: 'interface-settings',
    method: 'GET',
    token: API_GLOBAL.token
  });

  editInterfaceSettings = ({ data }: any) => request({
    url: 'interface-settings',
    method: 'PUT',
    data,
    token: API_GLOBAL.token
  });
}

const API_INTERFACESETTINGS = new ClassAPI();

export default API_INTERFACESETTINGS;
