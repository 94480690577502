// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import WorkCalendarForm from './form';
import WorkCalendarTable from './table';

const WorkCalendaries = () => {
  return (
    <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
      <WorkCalendarTable />
      <WorkCalendarForm />
    </GridRow>
  );
};

export default WorkCalendaries;