import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {
  getAllSectors = () => request({
    url: 'catalog/sector',
    method: 'GET',
    token: API_GLOBAL.token
  });

  createNewSector = ({ data }: any) => request({
    url: 'catalog/sector',
    data,
    method: 'POST',
    token: API_GLOBAL.token
  });

  editSector = ({ data }: any) => request({
    url: 'catalog/sector',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  })

  getByIdSector = ({ id }: any) => request({
    url: `catalog/sector/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })

  getByNameSector = ({ id }: any) => request({
    url: `catalog/sector/by-name/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })
}

const API_SECTOR = new ClassAPI();

export default API_SECTOR;
