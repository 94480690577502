import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api';

class ClassAPI {
  getAllLawFile = () => request({
    url: 'lawfile',
    method: 'GET',
    token: API_GLOBAL.token,
  });

  createNewLawFile = ({ data }: any) => request({
    url: 'lawfile',
    data,
    method: 'POST',
    token: API_GLOBAL.token,
  });

  editLawFile = ({ data }: any) => request({
    url: 'lawfile',
    data,
    method: 'PUT',
    token: API_GLOBAL.token,
  });

  getByIdLawFile = ({ id }: any) => request({
    url: `lawfile/${id}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  deleteByIdLawFile = (id: any) => request({
    url: `lawfile/${id}`,
    method: 'DELETE',
    token: API_GLOBAL.token,
  });

}

const API_LAWFILE = new ClassAPI();

export default API_LAWFILE;
