// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';

import API_CATEGORY from '../api';
import { GET_ALL_CATEGORIES_REQUEST, CREATE_NEW_CATEGORY_REQUEST, GET_CATEGORY_BY_ID_REQUEST, EDIT_CATEGORY_REQUEST } from './constants';

function* getAllCategories({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_CATEGORY.getAllCategories);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }))
      if (form) yield put(callback({ payload: { response }, meta: { form } }))
    }
  } catch (error) {
    console.error('Auth Wrong');
  }
}

function* createNewCategory({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_CATEGORY.createNewCategory, { data: fields });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getCategoryById({ payload: { callback, data: { id } }, meta: { form } }: any) {
  try {
    const response = yield call(API_CATEGORY.getByIdCategory, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* editCategory({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_CATEGORY.editCategory, { data: fields });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    console.error(error);
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_ALL_CATEGORIES_REQUEST, getAllCategories),
    takeLatest(CREATE_NEW_CATEGORY_REQUEST, createNewCategory),
    takeLatest(GET_CATEGORY_BY_ID_REQUEST, getCategoryById),
    takeLatest(EDIT_CATEGORY_REQUEST, editCategory)
  ]);
}

export default Saga;
