// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import ProductTypesForm from './form';
import ProductTypesTable from './table';

const ProductTypes = () => {
  return (
    <GridRow>
      <ProductTypesTable />
      <ProductTypesForm />
    </GridRow>
  );
};

export default ProductTypes;