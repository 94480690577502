import EmailAdd from './pages/add';
import EmailsView from "./pages/view";
import EmailEdit from "./pages/edit";
export default [
  {
    type: 'private',
    exact: true,
    path: '/email',
    roles: ['admin'],
    component: EmailsView,
  },
  {
    type: 'private',
    exact: true,
    path: '/email/edit/:id',
    roles: ['admin'],
    component: EmailEdit,
  },
  {
    type: 'private',
    exact: true,
    path: '/email/create',
    roles: ['admin'],
    component: EmailAdd,
  },

];