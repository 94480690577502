import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {
  getAllStockTypes = () => request({
    url: 'catalog/stock-type',
    method: 'GET',
    token: API_GLOBAL.token
  });

  createNewStockTypes = ({ data }: any) => request({
    url: 'catalog/stock-type',
    data,
    method: 'POST',
    token: API_GLOBAL.token
  });

  editStockTypes = ({ data }: any) => request({
    url: 'catalog/stock-type',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  })

  getByIdStockTypes = ({ id }: any) => request({
    url: `catalog/stock-type/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })

  getByNameStockTypes = ({ id }: any) => request({
    url: `catalog/stock-type/by-name/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })
}

const API_STOCKTYPES = new ClassAPI();

export default API_STOCKTYPES;
