import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isPristine, reset } from 'redux-form/immutable';
import { Dialog } from '@material-ui/core';
//import style from './Dialog.scss';

import { makeSelectDialogOpen } from './selector';
import { closeDialog } from './actions';
import { DIALOG_WIDTH } from './constants';
//import { TICKER_FORM } from '../../../constants/forms';
import { formReset } from '../../Graphs/Form/actions';


export class FlowDialog extends React.PureComponent {
  static propTypes = {
    isDialogOpen: PropTypes.bool,
    width: PropTypes.oneOf(Object.values(DIALOG_WIDTH)),
    confirmClose: PropTypes.bool,
    className: PropTypes.string,
    pristine: PropTypes.bool,
    onClose: PropTypes.func,

    stopPropagationOnClose: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    isDialogOpen: false,
    stopPropagationOnClose: false,
  };

  state = {
    confirmOpen: false,
  };

  componentDidUpdate(prevProps) {
    if (!this.props.isDialogOpen && prevProps.isDialogOpen) {
      const { onClose } = this.props;
      if (onClose) onClose();
    }
  }


  componentWillUnmount() {
    const { dispatch, id, form } = this.props;
    dispatch(closeDialog({ id }));
    form && dispatch(reset(form));
    form && dispatch(formReset({ meta: { form: form } }));
    document.body.classList.remove('c-dialog__opened');
  }

  handleClose = () => {
    this.setState({ confirmOpen: true });
  };

  handleCancel = () => {
    this.setState({ confirmOpen: false });
  };

  handleApprove = e => {
    const { dispatch, id } = this.props;
    if (this.props.stopPropagationOnClose) e.stopPropagation();
    dispatch(closeDialog({ id }));
    this.handleCancel();
  };

  render() {
    const {
      children, isDialogOpen, width, confirmClose, className,
      pristine,
      onClose, dispatch, stopPropagationOnClose, // prevent to be ...rest'ed
      ...rest
    } = this.props;

    const widthClass = width ? `c-dialog__paper--${width}` : '';
    const classes = {
      paper: `c-dialog__paper ${widthClass}`,
    };

    const onCloseApprovable = confirmClose && !pristine ? this.handleClose : this.handleApprove;
    return (
      <Dialog
        {...rest}
        disableEnforceFocus
        open={isDialogOpen}
        onClose={onCloseApprovable}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        classes={classes}
        className={`${className} c-dialog`}
      >
        {children}
      </Dialog>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isDialogOpen: makeSelectDialogOpen,
  pristine: (state, ownProps) => (ownProps.form ? isPristine(ownProps.form)(state) : false),
});

const withConnect = connect(mapStateToProps);

export { DIALOG_WIDTH } from './constants';

export default compose(withConnect)(FlowDialog);
