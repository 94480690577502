const validate = (values: any) => {
  const errors = {} as any;


  if (values.emails && !Object.keys(values.emails).length) {
    errors.emails = 'Required';
  }
  if (values &&  !values.emails) {
    errors.emails = 'Required';
  }

  if (values && values.emails && Object.keys(values.emails).length > 0) {
       errors.emails2 = ['',''];
       for(let i = 0; i< Object.keys(values.emails).length; i++) {
          const email = values.emails[i];
          if( !email ) {
            errors.emails = 'Empty field';
          }
          else {
            const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,15})$/;
            if(reg.test(email) == false) {
              errors.emails = 'Wrong Email';
            }
          }
       }
  }


  if (values &&  !values.nameEN) {
//    errors.nameEN = 'Name LastName';
     errors.nameEN = 'Required';
  }

  if (values &&  !values.nameRU) {
    errors.nameRU = 'Required';
  }

  if (values && !values.engLang && !values.rusLang) {
    errors.engLang = 'Eng or Rus';
    errors.rusLang = 'Eng or Rus';
  }

  if (values && !values.type) {
    errors.type = 'Required';
  }

  return errors;
};

export default validate