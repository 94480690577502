import {
    OPEN_SUCCESS_MESSAGE,
    CLOSE_SUCCESS_MESSAGE,

    OPEN_ERROR_MESSAGE,
    CLOSE_ERROR_MESSAGE,
} from './constants';


export const openSuccessMessage = item => ({
    type: OPEN_SUCCESS_MESSAGE, payload: item,
});

export const closeSuccessMessage = item => ({
    type: CLOSE_SUCCESS_MESSAGE, payload: item,
});


export const openErrorMessage = item => ({
    type: OPEN_ERROR_MESSAGE, payload: item,
});

export const closeErrorMessage = item => ({
    type: CLOSE_ERROR_MESSAGE, payload: item,
});
