import React, { useState, useRef } from 'react';
import { isEmpty } from 'lodash';
import JoditEditor from 'jodit-react';
import { titleButton, deviderButton, bodyButton, resetStyleButton } from './customButtons';

const config = {
  buttons: [ "source", "bold"],
  wrapSingleTextNodes: true,
  removeEmptyNodes: false,
  removeEmptyBlocks: true,
  cleanWhitespace: false,
  cleanHTML: {
    cleanOnPaste: true,
    // replaceNBSP: true,
  },
  uploader: {
    insertImageAsBase64URI: true
  },
  allowTags: 'a[href],table,tr,td',
  colorPickerDefaultTab: 'color',
  colors: ['#ff0000', '#00ff00', '#0000ff']
  // askBeforePasteHTML: false,
  // askBeforePasteFromWord: false,
};

const Example = ({ onChange, value, readonly }: any) => {
  const editor = useRef(null);
  const [content, setContent] = useState('');

  const handleChange = (value: any) => {
    // onChange(value)
    // console.log(value)
    if (!isEmpty(value)) {
      onChange(value);
    }
    setContent(value);
  };

  return (
    <div className="jodit_alfa">
      <JoditEditor
        ref={editor}
        value={value}
        // @ts-ignore
        config={config}
        // tabIndex={1} // tabIndex of textarea
        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
        onChange={handleChange}
      />
    </div>
  );
};

export default Example;
