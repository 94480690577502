import * as ACTION_TYPE from './constants';

export const getAllCountryRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_COUNTRY_REQUEST,
  payload,
  meta,
});

export const createNewCountryRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_COUNTRY_REQUEST,
  payload,
  meta,
});

export const editCountryRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_COUNTRY_REQUEST,
  payload,
  meta,
})

export const getCountryByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_COUNTRY_BY_ID_REQUEST,
  payload,
  meta,
})