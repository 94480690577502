// @ts-nocheck
import {
    call,
    put,
    takeLatest,
    all,
    takeLeading,
    select, takeEvery,
} from 'redux-saga/effects';

import API_CATEGORY from '../api';
import {
    GET_ALL_STATISTICS_REQUEST,
    GET_ALL_TYPE_STATISTIC,
} from './constants';
import {STATISTICS_TABLE} from '../../../constants/tables';
import {checkTouch} from '../../../utils/formatters';
import {
    PRODUCT_FILTER_FORM,
    STATISTICS_FILTER_FORM,
} from '../../../constants/forms';
import {parseFilters} from '../../../utils/normalizeFilters';
import {tableDataSuccess} from '../../../components/Graphs/Table/actions';
import {writeStatisticToXLSX} from '../../../utils/xlsx';
import {CREATE_FILTER_PRODUCT_REQUEST} from "../../Products/redux/constants";
import {onChangeFilterProduct} from "../../Products/pages/forms/filterform/changeFilterSaga";

export const setFilterParameters = (
    state: any,
    table: any,
    form: any,
    allRecords: any,
): any => {
    const graphTableDate = state.graphTable && state.graphTable.get(table);

    const tableSort = graphTableDate?.get('filterMeta');
    const page = graphTableDate?.get('page');
    const filterForm = state.form && state.form[form] && state.form[form].values;
    const graphId = parseFilters(filterForm, tableSort);
    graphId.page = page ? page : 0;
    // в нынешней реализации во всем проекте вовзращатся все станицы начиная с 0
    // Это максимальное количество записей
    if (allRecords) {
        graphId.page = 1000000;
    }
    return graphId;
};

const getUsersFromState = (state: any): any => {
    return state.valueSets && state.valueSets.get('user');
};
const getProductFromState = (state: any): any => {
    return state.valueSets && state.valueSets.get('product');
};

const getValuestFromState = (state: any, catalog: string): any => {
    return state.valueSets && state.valueSets.get(catalog);
};

function* getAllStatistics({
                               meta: {form, table, callback, exportToXLSX},
                           }: any) {
    try {
        const state = yield select();
        const graphId = setFilterParameters(
            state,
            STATISTICS_TABLE,
            STATISTICS_FILTER_FORM,
            exportToXLSX,
        );
        // console.log('Step1 getAllStatistics');

        const response = yield call(
            API_CATEGORY.getFilterStatistics,
            graphId,
            STATISTICS_TABLE,
        );
        // console.log('Step2 getAllStatistics');
        if (exportToXLSX) {
            // console.log('Now must to be export data to Excel');
            const users = getUsersFromState(state);
            const products = getProductFromState(state);
            const productTypes = getValuestFromState(state, 'productTypeSimple');
            const language = getValuestFromState(state, 'language');
            console.log('Product language = ', language);
            const categoryType = getValuestFromState(state, 'categoryType');
            console.log('Product categoryType = ', categoryType);
            const client = getValuestFromState(state, 'client');
            console.log('Product client = ', client);
            const company = getValuestFromState(state, 'company');
            console.log('Product company = ', company);

            yield call(
                writeStatisticToXLSX,
                response,
                users,
                products,
                productTypes,
                language,
                categoryType,
                client,
                company,
            );

            // console.log('Step6 getAllStatistics');
        }
        if (callback) {
            // console.log('getAllStatistics table/form',  table, form, callback)
            if (table) {
                yield put(callback({payload: {response}, meta: {table}}));
            }
            if (form) {
                yield put(callback({payload: {response}, meta: {form}}));
            }
        }
    } catch (error) {
        console.error('Error getAllStatistics');
    }
}

// сейчас тип статистики - список фиксированных значений
function* getAllTypeStatistics({
                                   payload,
                                   meta: {form, table, callback},
                               }: any) {
    try {
        const typeStatistics = [
            {id: 'SPR', name: 'Show product'},
            {id: 'DNL', name: 'Download from site'},
            {id: 'DNE', name: 'Download from e-mail'},
            {id: 'AUT', name: 'Authorization user'},
        ];

        if (callback) {
            //      console.log('getAllTypeStatistics',  typeStatistics)
            if (table) {
                yield put(
                    callback({payload: {response: typeStatistics}, meta: {table}}),
                );
            }
            if (form) {
                yield put(
                    callback({payload: {response: typeStatistics}, meta: {form}}),
                );
            }
        }
    } catch (error) {
        console.error('Error set type statistic');
    }
}

/*
 * Общий обработчик событий по изменению redux форм
*/
function* onChangeFilterForAll({payload, meta}: any) {
    if (meta.form === STATISTICS_FILTER_FORM && checkTouch(payload, meta)) {
        yield onChangeFilterStatistic({payload, meta})
    } else if (meta.form === PRODUCT_FILTER_FORM && (checkTouch(payload, meta) || meta.force === true)) {
        yield onChangeFilterProduct({payload, meta})
    }
}

/* Изменение в форме фильтра статистика
*/
function* onChangeFilterStatistic({payload, meta}: any) {
    try {
        //      console.log('onChangeFilter:Form filter OK')
        const state = yield select();
        const graphId = setFilterParameters(
            state,
            STATISTICS_TABLE,
            STATISTICS_FILTER_FORM,
            false,
        );
        console.log('onChangeFilterStatistic:graphId=', graphId);

        graphId.page = 0;
        const response = yield call(
            API_CATEGORY.getFilterStatistics,
            graphId,
            STATISTICS_TABLE,
        );
        //      console.log('response=', response);
        yield put(
            tableDataSuccess({
                type: '',
                payload: {response},
                meta: {table: STATISTICS_TABLE, callback: tableDataSuccess},
            }),
        );
    } catch (error) {
        console.error('onChangeFilterStatistic : error', error);
        //      yield put(errorMessageFailure({payload: {error}}))
    }
}


/*
 * Общий обработчик событий по сбросу фильтров redux форм
*/
function* onResetFilterForAll({payload, meta}: any) {
    if (meta.form === STATISTICS_FILTER_FORM) {
        yield onResetFilterStatistic({payload, meta})
    } else if (meta.form === PRODUCT_FILTER_FORM) {
        yield onChangeFilterProduct({payload, meta})
    }
}

function* onResetFilterStatistic({payload, meta}: any) {
    try {
        console.log('onChangeFilter:Form filter OK');
        const state = yield select();
        const graphTableDate =
            state.graphTable && state.graphTable.get(STATISTICS_FILTER_FORM);

        const tableSort = graphTableDate?.get('filterMeta');
        const page = graphTableDate?.get('page');
        const filterForm =
            state.form && state.form[meta.form] && state.form[meta.form].values;
        console.log('onChangeFilter : BEFORE');
        const graphId = parseFilters(filterForm, tableSort);
        console.log('onChangeFilter : AFTER');
        graphId.page = page ? page : 0;
        console.log('Set filter graphId = ', graphId);

        const response = yield call(
            API_CATEGORY.getFilterStatistics,
            graphId,
            STATISTICS_TABLE,
        );
        console.log('response=', response);
        yield put(
            tableDataSuccess({
                type: '',
                payload: {response},
                meta: {table: STATISTICS_TABLE, callback: tableDataSuccess},
            }),
        );
    } catch (error) {
        console.log('onChangeFilter : catch', error);
        //      yield put(errorMessageFailure({payload: {error}}))
    }
}

function* Saga() {
    yield all([
        takeLatest(GET_ALL_STATISTICS_REQUEST, getAllStatistics),
        takeLatest(GET_ALL_TYPE_STATISTIC, getAllTypeStatistics),
        takeLatest(`@@redux-form/CHANGE`, onChangeFilterForAll),
        takeLeading(`@@redux-form/RESET`, onResetFilterForAll),
// 2022-10-05 добавлю запуск onChangeFilterForAll по монтированию компонента фильтр продукта
// по другому надо слишком много менять в механизме
        takeLatest(CREATE_FILTER_PRODUCT_REQUEST, onChangeFilterForAll),

    ]);
}

export default Saga;
