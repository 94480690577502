import React from 'react';
import GridRow from 'arui-feather/grid-row';



export default class RowTable extends React.PureComponent {
  handleClick = event => {
    //const { onClick, disabled } = this.props;
    // if (disabled) {
    //   event.stopPropagation();
    //   event.preventDefault();
    //   return;
    // }
    //onClick && onClick(event);
  };

  render() {
    const { children, ...props } = this.props;

    return (
      <GridRow {...props}>
        {children}
      </GridRow>
    );
  }
}

