// @ts-nocheck
import React from 'react';

export default (id: any) => [
  {
    isGroup: true,
    label: 'Law file name (En)',
    fieldWidth: '12',
    customPosition: {
      label: 2,
      field: 10,
    },

    items: [
      {
        placeholder: 'EN',
        type: 'text',
        id: 'nameEn',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
  {
    isGroup: true,
    label: 'File (En)',
    fieldWidth: '12',
    customPosition: {
      label: 2,
      field: 10,
    },
    items: [
      {
        type: 'fileSimple',
        typeFile:'img',
        id: 'urlEn',
        label: 'file',
        fieldWidth: '5',
        accept: 'image/jpg,image/jpeg',
        noFileText: 'Select and upload file',
      },
    ],
  },
  {
    isGroup: true,
    label: 'Law file name (Ru)',
    fieldWidth: '12',
    customPosition: {
      label: 2,
      field: 10,
    },

    items: [
      {
        placeholder: 'Ru',
        type: 'text',
        id: 'nameRu',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
  {
    isGroup: true,
    label: 'File (Ru)',
    fieldWidth: '12',
    customPosition: {
      label: 2,
      field: 10,
    },
    items: [
      {
        type: 'fileSimple',
        typeFile:'img',
        id: 'urlRu',
        label: 'file',
        fieldWidth: '5',
        accept: 'image/jpg,image/jpeg',
        noFileText: 'Select and upload file',
      },
    ],
  },

];
