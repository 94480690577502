import { all, fork } from 'redux-saga/effects';
import graphFormSaga from 'components/Graphs/Form/saga'
import graphTableSaga from 'components/Graphs/Table/saga'
import fileInputSaga from 'components/InputFile/saga'
import errorSaga from 'components/Errors/saga'
import grapesJs from 'components/GrapesJs/saga'

import authSaga from 'modules/Auth/redux/saga'
import categoriesSaga from 'modules/Catalog/Categories/redux/saga'
import calendarSaga from 'modules/Calendar/redux/saga'
import selectorsSaga from 'modules/Catalog/Sectors/redux/saga'

import productTypesSaga from 'modules/Catalog/ProductTypes/redux/saga'
import commoditiesSaga from 'modules/Catalog/Commodities/redux/saga';
import countriesSaga from 'modules/Catalog/Countries/redux/saga'
import currenciesSaga from 'modules/Catalog/Currencies/redux/saga'
import periodTypesSaga from 'modules/Catalog/PeriodTypes/redux/saga'
import stockTypesSaga from 'modules/Catalog/StockTypes/redux/saga'
import authorTypesSaga from 'modules/Catalog/AuthorTypes/redux/saga'
import eventTypesSaga from 'modules/Catalog/EventTypes/redux/saga'
import languagesSaga from 'modules/Catalog/Languages/redux/saga'

import interfaceSettingsSaga from 'modules/InterfaceSettings/redux/saga'
import authorsSaga from 'modules/Authors/redux/saga'
import widgetsChartsSaga from 'modules/WidgetsCharts/redux/saga'
import clientSaga from 'modules/Client/redux/saga'
import companiesSaga from 'modules/Companies/redux/saga'
import usersRolesSaga from 'modules/UsersRoles/redux/saga'
import productsSaga from 'modules/Products/redux/saga'
import mailingListSettingsSaga from 'modules/MailingListSettings/redux/saga'
import lawFileSaga from 'modules/LawFile/redux/saga'
import statisticsSaga from 'modules/Statistics/redux/saga'
import dashboardSaga from 'modules/Dashboard/redux/saga'
import footerInfoSaga from 'modules/Footer/redux/saga'
import workCalendarSaga from 'modules/Catalog/WorkCalendar/redux/saga'
import emailSaga from 'modules/Email/redux/saga'

function* RootSaga() {
    yield all([
        // Components
        fork(graphFormSaga),
        fork(graphTableSaga),
        fork(fileInputSaga),
        fork(errorSaga),
        fork(grapesJs),

        // Modules
        fork(authSaga),
        fork(categoriesSaga),
        fork(selectorsSaga),
        fork(productTypesSaga),
        fork(commoditiesSaga),
        fork(countriesSaga),
        fork(currenciesSaga),
        fork(periodTypesSaga),
        fork(stockTypesSaga),
        fork(authorTypesSaga),
        fork(eventTypesSaga),
        fork(languagesSaga),

        fork(interfaceSettingsSaga),
        fork(calendarSaga),
        fork(authorsSaga),
        fork(widgetsChartsSaga),
        fork(clientSaga),
        fork(companiesSaga),
        fork(usersRolesSaga),
        fork(productsSaga),
        fork(mailingListSettingsSaga),
        fork(lawFileSaga),
        fork(statisticsSaga),
        fork(dashboardSaga),

        fork(footerInfoSaga),
        fork(workCalendarSaga),

        fork(emailSaga)

    ]);
}

export default RootSaga;
