// @ts-nocheck
import React from 'react';
import { isNil } from 'lodash';

const fields = (type: string) => {
  var dateOffset = (24*60*60*1000) * 1; //1 days
  var myDate = new Date();
  myDate.setTime(myDate.getTime() - dateOffset);

  let conf: any = {
    COMPANIES_WIDGETSCHARTS_TABLE: [
      {
        fieldWidth: '12',
        customPosition: {
          label: 3,
          field: 8,
          className: 'col-11-field',
        },
        type: 'date',
        id: 'date',
        placeholder: 'DD.MM.YYYY',
        label: 'Date',
        view: 'filled',
        width: 'available',
        defaultValue: new Date(myDate).toLocaleDateString('et',  { day: '2-digit', month: '2-digit', year: 'numeric' }),
        size: 's',
      },
      {
        fieldWidth: '12',
        customPosition: {
          label: 3,
          field: 8,
          className: 'col-11-field',
        },
        type: 'file',

        id: 'companyXlsx',
        typeFile: 'companyXlsx',
        disabledIf: ({ date }: any) => !Boolean(date),
        label: 'Upload file',
        accept: 'application/excel',
        noFileText: 'Select and upload file',
      }],

    SECTOR_WIDGETSCHARTS_TABLE: [
      {
        fieldWidth: '12',
        customPosition: {
          label: 3,
          field: 8,
          className: 'col-11-field',
        },
        type: 'date',
        id: 'date',
        placeholder: 'DD.MM.YYYY',
        defaultValue: new Date(myDate).toLocaleDateString('et',  { day: '2-digit', month: '2-digit', year: 'numeric' }),
        label: 'Date',
        view: 'filled',
        width: 'available',
        size: 's',
      },
      {
        type: 'file',

        id: 'sectorXlsx',
        typeFile: 'sectorXlsx',
        label: 'Upload file',
        fieldWidth: '12',
        customPosition: {
          label: 3,
          field: 8,
          className: 'col-11-field',
        },
        accept: 'application/excel',
        disabledIf: ({ date }: any) => !Boolean(date),
        noFileText: 'Select and upload file',
      }],
  };


  return conf[type];
};

export default fields;
