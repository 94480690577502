// @ts-nocheck
import React from 'react';
import { getAllCurrenciesRequest } from 'modules/Catalog/Currencies/redux/actions';
import { getAllCompaniesRequest } from 'modules/Companies/redux/actions'
import { getAllStockTypesRequest } from 'modules/Catalog/StockTypes/redux/actions'
import { language } from 'utils/formatters';

export default [
  {
    type: 'file',

    id: 'dividendsXlsx',
    typeFile:'dividendsXlsx',
    label: ' ',
    fieldWidth: '5',
    accept: 'application/excel',
    noFileText: 'Select and upload file',
  },
];
