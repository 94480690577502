// @ts-nocheck
import React from 'react';
import { withRouter } from 'react-router-dom';
import GraphTable from 'components/Graphs/Table';
import { ICategoryTable, IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import { FILE_TABLE } from 'constants/tables';
import { openDialog } from 'components/Flows/Dialog/actions';
import { deleteFileByIdRequest, getAllCompaniesRequest, getFilesByIDCompanyRequest } from '../../../redux/actions';
import { getCurrentCompany, language } from 'utils/formatters';
import IconDelete from 'arui-feather/icon/action/delete';
import { compose } from 'redux';
import { connect } from 'react-redux';
import FlowDialog from 'components/Flows/Dialog';
import StockForm from '../../form/stocks';
import { deleteAuthorsByIdRequest } from '../../../../Authors/redux/actions';
import Button from 'arui-feather/button';
import FileForm from '../../form/file';
import StringCol from '../../../../../components/Flows/Elements/StringCol';
import IconEdit from 'arui-feather/icon/action/edit/edit';
import { createStructuredSelector } from 'reselect';
import { selectAllValueSets } from '../../../../../components/ValueSet/selector';
import { setValueSet } from '../../../../../components/ValueSet/actions';

class FilesTable extends React.Component<ICategoryTable> {

  state = {
    fileId: null,
  };

  componentDidMount() {
    const { dispatch }: any = this.props;
    const actions: any = {
      company: getAllCompaniesRequest,
    };
    if (actions) {
      Object.keys(actions).forEach((key) => {
        dispatch(actions[key]({ meta: { form: key, callback: setValueSet } }));
      });
    }
  }

  normalizeTableData = (head: IHeadTable[], body: IBodyTable[]): any => {
    let processedData = body && body.map((item, i) => {
      const { id, name, description, currencyId, url, fileSize, createdDateTime }: any = item;
      let emptyWidth: any = '';
      return {
        id,
        cells: [
          {
            element: <>{StringCol(false, i + 1)} <IconEdit size='m'/></>,
            id,
            type: 'edit',
            width: emptyWidth,
          },
          {
            element: name,
            id,
            type: 'name',
            width: emptyWidth,
          },
          {
            element: language(description, 'en'),
            id,
            type: 'description',
            width: emptyWidth,
          },
          {
            element: `${(fileSize/1024/1024).toFixed(2)} Mb`,
            id,
            type: 'currencyId',
            width: emptyWidth,
            customClass: 'number-size'
          },
          {
            element: createdDateTime,
            id,
            type: 'stockTypeId',
            width: emptyWidth,
          },
          {
            element: <span className={'url-span'}>{url}</span>,
            id,
            type: 'url',
            width: emptyWidth,
          },
          {
            element: <IconDelete size={'s'}/>,
            id,
            type: 'delete',
            width: emptyWidth,
            customClass: 'delete-icon',
          },
        ],
      };
    }) || [];

    const normalizeData = processedData.map((val: any) => {
      let newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });

    return normalizeData;
  };

  getRowLink = (stockId: any) => {
    const { id }: any = this.props;
    return `/companies/edit/${id}/stock/${stockId}`;
  };

  handleDelete = (Itemid: string) => {
    const { dispatch, id }: any = this.props;
    dispatch(deleteFileByIdRequest({ payload: { id: Itemid, companyId: id } }));
  };

  // handleCreateOverride = () => {
  //   const { dispatch }: any = this.props
  //   dispatch(openDialog({ id: 'table.stoks' }))
  // };

  handleEdit(id: any) {
    const { dispatch }: any = this.props;
    this.setState({ fileId: id });
    dispatch(openDialog({ id: 'table.file' }));
  }

  handleClose = () => {
    this.setState({
      fileId: null,
    });
  };

  get createDialogs() {
    const { fileId } = this.state;
    const { id }: any = this.props;
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        <FlowDialog onClose={this.handleClose} id="table.file" width="big">
        <FileForm fileId={fileId} id={id}/>
      </FlowDialog>
    );
  }


  handleCreateNewStock = () => {
    const { dispatch }: any = this.props;
    dispatch(openDialog({ id: 'table.file' }));
  };

  currentCompany = () => {
    const { id, valueSetsAll }: any = this.props;
    const { company } = valueSetsAll;
    return company && <div className={'company-block'}>{getCurrentCompany(id, company)}</div>;
  };

  render() {
    const { handleRowClick, id }: any = this.props;
    const head = [
      {
        id: 'edit',
        type: 'edit',
        label: '№',
        style: { width: '1' },
      },
      {
        id: 'name',
        label: 'File name',
        style: { width: ' ' },
      },
      {
        id: 'description',
        label: 'Description',
        style: { width: ' ' },
      },
      {
        id: 'currencyId',
        label: 'Size',
        style: { width: ' ' },
        className: 'number-size'
      },
      {
        id: 'stockTypeId',
        label: 'Date',
        style: { width: ' ' },
      },
      {
        id: 'url',
        label: 'URL',
        style: { width: ' ' },
      },
      {
        id: 'delete',
        type: 'delete',
        label: '',
        style: { width: ' ' },
      },
    ];

    return (
      <>
        {this.currentCompany()}
        <GraphTable
          table={FILE_TABLE}
          actions={
            <Button onClick={this.handleCreateNewStock} className={'button-float'} view="extra" size="s">Create new file</Button>
          }
          normalizeTableData={this.normalizeTableData}
          title="Files company"
          graphId={id}
          init={getFilesByIDCompanyRequest}
          onRowClick={handleRowClick}
          onDelete={(id: any) => this.handleDelete(id)}
          onEdit={(id: any) => this.handleEdit(id)}
          head={head}
          createDialogs={this.createDialogs}
          width={'12'}
          gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
          activeLink
        />
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  valueSetsAll: selectAllValueSets,
});
const withConnect = connect(mapStateToProps);


export default compose<any>(
  withConnect,
  withRouter,
  connect(),
)(FilesTable);
