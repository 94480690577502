// @ts-nocheck
import React, { Component } from 'react';
import CheckboxGroup from 'arui-feather/checkbox-group';
import InputGroup from 'arui-feather/input-group';
import Heading from 'arui-feather/heading';
import { compose } from 'redux';
import { uniqueId, find, isEmpty } from 'lodash'
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { selectAllValueSets } from '../../../../../components/ValueSet/selector';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import { CheckBoxComponent, RadioComponent } from 'components/CheckboxesThree'
import { makeSelectData } from '../../../../../components/Graphs/Form/selector';
import { hashCode, language } from '../../../../../utils/formatters';
import { LANGUAGE_EN, LANGUAGE_RU } from '../../../../../constants/languages';

class ClientPageConfig extends Component<any, any> {

  findIt = (accessItems: any, id: any) => {
    return find(accessItems, { categoryId: id})
  }

  render() {
    const { graphData, valueSetsAll } = this.props
    let deliveryData = []
    let accessItems = [] as any
    if (graphData) {
       deliveryData = graphData.deliveryData
       accessItems = graphData.accessItems
    }

    return (
      <div className="files-tab__form">
        <div className="basic-information-tab">
          <div className="basic-information-tab__item">
            <Heading size="s">
            </Heading>
          </div>
        </div>

        <div className="basic-information-tab">
          <div className="basic-information-tab__item">
            <div className="form-row">
              <div className="form-row__cell label" style={{ flex: 0.5 }}>
                Default site language:
              </div>
              <div className="form-row__cell input">
                <div className="input--item">
                  <div className="input--item__col">
                    <Field type="radio" label="EN" name="portalDefaultLanguageId" value={LANGUAGE_EN} component={RadioComponent}/>
                    <Field type="radio" label="RU" name="portalDefaultLanguageId" value={LANGUAGE_RU} component={RadioComponent}/>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-row__cell label" style={{ flex: 0.5 }}>
                Setting up subscriptions for E-mail:
              </div>
              <div className="form-row__cell input">
                <div className="input--item">
                  {
                    deliveryData.map(({ email }: any, index: number) => (
                      <div key={uniqueId()} className="input--item__col">
                        <span>{email}</span>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-row__cell label" style={{ flex: 0.5 }}>
                Selected language:
              </div>
              <div className="form-row__cell input">

                <div className="input--item">
                  {
                     deliveryData.map(({ engLang, rusLang, email }: any, index: number) => (
                      <div key={uniqueId()} className="input--item__col">
                        <InputGroup>
                          <Field
                            name={`deliveryData.${hashCode(email)}.engLang`}
                            type="text"
                            component={CheckBoxComponent}
                            view='filled'
                            width='available'
                            size= 's'
                            label="EN"
                            disabled={!graphData?.permittEngLang}
                          />
                          <Field
                            name={`deliveryData.${hashCode(email)}.rusLang`}
                            type="text"
                            component={CheckBoxComponent}
                            view='filled'
                            width='available'
                            size= 's'
                            label="RU"
                            disabled={!graphData?.permittRusLang}
                          />
                        </InputGroup>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>

            <div className="form-row" style={{ alignItems: 'start' }}>
              <div className="form-row__cell label" style={{
                width: '20%',
                flex: 'none',
              }}>
                Selected product categories and types:
              </div>

              {
                deliveryData.map(({ engLang, rusLang, email }: any, inde: number) => (
                  <div key={inde} className="form-row__cell input">
                    <div className="input--item">
                      <div className="input--item__col">
                        {valueSetsAll &&
                        valueSetsAll.categories &&
                        valueSetsAll.categories.map((item: any) => {
                          if (!item.serviceCategory && item.active) {
                            return (
                              <div key={uniqueId()} className="input__group-checkbox">
                                <CheckboxGroup
                                  label={
                                    <Field
                                      name={`deliveryData.${hashCode(email)}.checkboxes.${item.id}`}
                                      type="text"
                                      component={CheckBoxComponent}
                                      disabled={isEmpty(this.findIt(accessItems, item.id))}
                                      group={true}
                                      view='filled'
                                      width='available'
                                      size= 's'
                                      label={language(item.name, 'en')}
                                    />
                                  }
                                >
                                  <div className="input__group-checkbox-items">
                                    {
                                      valueSetsAll && valueSetsAll.productType && valueSetsAll.productType.map((itemChild: any) => {
                                        if (itemChild.categories.includes(item.id)) {
                                          return (
                                            <Field
                                              key={uniqueId()}
                                              name={`deliveryData.${hashCode(email)}.checkboxes.${item.id}.${itemChild.id}`}
                                              type="text"
                                              component={CheckBoxComponent}
                                              // @ts-ignore
                                              disabled={!this.findIt(accessItems, item.id)?.productTypes?.includes(itemChild.id)}
                                              view='filled'
                                              width='available'
                                              size= 's'
                                              label={language(itemChild.name, 'en')}
                                            />
                                          )
                                        }
                                      })
                                    }
                                  </div>
                                </CheckboxGroup>
                              </div>
                            )
                          }
                        })}
                      </div>
                    </div>
                  </div>
                ))
              }

            </div>

            <div className="form-row" style={{ alignItems: 'start' }}>
              <div className="form-row__cell label" style={{
                flex: 'none',
                width: '20%'
              }}>
                Selected sectors:
              </div>
              {
                deliveryData.map(({ email }: any, index: number) => (
                  <div key={uniqueId()} className="form-row__cell input">
                    <div className="input--item">
                      <div className="input--item__col">
                        <div className="input__group-checkbox">
                            {valueSetsAll &&
                            valueSetsAll.sectors &&
                            valueSetsAll.sectors.map((sector: any, index: any) => (
                              <Field
                                key={uniqueId()}
                                className="display_block"
                                name={`deliveryData.${hashCode(email)}.sectors.${sector.id}`}
                                type="text"
                                component={CheckBoxComponent}
                                view='filled'
                                width='available'
                                size= 's'
                                label={language(sector.name, 'en')}
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="form-row">
              <div className="form-row__cell label" style={{ flex: 0.5 }}>
                A product file :
              </div>
              <div className="form-row__cell input">
                <div className="input--item">
                  {
                    deliveryData.map(({ attachProduct, email }: any, index: number) => (
                        <div key={uniqueId()} className="input--item__col">
                          <InputGroup>
                            <Field
                                name={`deliveryData.${hashCode(email)}.attachProduct`}
                                type="text"
                                component={CheckBoxComponent}
                                view='filled'
                                width='available'
                                size= 's'
                                label="Attach to an E-mail"
                                // disabled={!graphData?.permittEngLang}
                            />
                          </InputGroup>
                        </div>
                    ))
                  }
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any, { meta: { form }, id }: any) => {
  const defaultProps = {
    valueSetsAll: selectAllValueSets,
    graphData: makeSelectData(),
  };
  return createStructuredSelector(defaultProps);
};

const withConnect = connect(mapStateToProps);

export default compose<any>(
  withConnect,
  withRouter,
)(ClientPageConfig);