import { language } from 'utils/formatters';

const normalize = (data: any) => {
  if (!data) return null;
  let cormData = {
    id: data.id,
    company: data.companyId,
    currency: data.currencyId,
    stockType: data.stockTypeId,
    nameEN: language(data.name, 'en'),
    nameRU: language(data.name, 'ru'),
  };


  return cormData;
};

export default normalize;
