// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';

import API_USERS from '../api';
import {
  GET_ALL_USERS_ROLES_REQUEST, GET_USERS_ROLES_BY_ID_REQUEST,
  EDIT_PASSWORD_USERS_REQUEST,
} from './constants';
import { createNewAuthorsFailure } from '../../Authors/redux/actions';


function* getAllUsers({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_USERS.getAllUsersRoles);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getUsersRolesById({ payload: { callback, data: { id } }, meta: { form } }: any) {
  try {
    const response = yield call(API_USERS.getByIdUser, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* editPasswordUsers({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_USERS.editPasswordUsers, { data: fields });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_ALL_USERS_ROLES_REQUEST, getAllUsers),
    takeLatest(EDIT_PASSWORD_USERS_REQUEST, editPasswordUsers),
    takeLatest(GET_USERS_ROLES_BY_ID_REQUEST, getUsersRolesById),
  ]);
}

export default Saga;
