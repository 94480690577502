import { validateEmail } from 'components/Inputs/Text/validate';

interface IValidate {
  email?: string;
  lastName?: string;
  firstName?: string;
  roles?: string;
}

const validate = (values: any) => {
  const errors = {} as IValidate;

  if (!values.email) {
    errors.email = 'Введите почту';
  }

  if (values.email) {
    errors.email = validateEmail(values.email);
  }

  if (!values.lastName) {
    errors.lastName = 'Введите фамилию';
  }

  if (!values.firstName) {
    errors.firstName = 'Введите имя';
  }

  if (!values.roles) {
    errors.roles = 'Выберите роль';
  }

  return errors;
};

export default validate;
