// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';

import GraphForm from 'components/Graphs/Form';
import { formReset } from 'components/Graphs/Form/actions'
import { getObjectTrim } from 'utils/formatters'
import normalize from './normalize';
import config from './fields';
import validate from './validate';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import {PRODUCT_FILTER_FORM, PRODUCT_OPTION_FORM} from 'constants/forms';
import { connect } from 'react-redux';
import {createFilterProductRequest} from "../../../redux/actions";
import {createStructuredSelector} from "reselect";
import {selectAllValueSets} from "../../../../../components/ValueSet/selector";
import {getFlowFormValues} from "../../../../../utils/select";
import {makeSelectRoles} from "../../../../../app/selectors";

interface ICategoryForm {
  title?: string;
  form: string;
  match: any
}
const PRODUCT_PAGE_SIZE = 8;

class ProductFilterForm extends React.Component<ICategoryForm> {
  componentDidMount() {
    const { dispatch }: any = this.props;
    dispatch(createFilterProductRequest({payload:{}, meta:{form:PRODUCT_FILTER_FORM, force:true, page:0, pageSize:PRODUCT_PAGE_SIZE}}));
  }

  handleClick = () => {
    const {history, dispatch}: any = this.props;
    dispatch(reset(PRODUCT_FILTER_FORM))
    dispatch(formReset({ meta: { form: PRODUCT_FILTER_FORM } }))
    history.push('/statistics')
  };

  get actions() {
//    const { match: { params: { id } } } = this.props;
    const { dispatch}: any = this.props;
    const action = 'Reset filters';
    return (
      <GridRow>
        <GridCol>
          <Button className={"button-float"} view="extra" size="s" onClick={()=>  dispatch(reset(PRODUCT_FILTER_FORM)) }>
            {action}
          </Button>
        </GridCol>
      </GridRow>
    );
  }
  render() {
    const graphId = {
      future: true,
    };

    return (
      <GraphForm
        title="Product table"
        form={PRODUCT_FILTER_FORM}
        graphId={graphId}
        type={'create'}
        actions={this.actions}
        onSuccess={this.handleClick}
        create={createFilterProductRequest}
        validate={(values: object) => validate(getObjectTrim(values))}
        normalize={normalize}
        columns={3}
        config={config}
        width="12"
        reInitialize
      />
    );
  }
}

export default compose<any>(
  connect(),
  withRouter
)(ProductFilterForm)
