// @ts-nocheck
import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import PublicLayout from 'app/Layouts/public';

import { IRouterProps } from 'types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { makeSelectToken } from '../selectors';

const PublicRoute: FC<IRouterProps> = ({ path, component: Component }) => (
  <PublicLayout>
    <Route path={path} render={() => <Component />} />
  </PublicLayout>
);

const mapStateToProps = createStructuredSelector({
  token: makeSelectToken(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(PublicRoute);
