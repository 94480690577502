import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose, Store } from 'redux';
import { routerMiddleware } from 'connected-react-router'
import { persistStore } from 'redux-persist';
import { History } from 'history';

import { rootReducer } from './rootReducer';
import mySaga from './rootSaga';

import API_GLOBAL from 'api/restClient/api';

export const configureStore = (history: History) => {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [
    API_GLOBAL.middleware,
    sagaMiddleware,
    routerMiddleware(history)
  ];

  const enhancers = [applyMiddleware(...middlewares)];

  const reduxDevtools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const isProduction = process.env.NODE_ENV !== 'production';

  const composeEnhancers =
    isProduction && typeof window === 'object' && reduxDevtools
      ? reduxDevtools({ shouldHotReload: false })
      : compose;

  const store = createStore(
    rootReducer(history),
    {  },
    composeEnhancers(...enhancers),
  );

  (store as any).runSaga = sagaMiddleware.run(mySaga);

  // persist store to localStorage
  const persistor = persistStore(store);

  return { store, persistor };
};
