import * as ACTION_TYPE from './constants';

export const getAllEmailRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_EMAILS_REQUEST,
  meta,
  payload
});

export const createNewEmailRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_EMAILS_REQUEST,
  payload,
  meta,
});

export const editEmailRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_EMAILS_REQUEST,
  payload,
  meta,
})

export const getEmailByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_EMAILS_BY_ID_REQUEST,
  payload,
  meta,
})

export const deleteEmailRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.DELETE_EMAILS_BY_ID_REQUEST,
  payload,
  meta,
})


