import EditPage from './pages/editPage';
import CreatePage from './pages/createPage';
import TablePage from './pages'

export default [
  {
    type: 'private',
    exact: true,
    path: '/calendar/edit/:id',
    roles: ['admin'],
    component: EditPage,
  },
  {
    type: 'private',
    exact: true,
    path: '/calendar/create',
    roles: ['admin'],
    component: CreatePage,
  },
  {
    type: 'private',
    exact: true,
    path: '/calendar',
    roles: ['admin'],
    component: TablePage,
  },
];
