import { language } from 'utils/formatters';

const normalize = (data: any) => {
  if (!data) return null;
  let cormData = {
    nameEN: language(data.name, 'en'),
    nameRU: language(data.name, 'ru'),
    sector: data.sectors,
    active: data.active,
    serviceCategory: data.serviceCategory
  }

  return cormData;
};

export default normalize;
