// @ts-nocheck
import React from 'react';
import { getAllCurrenciesRequest } from 'modules/Catalog/Currencies/redux/actions';
import { getAllStocksRequest } from 'modules/Companies/redux/actions'
import Radio from 'components/Inputs/Radio'
import { RadioComponent } from 'components/CheckboxesThree'
import { language } from '../../../../../utils/formatters';

const fields = (ticketId: any, companyId: any) => {

  const field = [
    {
      isGroup: true,
      label: 'Name',
      fieldWidth: '12',
      items: [
        {
          placeholder: 'EN',
          type: 'text',
          id: 'name',
          view: 'filled',
          width: 'available',
          size: 's',
          style: {
            marginBottom: '15px',
          },
        },
      ],
    },
    {
      type: 'ValueSet',
      id: 'currency',
      fieldWidth: '12',
      placeholder: 'Currency',
      label: 'Currency',
      view: 'filled',
      width: 'available',
      size: 's',
      multiple: false,
      itemOptions: (item: any) => ({ value: item.id, text: item.name}),
      action: getAllCurrenciesRequest,
    },
    {
      fieldWidth: '12',
      type: 'ValueSet',
      id: 'gdr',
      placeholder: 'Type',
      label: 'Type',
      view: 'filled',
      width: 'available',
      size: 's',
      data: [ 'ADR', 'GDR' ],
      itemOptions: (item: any) => ({ value: item, text: item }),
    },
    {
      type: 'ValueSet',
      id: 'stock',
      fieldWidth: '12',
      placeholder: 'Stock',
      label: 'Stock',
      view: 'filled',
      width: 'available',
      size: 's',
      multiple: false,
      filter: (data: any) => data.companyId === companyId,
      itemOptions: (item: any) => ({ value: item.id, text: language(item.name, 'en')}),
      action: getAllStocksRequest,
    },
    {
      isGroup: true,
      label: 'Depo ratio',
      fieldWidth: '12',
      items: [
        {
          placeholder: '',
          type: 'text',
          id: 'depoRatio',
          view: 'filled',
          width: 'available',
          size: 's',
          style: {
            marginBottom: '15px',
          },
        },
      ],
    },
    {
      type: 'checkbox',
      id: 'main',
      disabled: true,
      text: 'Ticker main',
      bigLabel: false,
    },
  ];

  const data = [
    {
      isGroup: true,
      label: 'Recommendation',
      fieldWidth: '12',
      items: [],
    },
    {
      isGroup: true,
      label: 'Target price',
      fieldWidth: '12',
      items: [
        {
          placeholder: '',
          type: 'text',
          id: 'targetPrice',
          view: 'filled',
          width: 'available',
          size: 's',
          style: {
            marginBottom: '15px',
          },
        },
      ],
    },
    {
      isGroup: true,
      label: 'Recommendation',
      fieldWidth: '12',
      items: [
        {
          placeholder: 'EN',
          type: 'text',
          id: 'recommendationEN',
          view: 'filled',
          width: 'available',
          size: 's',
          style: {
            marginBottom: '15px',
          },
        },
        {
          placeholder: 'RU',
          type: 'text',
          id: 'recommendationRU',
          view: 'filled',
          width: 'available',
          size: 's',
        },
      ],
    },
    {
      type: 'date',
      id: 'date',
      fieldWidth: '12',
      placeholder: 'DD.MM.YYYY',
      label: 'Date',
      view: 'filled',
      width: 'available',
      size: 's',
    },
  ]

  return ticketId ? field : [...field, ...data]
}

export default fields