import { difference } from 'lodash';

const memoize = f => (function () {
    let lastArgs = [];
    let lastResult = null;
    return (...args) => {
        const shallowEqual = [
            ...difference(args, lastArgs),
            ...difference(lastArgs, args),
        ].length === 0;
        if (shallowEqual) {
            return lastResult;
        } else {
            lastArgs = args;
            return lastResult = f(...args);
        }
    };
}());

export default memoize;
