import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import IconButton from 'arui-feather/icon-button'
import IconLogout from "arui-feather/icon/action/logout";
import { selectCurrentUser } from 'modules/User/redux/selector'
import { logoutRequest } from 'modules/Auth/redux/actions'
import Link from 'arui-feather/link'

const AdminHeader = ({ user: { name }, logout }) => {
    return (
        <div className="admin-header">
            <Link text={name} to="/profile" />

            <IconButton size="xl" onClick={() => logout()}>
                <IconLogout size="s" />
            </IconButton>
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
})

export default connect(mapStateToProps, { logout: logoutRequest })(AdminHeader);
