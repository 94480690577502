// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import { reset } from 'redux-form/immutable';
import { formReset } from 'components/Graphs/Form/actions';
import normalize from './normalize';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { WIDGETUPLOAD_FORM } from 'constants/forms';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectAllValueSets } from 'components/ValueSet/selector';
import { closeDialog } from 'components/Flows/Dialog/actions';
import {
  createCompaniesWidgetsChartsRequest,
  createSectorsWidgetsChartsRequest,
} from 'modules/WidgetsCharts/redux/actions';
import { COMPANIES_WIDGETSCHARTS_TABLE, SECTOR_WIDGETSCHARTS_TABLE } from '../../../../constants/tables';

interface ICompaniesForm {
  title?: string;
  form: string;
  match: any
}

class WidgetUpload extends React.Component<ICompaniesForm> {
  handleClick = () => {
    const { dispatch, id}: any = this.props;
    dispatch(reset(WIDGETUPLOAD_FORM));
    dispatch(formReset({ meta: { form: WIDGETUPLOAD_FORM } }));
    //dispatch(getTickersByCompanyIDRequest({ meta: { graphId: ticketId, table: TICKERS_TABLE, callback: tableDataSuccess } }));
    // dispatch(getAllRecommendationByTickerIDRequest({
    //   meta: {
    //     graphId: ticketId,
    //     table: TICKERS_RECOMENDATION_TABLE,
    //     callback: tableDataSuccess,
    //   },
    // }));
    dispatch(closeDialog({ id: id }));
  };

  componentWillUnmount() {
    const { dispatch, id, form }: any = this.props;
    // dispatch(reset(WIDGETUPLOAD_FORM));
    //dispatch(formReset({ meta: { form: WIDGETUPLOAD_FORM } }));
  }


  get actions() {
    const {}: any = this.props;
    const action = 'Upload';
    return (
      <GridRow>
        <GridCol>
          <Button className={'button-float'} view="extra" size="s" type="submit">
            {action}
          </Button>
          <Button className={'mr20 button-float'} size="s" onClick={this.handleClick}>Cancel</Button>
        </GridCol>
      </GridRow>
    );
  }


  submit = (data: any) => {
    const { type }: any = this.props;

    const actionReq: any = {
      COMPANIES_WIDGETSCHARTS_TABLE: data && data.companyXlsx && data.companyXlsx.file,
      SECTOR_WIDGETSCHARTS_TABLE: data && data.sectorXlsx && data.sectorXlsx.file,
    };

    return {
      fileLoad: actionReq[type],
      loadDate: data && data.date,
    };
  };

  render() {
    const { type }: any = this.props;
    const actionReq: any = {
      COMPANIES_WIDGETSCHARTS_TABLE: createCompaniesWidgetsChartsRequest,
      SECTOR_WIDGETSCHARTS_TABLE: createSectorsWidgetsChartsRequest,
    };

    return (
      <div style={{paddingLeft:'20px', paddingTop:'20px'}}>
        <GraphForm
          form={WIDGETUPLOAD_FORM}
          actions={this.actions}
          onSuccess={this.handleClick}
          graphId={{}}
          init={() => {}}
          edit={() => {}}
          create={actionReq[type]}
          submit={this.submit}
          normalize={normalize}
          title=""
          validate={() => {}}
          type={'create'}
          columns={1}
          config={config(type)}
          width="11"
          reInitialize
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  valueSetsAll: selectAllValueSets,
});

const withConnect = connect(mapStateToProps);

export default compose<any>(
  withConnect,
  withRouter,
)(WidgetUpload);
