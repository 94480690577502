export const GET_ALL_PERIODTYPES_REQUEST = 'GET_ALL_PERIODTYPES_REQUEST'
export const GET_ALL_PERIODTYPES__SUCCESS = 'GET_ALL_PERIODTYPES__SUCCESS'
export const GET_ALL_PERIODTYPES__FAILURE = 'GET_ALL_PERIODTYPES__FAILURE'

export const CREATE_NEW_PERIODTYPES_REQUEST = 'CREATE_NEW_PERIODTYPES_REQUEST'
export const CREATE_NEW_PERIODTYPES_SUCCESS = 'CREATE_NEW_PERIODTYPES_SUCCESS'
export const CREATE_NEW_PERIODTYPES_FAILURE = 'CREATE_NEW_PERIODTYPES_FAILURE'

export const GET_PERIODTYPES_BY_ID_REQUEST = 'GET_PERIODTYPES_BY_ID_REQUEST'
export const GET_PERIODTYPES_BY_ID_SUCCESS = 'GET_PERIODTYPES_BY_ID_SUCCESS'
export const GET_PERIODTYPES_BY_ID_FAILURE = 'GET_PERIODTYPES_BY_ID_FAILURE'

export const EDIT_PERIODTYPES_REQUEST = 'EDIT_PERIODTYPES_REQUEST'