import { language } from 'utils/formatters';

const normalize = (data: any) => {
  if (!data) return null;
  let cormData = {
    date: data.date,
    equityTickerId: data.equityTickerId,
    id: data.id,
    recommendationEN: language(data.recommendation, 'en'),
    recommendationRU: language(data.recommendation, 'ru'),
    targetPrice: data.targetPrice
  };

  return cormData;
};

export default normalize;
