import XLSX from "xlsx"
import { language } from 'utils/formatters';

export function readXLSX( file ) {
    console.log('readXLSX', file);
    const reader = new FileReader();
    reader.onload = (e) => {
        /* Parse data */
        const ab = e.target.result;
        const wb = XLSX.read(ab, {type:'array'});
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, {header:1});
        /* Update state */
        console.log('XLS data=', data)

    };
    reader.readAsArrayBuffer(file[0]);
}

export function readXLSX2(file){
    return new Promise((resolve, reject) => {
        var fr = new FileReader();
        fr.onload = (e) => {
            /* Parse data */
            const ab = e.target.result;
            const wb = XLSX.read(ab, {type:'array'});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, {header:1});
            /* Update state */
            console.log('XLS data=', data)
            resolve(data )
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(file[0]);
    });
}

const normalizeData = (statistics, users, products, productTypes, languageArr,  categoryType, clients,  companies ) => {
    let data = [];
    let productMap = new Map();
    products.forEach( product => {
        productMap.set(product.id, product)
    })

    let productTypeMap = new Map();
    productTypes.forEach( productType => {
        productTypeMap.set(productType.id,  language(productType.name,'en'))
    })

    let userMap = new Map();
    users.forEach( user => {
        userMap.set(user.id, user)
    })

    let languageMap = new Map();
    languageArr.forEach( lang => {
        languageMap.set(lang.id,  lang.name)
    })

    let categoryMap = new Map();
    categoryType.forEach( category => {
        categoryMap.set(category.id,   language(category.name,'en'))
    })

    let clientMap = new Map();
    clients.forEach( client => {
        clientMap.set(client.id,   language(client.name,'en'))
    })

    let companyMap = new Map();
    companies.forEach( company => {
        companyMap.set(company.id,   language(company.name,'en'))
    })
    console.log('companyMap=',  companyMap )

    // console.log('statistics=', statistics )
    statistics.forEach( statistic => {
        console.log('#1 statistic=', statistic)
 //       console.log('#2 statistic=', statistic.type, userMap.get(statistic.userId).name,  productMap.get(statistic.productId).name, statistic.created)
        const product =  productMap.get(statistic.productId);
        // console.log('#2 statistic=', product)
        let productName;
        if( product ) {
            // console.log('OK', product)
            productName = product.name
        }
        else {
            // console.log('NOT OK', product)
            productName = ''
        }

        const productType =  productTypeMap.get(statistic.productTypeId);
        let productTypeName;
        if( productType ) {
            productTypeName = productType
        }
        else {
            productTypeName = ''
        }

        const user =  userMap.get(statistic.userId);
        let userName;
        if( user ) {
            userName = user.name
        }
        else {
            userName = ''
        }

        const lang =  languageMap.get(statistic.languageId);
        let languageName;
        if( lang ) {
            languageName = lang
        }
        else {
            languageName = ''
        }

        const category =  categoryMap.get(statistic.categoryId);
        // console.log('categoryMap category=', category, statistic.categoryId)
        let categoryName;
        if( category ) {
            categoryName = category
        }
        else {
            categoryName = ''
        }

        const client =  clientMap.get(statistic.clientId);
        // console.log('categoryMap category=', category, statistic.categoryId)
        let clientName;
        if( client ) {
            clientName = client
        }
        else {
            clientName = ''
        }
        console.log('companies = ', statistic.companies)

        let companiesNames = '';
        let first = true;
        statistic.companies &&
        statistic.companies.forEach(companyId => {
            const company =  companyMap.get(companyId);
            if( company ) {
                companiesNames += (first) ? company : ',' + company
                first = false
            }
        })



        data.push( {
            type: statistic.type,
            user: userName,
            product: productName,
            productType: productTypeName,
            language: languageName,
            category: categoryName,
            client: clientName,
            companies: companiesNames,
            date: statistic.created
        })
        // console.log('#3 statistic=', statistic.type, userMap.get(statistic.userId).name,  statistic.created)

    })
    let res = []
    let res_line = []
    res_line.push('date')
    res_line.push('type')
    res_line.push('user')
    res_line.push('product')
    res_line.push('product type')
    res_line.push('language')
    res_line.push('category')
    res_line.push('client')
    res_line.push('companies')

    res.push( res_line)

    data.forEach( line => {
        let res_line = []
        res_line.push(line.date)
        res_line.push(line.type)
        res_line.push(line.user)
        res_line.push(line.product)
        res_line.push(line.productType)
        res_line.push(line.language)
        res_line.push(line.category)
        res_line.push(line.client)
        res_line.push(line.companies)
        res.push( res_line)
    })
    // console.log('res=',  res)
    return res
}


export function writeStatisticToXLSX(statistics, users, products, productTypes,
                                     language,  categoryType, client,  company ) {
    // console.log('Export to XLSX', statistics, users, products)
    const ws_data = normalizeData(statistics, users, products, productTypes,
                                  language,  categoryType, client,  company )

    var wb = XLSX.utils.book_new();
    var ws_name = "SheetJS";

// make worksheet  Пример заполнения таблицы с данными
/*
    var ws_data = [
        [ "S", "h", "e", "e", "t", "J", "S" ],
        [  1 ,  2 ,  3 ,  4 ,  5, "2021-10-15 09:33" ]ws
    ];

 */
//    var ws = XLSX.utils.aoa_to_sheet(ws_data);
    console.log('ws_data=', ws_data)
    let ws = XLSX.utils.aoa_to_sheet(ws_data);

    console.log('ws=', ws)

//   Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
//  так можно поменять значение, не забываем что у ячеки может быть тип!
//    ws['C1'].v = 'Hello word';

    XLSX.writeFile(wb, 'Statistics.xlsx');

}