export const GET_ALL_STOCKTYPES_REQUEST = 'GET_ALL_STOCKTYPES_REQUEST'
export const GET_ALL_STOCKTYPES_SUCCESS = 'GET_ALL_STOCKTYPES_SUCCESS'
export const GET_ALL_STOCKTYPES_FAILURE = 'GET_ALL_STOCKTYPES_FAILURE'

export const CREATE_NEW_STOCKTYPES_REQUEST = 'CREATE_NEW_STOCKTYPES_REQUEST'
export const CREATE_NEW_STOCKTYPES_SUCCESS = 'CREATE_NEW_STOCKTYPES_SUCCESS'
export const CREATE_NEW_STOCKTYPES_FAILURE = 'CREATE_NEW_STOCKTYPES_FAILURE'

export const GET_STOCKTYPES_BY_ID_REQUEST = 'GET_STOCKTYPES_BY_ID_REQUEST'
export const GET_STOCKTYPES_BY_ID_SUCCESS = 'GET_STOCKTYPES_BY_ID_SUCCESS'
export const GET_STOCKTYPES_BY_ID_FAILURE = 'GET_STOCKTYPES_BY_ID_FAILURE'

export const EDIT_STOCKTYPES_REQUEST = 'EDIT_STOCKTYPES_REQUEST'