// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import ListHeader from 'arui-feather/list-header';
import CompaniesTable from './table';
import { Link } from 'react-router-dom';
import Button from 'arui-feather/button';
import UplodadCompanyFiles from './form/homeFile'

const ClientsView = () => {
  return (
  <>
    <GridRow>
      <CompaniesTable
        actions={
          <Link to={'companies/create'}>
            <Button className={'button-float'} view="extra" size="s" type="submit">
              Add new company
            </Button>
          </Link>
        }
      />
    </GridRow>
    <GridRow>
      <div>
        <ListHeader view='filled' title='Upload xls with dividends by company' />
        <UplodadCompanyFiles />
      </div>
    </GridRow>
    </>
  );
};

export default ClientsView;