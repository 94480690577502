// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {reset} from 'redux-form';
import {withRouter} from 'react-router';
import GraphForm from 'components/Graphs/Form';
import {closeDialog} from 'components/Flows/Dialog/actions'
import {formReset} from 'components/Graphs/Form/actions';
import {
    getStocksByIDRequest, editStockRequest, createNewStockRequest, getStocksByIDCompanyRequest,
} from '../../../redux/actions';
import normalize from './normalize';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import {STOCK_FORM} from 'constants/forms';
import {LANGUAGE_EN, LANGUAGE_RU} from '../../../../../constants/languages';

import {STOCKS_TABLE} from '../../../../../constants/tables';
import {tableDataSuccess} from '../../../../../components/Graphs/Table/actions';

interface ICompaniesForm {
    title?: string;
    form: string;
    match: any
    stockId?: any
}

class ClientsForm extends React.Component<ICompaniesForm> {

    componentWillUnmount() {
        const {dispatch, id, form}: any = this.props;
        dispatch(reset(STOCK_FORM));
        dispatch(formReset({meta: {form: STOCK_FORM}}));
    }

    handleClick = () => {
        const {dispatch, id}: any = this.props;
        dispatch(reset(STOCK_FORM));
        dispatch(formReset({meta: {form: STOCK_FORM}}));
        dispatch(getStocksByIDCompanyRequest({meta: {graphId: id, table: STOCKS_TABLE, callback: tableDataSuccess}}));
        dispatch(closeDialog({id: 'table.stoks'}))
    };

    get actions() {
        const {stockId} = this.props;
        const action = stockId ? 'Edit this stock' : 'Add new stock';
        return (
            <GridRow>
                <GridCol>
                    <Button className={'button-float'} view="extra" size="s" type="submit">
                        {action}
                    </Button>
                    <Button className={'mr20 button-float'} size="s" onClick={this.handleClick}>Cancel</Button>
                </GridCol>
            </GridRow>
        );
    }

    submit = (data: any) => {
        const {id}: any = this.props;
        return {
            id: data.id,
            companyId: id,
            currencyId: data.currency,
            stockTypeId: data.stockType,
            name: [
                {
                    language: {id: LANGUAGE_RU, name: 'Рус'},
                    translatedValue: data.nameRU,
                },
                {
                    language: {id: LANGUAGE_EN, name: 'En'},
                    translatedValue: data.nameEN,
                },
            ]
        };
    }

    render() {
        const {stockId} = this.props;
        return (
            <div style={{padding: '20px'}}>
                <GraphForm
                    form={STOCK_FORM}
                    title="Stock form"
                    actions={this.actions}
                    onSuccess={this.handleClick}
                    graphId={stockId}
                    init={getStocksByIDRequest}
                    edit={editStockRequest}
                    create={createNewStockRequest}
                    submit={this.submit}
                    normalize={normalize}
                    validate={() => {
                    }}
                    type={stockId ? 'edit' : 'create'}
                    columns={1}
                    config={config}
                    width="12"
                    reInitialize
                />
            </div>
        );
    }
}

export default compose<any>(
    withRouter,
    connect()
)(ClientsForm);
