import React from 'react';
import { withRouter, NavLink, Link } from 'react-router-dom';

import MenuData  from 'app/menu2';

import './AdminMenu.scss';
import { checkPermisions } from '../../constants/roles';
import { createStructuredSelector } from 'reselect';
import { makeSelectRoles } from '../../app/selectors';
import { connect } from 'react-redux';
import { compose } from 'redux';

const MenuDropItem = ({ menuItem, rolesUser, toggleSubmenu
}) => {
    const isCollapsed = (menuItem.opened)? true: false
    return (
      <div>
          { menuItem.submenu && checkPermisions(rolesUser, menuItem.submenu?.url, 'menu')
            ? (
              <div className={ isCollapsed ? 'dropdown-item is-expanded' : 'dropdown-item' } onClick={ ()=>toggleSubmenu(menuItem) }>
                            <span className="dropdown-item__value">
                                { menuItem.content }
                            </span>
                  <span className={ isCollapsed ? 'dropdown-item__arrow' : 'dropdown-item__arrow-close'}>
                            </span>
              </div>
            )
            : checkPermisions(rolesUser, menuItem.props?.url, 'menu') ? (
              <NavLink
                to={ menuItem.props.url }
                className="main-menu__item_link"
                activeClassName={'active'}
              >
                  { menuItem.content }
              </NavLink>
            ): null
          }
          { menuItem.submenu && menuItem.submenu.map((submenu, j) => {
              if (checkPermisions(rolesUser, submenu.props?.url, 'menu')) {
                  return (
                    <div key={ j } className={ isCollapsed ? 'main-menu__item_sub-menu is-expanded' : 'main-menu__item_sub-menu' }>
                        <NavLink
                          to={ submenu.props.url }
                          activeClassName={'active-sub'}
                        >
                            { submenu.content }
                        </NavLink>
                    </div>
                  )
              }
          }) }
      </div>
    )
}

class AdminMenu extends React.Component {
    static defaultProps = {
        menuItems: MenuData
    };

    constructor(props) {
        super(props);
        this.state = {
            toggle: false
        }
        this.toogleSubMenu = this.toogleSubMenu.bind(this)
    }

    toogleSubMenu(item) {
        console.log('Toggle submenu item=', item)
        item.opened = !item.opened;
        this.setState({ toggle : !this.state.toggle})

    }
    // 2021-11-18 not enougth space for menu, add overflowY: 'auto'
    render() {
        const { menuItems, rolesUser } = this.props;
        return (
            <div className="nav">
                <div className="nav__menu" style={{overflowY: 'auto'
                }}>
                    <ul className="main-menu">
                        { menuItems.map((menu, index) => {
                            return (
                              <li key={ index } className="main-menu__item">
                                  <MenuDropItem menuItem={ menu } rolesUser={rolesUser} toggleSubmenu={this.toogleSubMenu} />
                              </li>
                            )
                        }) }
                        <Link to="/" className="nav__menu_logo">
                            <svg width="28" height="42" viewBox="0 0 28 42" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 42h28v-6h-28v6zm28-42l-28 42m0-42l28 42m-17.851-24.427l3.815-11.34h.141l3.603
                11.34h-7.56zm8.83-13.433c-.776-2.313-1.67-4.14-4.732-4.14-3.063 0-4.014 1.82-4.831
                4.14l-8.417 23.925h5.581l1.943-5.687h10.739l1.802 5.687h5.935l-8.02-23.925z" fill="#fff">
                                </path>
                            </svg>
                        </Link>
                    </ul>
                </div>
            </div>
        )
    }
};

const mapStateToProps = createStructuredSelector({
    rolesUser: makeSelectRoles(),
});

const withConnect = connect(mapStateToProps);

export default compose(
  withConnect,
  withRouter
)(AdminMenu);