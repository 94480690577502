export const GET_ALL_EVENTTYPES_REQUEST = 'GET_ALL_EVENTTYPES_REQUEST'
export const GET_ALL_EVENTTYPES_SUCCESS = 'GET_ALL_EVENTTYPES_SUCCESS'
export const GET_ALL_EVENTTYPES_FAILURE = 'GET_ALL_EVENTTYPES_FAILURE'

export const CREATE_NEW_EVENTTYPES_REQUEST = 'CREATE_NEW_EVENTTYPES_REQUEST'
export const CREATE_NEW_EVENTTYPES_SUCCESS = 'CREATE_NEW_EVENTTYPES_SUCCESS'
export const CREATE_NEW_EVENTTYPES_FAILURE = 'CREATE_NEW_EVENTTYPES_FAILURE'

export const GET_EVENTTYPES_BY_ID_REQUEST = 'GET_EVENTTYPES_BY_ID_REQUEST'
export const GET_EVENTTYPES_BY_ID_SUCCESS = 'GET_EVENTTYPES_BY_ID_SUCCESS'
export const GET_EVENTTYPES_BY_ID_FAILURE = 'GET_EVENTTYPES_BY_ID_FAILURE'

export const EDIT_EVENTTYPES_REQUEST = 'EDIT_EVENTTYPES_REQUEST'