import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {
  getAllCategories = () => request({
    url: 'catalog/category',
    method: 'GET',
    token: API_GLOBAL.token
  });

  createNewCategory = ({ data }: any) => request({
    url: 'catalog/category',
    data,
    method: 'POST',
    token: API_GLOBAL.token
  })

  editCategory = ({ data }: any) => request({
    url: 'catalog/category',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  })

  getByIdCategory = ({ id }: any) => request({
    url: `catalog/category/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })
}

const API_CATEGORY = new ClassAPI();

export default API_CATEGORY;
