// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import { formReset } from 'components/Graphs/Form/actions'
import { tableDataSuccess } from 'components/Graphs/Table/actions'
import { createNewCommoditiesRequest, getCommoditiesByIdRequest,
  getAllCommoditiesRequest, editCommoditiesRequest} from '../../redux/actions';
import normalize from './normalize';
import { LANGUAGE_EN, LANGUAGE_RU } from 'constants/languages'
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { CATALOG_COMMODITIES_FORM } from 'constants/forms';
import { CATALOG_COMMODITIES_TABLE } from 'constants/tables';
import { connect } from 'react-redux';

interface ICommoditiesForm {
  title?: string;
  form: string;
  match: any
}

class CommoditiesForm extends React.Component<ICommoditiesForm> {

  handleClick = () => {
    const {history, dispatch}: any = this.props;
    dispatch(reset(CATALOG_COMMODITIES_FORM))
    dispatch(getAllCommoditiesRequest({ meta: { table: CATALOG_COMMODITIES_TABLE, callback: tableDataSuccess }}))
    dispatch(formReset({ meta: { form: CATALOG_COMMODITIES_FORM } }))
    history.push('/commodities')
  };

  get actions() {
    const { match: { params: { id } } } = this.props;
    const action = id ? 'Edit this commodity' : 'Add new commodity';
    return (
      <GridRow>
        <GridCol>
          <Button className={"button-float"} view="extra" size="s" type="submit">
            {action}
          </Button>
          <Button className={"mr20 button-float"} size="s" onClick={this.handleClick}>Cancel</Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = ({ nameEN, nameRU }: any) => {
    const { match: { params: { id } } } = this.props
    return {
      id,
      name: [
        {
          language: { id: LANGUAGE_RU, name: 'Рус' },
          translatedValue: nameRU,
        },
        {
          language: { id: LANGUAGE_EN, name: 'En' },
          translatedValue: nameEN,
        },
      ]
    };
  };

  render() {
    const {  match: { params: { id } }}  = this.props;
    return (
      <GraphForm
        title="Commodities form"
        form={CATALOG_COMMODITIES_FORM}
        actions={this.actions}
        onSuccess={this.handleClick}
        graphId={id}
        init={getCommoditiesByIdRequest}
        edit={editCommoditiesRequest}
        create={createNewCommoditiesRequest}
        submit={this.submit}
        normalize={normalize}
        validate={() => {}}
        type={id ? 'edit': 'create'}
        columns={1}
        config={config}
        width="5"
        reInitialize
      />
    );
  }
}

export default compose<any>(
  connect(),
  withRouter
)(CommoditiesForm)
