import { language } from 'utils/formatters';

const normalize = (data: any) => {
  if (!data) return null;
  let cormData = {
    bodyId: data.bodyId,
    categoryId: data.categoryId,
    footerMifidId: data.footerMifidId,
    footerAccId: data.footerAccId,
    headerId: data.headerId,
    name: data.name,
    productTypeId: data.productTypeId
  };
  return cormData;
};

export default normalize;
