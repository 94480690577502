export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';

const DIALOG_WIDTH_SMALL = 'small';
const DIALOG_WIDTH_SMALLEST = 'smallest';
const DIALOG_WIDTH_MEDIUM = 'medium';
const DIALOG_WIDTH_BIG = 'big';

export const DIALOG_WIDTH = {
    [DIALOG_WIDTH_SMALL]: DIALOG_WIDTH_SMALL,
    [DIALOG_WIDTH_SMALLEST]: DIALOG_WIDTH_SMALLEST,
    [DIALOG_WIDTH_MEDIUM]: DIALOG_WIDTH_MEDIUM,
    [DIALOG_WIDTH_BIG]: DIALOG_WIDTH_BIG,
};
