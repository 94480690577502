import { getAllcategoriesRequest} from 'modules/Catalog/Categories/redux/actions'
import { language } from 'utils/formatters'
export default [
  {
    isGroup: true,
    label: 'Product type name',
    items: [
      {
        placeholder: 'EN',
        type: 'text',
        id: 'nameEN',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU',
        type: 'text',
        id: 'nameRU',
        view: 'filled',
        width: 'available',
        size: 's',
      },
    ],
  },
  {
    isGroup: true,
    label: 'Product type description',
    items: [
      {
        placeholder: 'EN',
        type: 'text',
        id: 'descriptionEN',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU',
        type: 'text',
        id: 'descriptionRU',
        view: 'filled',
        width: 'available',
        size: 's',
      },
    ],
  },
  {
    type: 'ValueSet',
    id: 'categories',
    multiple: true,
    placeholder: 'Category',
    label: 'Category',
    view: 'filled',
    width: 'available',
    size: 's',
    itemOptions: (item: any) => !item.serviceCategory && item.active && ({ value: item.id, text: language(item.name,'en') }),
    action: getAllcategoriesRequest,
  },
  {
    type: 'checkbox',
    id: 'blastNote',
    text: 'Blast Note',
    bigLabel: false,
  },
];
