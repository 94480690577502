// @ts-nocheck
import React from 'react';
export default [
  {
    isGroup: true,
    label: 'Event type name',
    items: [
      {
        placeholder: 'EN',
        type: 'text',
        id: 'nameEN',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU',
        type: 'text',
        id: 'nameRU',
        view: 'filled',
        width: 'available',
        size: 's',
      },
    ],
  }
];
