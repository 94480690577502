// @ts-nocheck
import React, { Component } from 'react';
import GridCol from "arui-feather/grid-col";
import CalendarInput from "arui-feather/calendar-input";
import Input from "arui-feather/input";
import { connect } from 'react-redux';
import GridRow from 'arui-feather/grid-row';

// const FormItem = Form.Item;
// const Option = Select.Option;

class DateTime extends Component {
  onChange = (value: any) => {
    const { input }: any = this.props;
    input.onChange();
  };

  render() {
    const {
      input,
      id,
      label,
      data = [],
      required,
      disabled,
      hasFeedback,
      loading,
      itemOptions,
      multiple,
      meta: { touched, invalid, error },
      ...rest
    }: any = this.props;
    return (
      <>
        <GridRow gutter={ { mobile: 0, tablet: 16, desktop: { m: 12 } } } >
          <GridCol width={2}>
            <CalendarInput
              placeholder='DD.MM.YYYY'
              view='filled'
              onChange={this.onChange}
              size='m'
              width='available'
            />
          </GridCol>
          <GridCol width={2}>
            <Input
              placeholder='HH:MM'
              mask='11.11.1111 11:11'
              view='filled'
              onChange={this.onChange}
              size='m'
              width='available'
            />
          </GridCol>
        </GridRow>
      </>
    )
  }
}

export const InputDateTime = connect()(DateTime);
