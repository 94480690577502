// @ts-nocheck
import React, { ComponentType } from 'react';

export default [
  {
    isGroup: true,
    label: 'Year',
    items: [
      {
        placeholder: 'Year',
        type: 'text',
        id: 'year',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      }
    ]
  },
  {
    isGroup: true,
    label: 'Holidays',
    items: [
      {
        placeholder: 'Holidays list MON-FRI format dd.MM,dd.MM',
        type: 'text',
        id: 'holiday',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ]
  },
  {
    isGroup: true,
    label: 'Workdays',
    items: [
      {
        placeholder: 'Workdays list SAT-SUN format dd.MM,dd.MM',
        type: 'text',
        id: 'movedWorkDay',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      }
    ]
  },

];
