// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import { reset } from 'redux-form/immutable';
import { formReset } from 'components/Graphs/Form/actions';
import { tableDataSuccess } from 'components/Graphs/Table/actions';
import {
  getAllRecommendationByTickerIDRequest,
  getRecommendationByIDRequest,
  editRecommendationRequest, deleteTickerByIdRequest, createRecommendationRequest,
} from '../../../redux/actions';
import normalize from './normalize';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { RECOMENDATION_FORM } from 'constants/forms';
import { TICKERS_RECOMENDATION_TABLE } from 'constants/tables';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectAllValueSets } from 'components/ValueSet/selector';
import { IBodyTable, IHeadTable } from '../../../../../components/Flows/Table/interface';
import StringCol from '../../../../../components/Flows/Elements/StringCol';
import { language } from '../../../../../utils/formatters';
import IconDelete from 'arui-feather/icon/action/delete';
import { closeDialog } from '../../../../../components/Flows/Dialog/actions';
import { LANGUAGE_EN, LANGUAGE_RU } from '../../../../../constants/languages';

interface ICompaniesForm {
  title?: string;
  form: string;
  match: any
}

class RecommendationForm extends React.Component<ICompaniesForm> {
  handleClick = () => {
    const { dispatch, id, ticketId }: any = this.props;
    dispatch(reset(RECOMENDATION_FORM));
    dispatch(formReset({ meta: { form: RECOMENDATION_FORM } }));
    //dispatch(getTickersByCompanyIDRequest({ meta: { graphId: ticketId, table: TICKERS_TABLE, callback: tableDataSuccess } }));
    dispatch(getAllRecommendationByTickerIDRequest({
      meta: {
        graphId: ticketId,
        table: TICKERS_RECOMENDATION_TABLE,
        callback: tableDataSuccess,
      },
    }));
    dispatch(closeDialog({ id: 'table.recommendation' }));
  };

  componentWillUnmount() {
    const { dispatch, id, form }: any = this.props;
    dispatch(reset(RECOMENDATION_FORM));
    dispatch(formReset({ meta: { form: RECOMENDATION_FORM } }));
  }


  get actions() {
    const { recommendationId }: any = this.props;
    const action = recommendationId ? 'Edit this recommendation' : 'Add new recommendation';
    return (
      <GridRow>
        <GridCol>
          <Button className={'button-float'} view="extra" size="s" type="submit">
            {action}
          </Button>
          <Button className={'mr20 button-float'} size="s" onClick={this.handleClick}>Cancel</Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = (data: any) => {
    const { recommendationId, ticketId, id }: any = this.props;
    if (recommendationId) {
      return {
        id: recommendationId,
        date: data.date,
        equityTickerId: ticketId,
        recommendation: [
          {
            language: { id: LANGUAGE_RU, name: 'Рус' },
            translatedValue: data.recommendationRU,
          },
          {
            language: { id: LANGUAGE_EN, name: 'En' },
            translatedValue: data.recommendationEN,
          },
        ],
        targetPrice: data?.targetPrice.toString(),
      };
    } else {
      return {
        ...data,
        date: data.date,
        equityTickerId: ticketId,
        recommendation: [
          {
            language: { id: LANGUAGE_RU, name: 'Рус' },
            translatedValue: data.recommendationRU,
          },
          {
            language: { id: LANGUAGE_EN, name: 'En' },
            translatedValue: data.recommendationEN,
          },
        ],
        targetPrice: data.targetPrice,
      };
    }
  };

  normalizeTableData = (head: IHeadTable[], body: IBodyTable[]): any => {
    let processedData = body && body.map((item, i) => {
      const { id, targetPrice, recommendation, stockTypeId }: any = item;
      let emptyWidth: any = '';
      return {
        id,
        cells: [
          {
            element: StringCol(false, i + 1),
            id,
            type: 'id',
            width: emptyWidth,
          },
          {
            element: language(recommendation, 'en'),
            id,
            type: 'recommendation',
            width: emptyWidth,
          },
          {
            element: targetPrice,
            id,
            type: 'targetPrice',
            width: emptyWidth,
          },
          {
            element: <IconDelete size={'s'}/>,
            id,
            type: 'delete',
            width: emptyWidth,
            customClass: 'delete-icon',
          },
        ],
      };
    }) || [];


    //CheckBoxCol(Boolean(active)),
    const normalizeData = processedData.map((val: any) => {
      let newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });

    return normalizeData;
  };

  render() {
    const { recommendationId }: any = this.props;
    const head = [
      {
        id: 'id',
        label: '№',
        style: { width: '1' },
      },
      {
        id: 'recommendation',
        label: 'Recommendation',
        style: { width: ' ' },
      },
      {
        id: 'targetPrice',
        label: 'Target Price',
        style: { width: ' ' },
      },
      {
        id: 'delete',
        type: 'delete',
        label: '',
        style: { width: '2' },
      },
    ];
    return (
        <div style={{padding: '20px'}}>
        <GraphForm
          form={RECOMENDATION_FORM}
          actions={this.actions}
          onSuccess={this.handleClick}
          graphId={recommendationId}
          init={getRecommendationByIDRequest}
          edit={editRecommendationRequest}
          create={createRecommendationRequest}
          submit={this.submit}
          normalize={normalize}
          title="Recommendation form"
          validate={() => {}}
          type={recommendationId ? 'edit' : 'create'}
          columns={1}
          config={config()}
          width="12"
          reInitialize
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  valueSetsAll: selectAllValueSets,
});

const withConnect = connect(mapStateToProps);

export default compose<any>(
  withConnect,
  withRouter,
)(RecommendationForm);
