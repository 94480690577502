// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import HeadForm from 'modules/MailingListSettings/pages/head/form';
import HeadTable from 'modules/MailingListSettings/pages/head/table';

const Head = ({id}: any) => {
  return (
    <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
      <HeadTable/>
      <HeadForm idProps={id}/>
    </GridRow>
  );
};

export default Head;