// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty, isArray, isEqual } from 'lodash';
import { createStructuredSelector } from 'reselect';

import FlowTable from 'components/Flows/Table';
import { selectValueSet } from 'components/ValueSet/selector';
import { code2display } from 'utils/code2display';
import {tableDataPaginationRequest, tableDataRequest} from './actions';
import {filterPage, makeSelectData, tablePage, tablePages, tablePageSize} from './selector';
import { IGraphTable } from './interface';
import { setValueSet } from '../../ValueSet/actions';
import {parseFilters} from "../../../utils/normalizeFilters";
import {createFilterProductRequest} from "../../../modules/Products/redux/actions";
import {PRODUCT_FILTER_FORM} from "../../../constants/forms";

class GraphForm extends React.PureComponent<IGraphTable> {
  state = {
    tableData: null,
  };

  componentDidMount() {
    const { head } = this.props;
    if (!isEmpty(head)) {
      this.getValueSetFile(head);
    }

    this.getTableData(this.props);
  }
// 2021-10-27 add
  Paginate = () => {
    const {
      dispatch,
      table,
      init,
      // graphId,
      tablePage,
      filterForm,
      selectCurrentFilter,
      makeSelectSortData,
    } = this.props;
    const currentPage = tablePage + 1;
    const filterData =
        selectCurrentFilter && selectCurrentFilter[filterForm]?.values;
    const filterActive = filterData ? filterData : [];
    const graphId = parseFilters(filterActive, makeSelectSortData);
    dispatch(
        tableDataPaginationRequest({
          type: '',
          meta: { table, init, currentPage },
          payload: graphId,
        }),
    );
  };

// 2022-10-05 традиционная пагинация для продуктов
  Paginate2 = (index: any) => {
    const pageSize = 10;
    const {
      dispatch,
      graphData,
      tablePageSize,
    } = this.props;
    let currentPage;
    if( graphData && graphData.length > 0)
      currentPage = index
    else {
      currentPage = 0
    }

    // здесь надо кроме прочего в хранилище GraphTable записать текущую страницу
    dispatch(createFilterProductRequest({payload:{}, meta:{form:PRODUCT_FILTER_FORM, force:true, page:currentPage, pageSize:tablePageSize}}));
  };




  cellNormalize(data: any, props: any) {
    if (!data || !props) {
      return null;
    }
    const { head } = props;
    return data.map((row: any) => {
      if (row.cells) {
        head.forEach((column: any, index: any) => {
          if (column.type === 'valueSet') {
            row.cells[index].element = isArray(row.cells[index].element)
              ? row.cells[index].element
                  // @ts-ignore
                  .map((item) =>
                    code2display(
                      // @ts-ignore
                      this.props[`@ValueSet:${column.id}`],
                      item,
                      column,
                    ),
                  )
                  // @ts-ignore
                  .join(', ')
              : code2display(
                // @ts-ignore
                  this.props[`@ValueSet:${column.id}`],
                  row.cells[index].element,
                  column,
                );
          }
        });
      }
      return row;
    });
  }

  checkUpdateValueSet(props: any) {
    if (!props || !props.head) {
      return null;
    }
    const { head } = props;
    return head.some(
      (item: any) =>
        item.type === 'valueSet' &&
        item.id &&
        // @ts-ignore
        !isEqual(
          // @ts-ignore
          this.props[`@ValueSet:${item.id}`],
          props[`@ValueSet:${item.id}`],
        ),
    );
  }

  componentDidUpdate(prevProps: any) {
    const { head, graphData, normalizeTableData }: any = this.props;

    if (this.checkUpdateValueSet(prevProps)) {
      const tableData = graphData
        ? this.cellNormalize(normalizeTableData(head, graphData), this.props)
        : [];
      this.setState({ tableData });
    }

    if (this.props.graphData !== prevProps.graphData) {
      const tableData = graphData
        ? this.cellNormalize(normalizeTableData(head, graphData), this.props)
        : [];
      this.setState({ tableData });
    }

    if (this.props.graphId !== prevProps.graphId) {
      this.getTableData(this.props);
    }
  }

  getValueSetFile(dataHead: any) {
    const { dispatch } = this.props;
    dataHead.forEach((column: any) => {
      if (column.type && column.type === 'valueSet' && column.action) {
        dispatch(
          column.action({ meta: { form: column.id, callback: setValueSet } }),
        );
      }
    });
  }

  getTableData({ dispatch, table, init, graphId }: IGraphTable) {
    const type = '';
    dispatch(tableDataRequest({ type, meta: { table, init, graphId } }));
  }

  render() {
    const {
      head,
      normalizeTableData,
      width,
      gutter,
      graphData,
      createDialogs,
      ...rest
    }: IGraphTable = this.props;
    const { tableData = [] }: any = this.state;
    return (
      <>
        {createDialogs}
        <FlowTable
          width={width}
          normalizeTableData={normalizeTableData}
          data={tableData}
          head={head}
          body={graphData}
          gutter={gutter}
          Paginate={this.Paginate}
          Paginate2={this.Paginate2}
          tablePages={{currentPage: this.props.tablePage, ...this.props.tablePages}}
          currentPage = {this.props.tablePage}
          {...rest}
        />
      </>
    );
  }
}

const constructorValueSetProps = (ownProps: any) => {
  if (!ownProps || !ownProps.head) {
    return null;
  }
  const { head } = ownProps;

  const resObject = {};

  head.forEach((item: any) => {
    if (item.type === 'valueSet' && item.id) {
      // @ts-ignore
      resObject[`@ValueSet:${item.id}`] = selectValueSet(item.id);
    }
  });
  return resObject;
};

const mapStateToProps = (state: any, ownProps: any) => {
  const defaultProps = {
    graphData: makeSelectData(),
// 2021-10-27 add
    tablePage: tablePage(),
    tablePageSize:  tablePageSize(),
    tablePages: tablePages(),
    selectCurrentFilter: filterPage(),
  };
  return createStructuredSelector(
    Object.assign(defaultProps, constructorValueSetProps(ownProps)),
  );
};

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(GraphForm);
