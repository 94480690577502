import UsersRolesView from './pages/view';
import UsersRolesEdit from './pages/edit';
export default [
  {
    type: 'private',
    exact: true,
    path: '/users-roles',
    roles: ['admin'],
    component: UsersRolesView,
  },
  {
    type: 'private',
    exact: true,
    path: '/users-roles/edit/:id',
    roles: ['admin'],
    component: UsersRolesEdit,
  },
];
