// @ts-nocheck
import React from 'react';
import './DashBoardBestIndicators.scss';

const SvgWhiteDownload = (): any => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 16.419V2.33324H11V16.419L5.20711 10.6261L4.5 11.3332L11.5 18.3332L18.5 11.3332L17.7929 10.6261L12 16.419Z" fill="white"/>
            <path d="M20 22.3332V21.3332H3V22.3332H20Z" fill="white"/>
        </svg>
    )
}

class IndicatorPanel extends React.Component {
    calculateLayout( blocks, width, height ) {
        const colors = [
            '#525E6C', '#6A747F', '#818991', '#999EA3','#B0B3B6',
        ]

        //console.log('calculateLayout =', blocks)
        let blocksRes = [];
        let id = 0;
        const blockWidth = width / blocks.length
        for( let column = 0; column < blocks.length; column++) {
            const colBlocks = blocks[column]
            // console.log(height, colBlocks.length )
            const blockHeight = height / colBlocks.length
            for( let row = 0; row < colBlocks.length; row++) {
                const block = colBlocks[row]
                const left = column * blockWidth;
                const top  = row * blockHeight;
                // console.log('Block ', block, left, top, blockWidth, blockHeight )
                blocksRes.push({ id, block, left, top, width: blockWidth, height: blockHeight, backgroundColor: colors[column]})
                id++;
            }

        }
        return blocksRes;
    }


    calculateBlocks(data, numColumns) {
       // console.log('calculateBlocks data=', data )
       const ranges4 = [
           { min: 0,  max: 25, column: 0},
           { min: 25, max: 50, column: 1},
           { min: 50, max: 75, column: 2},
           { min: 75, max: 100, column: 3},
       ];
        const ranges3 = [
            { min: 0,  max: 33, column: 0},
            { min: 33, max: 66, column: 1},
            { min: 66, max: 100, column: 2},
        ];
        const maxRows = 6
        let curRow = 0;
        let ranges
        //console.log('calculateBlocks numColumns=', numColumns )
        if( numColumns === 3)
          ranges = ranges3
        else
          ranges = ranges4

       let procent = 0;
       let column = 0;

       let blocks = [];
       blocks.push([]);
//  с минимального значения лучше разместится в прямоугольнике

       for( let i = data.length - 1; i >=0; i--) {
           const item = data[i];
           // console.log('----------------------------------')
           // console.log('Calculate Block item=', item )
           const block = blocks[column]
           block.push( item )
           const range = ranges[column]
           procent += item.procent;
           // console.log('Calculate Block =', column, range.column, item.procent, procent, range, range.max )

          if(  column < (numColumns-1) && (procent > range.max || ++curRow > maxRows )) {
              // console.log('New column', procent,range.max ,curRow, maxRows )
              blocks.push([]);
              column++
              procent = range.max
              curRow = 0
          }

       }
// перевернем матрицу от максимального к минимальному значению
       let resBlocks = [];
       for( let column = blocks.length-1; column >=0; column--) {
           const blockRow = blocks[column]
//           console.log('!!!!resBlocks =', blockRow)
           let resBlocksRow = []
           for( let row = blockRow.length-1; row >=0; row--) {
               resBlocksRow.push(blockRow[row])
           }
           //console.log('Push blockRow =', resBlocksRow)
           if(resBlocksRow.length > 0)
             resBlocks.push(resBlocksRow)
       }
       //console.log('resBlocks = ', resBlocks )
       return resBlocks;
    }

    calculate(data, width, height, numColumns) {
       if(!data) return null
       let totalValue = data.reduce(function(sum, current) {
            return sum + current.value;
       }, 0);
       data.forEach(item => {
           item.procent = item.value * 100 / totalValue
       })
       //console.log('calculateBlock data=', totalValue, data)
        const blocks =  this.calculateBlocks( data, numColumns )
       // console.log('calculated blocks = ', blocks)
       const blocksRes = this.calculateLayout( blocks, width, height )
       // console.log('blockRes = ', blocksRes)
       return blocksRes;
    }

    render() {
        const {title}: any = this.props;
        let data = []
        // console.log('this.props =', this.props )
        let numColumns
        switch( title ) {
            case 'Topics':
                   data = (this.props.data) ? this.props.data.products : null;
                   numColumns = 4
                   break;
            case 'Analysts':
                data = (this.props.data) ? this.props.data.authors : null;
                numColumns = 3
                break;
            case 'Companies':
                data = (this.props.data) ? this.props.data.companies : null;
                numColumns = 3
                break;
            case 'Readers':
                data = (this.props.data) ? this.props.data.clients : null;
                numColumns = 4
                break;
            case 'Products type':
                data = (this.props.data) ? this.props.data.productTypes : null;
                numColumns = 4
                break;
        }
        // const blocks = this.calculate(data,1092,740)
        const blocks = this.calculate(data,1092,740, numColumns )
        return (
            <>
                <div className={'dashboard-best-indicators'} style={{position:'relative' }}>
                    { blocks && blocks.length > 0 && blocks.map( block => {
                        // console.log('block ', block)
                        return (
                            <div className={'dash-best-block'} key={block.id} style={{ background: block.backgroundColor,
                                left: block.left, top:block.top, width:block.width, height:block.height}}>
                                {title === 'Topics' &&
                                <>
                                <div className={'dash-best-value-box'}>
                                    <div>
                                        <SvgWhiteDownload/>
                                        {/*<IconDownload size={'m'}></IconDownload>*/}
                                    </div>
                                    <div className={'dash-best-value'} style={{position: 'absolute', right: '25px'}}>
                                        {block.block.value}
                                    </div>
                                </div>
                                    <div className={'dash-best-label'}>
                                    {block.block.name}
                                    </div>
                                </>
                                }
                                {title === 'Products type' &&
                                <>
                                    <div className={'dash-best-value-box'}>
                                        <div>
                                            <SvgWhiteDownload/>
                                            {/*<IconDownload size={'m'}></IconDownload>*/}
                                        </div>
                                        <div className={'dash-best-value'} style={{position: 'absolute', right: '25px'}}>
                                            {block.block.value}
                                        </div>
                                    </div>
                                    <div className={'dash-best-producttype-label'}>
                                        {block.block.name}
                                    </div>
                                </>
                                }
                                {title === 'Readers' &&
                                <>
                                    <div className={'dash-best-value-box-center'}>
                                        <div style={{ marginLeft: '8px'}} >
                                            <SvgWhiteDownload/>
                                            {/*<IconDownload size={'m'}></IconDownload>*/}
                                        </div>
                                        <div className={'dash-best-value-center'} style={{position: 'absolute', right: '27px', top:'3px'}}>
                                            {block.block.value}
                                        </div>
                                    </div>
                                    <div className={'dash-best-label-center'}>
                                        <span className={'dash-best-label-analyst'}>{block.block.name}</span>
                                    </div>
                                </>
                                }
                                {title === 'Analysts' &&
                                <>
                                    <div className={'dash-best-value-box-center'}>
                                        <div style={{ marginLeft: '8px'}} >
                                            <SvgWhiteDownload/>
                                        </div>
                                        <div className={'dash-best-value-center'} style={{position: 'absolute', right: '27px', top:'3px'}}>
                                            {block.block.value}
                                        </div>
                                    </div>
                                    <div className={'dash-best-label-center'} style={{ marginTop: '-30px'}}>
                                        <div style={{  display:'inline-block', width:'36px', height:'36px'}} >
                                            <img
                                                className="img-fluid"
                                                src={`${block.block.img}`}
                                            />
                                        </div>
                                        <div style={{ display:'inline-block', marginLeft: '16px'}}>
                                        <span className={'dash-best-label-analyst'}>{block.block.name}</span>
                                        <br/>
                                        <span className={'dash-best-label-analyst-products'}>Num products : {block.block.numProducts}</span>
                                        </div>
                                    </div>
                                </>
                                }
                                {title === 'Companies' &&
                                <>
                                    <div className={'dash-best-value-box-center'}>
                                        <div style={{ marginLeft: '8px'}} >
                                            <SvgWhiteDownload/>
                                        </div>
                                        <div className={'dash-best-value-center'} style={{position: 'absolute', right: '27px', top:'3px'}}>
                                            {block.block.value}
                                        </div>
                                    </div>
                                    <div className={'dash-best-label-center'} style={{ marginTop: '-30px'}}>
                                        <div style={{  display:'inline-block', width:'36px', height:'36px'}} >
                                            {block.block.img != null &&
                                            <img
                                                className="img-fluid"
                                                src={`${block.block.img}`}
                                            />
                                            }
                                        </div>
                                        <div style={{ display:'inline-block', marginLeft: '16px'}}>
                                            <span className={'dash-best-label-analyst'}>{block.block.name}</span>
                                            <br/>
                                            <span className={'dash-best-label-analyst-products'}>Views: {block.block.numLoads}</span>
                                        </div>
                                    </div>
                                </>
                                }

                            </div>
                        )
                    } )
                    }
                </div>
            </>
        );
    }
}

export default IndicatorPanel;

