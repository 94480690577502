export const GET_ALL_MAILING_TYPE_REQUEST = 'GET_ALL_MAILING_TYPE_REQUEST'
export const MAILING_TYPE_SUCCESS = 'MAILING_TYPE_SUCCESS'
export const MAILING_TYPE_FAILURE = 'MAILING_TYPE_FAILURE'

export const GET_ALL_HEAD_REQUEST = 'GET_ALL_HEAD_REQUEST';
export const CREATE_NEW_HEAD_REQUEST = 'CREATE_NEW_HEAD_REQUEST'
export const EDIT_HEAD_REQUEST = 'EDIT_HEAD_REQUEST'
export const GET_HEAD_BY_ID_REQUEST = 'GET_HEAD_BY_ID_REQUEST'
export const DELETE_HEAD_BY_ID_REQUEST = 'DELETE_HEAD_BY_ID_REQUEST'


export const GET_ALL_BODY_REQUEST = 'GET_ALL_BODY_REQUEST'
export const CREATE_NEW_BODY_REQUEST = 'CREATE_NEW_BODY_REQUEST'
export const EDIT_BODY_REQUEST = 'EDIT_BODY_REQUEST'
export const GET_BODY_BY_ID_REQUEST = 'GET_BODY_BY_ID_REQUEST'
export const DELETE_BODY_BY_ID_REQUEST = 'DELETE_BODY_BY_ID_REQUEST'



export const GET_ALL_FOOTER_REQUEST = 'GET_ALL_FOOTER_REQUEST'
export const CREATE_NEW_FOOTER_REQUEST = 'CREATE_NEW_FOOTER_REQUEST'
export const EDIT_FOOTER_REQUEST = 'EDIT_FOOTER_REQUEST'
export const GET_FOOTER_BY_ID_REQUEST = 'GET_FOOTER_BY_ID_REQUEST'
export const DELETE_FOOTER_BY_ID_REQUEST = 'DELETE_FOOTER_BY_ID_REQUEST'



export const CREATE_NEW_MAILING_TYPE_REQUEST = 'CREATE_NEW_MAILING_TYPE_REQUEST'
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE'

export const GET_MAILING_TYPE_BY_ID_REQUEST = 'GET_MAILING_TYPE_BY_ID_REQUEST'
export const DELETE_MAILING_TYPE_BY_ID_REQUEST = 'DELETE_MAILING_TYPE_BY_ID_REQUEST'


export const CATEGORY_SUCCESS = 'CATEGORY_SUCCESS'
export const CATEGORY_FAILURE = 'CATEGORY_FAILURE'

export const EDIT_MAILING_TYPE_REQUEST = 'EDIT_MAILING_TYPE_REQUEST'