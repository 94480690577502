// @ts-nocheck
import React from 'react';

export default [
  {
    type: 'text',
    id: 'username',
    label: 'Login',
    placeholder: 'Your login here',
    view: 'filled',
    size: 'm',
    width: 'available',
    bigLabel: false,
  },
  {
    type: 'password',
    id: 'password',
    label: 'Password',
    placeholder: 'Your password here',
    view: 'filled',
    size: 'm',
    width: 'available',
    bigLabel: false,
  },
  {
    type: 'checkbox',
    id: 'active',
    text: 'Remember me on this computer',
    bigLabel: false,
  },
];
