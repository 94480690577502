// @ts-nocheck
import React from 'react';
import GraphTable from 'components/Graphs/Table';
import { ICategoryTable, IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import { INFO_TABLE } from 'constants/tables';
import {
  deleteFooterByIdRequest,
  getAllFooterRequest,
} from 'modules/MailingListSettings/redux/actions';
import StringCol from 'components/Flows/Elements/StringCol';
import IconDelete from 'arui-feather/icon/action/delete/delete';
import { connect } from 'react-redux';

class FooterTable extends React.Component<ICategoryTable> {

  normalizeTableData = (head: IHeadTable[], body: IBodyTable[]): any => {
    let processedData = body && body.map((item, i) => {
      const {
        id, name
      }: any = item;
      let emptyWidth: any = '';
      return {
        id,
        cells: [
          {
            element: StringCol(false, i + 1),
            id,
            type: 'id',
            width: emptyWidth,
          },
          {
            element: name,
            id,
            type: 'name',
            width: emptyWidth,
          },
          {
            element: <IconDelete size={'s'}/>,
            id,
            type: 'delete',
            width: emptyWidth,
            customClass: 'delete-icon',
          },
        ],
      };
    }) || [];

    const normalizeData = processedData.map((val: any) => {
      let newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });

    return normalizeData;
  };

  getRowLink = (id: any) => `/mailing-list-settings/footer/${id}`;

  handleDelete = (id: string) => {
    const { dispatch }: any = this.props;
    dispatch(deleteFooterByIdRequest({ payload: { id } }));
  };

  render() {
    const { handleRowClick }: any = this.props;
    const head = [
      {
        id: 'id',
        label: '№',
        style: { width: '2' },
      },
      {
        id: 'name',
        label: 'Name',
        style: { width: ' ' },
      },
      {
        id: 'delete',
        type: 'delete',
        label: '',
        style: { width: '2' },
      },

    ];

    return (
      <GraphTable
        title="Footer templates table"
        table={INFO_TABLE}
        normalizeTableData={this.normalizeTableData}
        init={getAllFooterRequest}
        onRowClick={handleRowClick}
        head={head}
        onDelete={(id: any) => this.handleDelete(id)}
        link={this.getRowLink}
        width={'12'}
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
        activeLink
      />
    );
  }
}
export default connect()(FooterTable);
