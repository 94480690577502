export const getStylesForButton = ({ key }: any) => {
  switch (key) {
    case 'flr':
      return { color: 'red', fontSize: '18.0pt', fontFamily: "Arial", fontWeight: 'bold' }
    case 'flb':
      return { color: 'black', fontSize: '18.0pt', fontFamily: "Arial", fontWeight: 'bold' }
    case 'slr':
      return { color: 'red', fontSize: '14.0pt', fontFamily: "Arial", fontWeight: 'bold' }
    case 'slb':
      return { color: 'black', fontSize: '14.0pt', fontFamily: "Arial", fontWeight: 'bold' }
    case 'btb':
      return { color: 'black', fontSize: '11.0pt', fontFamily: "Arial", fontWeight: 'bold' }
    case 'bt':
      return { color: 'black', fontSize: '11.0pt', fontFamily: "Arial" }
    default:
      return {}
  }
}