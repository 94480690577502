export const GET_ALL_CLIENTS_REQUEST = 'GET_ALL_CLIENTS_REQUEST'
export const GET_ALL_CLIENTS_SUCCESS = 'GET_ALL_CLIENTS_SUCCESS'
export const GET_ALL_CLIENTS_FAILURE = 'GET_ALL_CLIENTS_FAILURE'

export const CREATE_NEW_CLIENTS_REQUEST = 'CREATE_NEW_CLIENTS_REQUEST'

export const GET_CLIENTS_BY_ID_REQUEST = 'GET_CLIENTS_BY_ID_REQUEST'

export const DELETE_CLIENTS_BY_ID_REQUEST = 'DELETE_CLIENTS_BY_ID_REQUEST'

export const EDIT_CLIENTS_REQUEST = 'EDIT_CLIENTS_REQUEST'
export const EDIT_CLIENTS_FAILURE = 'EDIT_CLIENTS_FAILURE'

export const GET_PROFILE_CLIENT = 'GET_PROFILE_CLIENT'
export const EDIT_PROFILE_CLIENT = 'EDIT_PROFILE_CLIENT'
// 2021-07-26
export const STORE_CURRENT_CLIENT = 'STORE_CURRENT_CLIENT'

// 2022-11-14
export const SET_SECTORS_CLIENTS_CUSTOM = 'SET_SECTORS_CLIENTS_CUSTOM'
