// @ts-nocheck
import React from 'react';
import { withRouter } from 'react-router-dom'
import LawFileForm from './form/basic';
import { Route, Switch } from 'react-router';


const EmailEdit = ({ history, location: { pathname }, match: { params: { id, stockId } }  }: any) => {
  const baseUrl = `/email/edit/${id}`
  return (
    <>
      <Switch>
        <Route exact path={baseUrl}>
          <LawFileForm id={id} />
        </Route>
      </Switch>
    </>
  );
};

export default withRouter(EmailEdit);