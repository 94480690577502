// @ts-nocheck
import React from 'react';
import { language } from 'utils/formatters'
import { productTypeRequest } from 'modules/Catalog/ProductTypes/redux/actions';
import {getAllcategoriesRequest} from 'modules/Catalog/Categories/redux/actions'
import {
  getAllHeadRequest,
  getAllBodyRequest, getAllFooterRequest,
} from 'modules/MailingListSettings/redux/actions';
import { change } from 'redux-form';
import { MAILING_LIST_SETTINGS_FORM, PRODUCT_OPTION_FORM } from '../../../../../constants/forms';
export default (values: any, list: any) => [
  {
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 10,
      className:'col-6-selector'
    },
    type: 'ValueSet',
    id: 'categoryId',
    placeholder: 'Category',
    label: 'Category',
    view: 'filled',
    width: 'available',
    size: 's',
    filter: (data: any) => {
      if (values && values.productTypeId) {
        const { categories } = list?.find(({ id }: any) => id === values.productTypeId)
        return categories.includes(data.id)
      }
    },
    itemOptions: (item: any) => !item.serviceCategory && item.active && ({ value: item.id, text: language(item.name,'en') }),
    action: getAllcategoriesRequest,
  },
  {
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 10,
      className:'col-6-selector'
    },
    type: 'ValueSet',
    id: 'productTypeId',
    placeholder: 'Product type',
    label: 'Product type',
    view: 'filled',
    width: 'available',
    size: 's',
    handleOnChange: (props: any, dispatch: any) => {
      if (list && list.length) {
        const categoriesList = list?.find((productType: any) => productType.id === props)
        dispatch(change(MAILING_LIST_SETTINGS_FORM, 'categoryId', categoriesList?.categories?.[0]))
      }
    },
    itemOptions: (item: any) => ({ value: item.id, text: language(item.name, 'en') }),
    action: productTypeRequest
  },
  {
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 10,
      className:'col-6-selector'
    },
    type: 'ValueSet',
    id: 'headerId',
    placeholder: 'Head',
    label: 'Head',
    view: 'filled',
    width: 'available',
    size: 's',
    itemOptions: (item: any) => ({ value: item.id, text: item.name}),
    action: getAllHeadRequest,
  },
  {
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 10,
      className:'col-6-selector'
    },
    type: 'ValueSet',
    id: 'bodyId',
    placeholder: 'Body',
    label: 'Body',
    view: 'filled',
    width: 'available',
    size: 's',
    multiple: true,
    itemOptions: (item: any) => ({ value: item.id, text: item.name}),
    action: getAllBodyRequest,
  },
  {
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 10,
      className:'col-6-selector'
    },
    type: 'ValueSet',
    id: 'footerAccId',
    placeholder: 'Footer ACC',
    label: 'Footer ACC',
    view: 'filled',
    width: 'available',
    size: 's',
    itemOptions: (item: any) => ({ value: item.id, text: item.name}),
    action: getAllFooterRequest,
  },
  {
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 10,
      className:'col-6-selector'
    },
    type: 'ValueSet',
    id: 'footerMifidId',
    placeholder: 'Footer',
    label: 'Footer',
    view: 'filled',
    width: 'available',
    size: 's',
    itemOptions: (item: any) => ({ value: item.id, text: item.name}),
    action: getAllFooterRequest,
  },
  {
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 10,
      className:'col-6-selector'
    },
    isGroup: true,
    label: 'Name',
    items: [
      {
        placeholder: 'Name',
        type: 'text',
        id: 'name',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
];
