export const GET_ALL_AUTHORTYPES_REQUEST = 'GET_ALL_AUTHORTYPES_REQUEST'
export const GET_ALL_AUTHORTYPES_SUCCESS = 'GET_ALL_AUTHORTYPES_SUCCESS'
export const GET_ALL_AUTHORTYPES_FAILURE = 'GET_ALL_AUTHORTYPES_FAILURE'

export const CREATE_NEW_AUTHORTYPES_REQUEST = 'CREATE_NEW_AUTHORTYPES_REQUEST'
export const CREATE_NEW_AUTHORTYPES_SUCCESS = 'CREATE_NEW_AUTHORTYPES_SUCCESS'
export const CREATE_NEW_AUTHORTYPES_FAILURE = 'CREATE_NEW_AUTHORTYPES_FAILURE'

export const GET_AUTHORTYPES_BY_ID_REQUEST = 'GET_AUTHORTYPES_BY_ID_REQUEST'
export const GET_AUTHORTYPES_BY_ID_SUCCESS = 'GET_AUTHORTYPES_BY_ID_SUCCESS'
export const GET_AUTHORTYPES_BY_ID_FAILURE = 'GET_AUTHORTYPES_BY_ID_FAILURE'

export const EDIT_AUTHORTYPES_REQUEST = 'EDIT_AUTHORTYPES_REQUEST'