import { getAllEventTypesRequest } from 'modules/Catalog/EventTypes/redux/actions';
import { getAllCountryRequest } from 'modules/Catalog/Countries/redux/actions';
import { getAllCompaniesRequest } from 'modules/Companies/redux/actions';
import { language } from '../../../../utils/formatters';

export default [
  {
    isGroup: true,
    label: 'Event name',
    fieldWidth: '12',
    customPosition: {
      label: 1,
      field: 11,
      className: 'col-11-calendar',
    },
    items: [
      {
        placeholder: 'EN',
        type: 'text',
        id: 'nameEN',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU',
        type: 'text',
        id: 'nameRU',
        view: 'filled',
        width: 'available',
        size: 's',
      },
    ],
  },
  {
    isGroup: true,
    fieldWidth: '12',
    customPosition: {
      label: 1,
      field: 11,
      className: 'col-11-calendar',
    },
    label: 'Event comment',
    items: [
      {
        placeholder: 'EN',
        type: 'text',
        id: 'commentEN',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU',
        type: 'text',
        id: 'commentRU',
        view: 'filled',
        width: 'available',
        size: 's',
      },
    ],
  },
  {
    type: 'ValueSet',
    id: 'eventTypeId',
    fieldWidth: '4',
    placeholder: 'Event type',
    label: 'Event type',
    view: 'filled',
    width: 'available',
    size: 's',
    itemOptions: (item: any) => ({
      value: item.id,
      text: language(item.name, 'en'),
    }),
    action: getAllEventTypesRequest,
  },
  {
    type: 'ValueSet',
    id: 'companies',
    fieldWidth: '4',
    placeholder: 'Companies',
    label: 'Companies',
    view: 'filled',
    width: 'available',
    size: 's',
    multiple: true,
    itemOptions: (item: any) => ({
      value: item.id,
      text: language(item.name, 'en'),
    }),
    action: getAllCompaniesRequest,
  },
  {
    type: 'ValueSet',
    id: 'country',
    fieldWidth: '4',
    placeholder: 'Country',
    label: 'Country',
    view: 'filled',
    width: 'available',
    size: 's',
    itemOptions: (item: any) => ({
      value: item.id,
      text: language(item.name, 'en'),
    }),
    action: getAllCountryRequest,
  },
  {
    type: 'date',
    id: 'startDate',
    fieldWidth: '4',
    placeholder: 'DD.MM.YYYY',
    label: 'Start event',
    view: 'filled',
    width: 'available',
    size: 's',
  },
  {
    fieldWidth: '8',
    type: 'gridCol',
  },
  {
    type: 'date',
    id: 'endDate',
    fieldWidth: '4',
    label: 'End event',
    placeholder: 'DD.MM.YYYY',
    width: 'available',
    view: 'filled',
    size: 's',
  },
];
