// @ts-nocheck
import { createSelector } from 'reselect';
import {
    AUTHORS_TABLE,
    CATALOG_PRODUCT_TYPE_TABLE,
    CLIENTS_TABLE,
    COMPANIES_TABLE,
    CATALOG_WORK_CALENDAR_TABLE
} from "../../../constants/tables";

const selectGraphTable = (state: any) => state.graphTable;

export const makeSelectDataDashBoard = () => createSelector(
    selectGraphTable,
    graphTableState => graphTableState && graphTableState.get('DASHBOARD_TABLE'),
);
export const makeSelectDataDashBoardPrev = () => createSelector(
    selectGraphTable,
    graphTableState => graphTableState && graphTableState.get('DASHBOARD_TABLE_PREV'),
);

export const makeSelectProduct = () => createSelector(
    selectGraphTable,
    graphTableState => graphTableState && graphTableState.get('PRODUCTS_TABLE'),
);

export const makeSelectProductType = () => createSelector(
    selectGraphTable,
    graphTableState => graphTableState && graphTableState.get(CATALOG_PRODUCT_TYPE_TABLE),
);

export const makeSelectAuthor = () => createSelector(
    selectGraphTable,
    graphTableState => graphTableState && graphTableState.get(AUTHORS_TABLE),
);

export const makeSelectCompany = () => createSelector(
    selectGraphTable,
    graphTableState => graphTableState && graphTableState.get(COMPANIES_TABLE),
);

export const makeSelectClient = () => createSelector(
    selectGraphTable,
    graphTableState => graphTableState && graphTableState.get(CLIENTS_TABLE),
);

export const makeWorkCalendar = () => createSelector(
    selectGraphTable,
    graphTableState => graphTableState && graphTableState.get(CATALOG_WORK_CALENDAR_TABLE),
);
