import AuthorTypes from './pages';

export default [
  {
    type: 'private',
    exact: true,
    path: '/author-types',
    roles: ['admin'],
    component: AuthorTypes,
  },
  {
    type: 'private',
    exact: true,
    path: '/author-types/:id',
    roles: ['admin'],
    component: AuthorTypes,
  },
];
