import React, { FC } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';

import { createStructuredSelector } from 'reselect';
import { makeSelectToken } from './selectors';

import PublicRoute from 'app/Routes/public';
import PrivateRoute from 'app/Routes/private';
import NotFound from 'app/NotFound';

import routes from 'app/rootRoutes';

// переменная для временного хранения идентификаторов новых вставленных записей
let global_id = 0;
export const get_global_id = () => {
    return global_id;
}
export const set_global_id = (id : number) => {
    global_id = id;
}


const App: FC<any> = () => (
    <Switch>
      {routes &&
      routes.map((item, index) => {
        switch (item.type) {
          case 'public':
            return <PublicRoute key={index} {...item} />;
          case 'private':
            return <PrivateRoute key={index} {...item} />;
          default:
            return <Route render={() => <NotFound />} />;
        }
      })}
    </Switch>
);

const mapStateToProps = createStructuredSelector({
  token: makeSelectToken(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(App);
