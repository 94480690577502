import * as ACTION_TYPE from './constants';

export const getAllEventTypesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_EVENTTYPES_REQUEST,
  payload,
  meta,
});

export const createNewEventTypesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_EVENTTYPES_REQUEST,
  payload,
  meta,
});

export const editEventTypesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_EVENTTYPES_REQUEST,
  payload,
  meta,
})

export const getEventTypesByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_EVENTTYPES_BY_ID_REQUEST,
  payload,
  meta,
})