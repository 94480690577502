import {
    GET_UPLOAD_URL_FAILURE,
    GET_UPLOAD_URL_REQUEST,
    GET_UPLOAD_URL_SUCCESS,

    FILE_UPLOAD_FAILURE,
    FILE_UPLOAD_REQUEST,
    FILE_UPLOAD_SUCCESS,

    FILE_GET_REQUEST,
    FILE_GET_SUCCESS,
    FILE_GET_FAILURE,

    FILE_URL_UPDATE_REQUEST,
    FILE_URL_UPDATE_SUCCESS,
    FILE_URL_UPDATE_FAILURE,

    FILE_INPUT_INIT,
} from './constants';


export const getUploadUrlRequest = item => ({
    type: GET_UPLOAD_URL_REQUEST, payload: item,
});

export const getUploadUrlSuccess = item => ({
    type: GET_UPLOAD_URL_SUCCESS, payload: item,
});

export const getUploadUrlFailure = item => ({
    type: GET_UPLOAD_URL_FAILURE, payload: item,
});


export const fileUploadRequest = item => ({
    type: FILE_UPLOAD_REQUEST, payload: item,
});

export const fileUploadSuccess = item => ({
    type: FILE_UPLOAD_SUCCESS, payload: item,
});

export const fileUploadFailure = item => ({
    type: FILE_UPLOAD_FAILURE, payload: item,
});


export const fileGetRequest = item => ({
    type: FILE_GET_REQUEST, payload: item,
});

export const fileURLUpdateRequest = (item, callback) => ({
    type: FILE_URL_UPDATE_REQUEST, payload: item, callback,
});

export const fileURLUpdateSuccess = item => ({
    type: FILE_URL_UPDATE_SUCCESS, payload: item,
});

export const fileURLUpdateFailure = item => ({
    type: FILE_URL_UPDATE_FAILURE, payload: item,
});

export const fileGetSuccess = item => ({
    type: FILE_GET_SUCCESS, payload: item,
});

export const fileGetFailure = item => ({
    type: FILE_GET_FAILURE, payload: item,
});


export const fileInputInit = item => ({
    type: FILE_INPUT_INIT, payload: item,
});
