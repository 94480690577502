import * as ACTION_TYPE from './constants';
//ProductType
export const productTypeRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_PRODUCT_TYPE_REQUEST,
  payload,
  meta,
});

export const createProductTypeRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_PRODUCT_TYPE_REQUEST,
  payload,
  meta,
});

export const editProductTypeRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_PRODUCT_TYPE,
  payload,
  meta,
});

export const categoryRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.PRODUCT_TYPE_REQUEST_ID,
  payload,
  meta,
});