// @ts-nocheck
import React from 'react';
import { language } from 'utils/formatters'
import { getAllSectorsRequest } from 'modules/Catalog/Sectors/redux/actions'
import {getAllUsersRolesRequest} from "../../../UsersRoles/redux/actions";
import {getAllProductsFastRequest, getAllProductsRequest} from "../../../Products/redux/actions";
import {getAllTypeStatistic} from "../../redux/actions";
import {productTypeRequest} from "../../../Catalog/ProductTypes/redux/actions";
import {getAllcategoriesRequest} from "../../../Catalog/Categories/redux/actions";
import {getAllLanguagesRequest} from "../../../Catalog/Languages/redux/actions";
import {getAllClientsRequest} from "../../../Client/redux/actions";
import {getAllCompaniesRequest} from "../../../Companies/redux/actions";
/*
export default [
  {
    type: 'date',
    id: 'dateFrom',
    label: 'From Date',
    view: 'filled',
    width: 'available',
    size: 's',
  },
  {
    type: 'date',
    id: 'dateTo',
    label: 'To Date',
    view: 'filled',
    width: 'available',
    size: 's',
  },
  {
    type: 'ValueSet',
    id: 'user',
    placeholder: 'User',
    label: 'Users',
    view: 'filled',
    width: 'available',
    size: 's',
    // multiple: true,
//    itemOptions: (item: any) => ({ value: item.id, text:item.username + ":" + item.name}),
    itemOptions: (item: any) => ({ value: item.id, text: item.name}),
    action: getAllUsersRolesRequest,
  },
  {
    type: 'ValueSet',
    id: 'product',
    placeholder: 'Product',
    label: 'Products',
    view: 'filled',
    width: 'available',
    size: 's',
    // multiple: true,
    itemOptions: (item: any) => ({ value: item.id, text: item.name}),
    action: getAllProductsFastRequest,
  },
];
*/
export default [
  {
    type: 'date',
    id: 'dateFrom',
    label: 'From Date',
    view: 'filled',
    width: 'available',
    size: 's',
    fieldWidth: '4',

  },

  {
    type: 'date',
    id: 'dateTo',
    label: 'To Date',
    view: 'filled',
    width: 'available',
    size: 's',
    fieldWidth: '4',
  },

  {
    type: 'ValueSet',
    id: 'type',
    placeholder: 'Type',
    label: 'Type',
    view: 'filled',
    width: 'available',
    size: 's',
    // multiple: true,
    itemOptions: (item: any) => ({ value: item.id, text: item.name}),
    action: getAllTypeStatistic,
    fieldWidth: '4',
  },
  {
    type: 'ValueSet',
    id: 'user',
    placeholder: 'User',
    label: 'User',
    view: 'filled',
    width: 'available',
    size: 's',
    // multiple: true,
//    itemOptions: (item: any) => ({ value: item.id, text:item.username + ":" + item.name}),
    itemOptions: (item: any) => ({ value: item.id, text: item.name}),
    action: getAllUsersRolesRequest,
    fieldWidth: '4',
  },
/*
  {
    fieldWidth: '1',
    type: 'gridCol',
  },
*/
  {
    type: 'ValueSet',
    id: 'product',
    placeholder: 'Product',
    label: 'Product',
    view: 'filled',
    width: 'available',
    size: 's',
    // multiple: true,
    itemOptions: (item: any) => ({ value: item.id, text: item.name}),
    action: getAllProductsFastRequest,
    fieldWidth: '4',
  },
  {
    type: 'ValueSet',
    id: 'language',
    placeholder: 'Language',
    label: 'Language',
    view: 'filled',
    width: 'available',
    size: 's',
    // multiple: true,
//    itemOptions: (item: any) => ( { value: item.id, text: item.name}),
    itemOptions: (item: any) => {
//      console.log('languageId item=', item)
      return  { value: item.id, text: item.name}
    },
    action: getAllLanguagesRequest,
    fieldWidth: '4',
  },
  {
    type: 'ValueSet',
    id: 'productTypeSimple',
    placeholder: 'Product Type',
    label: 'Product Type',
    view: 'filled',
    width: 'available',
    size: 's',
    // multiple: true,
//    itemOptions: (item: any) => ( { value: item.id, text: item.name}),
    itemOptions: (item: any) => {
      return  { value: item.id, text: item.name[1].translatedValue}
    },
    action: productTypeRequest,
    fieldWidth: '4',
  },
  {
    type: 'ValueSet',
    id: 'categoryType',
    placeholder: 'Category',
    label: 'Category',
    view: 'filled',
    width: 'available',
    size: 's',
    // multiple: true,
//    itemOptions: (item: any) => ( { value: item.id, text: item.name}),
    itemOptions: (item: any) => {
      return  { value: item.id, text: item.name[1].translatedValue}
    },
    action: getAllcategoriesRequest,
    fieldWidth: '4',
  },
  {
    type: 'ValueSet',
    id: 'client',
    placeholder: 'Client',
    label: 'Client',
    view: 'filled',
    width: 'available',
    size: 's',
    // multiple: true,
    itemOptions: (item: any) => {
      return  { value: item.id, text: item.name[1].translatedValue}
    },
    action: getAllClientsRequest,
    fieldWidth: '4',
  },
  {
    type: 'ValueSet',
    id: 'company',
    placeholder: 'Company',
    label: 'Company',
    view: 'filled',
    width: 'available',
    size: 's',
    // multiple: true,
    itemOptions: (item: any) => {
      return  { value: item.id, text: item.name[1].translatedValue}
    },
    action: getAllCompaniesRequest,
    fieldWidth: '4',
  },

];