// @ts-nocheck
import React from 'react';
import './DashBoardGraph.scss';
import Graph from './Graph'
const legends = [
    { title:'AUT: Daily Visits', color:'#FF5C5C'},
    { title:'DNL: Daily Reports Web Download', color:'#0B1F35'},
    { title:'DNE: Daily Reports E-mails Download', color:'#999EA3'},
]

const Legend = ({title, color}): any => {
    return (
    <div className={'dash-legend'}>
    <div className={'dash-legend-color'} style={{background:color}}>
    </div>
    <div className={'dash-legend-title'}>
        {title}
    </div>
    </div>
    )
}


const normalizeData = (data): any => {
    if( data == null) return [];
    let res = [];
    for( let i = data.length-1; i>=0; i--) {
        res.push(data[i]);
    }
    return res;
}
class DashboardGraph extends React.Component {
    render() {
        const { data }: any = this.props;
        // console.log('DashboardGraph render=', data)
        let dataRes = normalizeData(data)
        return (
           <>
           <div className={'dashboard-title'}>
               Indicators Dynamic
           </div>
{/*
               <div style={{width: '100%', height: '400px'}}>
                   <Graph data={dataRes}/>
               </div>
*/}

               {dataRes.length &&
               <>
                   <div className={'dash-graph'}>
                       <Graph data={dataRes}/>
                   </div>
                   <div className={'dash-legend-box'}>
                       { legends.length > 0 && legends.map(legend => {
                           return <Legend key={legend.title} title={legend.title} color={legend.color}/>
                       })}
                   </div>
               </>
               }
               {dataRes.length === 0 &&
               <div className={'dash-graph'}>
                  no data
               </div>
               }

           </>

        );
    }
};

export default DashboardGraph;

