export const CATALOG_CATEGORIES_FORM = 'CATALOG_CATEGORIES_FORM';
export const CATALOG_PRODUCT_TYPE_FORM = 'CATALOG_PRODUCT_TYPE_FORM';
export const CATALOG_SECTORS_FORM = 'CATALOG_SECTORS_FORM';
export const CATALOG_COMMODITIES_FORM = 'CATALOG_COMMODITIES_FORM';
export const CATALOG_COUNTRY_FORM = 'CATALOG_COUNTRY_FORM';
export const CATALOG_CURRENCIES_FORM = 'CATALOG_CURRENCIES_FORM';
export const CATALOG_PERIODTYPESS_FORM = 'CATALOG_PERIODTYPESS_FORM';
export const CATALOG_STOCKTYPES_FORM = 'CATALOG_STOCKTYPES_FORM';
export const CATALOG_AUTHORTYPES_FORM = 'CATALOG_AUTHORTYPES_FORM';
export const CATALOG_EVENTTYPES_FORM = 'CATALOG_EVENTTYPES_FORM';
export const CATALOG_LANGUAGES_FORM = 'CATALOG_LANGUAGES_FORM';
export const CATALOG_WORK_CALENDAR_FORM = 'CATALOG_WORK_CALENDAR_FORM';

export const INTERFACESETTINGS_FORM = 'INTERFACESETTINGS_FORM';
export const AUTHORS_FORM = 'AUTHORS_FORM';
export const COMPANIES_WIDGETSCHARTS_FORM = 'COMPANIES_WIDGETSCHARTS_FORM';
export const SECTOR_WIDGETSCHARTS_FORM = 'SECTOR_WIDGETSCHARTS_FORM';
export const CLIENTS_FORM = 'CLIENTS_FORM';
export const CLIENTS_CONFIG_FORM = 'CLIENTS_CONFIG_FORM';
export const CALENDAR_FORM = 'CALENDAR_FORM';
export const COMPANIES_FORM = 'COMPANIES_FORM';
export const STOCK_FORM = 'STOCK_FORM';
export const DIVIDEND_FORM = 'DIVIDEND_FORM';

export const PRODUCT_OPTION_FORM = 'PRODUCT_OPTION_FORM';
export const ANNOTATION_OPTION_FORM = 'ANNOTATION_OPTION_FORM';

export const MAILING_LIST_SETTINGS_FORM = 'MAILING_LIST_SETTINGS_FORM';
export const TICKER_FORM = 'TICKER_FORM'
export const RECOMENDATION_FORM = 'RECOMENDATION_FORM'

export const HEAD_FORM = 'HEAD_FORM';

export const BODY_FORM = 'HEAD_FORM';

export const BULK_OPTION_FORM = 'BULK_OPTION_FORM'
export const CYPRUS_OPTION_FORM = 'CYPRUS_OPTION_FORM'

export const WIDGETUPLOAD_FORM = 'WIDGETUPLOAD_FORM'

export const LAW_FILE_FORM = 'LAW_FILE_FORM';
export const INFO_FORM = 'INFO_FORM';
export const STATISTICS_FILTER_FORM = 'STATISTICS_FILTER_FORM';
export const DASHBOARD_FILTER_FORM = 'DASHBOARD_FILTER_FORM';

export const PRODUCT_FILTER_FORM = 'PRODUCT_FILTER_FORM';
export const CURRENT_MARKET_PRICES_FORM = 'CURRENT_MARKET_PRICES_FORM';

export const EMAIL_FORM = 'EMAIL_FORM';



