// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';

import API_CATEGORY from '../api';
import { GET_ALL_COMMODITIES_REQUEST, CREATE_NEW_COMMODITIES_REQUEST, GET_COMMODITIES_BY_ID_REQUEST, EDIT_COMMODITIES_REQUEST } from './constants';


function* getAllCommodities({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_CATEGORY.getAllCommodities);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }))
      if (form) yield put(callback({ payload: { response }, meta: { form } }))
    }
  } catch (error) {
    console.error('Auth Wrong');
  }
}

function* createNewCommodities({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_CATEGORY.createNewCommodities, { data: fields });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getCommoditiesById({ payload: { callback, data: { id } }, meta: { form } }: any) {
  try {
    const response = yield call(API_CATEGORY.getByIdCommodities, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* editCommodities({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_CATEGORY.editCommodities, { data: fields });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    console.error(error);
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_ALL_COMMODITIES_REQUEST, getAllCommodities),
    takeLatest(CREATE_NEW_COMMODITIES_REQUEST, createNewCommodities),
    takeLatest(GET_COMMODITIES_BY_ID_REQUEST, getCommoditiesById),
    takeLatest(EDIT_COMMODITIES_REQUEST, editCommodities)
  ]);
}

export default Saga;
