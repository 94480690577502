import * as ACTION_TYPE from './constants';

export const getAllSectorsRequest = ({ meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_SECTORS_REQUEST,
  meta
});

export const createNewSectorRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_SECTOR_REQUEST,
  payload,
  meta,
});

export const editSectorRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_SECTOR_REQUEST,
  payload,
  meta,
})

export const getSectorByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_SECTOR_BY_ID_REQUEST,
  payload,
  meta,
})