// @ts-nocheck
import React, { FC } from 'react';
import MetaData from 'app/metaData';
import Message from 'components/Message'
import { ILayoutProps } from 'types';

const Public: FC<ILayoutProps> = ({ children }) => {
    return (
        <div className="admin-main">
            <MetaData />
            {children}
            <Message id="error" variant="error" vertical="top" horizontal="center" />
        </div>
    );
};

export default Public;
