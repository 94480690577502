// @ts-nocheck
import React from 'react';
import { IBodyTable } from '../Table/interface';
import { Heading } from 'arui-feather/heading';
import CalendarInput from 'arui-feather/calendar-input';

const Charts = (body: any, title: string, lastFile: string, lastDate: string, button:any, handleChange: any) => {
  const date = body && body.length && body.sort((a: any, b: any) => {
    let x = +new Date(b.loadDate);
    let y = +new Date(a.loadDate);
    return x - y;
  });

  const d = new Date()
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
  const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d)
  const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)

  const currentDate = `${da}.0${mo}.${ye}`
  const loadDate = date && date.length && date[0] && date[0].loadDate || undefined

  return <div>
    <span className='admin-spacer'/>
    <Heading size="s">{title}</Heading>
    <CalendarInput
      placeholder='DD.MM.YYYY'
      view='filled'
      onChange={handleChange}
      size='s'
      value={loadDate}
      defaultValue={loadDate}
    />
    {button}
    <span className='admin-spacer'/>
  </div>;
};

export default Charts;