// @ts-nocheck
import React, { ComponentType } from 'react';
import Date from 'arui-feather/calendar-input';
import Input from 'arui-feather/input';

const renderDateField = ({ input, id, label, type, required, style, meta: { touched, error }, ...props }: any) => (
  <div style={style}>
    <Date
      {...input}
      {...props}
      id={id}
      type={type}
      required={required}
      label={label}

      autocomplete={false}
      error={touched ? error : undefined}
    />
  </div>
);

export default renderDateField;
