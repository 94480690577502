// @ts-nocheck
import { fromJS } from 'immutable'
import { IActionProps } from 'types'
import { LOGOUT_SUCCESS } from 'modules/Auth/redux/constants'
import { SET_USER } from './constants'

const initialState = fromJS({})

const userReducer = (state = initialState, { type, payload }: IActionProps) => {
  switch (type) {
    case SET_USER: {
      return payload
    }

    case LOGOUT_SUCCESS: {
      return initialState
    }

    default:
      return state
  }
}

export default userReducer
