// @ts-nocheck
import React, { ReactNode, ComponentType } from 'react';
import Editor from 'components/Inputs/Jodi'
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEqual, findKey } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { makeSelectFields } from './selector';
import renderTextField from 'components/Inputs/Text'
import RenderTimeField from 'components/Inputs/Text/time'
import renderTextAreaField from 'components/Inputs/TextArea'
import renderDateField from 'components/Inputs/Date'
import renderCheckboxField from 'components/Inputs/Checkbox'
import renderEditorField from 'components/Inputs/Jodi/renderFieldEditor';
import renderEditorMailingField from 'components/Inputs/JodiMailing/renderFieldEditor'
import renderEditorFooterField from 'components/Inputs/JodiFooter/renderFieldEditor';
import renderEditorAuthorField from 'components/Inputs/JodiAuthor/renderFieldEditor'
import renderEditorAuthorRuField from 'components/Inputs/JodiAuthorRu/renderFieldEditor'
import renderEditorAnnotationField from 'components/Inputs/JodiAnnotation/renderFieldEditor';
import renderEditorContactField from 'components/Inputs/JodiContact/renderFieldEditor';

import { Field, reduxForm, FieldArray } from 'redux-form';

import { makeSelectLoading } from './selector';
import { InputDateTime } from 'components/Inputs/DateTime'

import ValueSet from 'components/ValueSet';
import UploadFile from 'components/InputFile'
import FileInput from 'components/FileInput';
import CheckboxesThree from 'components/CheckboxesThree';

import GridRow from 'arui-feather/grid-row';
import GridCol from 'arui-feather/grid-col';
import Label from 'arui-feather/label';
import Heading from 'arui-feather/heading';
import PlateComponent from 'components/Inputs/Plate';

interface IFlowForm {
  title?: string;
  config: Array<any>;
  actions: ReactNode;
  normalize: any;
  formData: any;
  loading: boolean;
  reInitialize: any;
  initialize: any;
  hideActions: boolean;
  form: string;
  width?: string;
  type: string
}

class FlowForm extends React.Component<IFlowForm> {
  private unblock: any;

  componentDidMount() {
    this.initialize(this.props);
  }

  componentDidUpdate(prevProps: IFlowForm) {
    this.initialize(prevProps);
  }

  componentWillUnmount() {
    this.initialized = false;
    // @ts-disable
    if (typeof this.unblock === 'function') {
      this.unblock();
    }
  }

  initialized = false;

  fileInit = false;

  initialize(prevProps: IFlowForm) {
    const { formData, reInitialize, initialize }: IFlowForm = this.props;
    if (!formData) {
      return null;
    }
    const formDataFormatted = Object.keys(formData).reduce((memo, key) => {
      return {
        ...memo,
        [key]: formData[key],
      };
    }, {});

    if (!this.initialized) {
      this.initialized = true;
      initialize(formDataFormatted);
      this.fileInit = true;
    } else if (reInitialize) {
      if (!isEqual(formData, prevProps.formData)) {
        initialize(formDataFormatted);
        this.fileInit = true;
      }
    }
  }

  makeField = (Component: ComponentType) => ({
                                               input,
                                               meta,
                                               children,
                                               required,
                                               hasFeedback,
                                               id,
                                               style,
                                               ...rest
                                             }: any) => {
    //const hasError = meta.touched && meta.invalid;
    return (
      <div style={style}>
        <Component {...input} error={meta.error} autocomplete={false} {...rest} children={children} />
      </div>
    );
  };

  getField(
    {
      showIf,
      fixedCell,
      name,
      disabled,
      required,
      preview,
      component,
      disabledIf,
      ...item
    }: any,
    index: number,
  ) {
    const { formValues, form }: any = this.props;

    if (disabledIf && typeof disabled === 'undefined') disabled = Boolean(disabledIf(formValues));
    const calculated = {
      index,
      name: name || item.id,
      required:
        typeof required === 'function' ? required(formValues) : required,
      disabled
    };
    switch (item.type) {
      case 'whiteSpace':
        return null;
      case 'gridCol':
        return <GridCol width={item.fieldWidth} />;
      case 'text':
      case 'number':
      case 'email':
      case 'password':
        return (
          <Field
            hasFeedback
            key={index}
            component={renderTextField}
            {...item}
            {...calculated}
          />
        );
      case 'timeCurrentDefault':
        return (<RenderTimeField index={index} item={item} calculated={calculated} />)

      case 'textArea':
        return (
          <Field
            class={''}
            hasFeedback
            key={index}
            component={renderTextAreaField}
            {...item}
            {...calculated}
          />
        );
      case 'ValueSet':
        return <ValueSet key={index} {...item} {...calculated} />;
      case 'file':
        return (
          <Field
            form={form}
            class={''}
            hasFeedback
            key={index}
            component={UploadFile}
            {...item}
            {...calculated}
          />  )
      case 'fileSimple':
        return (
          <FileInput
            form={form}
            {...item}
            {...calculated}
          />
        );
      case 'checkbox':
        return (
          <Field
            hasFeedback
            key={index}
            component={renderCheckboxField}
            {...item}
            {...calculated}
          />
        );
      case 'date':
        return (
          <Field
            hasFeedback
            key={index}
            component={renderDateField}
            {...item}
            {...calculated}
          />
        );
      case 'dateTime':
        return (
          <Field
            hasFeedback
            key={index}
            component={InputDateTime}
            {...item}
            {...calculated}
          />
        );
      case 'editor':
        return (
          <Field
            hasFeedback
            key={index}
            component={renderEditorField}
            {...item}
            {...calculated}
          />
        );
      case 'editorMailing':
        return (
          <Field
            hasFeedback
            key={index}
            component={renderEditorMailingField}
            {...item}
            {...calculated}
          />
        );
      case 'editorFooter':
        return (
          <Field
            hasFeedback
            key={index}
            component={renderEditorFooterField}
            {...item}
            {...calculated}
          />
        );
      case 'editorAnnotation':
        return (
          <Field
            hasFeedback
            key={index}
            component={renderEditorAnnotationField}
            {...item}
            {...calculated}
          />
        );
      case 'editorAuthor':
        return (
          <Field
            hasFeedback
            key={index}
            component={renderEditorAuthorField}
            {...item}
            {...calculated}
          />
        );
      case 'editorAuthorRu':
        return (
          <Field
            hasFeedback
            key={index}
            component={renderEditorAuthorRuField}
            {...item}
            {...calculated}
          />
        );
      case 'editorContact':
        return (
            <Field
                hasFeedback
                key={index}
                component={renderEditorContactField}
                {...item}
                {...calculated}
            />
        );
      case 'fieldArray':
        return (
          <FieldArray
            key={index}
            component={component}
            {...item}
            {...calculated}
          />
        );
      case 'checkboxesTree':
        return (
          <FieldArray
            hasFeedback
            key={index}
            component={CheckboxesThree}
            {...item}
            {...calculated}
          />
        );
      case 'custom':
        return (
          <FieldArray
            hasFeedback
            key={index}
            component={component}
            {...item}
            {...calculated}
          />
        );

      case 'plate':
        return (
          <PlateComponent key={index} {...item} {...calculated} />
        )
      default:
        return null;
    }
  }

  getCell({ columns, bigLabel = true, ...item }: any, index: number) {
    const { label } = item;
    const customPosition = item.customPosition
      ? item.customPosition
      : { label: 3, field: 8, className: '' };
    return (
      <div key={index} style={{ marginBottom: '30px' }}>
        {bigLabel ? (
          <GridRow gutter={{ desktop: 12 }}>
            {customPosition.label >= 1 ? (
              <GridCol width={customPosition.label} align="top">
                <Label size="m" isNoWrap={true}>
                  {label}
                </Label>
              </GridCol>
            ) : (
              ''
            )}
            <GridCol
              className={customPosition.className}
              width={customPosition.field}
            >
              {this.getField(item, index)}
            </GridCol>
          </GridRow>
        ) : (
          <GridRow gutter={{ desktop: 12 }}>
            <GridCol width={12}>{this.getField(item, index)}</GridCol>
          </GridRow>
        )}
      </div>
    );
  }

  getCellGroup({ columns, items, label, ...item }: any, index: number) {
    const customPosition = item.customPosition
      ? item.customPosition
      : { label: 3, field: 8, className: '' };
    return (
      <div key={index} style={{ marginBottom: '30px' }}>
        <GridRow gutter={{ desktop: 12 }}>
          {customPosition.label >= 1 ? (
            <GridCol width={customPosition.label} align="top">
              <Label size="m" isNoWrap={true}>
                {label}
              </Label>
            </GridCol>
          ) : (
            ''
          )}
          <GridCol
            className={customPosition.className}
            width={customPosition.field}
          >
            {items.map((item: any, index: number) =>
              this.getField(item, index),
            )}
          </GridCol>
        </GridRow>
      </div>
    );
  }

  handleSubmit = (e: any) => {
    e.stopPropagation();
    // @ts-ignore
    this.props.handleSubmit(e);
  };

  render() {
    const { config, actions, hideActions, width, title, type } = this.props;

    // console.log('Flow form loading=', this.props.loading)


    const isFieldWidth =
      config && config.length && config.some((item) => item.fieldWidth);
    const isCustom = config && config.length && config.some((item) => item.type === 'custom');
    return (
      <GridCol width={width}>
        {title && (
          <Heading size='xs'>
            { title }
          </Heading>
        )}
        <form onSubmit={(e) => this.handleSubmit(e)}>
          {isFieldWidth ? (
            <GridRow>
              {config.map((item: any, index: any) => {
                const isGroup = item && item.isGroup;
                return isGroup ? (
                  <GridCol key={index} width={item.fieldWidth}>
                    {this.getCellGroup(item, index)}
                  </GridCol>
                ) : (
                  <GridCol key={index} width={item.fieldWidth}>
                    {this.getCell(item, index)}
                  </GridCol>
                );
              })}
            </GridRow>
          ) : (
            config.map((item: any, index: any) => {
              const isGroup = item && item.isGroup;
              return isGroup
                ? this.getCellGroup(item, index)
                : this.getCell(item, index);
            })
          )}
          {!hideActions && type !== 'view' && actions}
        </form>
      </GridCol>
    );
  }
}

const scrollToFirstError = (errors: any) => {
  const res = findKey(
    errors,
    (key, item) => key && document.querySelector(`[name=${item}]`),
  );
  if (res) {
    (document as any).querySelector(`[name=${res}]`).scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    });
  }
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  formValues: makeSelectFields(),
});

const withConnect = connect(mapStateToProps, (state: any, props: any) => ({
  form: props.form,
  validate: (values: any) => props.validate(values.toJS(), props.type),
  onSubmit: (values: any) => props.onSubmit(values.toJS()),
}));

export default compose<any>(
  reduxForm({
    onSubmitFail: scrollToFirstError,
    // destroyOnUnmount: true,
    // keepDirtyOnReinitialize: true,
    // enableReinitialize: true
  }),
  withRouter,
  withConnect,
)(FlowForm);
