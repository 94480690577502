import * as ACTION_TYPE from './constants';

export const getAllLawFileRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_LAW_FILE_REQUEST,
  meta,
  payload
});

export const createNewLawFileRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_LAW_FILE_REQUEST,
  payload,
  meta,
});

export const editLawFileRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_LAW_FILE_REQUEST,
  payload,
  meta,
})

export const getLawFileByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_LAW_FILE_BY_ID_REQUEST,
  payload,
  meta,
})

export const deleteLawFileRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.DELETE_LAW_FILE_BY_ID_REQUEST,
  payload,
  meta,
})


//////////////////////////////////////////////////////////////////////////
//// стрыый код удалить позднее

export const getAllCompaniesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_COMPANIES_REQUEST,
  meta,
  payload
});

export const createNewCompaniesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_COMPANIES_REQUEST,
  payload,
  meta,
});

export const editCompaniesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_COMPANIES_REQUEST,
  payload,
  meta,
})

export const getCompaniesByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_COMPANIES_BY_ID_REQUEST,
  payload,
  meta,
})

export const deleteCompaniesByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.DELETE_COMPANIES_BY_ID_REQUEST,
  payload,
  meta,
})

export const deleteFileByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.DELETE_FILE_BY_ID_REQUEST,
  payload,
  meta,
})

export const deleteStocksByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.DELETE_STOCKS_BY_ID_REQUEST,
  payload,
  meta,
})

export const deleteDividendByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.DELETE_DIVIDEND_BY_ID_REQUEST,
  payload,
  meta,
})

export const deleteTickerByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.DELETE_TICKER_BY_ID_REQUEST,
  payload,
  meta,
})

export const getAllStocksRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_STOCKS_REQUEST,
  payload,
  meta,
})

export const getStocksByIDRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_STOCKS_BY_ID_REQUEST,
  payload,
  meta,
})


export const getRecommendationByIDRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_RECOMMENDATION_BY_ID_REQUEST,
  payload,
  meta,
})

export const editRecommendationRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_RECOMMENDATION_REQUEST,
  payload,
  meta,
})

export const deleteRecommendationByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.DELETE_RECOMMENDATION_BY_ID_REQUEST,
  payload,
  meta,
})

export const getStocksByIDCompanyRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_STOCKS_BY_ID_COMPANY,
  payload,
  meta,
})

export const getFilesByIDCompanyRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_FILES_BY_ID_COMPANY,
  payload,
  meta,
})

export const editStockRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_STOCK_REQUEST,
  payload,
  meta,
})

export const createNewStockRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_STOCK_REQUEST,
  payload,
  meta,
});

export const createFileFieldRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_FILE_FIELD_REQUEST,
  payload,
  meta,
});

export const editFileFieldRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_FILE_FIELD_REQUEST,
  payload,
  meta,
});

export const getAllDividendsRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_DIVIDENDS_REQUEST,
  payload,
  meta,
})

export const getDividendByIDRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_DIVIDEND_BY_ID,
  payload,
  meta,
})

export const getDevidendsByStockIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_DIVIDEND_BY_STOCK_ID,
  payload,
  meta,
})

export const editDividendRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_DIVIDEND_REQUEST,
  payload,
  meta,
})

export const editTickerRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_TICKER_REQUEST,
  payload,
  meta,
})

export const createDividendRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_DIVIDEND_REQUEST,
  payload,
  meta,
});

export const getAllTickersRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_TICKERS_REQUEST,
  payload,
  meta,
})

export const getTickerByIDRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_TICKER_BY_ID_REQUEST,
  payload,
  meta,
})


export const createTickerRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_TICKER_REQUEST,
  payload,
  meta,
});

export const createRecommendationRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_RECOMMENDATION_REQUEST,
  payload,
  meta,
});


export const getFileByIDRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_FILE_BY_ID_REQUEST,
  payload,
  meta,
})

export const getTickersByCompanyIDRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_TICKERS_BY_COMPANY_ID_REQUEST,
  payload,
  meta,
})

export const getAllRecommendationByTickerIDRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_RECOMMENDATION_BY_TICKER_ID_REQUEST,
  payload,
  meta,
})
