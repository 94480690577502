// @ts-nocheck
import React from 'react';

export default [
  {
    isGroup: true,
    label: 'Name',
    fieldWidth: '12',
    items: [
      {
        placeholder: 'Name',
        type: 'text',
        id: 'name',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
  {
    isGroup: true,
    label: 'Description',
    fieldWidth: '12',
    items: [
      {
        placeholder: 'EN',
        type: 'text',
        id: 'descriptionEN',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU',
        type: 'text',
        id: 'descriptionRU',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
  {
    type: 'fileSimple',
    typeFile:'img',
    id: 'photo',
    fieldWidth: '12',
    label: 'File',
    accept: '.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf',
    noFileText: 'Select and upload file',
  },
];
