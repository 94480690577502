import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Notification from 'arui-feather/notification';

import { closeSuccessMessage, closeErrorMessage } from './actions';
import normalize from './normalize';

const AUTO_HIDE_DURATION = 2000;


export class Message extends PureComponent {
    handleClose = id => () => {
        const { dispatch } = this.props;
        if (id === 'success') dispatch(closeSuccessMessage());
        if (id === 'error') dispatch(closeErrorMessage());
    };

    render() {
        const { message, id } = this.props;
        const text = normalize(message[id]);
        const open = !!text;
        const title = id === 'success' ? 'Successful action' : 'Unsuccessful action'
        const status = id === 'success' ? 'ok' : 'error'
        return (
          <Notification
            visible={open}
            status={status}
            offset={ 12 }
            autoCloseDelay={AUTO_HIDE_DURATION}
            stickTo='right'
            title={title}
            onCloseTimeout={this.handleClose(id)}
            onCloserClick={this.handleClose(id)}
          >
              { text }
          </Notification>
        );
    }
}

Message.propTypes = {
    id: PropTypes.oneOf(['success', 'error']).isRequired,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
    vertical: PropTypes.oneOf(['top', 'center', 'bottom']),
    horizontal: PropTypes.oneOf(['left', 'center', 'right']),
    autoHideDuration: PropTypes.number,
    cancelAutoHide: PropTypes.bool,
};


const mapStateToProps = state => ({
    message: state.message && state.message.toJS(),
});

const withConnect = connect(mapStateToProps);
export default compose(
    withConnect,
)(Message);
