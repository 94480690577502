import Categories from './pages';

export default [
  {
    type: 'private',
    exact: true,
    path: '/categories',
    roles: ['admin'],
    component: Categories,
  },
  {
    type: 'private',
    exact: true,
    path: '/categories/:id',
    roles: ['admin'],
    component: Categories,
  },
];
