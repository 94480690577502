// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { getAllInterfaceSettingsRequest, getAllInterfaceSettingsSuccess } from './actions';

import API_INTERFACESETTINGS from '../api';
import { GET_ALL_INTERFACESETTINGS_REQUEST, UPDATE_INTERFACESETTINGS_REQUEST } from './constants';
import { openSuccessMessage } from '../../../components/Message/actions';
import { createNewAuthorsFailure } from '../../Authors/redux/actions';
import { formDataRequest } from '../../../components/Graphs/Form/actions';

function* getAllInterfaceSettings({ payload: { callback }, meta: { form, table } }: any) {
  try {
    const responseInterfaces = yield call(API_INTERFACESETTINGS.getAllInterfaceSettings);
    let resObject = {} as any

    responseInterfaces.forEach((setting: any) => {
      resObject[setting.id] = setting
    })
    if (callback) {
      if (table) yield put(callback({ payload: { response: resObject }, meta: { table } }))
      if (form) yield put(callback({ payload:  { response: resObject }, meta: { form } }))
    }
    yield put(getAllInterfaceSettingsSuccess({ payload: resObject }))
  } catch (error) {
    console.error('Auth Wrong');
  }
}

function* editInterface({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_INTERFACESETTINGS.editInterfaceSettings, { data: fields });
    yield put(formDataRequest({type: '', meta: { form }, payload: { init: getAllInterfaceSettingsRequest } }))
    yield put(openSuccessMessage('Interface successfully changed.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess: () => {} } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_ALL_INTERFACESETTINGS_REQUEST, getAllInterfaceSettings),
    takeLatest(UPDATE_INTERFACESETTINGS_REQUEST, editInterface)
  ]);
}

export default Saga;
