// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import { closeDialog } from 'components/Flows/Dialog/actions';
import { formReset } from 'components/Graphs/Form/actions';
import {
  getFileByIDRequest, editFileFieldRequest, getAllStocksRequest, createFileFieldRequest, getFilesByIDCompanyRequest,
} from '../../../redux/actions';
import normalize from './normalize';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { DIVIDEND_FORM, STOCK_FORM } from 'constants/forms';
import { LANGUAGE_EN, LANGUAGE_RU } from '../../../../../constants/languages';

import { AUTHORS_TABLE, FILE_TABLE, STOCKS_TABLE } from '../../../../../constants/tables';
import { tableDataSuccess } from '../../../../../components/Graphs/Table/actions';

interface ICompaniesForm {
  title?: string;
  form: string;
  match: any
  stockId?: any
}

class FileForm extends React.Component<ICompaniesForm> {
  componentWillUnmount() {
    const { dispatch, id, form }: any = this.props;
    dispatch(reset(STOCK_FORM));
    dispatch(formReset({ meta: { form: STOCK_FORM } }));
  }

  handleClick = () => {
    const { history, dispatch, id}: any = this.props;
    dispatch(reset(STOCK_FORM));
    dispatch(formReset({ meta: { form: STOCK_FORM } }));
    dispatch(getFilesByIDCompanyRequest({ meta: {table: FILE_TABLE, callback: tableDataSuccess, graphId: id } }));
    dispatch(closeDialog({ id: 'table.file' }));
  };

  get actions() {
    const { fileId }: any = this.props;
    const action = fileId ? 'Edit this file' : 'Add new file';
    return (
      <GridRow>
        <GridCol>
          <Button className={'button-float'} view="extra" size="s" type="submit">
            {action}
          </Button>
          <Button className={'mr20 button-float'} size="s" onClick={this.handleClick}>Cancel</Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = (data: any) => {
    const { id }: any = this.props;
    return {
      id: data.id,
      url: data.photo?.url,
      companyId: id,
      name: data.name,
      fileSize: data.photo?.size,
      createdDateTime: data.createdDateTime,
      description: [
        {
          language: { id: LANGUAGE_RU, name: 'Рус' },
          translatedValue: data.descriptionRU,
        },
        {
          language: { id: LANGUAGE_EN, name: 'En' },
          translatedValue: data.descriptionEN,
        },
      ],
    };
  };

  render() {
    const { fileId }: any = this.props;
    return (
        <div style={{padding: '20px'}}>
        <GraphForm
        form={STOCK_FORM}
        title="File form1"
        actions={this.actions}
        onSuccess={this.handleClick}
        graphId={fileId}
        init={getFileByIDRequest}
        edit={editFileFieldRequest}
        create={createFileFieldRequest}
        submit={this.submit}
        normalize={normalize}
        validate={() => {}}
        type={fileId ? 'edit' : 'create'}
        columns={1}
        config={config}
        width="12"
        reInitialize
      />
      </div>
    );
  }
}

export default compose<any>(
  withRouter,
  connect(),
)(FileForm);
