import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {
  getProductType = () => request({
    url: 'catalog/product-type',
    method: 'GET',
    token: API_GLOBAL.token
  });

  createProductType = ({ data }: any) => request({
    url: 'catalog/product-type',
    data,
    method: 'POST',
    token: API_GLOBAL.token
  });

  getProductTypeId = ({ id }: any) => request({
    url: `catalog/product-type/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  });

  editProductType = ({ data }: any) => request({
    url: 'catalog/product-type',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  })
}

const API_PRODUCT_TYPE = new ClassAPI();

export default API_PRODUCT_TYPE;
