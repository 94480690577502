// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';

import API_WIDGETSCHARTS from '../api';
import {
  GET_ALL_COMPANIES_WIDGETSCHARTS_REQUEST,
  GET_ALL_SECTOR_WIDGETSCHARTS_REQUEST,
  CREATE_COMPANIES_WIDGETSCHARTS_REQUEST,
  CREATE_SECTORS_WIDGETSCHARTS_REQUEST
} from './constants';

import { openSuccessMessage } from 'components/Message/actions';
import { createNewAuthorsFailure } from '../../Authors/redux/actions';

function* getAllCompanyWidgetsCharts({ meta: { form, table, callback, graphId } }: any) {
  try {
    const response = yield call(API_WIDGETSCHARTS.getAllCompanyWidgetsCharts, graphId);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getAllSectorWidgetsCharts({ meta: { form, table, callback, graphId } }: any) {
  try {
    const response = yield call(API_WIDGETSCHARTS.getAllSectorWidgetsCharts, graphId);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* createCompaniesWidgetsChartsRequest({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_WIDGETSCHARTS.createCompaniesWidgetsChartsRequest, { data: fields });
    yield put(openSuccessMessage('File successfully created.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* createSectorsWidgetsChartsRequest({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_WIDGETSCHARTS.createSectorsWidgetsChartsRequest, { data: fields });
    yield put(openSuccessMessage('File successfully created.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_ALL_COMPANIES_WIDGETSCHARTS_REQUEST, getAllCompanyWidgetsCharts),
    takeLatest(GET_ALL_SECTOR_WIDGETSCHARTS_REQUEST, getAllSectorWidgetsCharts),

    takeLatest(CREATE_COMPANIES_WIDGETSCHARTS_REQUEST, createCompaniesWidgetsChartsRequest),
    takeLatest(CREATE_SECTORS_WIDGETSCHARTS_REQUEST, createSectorsWidgetsChartsRequest),

  ]);
}

export default Saga;
