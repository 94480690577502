import { language } from 'utils/formatters';
import { LANGUAGE_EN } from '../../../../../constants/languages';

const normalize = (data: any) => {
  if (!data) return null;
//  console.log('normalize data=', data);
  let cormData = {
    ...data,
    file: {
      url: data?.file,
      title: data?.file?.split('/').pop()
    },
    languageId: data.languageId === LANGUAGE_EN ? 'EN' : 'RU'
    // annotation: "Тестовый продукт"
    // authors: []
    // categoryId: "5eda0abc396529280c953c14"
    // commodities: ["5edf6905e1edfa0319791efe"]
    // companies: ["5edcf641055389768df673f0"]
    // date: "01.01.2020"
    // events: ["5ee0a695cbdd2c713e23ef86"]
    // file: "UPLOADED FILE!"
    // id: "5edf72d25bafba55d7e8c6a4"
    // languageId: "5ed9f65f396529280c953be3"
    // mark: true
    // name: "Тестовый продукт Fixed Income"
    // productTypeId: "5eda262e396529280c953c76"
    // published: false
    // sectors: ["5eda02c3396529280c953be6"]
  };


  return cormData;
};

export default normalize;
