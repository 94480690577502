// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';

import API_COMPANIES from '../api';
import {
  GET_ALL_COMPANIES_REQUEST,
  CREATE_NEW_COMPANIES_REQUEST,
  GET_COMPANIES_BY_ID_REQUEST,
  EDIT_COMPANIES_REQUEST,
  DELETE_COMPANIES_BY_ID_REQUEST,
  GET_ALL_STOCKS_REQUEST,
  GET_STOCKS_BY_ID_REQUEST,
  EDIT_STOCK_REQUEST,
  GET_ALL_DIVIDENDS_REQUEST,
  GET_DIVIDEND_BY_ID,
  EDIT_DIVIDEND_REQUEST,
  GET_ALL_TICKERS_REQUEST,
  GET_TICKER_BY_ID_REQUEST,
  GET_STOCKS_BY_ID_COMPANY,
  CREATE_NEW_STOCK_REQUEST, GET_DIVIDEND_BY_STOCK_ID,
  CREATE_NEW_DIVIDEND_REQUEST,
  GET_FILE_BY_ID_REQUEST,
  GET_FILES_BY_ID_COMPANY,
  GET_TICKERS_BY_COMPANY_ID_REQUEST,
  DELETE_STOCKS_BY_ID_REQUEST,
  DELETE_DIVIDEND_BY_ID_REQUEST, CREATE_NEW_TICKER_REQUEST,
  DELETE_TICKER_BY_ID_REQUEST,
  EDIT_TICKER_REQUEST,
  CREATE_RECOMMENDATION_REQUEST,
  GET_RECOMMENDATION_BY_TICKER_ID_REQUEST,
  GET_RECOMMENDATION_BY_ID_REQUEST,
  CREATE_NEW_FILE_FIELD_REQUEST,
  EDIT_FILE_FIELD_REQUEST,
  EDIT_RECOMMENDATION_REQUEST,
  DELETE_RECOMMENDATION_BY_ID_REQUEST, DELETE_FILE_BY_ID_REQUEST,
} from './constants';
import {
  getAllCompaniesRequest,
  getDevidendsByStockIdRequest,
  getStocksByIDCompanyRequest,
  getTickersByCompanyIDRequest,
  getFilesByIDCompanyRequest,
  createRecommendationRequest, getAllRecommendationByTickerIDRequest, deleteFileByIdRequest,
} from './actions';
import {
  AUTHORS_TABLE,
  COMPANIES_TABLE,
  DIVIDENDS_TABLE, FILE_TABLE,
  STOCKS_TABLE, TICKERS_RECOMENDATION_TABLE,
  TICKERS_TABLE,
} from '../../../constants/tables';
import { tableDataSuccess } from '../../../components/Graphs/Table/actions';
import API_AUTHORS from '../../Authors/api';
import { openSuccessMessage } from '../../../components/Message/actions';
import { createNewAuthorsFailure } from '../../Authors/redux/actions';
import {set_global_id} from "../../../app";


function* getAllCompanies({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_COMPANIES.getAllCompanies);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* createNewCompanies({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_COMPANIES.createNewCompanies, { data: fields });
    // 2021-08-05 сохраним id для дальней шего перехода на эту страницу для редактирования
    set_global_id(response.id);

    yield put(openSuccessMessage('Company successfully created.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getCompaniesById({ payload: { callback, data: { id } }, meta: { form } }: any) {
  try {
    const response = yield call(API_COMPANIES.getByIdCompanies, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* deleteCompaniesById({ payload: { id }, meta }: any) {
  try {
    yield call(API_COMPANIES.deleteByIdCompanies, id);
    yield put(getAllCompaniesRequest({ meta: { table: COMPANIES_TABLE, callback: tableDataSuccess } }));
    yield put(openSuccessMessage('Company successfully deleted.'));
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* deleteStockById({ payload: { id, companyId } }: any) {
  try {
    yield call(API_COMPANIES.deleteStockById, id);
    yield put(getStocksByIDCompanyRequest({
      meta: {
        graphId: companyId,
        table: STOCKS_TABLE,
        callback: tableDataSuccess,
      },
    }));
    yield put(openSuccessMessage('Stock successfully deleted.'));
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* deleteDividendById({ payload: { id, stockId } }: any) {
  try {
    yield call(API_COMPANIES.deleteDividendById, id);
    yield put(getDevidendsByStockIdRequest({
      meta: {
        graphId: stockId,
        table: DIVIDENDS_TABLE,
        callback: tableDataSuccess,
      },
    }));
    yield put(openSuccessMessage('Dividend successfully deleted.'));
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* deleteTicker({ payload: { id, stockId } }: any) {
  try {
    yield call(API_COMPANIES.deleteTickerById, id);
    yield put(getTickersByCompanyIDRequest({
      meta: {
        graphId: stockId,
        table: TICKERS_TABLE,
        callback: tableDataSuccess,
      },
    }));
    yield put(openSuccessMessage('Ticker successfully deleted.'));
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* editCompanies({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_COMPANIES.editCompanies, { data: fields });
    yield put(openSuccessMessage('Company successfully edited.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getAllStocks({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_COMPANIES.getAllStock);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getStocksByID({ payload: { callback, data: { id } }, meta: { form, table } }: any) {
  try {
    const response = yield call(API_COMPANIES.getStockById, { id });
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getStocksByIDCompany({ meta: { form, table, graphId, callback } }: any) {
  try {
    const response = yield call(API_COMPANIES.getStocksByIdCompany, { id: graphId });
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getTickerByCompanyID({ meta: { form, table, graphId, callback } }: any) {
  try {
    const response = yield call(API_COMPANIES.getTickersByIdCompany, { id: graphId });
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getFilesByIDCompany({ meta: { form, table, graphId, callback } }: any) {
  try {
    const response = yield call(API_COMPANIES.getFilesByIdCompany, { id: graphId });
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* editStock({ payload: { callback, fields }, meta: { form, table, onSuccess } }: any) {
  try {
    const response = yield call(API_COMPANIES.editStock, { data: fields });
    yield put(openSuccessMessage('Stock successfully edited.'));
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getAllDividends({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_COMPANIES.getAllDividends);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getDividendByID({ payload: { callback, data: { id } }, meta: { form, table } }: any) {
  try {
    const response = yield call(API_COMPANIES.getDividendById, { id });
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getDividendsByStockID({ meta: { form, table, callback, graphId } }: any) {
  try {
    const response = yield call(API_COMPANIES.getDividendByStockId, { id: graphId });
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getRecommendationByTickerID({ meta: { form, table, callback, graphId } }: any) {
  try {
    const response = yield call(API_COMPANIES.getRecommendationsByTickerId, { id: graphId });
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* editDividend({ payload: { callback, fields }, meta: { form, table, onSuccess } }: any) {
  try {
    const response = yield call(API_COMPANIES.editDividend, { data: fields });
    yield put(openSuccessMessage('Dividend successfully edited.'));
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* editTicker({ payload: { callback, fields }, meta: { form, table, onSuccess } }: any) {
  try {
    const response = yield call(API_COMPANIES.editTicker, { data: fields });
    yield put(openSuccessMessage('Ticker successfully edited.'));
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}


function* getAllTickers({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_COMPANIES.getAllTickers);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getTickerByID({ payload: { callback, data: { id } }, meta: { form, table } }: any) {
  try {
    const response = yield call(API_COMPANIES.getTickerById, { id });
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getRecommendationByID({ payload: { callback, data: { id } }, meta: { form, table } }: any) {
  try {
    const response = yield call(API_COMPANIES.getRecommendationById, { id });
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* editRecommendationRequest({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_COMPANIES.editRecommendationField, { data: fields });
    yield put(openSuccessMessage('Recommendation successfully created.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* deleteRecommendation({ payload: { id, ticketId } }: any) {
  try {
    yield call(API_COMPANIES.deleteRecommendationsByTickerId, id);
    yield put(getAllRecommendationByTickerIDRequest({
      meta: {
        graphId: ticketId,
        table: TICKERS_RECOMENDATION_TABLE,
        callback: tableDataSuccess,
      },
    }));
    yield put(openSuccessMessage('Recommendations successfully deleted.'));
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* deleteFileById({ payload: { id, companyId } }: any) {
  try {
    yield call(API_COMPANIES.deleteFileById, id);
    yield put(getFilesByIDCompanyRequest({
        meta: {
          graphId: companyId,
          table: FILE_TABLE,
          callback: tableDataSuccess,
        },
    }))
    yield put(openSuccessMessage('Recommendations successfully deleted.'));
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* createNewStock({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_COMPANIES.createStock, { data: fields });
    yield put(openSuccessMessage('Stock successfully created.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* createFileField({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_COMPANIES.createFileField, { data: fields });
    yield put(openSuccessMessage('File successfully created.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* editFileFieldRequest({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_COMPANIES.editFileField, { data: fields });
    yield put(openSuccessMessage('File successfully created.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}


function* createNewDividend({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_COMPANIES.createDividend, { data: fields });
    yield put(openSuccessMessage('Dividend successfully created.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* createNewTicker({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const { date, recommendation, targetPrice, ...rest } = fields;
    const response = yield call(API_COMPANIES.createTicker, { data: rest });
    yield put(openSuccessMessage('Ticker successfully created.'));
    yield put(createRecommendationRequest({
      payload: {
        callback,
        fields: { date, recommendation, targetPrice, equityTickerId: response.id },
      }, meta: { form, onSuccess },
    }));
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* createRecommendation({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_COMPANIES.createRecommendation, { data: fields });
    yield put(openSuccessMessage('Recommendation successfully created.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* getFileByID({ payload: { callback, data: { id } }, meta: { form, table } }: any) {
  try {
    const response = yield call(API_COMPANIES.getFileById, { id });
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_ALL_COMPANIES_REQUEST, getAllCompanies),
    takeLatest(CREATE_NEW_COMPANIES_REQUEST, createNewCompanies),
    takeLatest(GET_COMPANIES_BY_ID_REQUEST, getCompaniesById),
    takeLatest(EDIT_COMPANIES_REQUEST, editCompanies),
    takeLatest(DELETE_COMPANIES_BY_ID_REQUEST, deleteCompaniesById),

    takeLatest(GET_ALL_STOCKS_REQUEST, getAllStocks),
    takeLatest(GET_STOCKS_BY_ID_REQUEST, getStocksByID),
    takeLatest(EDIT_STOCK_REQUEST, editStock),

    takeLatest(GET_ALL_DIVIDENDS_REQUEST, getAllDividends),
    takeLatest(GET_DIVIDEND_BY_ID, getDividendByID),
    takeLatest(EDIT_DIVIDEND_REQUEST, editDividend),

    takeLatest(GET_ALL_TICKERS_REQUEST, getAllTickers),
    takeLatest(GET_TICKER_BY_ID_REQUEST, getTickerByID),
    takeLatest(GET_STOCKS_BY_ID_COMPANY, getStocksByIDCompany),
    takeLatest(CREATE_NEW_STOCK_REQUEST, createNewStock),
    takeLatest(GET_DIVIDEND_BY_STOCK_ID, getDividendsByStockID),
    takeLatest(CREATE_NEW_DIVIDEND_REQUEST, createNewDividend),
    takeLatest(GET_FILES_BY_ID_COMPANY, getFilesByIDCompany),
    takeLatest(GET_FILE_BY_ID_REQUEST, getFileByID),


    takeLatest(CREATE_NEW_FILE_FIELD_REQUEST, createFileField),
    takeLatest(EDIT_FILE_FIELD_REQUEST, editFileFieldRequest),

    takeLatest(GET_TICKERS_BY_COMPANY_ID_REQUEST, getTickerByCompanyID),
    takeLatest(DELETE_STOCKS_BY_ID_REQUEST, deleteStockById),
    takeLatest(DELETE_DIVIDEND_BY_ID_REQUEST, deleteDividendById),
    takeLatest(CREATE_NEW_TICKER_REQUEST, createNewTicker),
    takeLatest(DELETE_TICKER_BY_ID_REQUEST, deleteTicker),
    takeLatest(EDIT_TICKER_REQUEST, editTicker),
    takeLatest(CREATE_RECOMMENDATION_REQUEST, createRecommendation),

    takeLatest(GET_RECOMMENDATION_BY_TICKER_ID_REQUEST, getRecommendationByTickerID),
    takeLatest(GET_RECOMMENDATION_BY_ID_REQUEST, getRecommendationByID),
    takeLatest(EDIT_RECOMMENDATION_REQUEST, editRecommendationRequest),
    takeLatest(DELETE_RECOMMENDATION_BY_ID_REQUEST, deleteRecommendation),
    takeLatest(DELETE_FILE_BY_ID_REQUEST, deleteFileById)


  ]);
}

export default Saga;
