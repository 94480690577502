// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import GraphTable from 'components/Graphs/Table';
import { ICategoryTable, IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import { INFO_TABLE } from 'constants/tables';
import {getAllFooterInfoRequest, deleteFooterInfoRequest, getAllTypeInfo} from '../../redux/actions';
import StringCol from 'components/Flows/Elements/StringCol';
import IconDelete from 'arui-feather/icon/action/delete';
import {INFO_FORM} from "../../../../constants/forms";
import {emptyAction} from "../../../Products/redux/actions";
import {getAllAuthorTypesRequest} from "../../../Catalog/AuthorTypes/redux/actions";

class FooterTable extends React.Component<ICategoryTable> {

  normalizeTableData = (head: IHeadTable[], body: IBodyTable[]): any => {

    console.log('normalizeTableData ', body );
    let processedData = body && body.map((item, i) => {
      const { id, nameEn, type, order }:any = item;
      let emptyWidth: any = '';
      return {
        id,
        cells: [
          {
            element: StringCol(false, i + 1),
            id,
            type: 'id',
            width: '1',
          },
          {
            element: nameEn,
            id,
            type: 'nameEn',
            width: '6',
          },
          {
            element: type,
            id,
            type: 'infoType',
            width: '4',
          },
          {
            element: <IconDelete size={'s'}/>,
            id,
            type: 'delete',
            width: '1',
            customClass: 'delete-icon',
          },
        ],
      };
    }) || [];

    const normalizeData = processedData.map((val: any) => {
      let newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });

    console.log('normalizeData= ', normalizeData );
    return normalizeData;
  };

  getRowLink = (id: any) => `/footerinfo/edit/${id}`;

  handleDelete = (id: string) => {
    const { dispatch }: any = this.props;
    dispatch(deleteFooterInfoRequest({ payload: { id } }));
  };

  render() {
    const { handleRowClick }: any = this.props;
    const head = [
      {
        id: 'id',
        label: 'ID',
        style: { width: '1' },
      },
      // {
      //   id: 'order',
      //   style: {width: '1'},
      //   label: 'Order',
      // },
      {
        id: 'nameEn',
        style: { width: '6'},
        label: 'Name',
      },
      {
        id: 'infoType',
        label: 'Type',
        code2displayFunc: ( item :any) => {
          if(item && item.name) return item.name
          else return "uknown"
        },

        action: getAllTypeInfo,
        type: 'valueSet',
        style: { width: '4' },
      },
      {
        id: 'delete',
        type: 'delete',
        label: '',
        style: { width: '1' },
      },
    ];

    const { actions } = this.props
    console.log('footer info table render head=', head);
    return (
      <GraphTable
        title="Footer/Contacts table"
        table={INFO_FORM}
        actions={actions}
        normalizeTableData={this.normalizeTableData}
        init={getAllFooterInfoRequest}
        onRowClick={handleRowClick}
        onDelete={(id: any) => this.handleDelete(id)}
        head={head}
        link={this.getRowLink}
        width={'6'}
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
      />
    );
  }
}

export default connect()(FooterTable);
