import { language } from 'utils/formatters';

const normalize = (data: any) => {
  if (!data) return null;
  let cormData = {
    urlEn: {
      url: data?.urlEn,
      title: data?.urlEn?.split('/').pop()
    },
    nameEn: data.nameEn,
    urlRu: {
      url: data?.urlRu,
      title: data?.urlRu?.split('/').pop()
    },
    nameRu: data.nameRu
  };


  return cormData;
};

export default normalize;
