export const GET_ALL_LANGUAGES_REQUEST = 'GET_ALL_LANGUAGES_REQUEST'
export const GET_ALL_LANGUAGES_SUCCESS = 'GET_ALL_LANGUAGES_SUCCESS'
export const GET_ALL_LANGUAGES_FAILURE = 'GET_ALL_LANGUAGES_FAILURE'

export const CREATE_NEW_LANGUAGES_REQUEST = 'CREATE_NEW_LANGUAGES_REQUEST'
export const CREATE_NEW_LANGUAGES_SUCCESS = 'CREATE_NEW_LANGUAGES_SUCCESS'
export const CREATE_NEW_LANGUAGES_FAILURE = 'CREATE_NEW_LANGUAGES_FAILURE'

export const GET_LANGUAGES_BY_ID_REQUEST = 'GET_LANGUAGES_BY_ID_REQUEST'
export const GET_LANGUAGES_BY_ID_SUCCESS = 'GET_LANGUAGES_BY_ID_SUCCESS'
export const GET_LANGUAGES_BY_ID_FAILURE = 'GET_LANGUAGES_BY_ID_FAILURE'

export const EDIT_LANGUAGES_REQUEST = 'EDIT_LANGUAGES_REQUEST'