// @ts-nocheck
import React, { FC } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PrivateLayout from 'app/Layouts/private';

import { IRouterProps } from 'types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { makeSelectToken, makeSelectRoles } from '../selectors';
import { checkPermisions } from 'constants/roles'

const PrivateRoute: FC<IRouterProps> = ({
  path,
  component: Component,
  token,
  rolesUser
}) => {

  return (
    <PrivateLayout>
      <Route
        path={path}
        render={() => (token && checkPermisions(rolesUser, path, 'routes', 'routesExcludet') ? <Component /> : <Redirect to="/login" />)}
      />
    </PrivateLayout>
  );
}

const mapStateToProps = createStructuredSelector({
  token: makeSelectToken(),
  rolesUser: makeSelectRoles(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(PrivateRoute);
