import { createSelector } from 'reselect';
import { getFormValues, getFormInitialValues, getFormAsyncErrors, getFormSyncErrors } from 'redux-form/immutable';

export default (reducer, property) => createSelector(
    state => {
        const { [reducer]: immutableReducer } = state;
        if (!immutableReducer) return immutableReducer;
        if (Array.isArray(property)) return immutableReducer.getIn(property);
        return immutableReducer.get(property);
    },
    value => (value && value.toJS ? value.toJS() : value),
);


export const getFlowFormValues = form => {
    const formValues = getFormValues(form);

    return createSelector(
        formValues,
        values => values,
    );
};

export const getFlowFormInitialValues = form => {
    const formValues = getFormInitialValues(form);

    return createSelector(
        formValues,
        values => values && values.toJS(),
    );
};

export const getFlowAsyncErrors = form => {
    const formValues = getFormAsyncErrors(form);

    return createSelector(
        formValues,
        values => values && values.toJS(),
    );
};

export const getFlowSyncErrors = form => {
    const formValues = getFormSyncErrors(form);

    return createSelector(
        formValues,
        values => values && values.toJS(),
    );
};


export const convertToMutable = data => (data ? typeof data.toJS === 'function' ? data.toJS() : data : null);
