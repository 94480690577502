interface IValidate {
  mailingListSettingsId?: string;
}

const validate = (values: any) => {
  const errors = {} as IValidate;

  if (!values.mailingListSettingsId) {
    errors.mailingListSettingsId = "Required";
  }

  return errors;
};

export default validate;
