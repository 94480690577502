import * as ACTION_TYPE from './constants';

export const getAllFooterInfoRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_FOOTER_INFO_REQUEST,
  meta,
  payload
});

export const createNewFooterInfoRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_FOOTER_INFO_REQUEST,
  payload,
  meta,
});

export const editFooterInfoRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_FOOTER_INFO_REQUEST,
  payload,
  meta,
})

export const getFooterInfoByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_FOOTER_INFO_BY_ID_REQUEST,
  payload,
  meta,
})

export const deleteFooterInfoRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.DELETE_FOOTER_INFO_BY_ID_REQUEST,
  payload,
  meta,
})

export const getAllTypeInfo = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_TYPE_INFO,
  meta,
  payload
});


