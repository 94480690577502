// @ts-nocheck
import React, { Component } from 'react';
import Select from 'arui-feather/select';
import Button from 'arui-feather/button';
import { connect } from 'react-redux';
import cn from 'classnames'
import style from './button.module.scss'
// import style from 'components/Flows/Form/form.module.scss';

// const FormItem = Form.Item;
// const Option = Select.Option;

class SelectMultiple extends Component {
  onChange = (value: any) => {
    const { input, multiple, handleOnChange, accept, dispatch }: any = this.props;
    input.onChange(
      multiple ? value.map((itemValue: any) => itemValue) : value[0],
    );
    if (handleOnChange && !accept){handleOnChange(multiple ? value.map((itemValue: any) => itemValue) : value[0], dispatch)}
  };

  handleChange = () => {
    const { input, handleOnChange, dispatch }: any = this.props;
    if (handleOnChange && input?.value) {
      handleOnChange(input?.value, dispatch);
    }
  };

  render() {
    const {
      input,
      id,
      label,
      data = [],
      required,
      disabled,
      hasFeedback,
      loading,
      itemOptions,
      accept,
      multiple,
      handleOnChange,
      meta: { touched, invalid, error },
      ...rest
    }: any = this.props;

    const hasError = touched && invalid;
    const value = input.value
      ? typeof input.value.toJS === 'function'
        ? input.value.toJS()
        : input.value
      : [];

    let options = [];
    if (itemOptions) {
      data &&
        data.forEach((item: any) => {
          const option = itemOptions(item);
          if (option) {
            options.push(option);
          }
        });
    } else {
      options = data && data.map((value: any) => ({ value, text: value }));
    }

    return (
      <>
        <Select
          className={cn({
            [style.valueSetAccecpt]: handleOnChange && accept
          })}
          mode={multiple ? 'check' : 'radio-check'}
          width="available"
          view="filled"
          value={value}
          onChange={this.onChange}
          options={options}
          disabled={disabled}
          error={touched ? error : undefined}
          {...rest}
        />
        {handleOnChange && accept && <Button view='extra' className={style.buttonStyle} onClick={this.handleChange}>Accept</Button>}
      </>
    );
  }
}

export const InputSelect = connect()(SelectMultiple);
