// @ts-nocheck
import React from 'react';
import GraphTable from 'components/Graphs/Table';
import { ICategoryTable, IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import { CATALOG_LANGUAGES_TABLE } from 'constants/tables';
import { getAllLanguagesRequest } from '../../redux/actions';
import StringCol from 'components/Flows/Elements/StringCol'

class LanguagesTable extends React.Component<ICategoryTable> {

  normalizeTableData = (head: IHeadTable[], body: IBodyTable[]) : any => {
    let processedData = body && body.map((item, i) => {
      const { id, name, active } = item;
      let emptyWidth: any = '';
      return {
        id,
        cells: [
          {
            element: StringCol(false,i + 1),
            id,
            type: 'id',
            width: emptyWidth
          },
          {
            element: StringCol(true,name),
            id,
            type: 'name',
            width: emptyWidth
          },
        ]};
    }) || [];

    const normalizeData = processedData.map((val: any) => {
      let newCells = val
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells
    });

    return normalizeData;
  };

  getRowLink = (id: any) => `/languages/${id}`

  render() {
    const { handleRowClick }: any = this.props
    const head = [
      {
        id: 'id',
        label: '№',
        style: { width: '1' },
      },
      {
        id: 'name',
        label: 'Name',
        style: { width: ' ' },
      },
    ];

    return (
      <GraphTable
        title="Languages table"
        table={CATALOG_LANGUAGES_TABLE}
        normalizeTableData={this.normalizeTableData}
        init={getAllLanguagesRequest}
        onRowClick={handleRowClick}
        head={head}
        link={this.getRowLink}
        width={'6'}
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
      />
    );
  }
}

export default LanguagesTable;
