import Commodities from './pages';

export default [
  {
    type: 'private',
    exact: true,
    path: '/commodities',
    roles: ['admin'],
    component: Commodities,
  },
  {
    type: 'private',
    exact: true,
    path: '/commodities/:id',
    roles: ['admin'],
    component: Commodities,
  },
];
