import StockTypes from './pages';

export default [
  {
    type: 'private',
    exact: true,
    path: '/stock-types',
    roles: ['admin'],
    component: StockTypes,
  },
  {
    type: 'private',
    exact: true,
    path: '/stock-types/:id',
    roles: ['admin'],
    component: StockTypes,
  },
];
