const validate = (values: any) => {
  const errors = {} as any;

  if (values.nameRu === '' && Object.keys(values.nameRu).length === 0) {
    errors.nameRu = 'Required field';
  }
  if (values.nameEn  === ''  && !Object.keys(values.nameEn).length) {
    errors.nameEn = 'Required field';
  }
  console.log('Validate values.logo',  values.logo);
  if (values.logo && values.logo.url  === '') {
    console.log('File required');
    errors.logo = 'Required field';
  }

  return errors;
};

export default validate