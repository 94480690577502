// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import { formReset } from 'components/Graphs/Form/actions';
import {
  getUsersRolesByIdRequest,
  editPasswordUserRequest
} from '../../redux/actions';
import normalize from './normalize';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { AUTHORS_FORM } from 'constants/forms';
import { connect } from 'react-redux';

interface IAuthorsForm {
  title?: string;
  form: string;
  match: any
}

class AuthorsForm extends React.Component<IAuthorsForm> {

  handleClick = () => {
    const { history, dispatch }: any = this.props;
    dispatch(reset(AUTHORS_FORM));
    // dispatch(getAllAuthorsRequest({ meta: { table: AUTHORS_TABLE, callback: tableDataSuccess } }));
    dispatch(formReset({ meta: { form: AUTHORS_FORM } }));
    history.push('/users-roles');
  };

  get actions() {
    const { match: { params: { id } } } = this.props;
    const action = id ? 'Edit password this user' : 'Add new user';
    return (
      <GridRow>
        <GridCol>
          <Button className={'button-float'} view="extra" size="s" type="submit">
            {action}
          </Button>
          <Button className={'mr20 button-float'} size="s" onClick={this.handleClick}>Cancel</Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = ({ keycloakId, password }: any) => {
    return {
      keycloakId,
      password
    };
  };

  render() {
    const { match: { params: { id } } } = this.props;
    return (
      <GraphForm
        form={AUTHORS_FORM}
        actions={this.actions}
        onSuccess={this.handleClick}
        graphId={id}
        init={getUsersRolesByIdRequest}
        edit={editPasswordUserRequest}
        title="User form"
        submit={this.submit}
        normalize={normalize}
        validate={() => {}}
        type={id ? 'edit' : 'create'}
        columns={1}
        config={config}
        width="12"
        reInitialize
      />
    );
  }
}

export default compose<any>(
  connect(),
  withRouter,
)(AuthorsForm);
