import { language } from 'utils/formatters';

const normalize = (data: any) => {
  if (!data) return null;
  let cormData = {
    nameEn: data.nameEn,
    nameRu: data.nameRu,
    infoEn: data.infoEn,
    infoRu: data.infoRu,
    typeInfo:data.type,
    order:data.order,
  };

  console.log('normalize form cormData=', cormData)
  return cormData;
};

export default normalize;
