import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form/immutable';
import { convertToMutable } from 'utils/select';

const selectGraphFormState = (state: any) => state.graphForm;

export const selectCurrentForm = createSelector(
  [
      selectGraphFormState,
      (state: any, ownProps: any) => ownProps.form,
  ],
  (graphForm, form) => graphForm.get(form),
);

export const makeSelectLoading = () => createSelector(
  selectCurrentForm,
  state => state && state.get('loading'),
);

const selectGraphForm = (state: any, ownProps: any) => ({
  state,
  selector: formValueSelector(ownProps.form),
  config: ownProps.config,
  form: ownProps.form,
});

export const makeSelectFields = () => createSelector(
  selectGraphForm,
  ({ state, selector, config }) => config.reduce((obj: any, item: any) => {
    const fieldValue = selector(state, item.id);
    obj[item.id] = convertToMutable(fieldValue);
    return obj;
  }, {}),
);

export const makeSelectField = (id: any) => createSelector(
  selectGraphForm,
  ({ state, selector }) => {
    const fieldValue = selector(state, id);
    return convertToMutable(fieldValue);
  },
);
