// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';

import GraphForm from 'components/Graphs/Form';
import { formReset } from 'components/Graphs/Form/actions';
import { tableDataSuccess } from 'components/Graphs/Table/actions';
import { getObjectTrim } from 'utils/formatters';
import {
  createNewCategoryRequest,
  getCategoryByIdRequest,
  getAllcategoriesRequest,
  editCategoryRequest,
} from '../../redux/actions';
import normalize, {NUM_SLOTS} from './normalize';
import config from './fields';
import validate from './validate';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { CATALOG_CATEGORIES_FORM } from 'constants/forms';
import { CATALOG_CATEGORIES_TABLE } from 'constants/tables';
import { LANGUAGE_EN, LANGUAGE_RU } from 'constants/languages';
import { connect } from 'react-redux';

interface ICategoryForm {
  title?: string;
  form: string;
  match: any;
}

class CategoryForm extends React.Component<ICategoryForm> {
  // eslint-disable-next-line react/sort-comp
  handleClick = () => {
    const { history, dispatch }: any = this.props;
    dispatch(reset(CATALOG_CATEGORIES_FORM));
    dispatch(
      getAllcategoriesRequest({
        meta: { table: CATALOG_CATEGORIES_TABLE, callback: tableDataSuccess },
      }),
    );
    dispatch(formReset({ meta: { form: CATALOG_CATEGORIES_FORM } }));
    history.push('/categories');
  };

  get actions() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const action = id ? 'Edit this category' : 'Add new category';
    return (
      <GridRow>
        <GridCol>
          <Button
            className={'button-float'}
            view="extra"
            size="s"
            type="submit"
          >
            {action}
          </Button>
          <Button
            className={'mr20 button-float'}
            size="s"
            onClick={this.handleClick}
          >
            Cancel
          </Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = ({
    active,
    nameEN,
    nameRU,
    sector,
    filesEn,
    filesRu,
    serviceCategory,
    blastNote,
  }: any) => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    console.log('submit form nameEn=', nameEN);
    console.log('submit form filesEn=', filesEn);
    console.log('submit form filesRu=', filesRu);
    const files: any = { en: [], ru: [] };
    for(let i = 0; i< NUM_SLOTS; i++) {
       if( filesEn[i].url !== undefined ) {
         files.en.push(filesEn[i])
       }
    }
    for(let i = 0; i< NUM_SLOTS; i++) {
      if( filesRu[i].url !== undefined ) {
        files.ru.push(filesRu[i])
      }
    }

    return {
      id,
      name: [
        {
          language: { id: LANGUAGE_RU, name: 'Рус' },
          translatedValue: nameRU,
        },
        {
          language: { id: LANGUAGE_EN, name: 'En' },
          translatedValue: nameEN,
        },
      ],
      serviceCategory,
      active,
      sectors: sector,
      files,
    };
  };

  render() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    return (
      <GraphForm
        title="Categories form"
        form={CATALOG_CATEGORIES_FORM}
        actions={this.actions}
        onSuccess={this.handleClick}
        graphId={id}
        init={getCategoryByIdRequest}
        edit={editCategoryRequest}
        create={createNewCategoryRequest}
        submit={this.submit}
        validate={(values: object) => validate(getObjectTrim(values))}
        normalize={normalize}
        type={id ? 'edit' : 'create'}
        columns={1}
        config={config}
        width="5"
        reInitialize
      />
    );
  }
}

export default compose<any>(connect(), withRouter)(CategoryForm);
