const DAY_CONTAIN_MSEC = 24 * 3600 * 1000;


/* Возвращает число рабочих дней в указанном диапазоне, учитывая рабочий календарь */
export function getNumberWorkedDays(workCalendars: any, startDate: any, endDate: any) {
    if (!startDate || !endDate) {
        return 0;
    }
    const start = convertDate(startDate);
    const end = convertDate(endDate);

  const diff = end - start;
  let days = Math.floor(diff / DAY_CONTAIN_MSEC) + 1;

  // Subtract two weekend days for every week in between
  const weeks = Math.floor(days / 7);
  days = days - weeks * 2;

  // Handle special cases
  const startDay = start.getDay();
  const endDay = end.getDay();

  // Remove weekend not previously removed.
  if (startDay - endDay > 1) {
    days = days - 2;
  }

  // Remove start day if span starts on Sunday but ends before Saturday
  if (startDay === 0 && endDay !== 6) {
    days = days - 1;
  }

  // Remove end day if span ends on Saturday but starts after Sunday
  if (endDay === 6 && startDay !== 0) {
    days = days - 1;
  }

    const numHolydays = calcHolidays(workCalendars,start, end);
    days = days - numHolydays;

    const numMovedWorkdays = calcMovedWorkdays(workCalendars,start, end);
    days = days + numMovedWorkdays;

    return days;
}




/*  Проверка является ли день рабочим, учитывая календарь */

export function isWorkday(workCalendars: any, strDate: any) {
    if (!strDate) {
        return true;
    }
    const date = convertDate(strDate);

    if (isHoliday(workCalendars, date)) {
        return false;
    }
    if (isMovedWorkday(workCalendars, date)) {
        return true;
    }
    if (date.getDay() === 0 || date.getDay() === 6) {
        return false;
    }
    return true;
}

function isHoliday(workCalendars: any, date: any) {
    const arr: any = workCalendars.holidays;
    if (calcSpecialDays(arr, date, date) > 0) {
        return true;
    }
    return false;
}

function isMovedWorkday(workCalendars: any, date: any) {
    const arr: any = workCalendars.movedWorkDays;
    if (calcSpecialDays(arr, date, date) > 0) {
        return true;
    }
    return false;
}

// преобразование строки в дату
function convertDate(dataStr_DDMMYYY: any): any {
    const d = dataStr_DDMMYYY.split('.');
    return new Date(`${d[2]}.${d[1]}.${d[0]}`);
}

function calcHolidays(workCalendars: any, start: any, end: any): any {
    const arr: any = workCalendars.holidays
    return calcSpecialDays(arr, start, end);
}

function calcMovedWorkdays(workCalendars: any, start: any, end: any): any {
    const arr: any = workCalendars.movedWorkDays;
    return calcSpecialDays(arr, start, end);
}

function calcSpecialDays(arr: any, start: any, end: any): any {
    const yearStart = start.getFullYear();
    const yearEnd = end.getFullYear();
    let count = 0;
    if (arr[String(yearStart)] !== undefined) {
        arr[String(yearStart)].forEach((date: any) => {
            if (date >= start && date <= end) {
                count++;
            }
        });
    }
    if (yearStart !== yearEnd) {
        if (arr[String(yearEnd)] !== undefined) {
            arr[String(yearEnd)].forEach((date: any) => {
                if (date >= start && date <= end) {
                    count++;
                }
            });
        }
    }
    return count;
}

