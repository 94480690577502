const normalize = (data: any) => {
  if (!data) return null;

  // let resObject = {} as any
  //
  // data.forEach((setting: any) => {
  //   resObject[setting.id] = setting
  // })

  return data;
};

export default normalize;
