// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { isEmpty } from 'lodash'

import API_PRODUCTS from '../api';
import {
  GET_ALL_PRODUCTS_REQUEST,
  DELETE_COMPANY_BY_ID_REQUEST,
  GET_PRODUCT_BY_ID_REQUEST,
  EDIT_PRODUCT_REQUEST,
  CREATE_PRODUCT_REQUEST,
  GET_DELIVERY_MAILING_REQUEST, CREATE_DELIVERY_MAILING_REQUEST,
  EDIT_DELIVERY_MAILING_REQUEST,
  GET_DELIVERY_MAILING_BULK_REQUEST,
  GET_DELIVERY_MAILING_CYPRUS_REQUEST,
  ACTIVATE_MAILING_REQUEST, GET_ALL_PRODUCTS_FAST_REQUEST,
} from './constants';
import { openErrorMessage, openSuccessMessage } from '../../../components/Message/actions';
import { editClientsFailure, getAllClientsRequest } from '../../Client/redux/actions';
import { PRODUCTS_TABLE } from '../../../constants/tables';
import { tableDataSuccess } from '../../../components/Graphs/Table/actions';
import { getAllProductsRequest, activateMailingRequest, getProductByIDRequest } from './actions';
import { createNewAuthorsFailure } from '../../Authors/redux/actions';
import API_GRAPS from '../../../components/GrapesJs/api';
import { setValueSet } from '../../../components/ValueSet/actions';

export let blastNote = null;


function* getAllProducts({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_PRODUCTS.getAllProducts);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getAllProductsFast({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_PRODUCTS.getAllProductsFast);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}


function* deleteProductByID({ payload: { id }, meta }: any) {
  try {
    yield call(API_PRODUCTS.deleteByIdProduct, id);
    yield put(openSuccessMessage('Product successfully deleted.'));
    yield put(getAllProductsRequest({ meta: { table: PRODUCTS_TABLE, callback: tableDataSuccess } }));
  } catch (error) {
    openErrorMessage(error)
  }
}

function* getProductByID({ payload: { callback, data: { id } }, meta: { form }}: any) {
  try {
    const response = yield call(API_PRODUCTS.getProductByID, { id });
    if (callback) {
// Довольно некрасивое решение хранить значение поля так , но здесь все мутно!
// Почему то то загруженный продукт не хранится в redux и нет времени с этим разбираться!
      blastNote = response.blastNote;
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    openErrorMessage(error)
  }
}

function* editProduct({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_PRODUCTS.editProduct, { data: fields });
    yield put(openSuccessMessage('Product successfully changed.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    openErrorMessage(error)
    yield put(editClientsFailure({ error }));
  }
}

function* createProduct({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_PRODUCTS.createNewProduct, { data: fields });
    yield put(openSuccessMessage('Product successfully created.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    openErrorMessage(error)
  }
}

function* getDeliveryMailing({ payload: { callback, data: { id } }, meta: { form }}: any) {
  try {
    const response = yield call(API_PRODUCTS.getDeliveryMailing, { id });
    blastNote = response.blastNote;
    console.log('getDeliveryMailing #1 blastNote=', blastNote);
    if (isEmpty(response)){
      const responseInit = yield call(API_PRODUCTS.getProductByID, { id });
      blastNote = responseInit.blastNote;
      console.log('getDeliveryMailing #2 blastNote=', blastNote);
      return yield put(callback({ payload: { response: { subject: responseInit?.name } }, meta: { form } }));
    }
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    openErrorMessage(error)
  }
}

function* getDeliveryMailingBulk({ payload: { callback, data: { id } }, meta: { form }}: any) {
  try {
    const response = yield call(API_PRODUCTS.getDeliveryMailing, { id });
    blastNote = response.blastNote;
    console.log('getDeliveryMailingBulk #1 blastNote=', blastNote);

    const product = yield call(API_PRODUCTS.getProductByID, {id: response?.productId})
    blastNote = product.blastNote;
    console.log('getDeliveryMailingBulk #2 blastNote=', blastNote);

    yield put(setValueSet({ payload: { response: [ product?.categoryId, product?.productTypeId ] }, meta: { form: 'initProduct' } }))
    const { mailingListSettingsId } = response
    const { footerAccId } = yield call(API_GRAPS.getTemplates, mailingListSettingsId);
    const templateFooter = yield call(API_GRAPS.getTemplateFooter, footerAccId);

    response.deliveryACC.footer = response.deliveryACC.footer !== "string" ? templateFooter : ""
    response.initProduct = { [product?.categoryId]: { [ product?.productTypeId ]: true } }
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    openErrorMessage(error)
  }
}

function* getDeliveryMailingCyprus({ payload: { callback, data: { id } }, meta: { form }}: any) {
  try {
    const response = yield call(API_PRODUCTS.getDeliveryMailing, { id });
    const product = yield call(API_PRODUCTS.getProductByID, {id: response?.productId})
    yield put(setValueSet({ payload: { response: [ product?.categoryId, product?.productTypeId ] }, meta: { form: 'initProduct' } }))
    const { mailingListSettingsId } = response
    const { footerMifidId } = yield call(API_GRAPS.getTemplates, mailingListSettingsId);
    const templateFooter = yield call(API_GRAPS.getTemplateFooter, footerMifidId);

    response.deliveryMIFID.footer = response.deliveryMIFID.footer !== "string" ? templateFooter : ""
    response.initProduct = { [product?.categoryId]: { [ product?.productTypeId ]: true } }
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    openErrorMessage(error)
  }
}

function* createDeliveryMailing({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_PRODUCTS.createDeliveryMailing, { data: fields });
    yield put(openSuccessMessage('Product successfully created.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    openErrorMessage(error)
  }
}

function* editDeliveryMailingRequest({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    let responseInit = yield call(API_PRODUCTS.getProductByID, { id: fields?.productId });
    fields.annotation = fields.annotation || responseInit.annotation
    const response = yield call(API_PRODUCTS.editDeliveryMailing, { data: fields });
    responseInit.annotation = fields?.annotation || responseInit.annotation
    responseInit.date = fields?.createdDateTime?.split(' ')?.[0]
    delete responseInit.created
    delete responseInit.updated
    yield call(API_PRODUCTS.editProduct, { data: responseInit })
    yield put(openSuccessMessage('Delivery mailing successfully changed.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    openErrorMessage(error)
    yield put(editClientsFailure({ error }));
  }
}

function* activateMailing({ payload: { clientType, productId }}: any) {
  try {
    yield call(API_PRODUCTS.activateMailing, { clientType, productId });
    yield put(openSuccessMessage('Mailing successfully created.'))
  } catch (error) {
    openErrorMessage(error)
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_ALL_PRODUCTS_REQUEST, getAllProducts),
// add 2021-11-01
    takeLatest(GET_ALL_PRODUCTS_FAST_REQUEST, getAllProductsFast),

    takeLatest(DELETE_COMPANY_BY_ID_REQUEST, deleteProductByID),
    takeLatest(GET_PRODUCT_BY_ID_REQUEST, getProductByID),
    takeLatest(EDIT_PRODUCT_REQUEST, editProduct),
    takeLatest(CREATE_PRODUCT_REQUEST, createProduct),
    takeLatest(GET_DELIVERY_MAILING_REQUEST, getDeliveryMailing),
    takeLatest(CREATE_DELIVERY_MAILING_REQUEST, createDeliveryMailing),
    takeLatest(EDIT_DELIVERY_MAILING_REQUEST, editDeliveryMailingRequest),
    takeLatest(GET_DELIVERY_MAILING_BULK_REQUEST, getDeliveryMailingBulk),
    takeLatest(GET_DELIVERY_MAILING_CYPRUS_REQUEST, getDeliveryMailingCyprus),
    takeLatest(ACTIVATE_MAILING_REQUEST, activateMailing)
  ]);
}

export default Saga;
