import * as ACTION_TYPE from './constants';

export const getAllClientsRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_CLIENTS_REQUEST,
  meta,
  payload
});

export const createNewClientsRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_CLIENTS_REQUEST,
  payload,
  meta,
});

export const editClientsRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_CLIENTS_REQUEST,
  payload,
  meta,
})

export const editClientsFailure = (payload: any) => ({
  type: ACTION_TYPE.EDIT_CLIENTS_FAILURE,
  payload,
})

export const getClientsByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_CLIENTS_BY_ID_REQUEST,
  payload,
  meta,
})

export const deleteClientsByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.DELETE_CLIENTS_BY_ID_REQUEST,
  payload,
  meta,
})

export const getProfileClient = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_PROFILE_CLIENT,
  payload,
  meta,
})

export const editProfileClient = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_PROFILE_CLIENT,
  payload,
  meta,
})

export const setSectorsCustom = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.SET_SECTORS_CLIENTS_CUSTOM,
  payload,
  meta,
})
