// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import GraphTable from 'components/Graphs/Table';
import { ICategoryTable, IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import { PRODUCTS_TABLE } from 'constants/tables';
import {
  createFilterProductRequest,
  emptyAction,
  getAllProductsFastRequest,
  getAllProductsRequest
} from '../../redux/actions';
import StringCol from 'components/Flows/Elements/StringCol';
import IconDelete from 'arui-feather/icon/action/delete';
import { getAllAuthorTypesRequest } from '../../../Catalog/AuthorTypes/redux/actions';
import { getAllcategoriesRequest } from '../../../Catalog/Categories/redux/actions';
import { getAllAuthorsRequest } from '../../../Authors/redux/actions';
import { deleteCompanyByIdRequest } from '../../redux/actions';
import Button from 'arui-feather/button';
import { Link } from 'react-router-dom';
import { productTypeRequest } from '../../../Catalog/ProductTypes/redux/actions';

class ProductsTable extends React.Component<ICategoryTable> {

  normalizeTableData = (head: IHeadTable[], body: IBodyTable[]): any => {
    let processedData = body && body.map((item, i) => {
      const { id, name, categoryId, date, authors, productTypeId, numRecords }:any = item;
      let emptyWidth: any = '';
      return {
        id,
        cells: [
          {
            element: StringCol(false, i + 1),
            id,
            type: 'id',
            width: emptyWidth,
          },
          {
            element: name,
            id,
            type: 'name',
            width: emptyWidth,
          },
          {
            element: categoryId,
            id,
            type: 'category',
            width: emptyWidth,
          },
          {
            element: date,
            id,
            type: 'date',
            width: emptyWidth,
          },
          {
            element: authors,
            id,
            type: 'analyst',
            width: emptyWidth,
          },
          {
            element: productTypeId,
            id,
            type: 'productTypeId',
            width: emptyWidth,
          },
          {
            element: <IconDelete size={'s'}/>,
            id,
            type: 'delete',
            width: emptyWidth,
            customClass: 'delete-icon',
          },
        ],
      };
    }) || [];

    const normalizeData = processedData.map((val: any) => {
      let newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });
    return normalizeData;
  };

  handleDelete = (id: string) => {
    const { dispatch }: any = this.props;
    dispatch(deleteCompanyByIdRequest({ payload: { id } }));
  };

  getRowLink = (id: any) => `products/edit/${id}`;

  render() {
    const { handleRowClick }: any = this.props;
    const head = [
      {
        id: 'id',
        label: 'ID',
        style: { width: '1' },
      },
      {
        id: 'name',
        label: 'Name',
        style: { width: '3' },
      },
      {
        id: 'category',
        action: getAllcategoriesRequest,
        type: 'valueSet',
        label: 'Category',
        style: { width: '2' },
      },
      {
        id: 'date',
        label: 'Date',
        style: { width: ' ' },
      },
      {
        id: 'analyst',
        label: 'Authors',
        action: getAllAuthorsRequest,
        type: 'valueSet',
        style: { width: '3' },
      },
      {
        id: 'productTypeId',
        label: 'Product type',
        action: productTypeRequest,
        type: 'valueSet',
        style: { width: ' ' },
      },
      {
        id: 'delete',
        type: 'delete',
        label: '',
        style: { width: '1' },
      },
    ];

    return (
      <GraphTable
        customTitleHtml={<Link className={'button-prod'} to={'products/add'}>
          <Button view="extra" size="s">
            Add new product
          </Button>
        </Link>}
        table={PRODUCTS_TABLE}
        // title="Product table"
        normalizeTableData={this.normalizeTableData}
// мне не надо что то грузить, здесь загрузка по фильтру производится, пустая action запускается
//         init={getAllProductsFastRequest}
        init={emptyAction}
        onRowClick={handleRowClick}
        onDelete={(id: any) => this.handleDelete(id)}
// Новое свойство: показывать /не показывать компонент пагинация
        show_pagination={true}
        head={head}
        link={this.getRowLink}
        width={'12'}
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
      />
    );
  }
}

export default connect()(ProductsTable);
