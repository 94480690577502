import React from 'react';

import Button from 'arui-feather/button';
import Notification from 'arui-feather/notification';
import API_USERS from "../../../modules/UsersRoles/api";
import API_TASK from "./api";


class SynchroUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            status: 'stop',
            taskId: null,
            intervalId: null,
            showResult: false,
            content: null
        }
        this.timeout = 500;    // ms
        this.startProcess = this.startProcess.bind(this);
        this.getProgress = this.getProgress.bind(this);
    }

    getProgress(id) {
        // console.log('getProgress start');
        this.setState({status: 'start'});
        API_TASK.getByIdTask(id).then(res => {
                // console.log('getProgress result=', res)
                if (res.complete) {
                    // console.log('!!!!!CLEAR process ..', this.state.intervalId);
                    clearInterval(this.state.intervalId);

                    // console.log('Content = ', res.content)
                    const content = JSON.parse(res.content);
                    // console.log('Content OBJ = ', content)
                    this.setState({
                        status: 'ok',
                        taskId: null,
                        showResult: true,
                        content
                    });

                }
            }
        ).catch(error => {
            clearInterval(this.state.intervalId);
            console.log('Ошибка error=', error)
            this.setState({
                status: 'error',
                taskId: null,
                intervalId: null,
                showResult: true,
            });
        })

    }

    startProcess() {
        // console.log('!!!!!Start process ..');
        this.setState({status: 'start'});
        API_USERS.getSynchroKeyCloak().then(res => {
                // console.log('startProcess result=', res)
// проверка прогресса
                const intervalId = setInterval(() => this.getProgress(res.id), this.timeout);
                console.log('intervalId=', intervalId)
                this.setState({
                    status: 'work',
                    taskId: res.id,
                    intervalId: intervalId
                });

            }
        ).catch(error => {
            console.log('Ошибка error=', error)
            this.setState({
                status: 'error',
                taskId: null,
                intervalId: null,
                showResult: true
            });
        })

    }

    render() {
        const {status, showResult, content } = this.state;
        let message;
        let title = '';
        if (showResult) {
            if( status === 'ok') {
                title = 'Success';
                message = `Fix ${content.fixUsers} from ${content.users} users`;
            }
            else if(status === 'error') {
                title = 'Success';
                message = 'Error network operation';
            }
            else {
                title = 'Success';
                message = 'Unknown error';
            }
        }
        else  message = '';

        return (
            <>
                <Button
                    size="s"
                    onClick={this.startProcess}
                >
                    {this.props.text}
                </Button>
                <Notification
                    visible={this.state.showResult}
                    status={status}
                    offset={12}
                    stickTo='right'
                    title= {title}
                    onCloseTimeout={() => {
                        this.setState({showResult: false});
                    }}
                    onCloserClick={() => {
                        this.setState({showResult: false});
                    }}
                >
                    {message}
                </Notification>
            </>
        )
    }
}

export default SynchroUser;
