// @ts-nocheck
import React from 'react';
import {getAllTypeInfo} from "../../../redux/actions";
import {getAllAuthorTypesRequest} from "../../../../Catalog/AuthorTypes/redux/actions";

export default (id:any) => [
  {
    type: 'ValueSet',
    id: 'typeInfo',
    placeholder: 'Type',
    label: 'Type',
    view: 'filled',
    width: 'available',
    size: 's',
    itemOptions: (item: any) => ({ value: item.id, text: item.name}),
    action: getAllTypeInfo,
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 10,
    },
  },
  {
    type: 'number',
    id: 'order',
    placeholder: '',
    label: 'Order sort by some types' ,
    view: 'filled',
    width: 'available',
    size: 's',
    fieldWidth: '4',
    customPosition: {
      label: 6,
      field: 6,
    },
  },
  {
    isGroup: true,
    label: 'Info En',
    fieldWidth: '12',
    customPosition: {
      label: 1,
      field: 11,
    },

    items: [
      {
        type: 'text',
        id: 'nameEn',
        placeholder: '',
        label: 'nameEn' ,
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginLeft: '135px',
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'EN',
        type: 'editorContact',
        id: 'infoEn',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },

  {
    isGroup: true,
    label: 'Info Ru',
    customPosition: {
      label: 1,
      field: 11,
    },

    items: [
      {
        type: 'text',
        id: 'nameRu',
        placeholder: '',
        label: 'nameRu' ,
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginLeft: '135px',
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU',
        type: 'editorContact',
        id: 'infoRu',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },

    ],
  },
];
