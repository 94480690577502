import СompaniesEdit from './pages/edit';
import СompaniesAdd from './pages/add';
import СompaniesEditStocks from './pages/form/stocks';
import СompaniesEditDividents from './pages/form/dividents'
import CompanyEditTicker from './pages/form/tickers'
import СompaniesView from './pages/view';
export default [
  {
    type: 'private',
    exact: true,
    path: '/companies',
    roles: ['admin'],
    component: СompaniesView,
  },
  {
    type: 'private',
    exact: true,
    path: '/companies/edit/:id',
    roles: ['admin'],
    component: СompaniesEdit,
  },
  {
    type: 'private',
    exact: true,
    path: '/companies/create',
    roles: ['admin'],
    component: СompaniesAdd,
  },
  {
    type: 'private',
    exact: true,
    path: '/companies/edit/:id/:tab',
    roles: ['admin'],
    component: СompaniesEdit,
  },
  {
    type: 'private',
    exact: true,
    path: '/companies/edit/:id/:tab/:stockId',
    roles: ['admin'],
    component: СompaniesEdit,
  },

];