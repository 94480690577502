// @ts-nocheck
import React from 'react';
import './DashBoardTotal.scss';
import IconUsers from 'arui-feather/icon/banking/payment-to-person';
import IconDownload from 'arui-feather/icon/action/download';
import IconEmail from 'arui-feather/icon/action/email';
import {getPeriodDashboard, getPrevPeriodDashboard} from '../calculate';
import {getNumberWorkedDays} from '../../../../utils/dashboardUtils';

const SvgDown = (): any => {
  return (
      <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5 6L10 0H0L5 6Z" fill="white"/>
      </svg>
  );
};
const SvgUp = (): any => {
  return (
      <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5 0L10 6H0L5 0Z" fill="white"/>
      </svg>
  );
};

const CardTotal = ({mode, title, currentValue, prevValue}: any): any => {
  let procent = '';
  let icon;
  let direction;
  switch (mode) {
    case 'AUT':
      icon = <IconUsers className={'dash-value-back__icon'} size={'m'}/>;
      break;
    case 'DNL':
      icon = <IconDownload className={'dash-value-back__icon'} size={'m'}/>;
      break;
    case 'DNE':
      icon = <IconEmail className={'dash-value-back__icon'} size={'m'}/>;
      break;
  }

  if (prevValue) {
    if (currentValue >= prevValue) {
      procent = `${Math.round((currentValue * 100) / prevValue) - 100}%`;
      direction = <SvgUp/>;
    } else {
      procent = `${100 - Math.round((currentValue * 100) / prevValue)}%`;
      direction = <SvgDown/>;
    }
  }
  return (
      <div className={'dashboard-total-plate'}>
        <div className={'dash-value'}>
          <div>
            <div className={'dash-value-back'}>{icon}</div>
            <div className={'dash-total-number'}>{currentValue}</div>
          </div>
        </div>
        <div className={'dash-title'}>{title}</div>
        <div
            className={
              currentValue >= prevValue
                  ? 'dash-prev dash-prev__green'
                  : 'dash-prev dash-prev__red'
            }
        >
          <div className={'dash-prev-value'}>
            {procent}
            <span style={{marginLeft: '7px'}}>{direction}</span>
          </div>
        </div>
      </div>
  );
};

class DashboardTotal extends React.Component {

  render() {
    const {data}: any = this.props;
    const dataNormalize: any = data
    const autCur =
        dataNormalize && dataNormalize.AUT ? dataNormalize.AUT.cur : '';
    const autPrev =
        dataNormalize && dataNormalize.AUT ? dataNormalize.AUT.prev : '';
    const dnlCur =
        dataNormalize && dataNormalize.DNL ? dataNormalize.DNL.cur : '';
    const dnlPrev =
        dataNormalize && dataNormalize.DNL ? dataNormalize.DNL.prev : '';
    const dneCur =
        dataNormalize && dataNormalize.DNE ? dataNormalize.DNE.cur : '';
    const dnePrev =
        dataNormalize && dataNormalize.DNE ? dataNormalize.DNE.prev : '';

    return (
        <>
          <div className={'dashboard-title'}>
            Average users activity for period
          </div>
          <CardTotal
              mode="AUT"
              title={'Daily Visits '}
              currentValue={autCur}
              prevValue={autPrev}
          />
          <CardTotal
              mode="DNL"
              title={'Daily Reports Web Download'}
              currentValue={dnlCur}
              prevValue={dnlPrev}
          />
          <CardTotal
              mode="DNE"
              title={'Daily Reports E-mails Download'}
              currentValue={dneCur}
              prevValue={dnePrev}
          />
        </>
    );
  }
}

export default DashboardTotal;
