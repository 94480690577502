import ProductTypes from './pages';

export default [
  {
    type: 'private',
    exact: true,
    path: '/product-types',
    roles: ['admin'],
    component: ProductTypes,
  },
  {
    type: 'private',
    exact: true,
    path: '/product-types/:id',
    roles: ['admin'],
    component: ProductTypes,
  },
];
