import AuthorsEdit from './pages/edit';
import AuthorsView from './pages/view';
import AuthorsAdd from './pages/add';
export default [
  {
    type: 'private',
    exact: true,
    path: '/authors',
    roles: ['admin'],
    component: AuthorsView,
  },
  {
    type: 'private',
    exact: true,
    path: '/authors/edit/:id',
    roles: ['admin'],
    component: AuthorsEdit,
  },
  {
    type: 'private',
    exact: true,
    path: '/authors/create',
    roles: ['admin'],
    component: AuthorsAdd,
  },

];
