import ProductsView from './pages/view'
import EditPage from './pages/EditPage'
import AddPage from './pages/add'

export default [
  {
    type: 'private',
    exact: true,
    path: '/products',
    roles: ['admin'],
    component: ProductsView,
  },
  {
    type: 'private',
    exact: true,
    path: '/products/edit/:id',
    roles: ['admin'],
    component: EditPage,
  },
  {
    type: 'private',
    exact: true,
    path: '/products/edit/:id/:tab',
    roles: ['admin'],
    component: EditPage,
  },
  {
    type: 'private',
    exact: true,
    path: '/products/add',
    roles: ['admin'],
    component: AddPage,
  },
  {
    type: 'private',
    exact: true,
    path: '/products/add/annotation',
    roles: ['admin'],
    component: EditPage,
  },
  {
    type: 'private',
    exact: true,
    path: '/products/add/bulk',
    roles: ['admin'],
    component: EditPage,
  },
  {
    type: 'private',
    exact: true,
    path: '/products/add/cyprus',
    roles: ['admin'],
    component: EditPage,
  },
  // {
  //   type: 'private',
  //   exact: true,
  //   path: '/clients/create',
  //   roles: ['admin'],
  //   component: ClientsPage,
  // },

];
