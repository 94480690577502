


export const GET_ALL_COMPANIES_WIDGETSCHARTS_REQUEST = 'GET_ALL_COMPANIES_WIDGETSCHARTS_REQUEST'
export const GET_ALL_COMPANIES_WIDGETSCHARTS_SUCCESS = 'GET_ALL_COMPANIES_WIDGETSCHARTS_SUCCESS'
export const GET_ALL_COMPANIES_WIDGETSCHARTS_FAILURE = 'GET_ALL_COMPANIES_WIDGETSCHARTS_FAILURE'


export const GET_ALL_SECTOR_WIDGETSCHARTS_REQUEST = 'GET_ALL_SECTOR_WIDGETSCHARTS_REQUEST'
export const GET_ALL_SECTOR_WIDGETSCHARTS_SUCCESS = 'GET_ALL_SECTOR_WIDGETSCHARTS_SUCCESS'
export const GET_ALL_SECTOR_WIDGETSCHARTS_FAILURE = 'GET_ALL_SECTOR_WIDGETSCHARTS_FAILURE'


export const CREATE_COMPANIES_WIDGETSCHARTS_REQUEST = 'CREATE_COMPANIES_WIDGETSCHARTS_REQUEST'

export const CREATE_SECTORS_WIDGETSCHARTS_REQUEST = 'CREATE_SECTORS_WIDGETSCHARTS_REQUEST'

