// @ts-nocheck
import {call, put, select} from "redux-saga/effects";
import {PRODUCTS_TABLE} from "../../../../../constants/tables";
import {PRODUCT_FILTER_FORM} from "../../../../../constants/forms";
import API_PRODUCTS from "../../../api";
import {tableDataSuccess} from "../../../../../components/Graphs/Table/actions";
import {setFilterParameters} from "../../../../Statistics/redux/saga";
import {debounce} from "lodash";

// пока отключаю до разбирательства с непонятными эффектами
//const getFilterProductsDebouced = debounce(API_PRODUCTS.getFilterProducts, 500)

export function* onChangeFilterProduct({payload, meta}: any) {
    try {
        console.log('On change Filter', payload, meta)
        const state = yield select();
        const graphId = setFilterParameters(
            state,
            PRODUCTS_TABLE,
            PRODUCT_FILTER_FORM,
            false,
        );

        if (!meta.force) {
            graphId.page = 0
            graphId.pageSize = meta.pageSize
        }   // при смене фильтра
        else {
            graphId.page = (meta.page) ? meta.page : 0; // при смене страницы
            graphId.pageSize = meta.pageSize
        }
        const response = yield call(
            API_PRODUCTS.getFilterProducts,
            // getFilterProductsDebouced,
            graphId,
        );
        console.log('response=', response);
        yield put(
            tableDataSuccess({
                type: '',
                payload: {response},
                meta: {table: PRODUCTS_TABLE, callback: tableDataSuccess, currentPage: graphId.page, pageSize: meta.pageSize},
            }),
        );
    } catch (error) {
        console.error('onChangeFilterProduct : error', error);
        //      yield put(errorMessageFailure({payload: {error}}))
    }
}
