import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form/immutable';
import { convertToMutable } from 'utils/select';

const selectGraphTableState = (state: any) => state.graphTable;

export const selectCurrentTable = createSelector(
  [
      selectGraphTableState,
      (state: any, ownProps: any) => ownProps.table,
  ],
  (graphForm, form) => graphForm.get(form),
);

export const makeSelectLoading = () => createSelector(
    selectCurrentTable,
  state => state && state.get('loading'),
);

