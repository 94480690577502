// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';

import API_AUTHORS from '../api';
import {
  GET_ALL_AUTHORS_REQUEST, CREATE_NEW_AUTHORS_REQUEST, GET_AUTHORS_BY_ID_REQUEST, EDIT_AUTHORS_REQUEST,
  DELETE_AUTHORS_BY_ID_REQUEST,
} from './constants';
import { getAllAuthorsRequest, createNewAuthorsFailure } from './actions';
import { openSuccessMessage, openErrorMessage } from 'components/Message/actions'
import { AUTHORS_TABLE } from '../../../constants/tables';
import { tableDataSuccess } from '../../../components/Graphs/Table/actions';


function* getAllAuthors({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_AUTHORS.getAllAuthors);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(openErrorMessage(error.message))
  }
}

function* createNewAuthors({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_AUTHORS.createNewAuthors, { data: fields });
    yield put(openSuccessMessage('Author successfully created.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getAuthorsById({ payload: { callback, data: { id } }, meta: { form } }: any) {
  try {
    const response = yield call(API_AUTHORS.getByIdAuthors, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(openErrorMessage(error.message))
  }
}

function* deleteAuthorsById({ payload: { id }, meta }: any) {
//////////для отладки///////////////////////////////
/*
  const fields = {
    id,
    blocked: true
  }
  const response = yield call(API_AUTHORS.setBlockAuthor, { data: fields });
  yield put(openSuccessMessage('Author successfully changed.'));
  return;
*/
//////////////////////////////////////////


  try {
    yield call(API_AUTHORS.deleteByIdAuthors, id);
    yield put(getAllAuthorsRequest({ meta: { table: AUTHORS_TABLE, callback: tableDataSuccess } }));
    yield put(openSuccessMessage('Author successfully deleted.'));
  } catch (error) {
// 2021-10-04 Пользователь используется сделать неактивным
    if(  error.response && error.response.data.message && error.response.data.message.indexOf('exists in') !== -1) {
      console.log("blocker user (blocked = true)");
      const fields = {
        id,
        blocked: true
      }
      yield call(API_AUTHORS.setBlockAuthor, { data: fields });
      yield put(getAllAuthorsRequest({ meta: { table: AUTHORS_TABLE, callback: tableDataSuccess } }));
      yield put(openSuccessMessage('Author successfully changed.'));
    }
    else {
// иначе как ренее просто удалить
      yield put(createNewAuthorsFailure({payload: {error}}))
    }
  }
}

function* editAuthors({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_AUTHORS.editAuthors, { data: fields });
    yield put(openSuccessMessage('Author successfully changed.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_ALL_AUTHORS_REQUEST, getAllAuthors),
    takeLatest(CREATE_NEW_AUTHORS_REQUEST, createNewAuthors),
    takeLatest(GET_AUTHORS_BY_ID_REQUEST, getAuthorsById),
    takeLatest(EDIT_AUTHORS_REQUEST, editAuthors),
    takeLatest(DELETE_AUTHORS_BY_ID_REQUEST, deleteAuthorsById),
  ]);
}

export default Saga;
