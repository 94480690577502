// @ts-nocheck
import React from 'react';
import Input from 'arui-feather/input';

const renderTextField = ({ input, id, label, type, required, style, meta: { touched, error }, ...props }: any) => (
  <div style={style}>
    <Input
      {...input}
      {...props}
      id={id}
      type={type}
      required={required}
      label={label}

      autocomplete={false}
      error={touched ? error : undefined}
    />
  </div>
);

export default renderTextField;