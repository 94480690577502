// @ts-nocheck
import React from 'react';
import { language } from '../../../../../utils/formatters';
import { getAllSectorsRequest } from '../../../../Catalog/Sectors/redux/actions';
import { getAllAuthorsRequest } from 'modules/Authors/redux/actions'
import { getTickersByCompanyIDRequest } from '../../../redux/actions';

export default (id: any) => [
  {
    isGroup: true,
    label: 'Company name',
    fieldWidth: '5',
    items: [
      {
        placeholder: 'EN',
        type: 'text',
        id: 'CompanyNameEN',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU',
        type: 'text',
        id: 'CompanyNameRU',
        view: 'filled',
        width: 'available',
        size: 's',
      },
    ],
  },
  {
    fieldWidth: '1',
    type: 'gridCol',
  },
  {
    isGroup: true,
    label: 'Company short name',
    fieldWidth: '5',
    items: [
      {
        placeholder: 'EN',
        type: 'text',
        id: 'CompanyShortNameEN',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU',
        type: 'text',
        id: 'CompanyShortNameRU',
        view: 'filled',
        width: 'available',
        size: 's',
      },
    ],
  },
  {
    isGroup: true,
    label: 'Logo',
    fieldWidth: '5',
    items: [
      {
        type: 'fileSimple',
        typeFile:'img',
        id: 'logo',
        label: 'logo',
        fieldWidth: '5',
        accept: 'image/jpg,image/jpeg',
        noFileText: 'Select and upload file',
      },
    ],
  },
  {
    fieldWidth: '2',
    type: 'gridCol',
  },
  {
    isGroup: true,
    label: 'Authors',
    fieldWidth: '5',
    items: [
      {
        placeholder: 'EN',
        type: 'ValueSet',
        id: 'authors',
        view: 'filled',
        width: 'available',
        size: 's',
        multiple: true,
        itemOptions: (item: any) => ({ value: item.id, text:language(item.name, 'en')}),
        action: getAllAuthorsRequest,
      },
    ],
  },
  {
    fieldWidth: '5',
    type: 'ValueSet',
    id: 'sector',
    placeholder: 'Sector',
    label: 'Sector',
    view: 'filled',
    width: 'available',
    size: 's',
    multiple: false,
    itemOptions: (item: any) => ({ value: item.id, text:language(item.name, 'en')}),
    action: getAllSectorsRequest,
  },
  {
    fieldWidth: '5',
    type: 'ValueSet',
    id: 'equityTickerId',
    placeholder: 'Main ticker',
    label: 'Main ticker',
    view: 'filled',
    width: 'available',
    size: 's',
    multiple: false,
    graphId: id,
    itemOptions: (item: any) => ({ value: item.id, text: item.name}),
    action: getTickersByCompanyIDRequest,
  },
  {
    isGroup: true,
    label: 'Financial Indicators',
    fieldWidth: '5',
    items: [
      {
        type: 'fileSimple',
        typeFile:'img',
        id: 'tableInfo',
        label: 'logo',
        fieldWidth: '5',
        accept: 'image/jpg,image/jpeg',
        noFileText: 'Select and upload file',
      },
    ],
  },
  {
    fieldWidth: '5',
    type: 'gridCol',
  },
  {
    isGroup: true,
    label: 'Company description',
    fieldWidth: '12',
    customPosition: {
      label: 1,
      field: 11,
      className:'col-11-field'
    },
    items: [
      {
        placeholder: 'EN',
        type: 'editorAuthor',
        id: 'CompanyDescriptionEN',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU',
        type: 'editorAuthor',
        id: 'CompanyDescriptionRU',
        view: 'filled',
        width: 'available',
        size: 's',
      },
    ],
  },
];
