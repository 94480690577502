import Sectors from './pages';


export default [
  {
    type: 'private',
    exact: true,
    path: '/sectors',
    roles: ['admin'],
    component: Sectors,
  },
  {
    type: 'private',
    exact: true,
    path: '/sectors/:id',
    roles: ['admin'],
    component: Sectors,
  },

];
