export const GET_PRODUCT_TYPE_REQUEST = 'GET_PRODUCT_TYPE_REQUEST'
export const GET_PRODUCT_TYPE_SUCCESS = 'GET_PRODUCT_TYPE_SUCCESS'
export const GET_PRODUCT_TYPE_FAILURE = 'GET_PRODUCT_TYPE_FAILURE'

export const CREATE_PRODUCT_TYPE_REQUEST = 'CREATE_PRODUCT_TYPE_REQUEST'
export const CREATE_PRODUCT_TYPE_REQUEST_SUCCESS = 'CREATE_PRODUCT_TYPE_REQUEST_SUCCESS'
export const CREATE_PRODUCT_TYPE_REQUEST_FAILURE = 'CREATE_PRODUCT_TYPE_REQUEST_FAILURE'

export const PRODUCT_TYPE_REQUEST_ID = 'PRODUCT_TYPE_REQUEST_ID'
export const PRODUCT_TYPE_REQUEST_ID_SUCCESS = 'PRODUCT_TYPE_REQUEST_ID_SUCCESS'
export const PRODUCT_TYPE_REQUEST_ID_FAILURE = 'PRODUCT_TYPE_REQUEST_ID_FAILURE'

export const EDIT_PRODUCT_TYPE = 'EDIT_PRODUCT_TYPE'