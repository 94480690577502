import { language } from 'utils/formatters';

const normalize = (data: any) => {
  if (!data) return null;
  let cormData = {
    ...data,
    nameEN: language(data.name, 'en'),
    nameRU: language(data.name, 'ru'),
    clientRightSettings: data.clientRightSettings,
  };

  const ThreeValues = {} as any
  data && data.clientRightSettings.categories.forEach((item: any) => {
    ThreeValues[item.id] = {}
    item.productTypes.forEach((types: any) => {
      ThreeValues[item.id][types] = true
    })
  })

  return Object.assign(cormData, { checkboxes: ThreeValues });
};

export default normalize;
