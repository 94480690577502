// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import {
  getAllDashboardRequest,
} from '../../redux/actions';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { DASHBOARD_TABLE } from 'constants/tables';
import { connect } from 'react-redux';
import { periods } from './periods';
import TagButton from 'arui-feather/tag-button';

class DashboardFilterForm extends React.Component {
  constructor() {
      super();
      this.state = {
          checked : '7day'
      }
      this.loadData = this.loadData.bind(this)
  }

  componentDidMount() {
      const period = periods.find(item =>
          item.id === this.state.checked
      )
      this.loadData(period)
  }

    loadData(mode : object) {
    const { dispatch }: any = this.props;
    this.setState({checked: mode.id})
    dispatch(getAllDashboardRequest({ meta: { table: DASHBOARD_TABLE, mode } }))
  }

  render() {
      // console.log('this state', this.state)
    return (
        <>
        <div className={'dashboard-title'}>
            Period
{/*
            <TagButton size='m' className={'button-checkout'}>Button1</TagButton>
            <TagButton size='m' className={'button-checkout__check'}>Button1</TagButton>
*/}
        </div>
            <GridRow>
          <GridCol>
            { periods && periods.map( item => {
                let className;
                if( item.id == this.state.checked)
                    className = 'button-checkout__check'
                else
                    className = 'button-checkout'
                // console.log('!!!!!!this state', this.state, item.name, className)
                    return (
/*
                  <Button key={item.id} view="extra" size="s" onClick={()=> this.loadData(item) }>
                      { item.name }
                  </Button>
*/
                <TagButton key={item.id} size="s" className = {className} onClick={()=> this.loadData(item) }>
                    { item.name }
                </TagButton>
                )
            })

            }
          </GridCol>
        </GridRow>
        </>
    );
  }
}

export default connect()(DashboardFilterForm);
