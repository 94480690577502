// @ts-nocheck
import React from 'react';
import Heading from 'arui-feather/heading';
import LoginForm from './form';

import style from './login.module.scss';

const Login = () => {
  return (
    <div className={style.loginForm}>
      <Heading size="xs">Please log in</Heading>
      <LoginForm type="create" />
    </div>
  );
};
export default Login;
