// @ts-nocheck
import React  from 'react';
import ConfigPage from './page'

export default [
  {
    fieldWidth: '12',
    customPosition: {
      label: 0,
      field: 12,
    },
    id: 'clientRightSettingsThree',
    component: ConfigPage,
    type: 'custom',
  },
];
