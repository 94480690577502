import WorkCalendaries from "./pages";

export default [
  {
    type: 'private',
    exact: true,
    path: '/work-calendar',
    roles: ['admin'],
    component: WorkCalendaries,
  },
  {
    type: 'private',
    exact: true,
    path: '/work-calendar/:id',
    roles: ['admin'],
    component: WorkCalendaries,
  },
];
