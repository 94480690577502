import { language } from 'utils/formatters';

const normalize = (data: any) => {
  if (!data) return null;
  let cormData = {
    nameEN: language(data.name, 'en'),
    nameRU: language(data.name, 'ru'),
    addressEN: language(data.address, 'en'),
    addressRU: language(data.address, 'ru'),
    biographyEN: language(data.biography, 'en'),
    biographyRU: language(data.biography, 'ru'),
    positionEN: language(data.position, 'en'),
    positionRU: language(data.position, 'ru'),
    authorType: data.authorTypeId,
    birthday: data.birthday,
    email: data.email,
    id: data.id,
    phone1: data.phone1,
    phone2: data.phone2,
    photo: {
      url: data?.photo,
      title: data?.photo?.split('/').pop()
    },
  };

  return cormData;
};

export default normalize;
