// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import LanguagesForm from './form';
import LanguagesTable from './table';

const Languages = () => {
  return (
    <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
      <LanguagesTable />
      <LanguagesForm />
    </GridRow>
  );
};

export default Languages;