// @ts-nocheck
import { language } from 'utils/formatters';
import {isArray} from "lodash";

export const NUM_SLOTS = 5

const normalize = (data: any) => {
  if (!data) {
    return null;
  }

  function toStringDates(dateArr: any) {
    let res = ''
    if(!dateArr) return res
    if(dateArr.length == 0) return res
    for( let i=0; i<dateArr.length; i++ ) {
      const date: any = new Date(dateArr[i])
      res += date.getDate() + '.' + (date.getMonth() + 1)
      if( i != dateArr.length - 1) res += ','
    }
    return res;
  }

  const cormData = {
    id: data.id,
    year: data.year,
    holiday: toStringDates(data.holiday),
    movedWorkDay: toStringDates(data.movedWorkDay),
  };
  return cormData;
};

export default normalize;
