// @ts-nocheck
import { fromJS } from 'immutable';
import {
  EVENT_FAILURE,
  EVENT_SUCCESS,
} from './constants';

const initialState = fromJS({
  error: null,
  token: null,
  logoutData: null,
});

const eventReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case EVENT_FAILURE: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state
        .set('token', payload)
        .set('error', null);
    }
    case EVENT_SUCCESS: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state.set('error', payload);
    }

    default:
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state;
  }
};

export default eventReducer;
