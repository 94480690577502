import { language } from 'utils/formatters';

const normalize = (data: any) => {
  if (!data) return null;

  let cormData = {
    language: data.language,
    name: data.name,
    text: data.text,
  };
  return cormData;
};

export default normalize;
