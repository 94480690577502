export const GET_ALL_SECTORS_REQUEST = 'GET_ALL_SECTORS_REQUEST'
export const GET_ALL_SECTORS_SUCCESS = 'GET_ALL_SECTORS_SUCCESS'
export const GET_ALL_SECTORS_FAILURE = 'GET_ALL_SECTORS_FAILURE'

export const CREATE_NEW_SECTOR_REQUEST = 'CREATE_NEW_SECTOR_REQUEST'
export const CREATE_NEW_SECTOR_SUCCESS = 'CREATE_NEW_SECTOR_SUCCESS'
export const CREATE_NEW_SECTOR_FAILURE = 'CREATE_NEW_SECTOR_FAILURE'

export const GET_SECTOR_BY_ID_REQUEST = 'GET_SECTOR_BY_ID_REQUEST'
export const GET_SECTOR_BY_ID_SUCCESS = 'GET_SECTOR_BY_ID_SUCCESS'
export const GET_SECTOR_BY_ID_FAILURE = 'GET_SECTOR_BY_ID_FAILURE'

export const EDIT_SECTOR_REQUEST = 'EDIT_SECTOR_REQUEST'