// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import { formReset } from 'components/Graphs/Form/actions'
import { tableDataSuccess } from 'components/Graphs/Table/actions'
import { createNewLanguagesRequest, getLanguagesByIdRequest,
  getAllLanguagesRequest, editLanguagesRequest} from '../../redux/actions';
import normalize from './normalize';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { CATALOG_LANGUAGES_FORM } from 'constants/forms';
import { CATALOG_LANGUAGES_TABLE } from 'constants/tables';
import { connect } from 'react-redux';

interface ILanguagesForm {
  title?: string;
  form: string;
  match: any
}

class LanguagesForm extends React.Component<ILanguagesForm> {

  handleClick = () => {
    const {history, dispatch}: any = this.props;
    dispatch(reset(CATALOG_LANGUAGES_FORM))
    dispatch(getAllLanguagesRequest({ meta: { table: CATALOG_LANGUAGES_TABLE, callback: tableDataSuccess }}))
    dispatch(formReset({ meta: { form: CATALOG_LANGUAGES_FORM } }))
    history.push('/languages')
  };

  get actions() {
    const { match: { params: { id } } } = this.props;
    const action = id ? 'Edit this Language' : 'Add new Language';
    return (
      <GridRow>
        <GridCol>
          <Button className={"button-float"} view="extra" size="s" type="submit">
            {action}
          </Button>
          <Button className={"mr20 button-float"} size="s" onClick={this.handleClick}>Cancel</Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = ({ name }: any) => {
    const { match: { params: { id } } } = this.props
    return {
      id,
      name
    };
  };

  render() {
    const { title, match: { params: { id } }}  = this.props;
    return (
      <GraphForm
        title="Languages form"
        form={CATALOG_LANGUAGES_FORM}
        actions={this.actions}
        onSuccess={this.handleClick}
        graphId={id}
        init={getLanguagesByIdRequest}
        edit={editLanguagesRequest}
        create={createNewLanguagesRequest}
        submit={this.submit}
        normalize={normalize}
        validate={() => {}}
        type={id ? 'edit': 'create'}
        columns={1}
        config={config}
        width="5"
        reInitialize
      />
    );
  }
}

export default compose<any>(
  connect(),
  withRouter
)(LanguagesForm)
