import { combineReducers } from 'redux';
import { History, LocationState } from 'history';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
// @ts-ignore
import immutableTransform from 'redux-persist-transform-immutable';
import storage from 'localforage';

// Components
import graphFormReducer from 'components/Graphs/Form/reducer';
import graphTableReducer from 'components/Graphs/Table/reducer';
import valueSetReducer from 'components/ValueSet/reducer';
import messageReducer from 'components/Message/reducer';
import dialogReducer from 'components/Flows/Dialog/reducer';

// Modules
import authReducer from 'modules/Auth/redux/reducer';
import userReducer from 'modules/User/redux/reducer';
import categoriesReducer from 'modules/Catalog/Categories/redux/reducer';
import productTypesReducer from 'modules/Catalog/ProductTypes/redux/reducer';
import selectorsReducer from 'modules/Catalog/Sectors/redux/reducer';
import commoditiesReducer from 'modules/Catalog/Commodities/redux/reducer';
import countriesReducer from 'modules/Catalog/Countries/redux/reducer';
import currenciesReducer from 'modules/Catalog/Currencies/redux/reducer';
import periodTypesReducer from 'modules/Catalog/PeriodTypes/redux/reducer';
import stockTypesReducer from 'modules/Catalog/StockTypes/redux/reducer';
import authorTypesReducer from 'modules/Catalog/AuthorTypes/redux/reducer';
import eventTypesReducer from 'modules/Catalog/EventTypes/redux/reducer';
import languagesReducer from 'modules/Catalog/Languages/redux/reducer';

import calendarReducer from 'modules/Calendar/redux/reducer';
import interfaceSettingsReducer from 'modules/InterfaceSettings/redux/reducer';
import authorsReducer from 'modules/Authors/redux/reducer';
import widgetsChartsReducer from 'modules/WidgetsCharts/redux/reducer';
import clientReducer from 'modules/Client/redux/reducer';

import СompaniesReducer from 'modules/Companies/redux/reducer';
import usersRolesReducer from 'modules/Client/redux/reducer';
import productsReducer from 'modules/Products/redux/reducer';
import mailingListSettingsReducer from 'modules/MailingListSettings/redux/reducer';
import grapesReducer from '../components/GrapesJs/reducer';


const rootPersistConfig = {
    transforms: [immutableTransform()],
    key: 'AlfaRoot_1',
    whitelist: ['auth', 'user', 'global'],
    storage,
};

export const rootReducer = (history: History<LocationState>) =>
    persistReducer(
        rootPersistConfig,
        combineReducers({
            router: connectRouter(history),
            // Redux-form
            form: formReducer,
            // Components
            graphForm: graphFormReducer,
            graphTable: graphTableReducer,
            valueSets: valueSetReducer,
            message: messageReducer,
            dialog: dialogReducer,
            grapes: grapesReducer,

            // Modules
            auth: authReducer,
            user: userReducer,
            categories: categoriesReducer,
            productTypes: productTypesReducer,
            selectors: selectorsReducer,
            commodities: commoditiesReducer,
            countries: countriesReducer,
            currencies: currenciesReducer,
            periodTypes: periodTypesReducer,
            stockTypes: stockTypesReducer,
            authorTypes: authorTypesReducer,
            eventTypes: eventTypesReducer,
            languages: languagesReducer,

            calendar: calendarReducer,
            interfaceSettings: interfaceSettingsReducer,
            authors: authorsReducer,
            widgetsCharts: widgetsChartsReducer,
            client: clientReducer,
            companies: СompaniesReducer,
            userRoles: usersRolesReducer,
            products: productsReducer,
            mailingListSettings: mailingListSettingsReducer,

        }),
    );
