import Countries from './pages';

export default [
  {
    type: 'private',
    exact: true,
    path: '/countries',
    roles: ['admin'],
    component: Countries,
  },
  {
    type: 'private',
    exact: true,
    path: '/countries/:id',
    roles: ['admin'],
    component: Countries,
  },
];
