import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import cn from 'classnames';

import {
  Input,
  InputLabel,
  FormControl,
  FormHelperText,
  Button,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faPrint } from '@fortawesome/free-solid-svg-icons';

import { makeSelectField } from 'components/Flows/Form/selector';
import {
  fileGetRequest,
  getUploadUrlRequest,
  fileURLUpdateRequest,
} from './actions';
import IconDelete from 'arui-feather/icon/action/delete';
import IconButton from 'arui-feather/icon-button';
import { imageUploadRequest } from '../InputFile/actions';
import { dowloadFile } from '../../utils/formatters';
import { API_HOST } from '../InputFile/api';

const styles = {
  root: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    boxSizing: 'border-box',
    overflow: 'hidden',
    zIndex: '1',
    position: 'relative',
    paddingLeft: 8,
    paddingRight: 10,
    backgroundColor: 'rgba(240, 50, 38, 0.85)',
    color: 'rgba(255, 255, 255, 1)',
  },
  buttonLabel: {
    height: '100%',
  },
  fieldRoot: {
    display: 'flex',
    flex: '1',
    boxSizing: 'border-box',
    position: 'relative',
    maxWidth: 'calc(100% - 55px)',
  },
  fieldLabel: {
    fontSize: 12,
    lineHeight: 1,
    letterSpacing: 1,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#9ea0a5',
  },
  field: {
    background: 'white',
    border: 'solid 1px rgba(0, 0, 0, 0.1)',
    padding: '5px 14px',
    fontSize: 14,
    marginTop: 5,
    minHeight: 38,
    boxSizing: 'border-box',
    borderRadius: 4,
    backgroundColor: 'white',
    color: '#3e3f42',

    '$root.preview &': {
      marginTop: 0,
      paddingLeft: 0,
      fontSize: 16,
      lineHeight: 1,
      border: 'none',
      background: 'initial',
      color: 'initial',
      cursor: 'default',
    },
  },
  input: {
    width: '100%',
  },
  formControl: {
    maxWidth: '100%',
    width: '100%',
  },
  inputLabel: {
    cursor: 'pointer',
  },
  clear: {
    position: 'absolute',
    right: 2,
    top: 24,
    '& svg': {
      fill: '#9ea0a5',
    },
    '$root.preview &': {
      display: 'none',
    },
  },
  print: {},
  printAlign: {
    margin: '18px 0',
  },
  preview: {
    position: 'relative',
    marginRight: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    flex: '50px 0 0',
  },
  previewLabel: {
    position: 'absolute',
    left: '0',
    minWidth: 250,
    alignSelf: 'flex-start',
    fontSize: 12,
    lineHeight: 1,
    letterSpacing: 1,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#9ea0a5',
  },
  previewImg: {
    width: '100%',
    height: 'auto',
    maxHeight: 50,
    textIndent: -9999,
    color: '#fff',
  },
  fileIcon: {
    margin: '18px 0',
  },
  loading: {
    width: 50,
    height: 50,
    position: 'relative',
  },
  circular: {
    animation: 'rotate 2s linear infinite',
    height: '80%',
    transformOrigin: 'center center',
    width: '80%',
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    margin: 'auto',
  },
  imageButton: {
    width: 24,
    height: 'auto',
    margin: 3,
  },
};

export class FileInput extends React.Component {
  componentDidUpdate(prevProps) {
    const { dispatch, file, form, id } = this.props;
    if (
      (prevProps.file.id && file.id && file.id !== prevProps.file.id) ||
      // this line is about forms that being updated after init and gets uuid as value
      // calling this saga overwrites fieldId's value to file object
      // TODO find out more about src/containers/Requisition/tabs/Order/sections/Order/index.jx form
      // TODO maybe do better
      (prevProps.file.id && typeof file === 'string')
    ) {
      dispatch(fileGetRequest({ graphId: file.id || file, form, fieldId: id }));
    }
  }

  renderTextField = ({
    input,
    id,
    label,
    type,
    onClick,
    placeholder,
    required,
    disabled,
    meta: { touched, error },
    classes,
    ...custom
  }) => (
    <div className={classes.fieldRoot}>
      <FormControl
        className={classes.formControl}
        error={!!error && touched}
        aria-describedby={`${id}-error`}
        required={required}
      >
        <Input
          id={id}
          {...input}
          {...custom}
          type={type}
          inputProps={{
            readOnly: true,
          }}
          disabled
          classes={{
            input: classes.field,
          }}
        />
        <FormHelperText id={`${id}-error`} className={classes.error}>
          {touched ? error : ''}
        </FormHelperText>
      </FormControl>
      {input.value && !disabled ? (
        <IconButton
          size="s"
          className={classes.imageButton}
          onClick={(e) => this.handleClickClear(e)}
        >
          <IconDelete colored />
        </IconButton>
      ) : (
        ' '
      )}
    </div>
  );

  readFile = (event, fieldId) => {
    const {
      dispatch,
      form,
      id,
      fileDataTransform,
      fieldIdTransform,
      onSuccessUpload,
    } = this.props;
    let { non_image } = this.props;
    const { files } = event.target;
    if (files && files.length) {
      const file = files[0];
      const {
        lastModified,
        lastModifiedDate,
        name,
        size,
        type,
        webkitRelativePath,
      } = file;

      non_image = !this.isImageType(type);

      const params = {
        mime_type: type || 'undefined',
        title: name,
        kind: !non_image ? 'image' : '',
      };
      // write uploaded file to store in the same format as at backend, with title and mime_type
      const fileFormatted = {
        lastModified,
        lastModifiedDate,
        size,
        webkitRelativePath,
        ...params,
      };
      dispatch(imageUploadRequest({ file, id: fieldId, form }));
      // dispatch(getUploadUrlRequest({
      //     params,
      //     fieldId,
      //     form,
      //     file,
      //     non_image,
      //     fileDataTransform,
      //     fieldIdTransform,
      //     onSuccessUpload,
      // }));
      // dispatch(change(form, id, fileFormatted));
    }
  };

  handleClickClear = (e) => {
    e.stopPropagation();
    const { dispatch, form, id } = this.props;

    dispatch(change(form, id, ''));
  };

  renderFileField = ({ input, id, accept, disabled }) => {
    const fileInputKey = input.value ? input.value.name : +new Date();
    return (
      <input
        key={fileInputKey}
        id={id}
        name={input.name}
        className={id}
        type="file"
        onChange={(e) => this.readFile(e, id)}
        ref={(ref) => (this.fileInput = ref)}
        accept={accept}
        hidden
        disabled={disabled}
      />
    );
  };

  handlerClickIcon = () => {
    const { dispatch, file, form, id, disaptch } = this.props;
    if (!this.isImage(file.url)) {
      const newWindow = window.open('', '_blank');
      if (file && file.url) {
        newWindow.location.href = file.url;
      }
    } else {
      dowloadFile(file.url);
    }
  };

  parseDataURL = ({ target }) => {
    try {
      const canvas = document.createElement('canvas');
      canvas.width = target.naturalWidth;
      canvas.height = target.naturalHeight;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(target, 0, 0);

      this.dataURL = canvas.toDataURL();
    } catch (error) {
      console.warn(error);
    }
  };

  print = () => {
    const { dataURL } = this;

    const printWin = window.open();
    printWin.document.open();
    printWin.document.write(
      `<img alt="printImg" src="${dataURL}" width="100%">`,
    );
    printWin.document.close();
    printWin.focus();

    setTimeout(() => printWin.print(), 0);
  };

  getPreview(id, imageUrl, onLoad) {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.alt = `${id}_preview`;
    image.className = this.props.classes.previewImg;
    image.onload = onLoad;
    image.src = imageUrl;
    return (
      <div
        {...{
          ref: (nodeElement) => {
            nodeElement &&
              !nodeElement.hasChildNodes() &&
              nodeElement.appendChild(image);
          },
        }}
      />
    );
  }

  get Loading() {
    const { classes } = this.props;
    return (
      <div className={classes.loading}>
        <svg className={classes.circular} viewBox="25 25 50 50">
          <circle
            className="c-loader__path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </svg>
      </div>
    );
  }

  isImageType(type) {
    return [
      'image/gif',
      'image/jpg',
      'image/jpeg',
      'image/tiff',
      'image/png',
      'image/svg',
    ].includes(type);
  }

  isImage = (value) => {
    if (!value) {
      return false;
    }
    const getExtension = (filename) => {
      const parts = filename.split('.');
      return parts[parts.length - 1];
    };

    const isImageData = (filename) => {
      const ext = getExtension(filename);
      switch (ext.toLowerCase()) {
        case 'jpg':
        case 'gif':
        case 'bmp':
        case 'png':
        case 'jpeg':
          // etc
          return false;
      }
      return true;
    };

    return isImageData(value);
  };

  fileIcon(fileUrl, id) {
    let { non_image, file, classes, loading } = this.props;

    if (file) {
      non_image = this.isImage(file.url);
    }

    return (
      <div id="file-icon" onClick={() => this.handlerClickIcon()}>
        {non_image ? (
          <FontAwesomeIcon
            id="non-image-icon"
            className={classes.fileIcon}
            icon={faFileAlt}
            size="lg"
          />
        ) : (loading ? (
          this.Loading
        ) : (
          this.getPreview(id, fileUrl, this.parseDataURL)
        ))}
      </div>
    );
  }

  uploadButton(id, disabled) {
    const { classes } = this.props;
    const button = (
      <Button
        type="button"
        variant="contained"
        // color="primary"  Дает яркий синий при при наведении, отключено
        size="medium"
        component="span"
        className={classes.button}
        classes={{
          label: classes.buttonLabel,
        }}
        disabled={disabled}
      >
        Upload
      </Button>
    );

    return disabled ? (
      button
    ) : (
      <label htmlFor={id} className={classes.inputLabel}>
        {button}
      </label>
    );
  }

  render() {
    //        console.log(this.props)
    const {
      label,
      name,
      drag,
      id,
      accept,
      file,
      disabled,
      required,
      printable,
      hideInput,
      classes,
      className,
      preview,
      loading,
    } = this.props;
    return (
      <div className={cn(className, classes.root, { preview })}>
        <Field
          id={id}
          name={name}
          accept={accept}
          component={this.renderFileField}
          disabled={disabled}
        />
        {hideInput && (
          <InputLabel
            shrink
            className={cn('previewLabel', classes.previewLabel)}
          >
            {label}
          </InputLabel>
        )}
        <>
          <div className={classes.preview}>
            {file.url
              ? this.fileIcon(file.url, id)
              : (loading
              ? this.Loading
              : this.uploadButton(id, disabled))}
          </div>

          {!hideInput && (
            <Field
              className={classes.input}
              id={`${id}.title`}
              name={`${id}.title`}
              type="text"
              label={label}
              component={this.renderTextField}
              disabled={loading || disabled || preview}
              required={required}
              classes={classes}
            />
          )}

          {file.url && printable ? (
            <Button
              id="print-button"
              onClick={() => this.print()}
              className={cn(classes.print, { [classes.printAlign]: hideInput })}
            >
              <FontAwesomeIcon icon={faPrint} />
            </Button>
          ) : null}
        </>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  file: (state, ownProps) =>
    makeSelectField(ownProps.id)(state, ownProps) || {},
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, withStyles(styles))(FileInput);
