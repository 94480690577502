// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';

import API_CATEGORY from '../api';
import { GET_ALL_COUNTRY_REQUEST, CREATE_NEW_COUNTRY_REQUEST, GET_COUNTRY_BY_ID_REQUEST, EDIT_COUNTRY_REQUEST } from './constants';

function* getAllCountry({meta: { table, callback, form }}: any) {
  try {
    const response = yield call(API_CATEGORY.getAllCountry);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }))
      if (form) yield put(callback({ payload: { response }, meta: { form } }))
    }
  } catch (error) {
    console.error('Auth Wrong');
  }
}

function* createNewCountry({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_CATEGORY.createNewCountry, { data: fields });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getCountryById({ payload: { callback, data: { id } }, meta: { form } }: any) {
  try {
    const response = yield call(API_CATEGORY.getByIdCountry, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* editCountry({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_CATEGORY.editCountry, { data: fields });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    console.error(error);
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_ALL_COUNTRY_REQUEST, getAllCountry),
    takeLatest(CREATE_NEW_COUNTRY_REQUEST, createNewCountry),
    takeLatest(GET_COUNTRY_BY_ID_REQUEST, getCountryById),
    takeLatest(EDIT_COUNTRY_REQUEST, editCountry)
  ]);
}

export default Saga;
