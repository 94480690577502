import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {
  getAllLanguages = () => request({
    url: 'catalog/language',
    method: 'GET',
    token: API_GLOBAL.token
  });

  createNewLanguages = ({ data }: any) => request({
    url: 'catalog/language',
    data,
    method: 'POST',
    token: API_GLOBAL.token
  });

  editLanguages = ({ data }: any) => request({
    url: 'catalog/language',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  })

  getByIdLanguages = ({ id }: any) => request({
    url: `catalog/language/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })

  getByNameLanguages = ({ id }: any) => request({
    url: `catalog/language/by-name/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })
}

const API_LANGUAGES = new ClassAPI();

export default API_LANGUAGES;
