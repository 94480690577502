// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import {
  createNewLawFileRequest, getLawFileByIdRequest,
  editLawFileRequest,
} from '../../../redux/actions';
import normalize from './normalize';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { LAW_FILE_FORM } from 'constants/forms';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectAllValueSets } from 'components/ValueSet/selector';
import validate from "./validate";

interface ICompaniesForm {
  title?: string;
  form: string;
  match: any
}

class LawFileForm extends React.Component<ICompaniesForm> {
  handleClick = () => {
    const { history, dispatch }: any = this.props;
    dispatch(reset(LAW_FILE_FORM));
    console.log('push to list');
    history.push('/lawfile');
  };

  get actions() {
    const { id }: any = this.props;
    const action = id ? 'Edit this law file' : 'Add new law file';
    return (
      <GridRow>
        <GridCol>
          <Button className={'button-float'} view="extra" size="s" type="submit">
            {action}
          </Button>
          <Button className={'mr20 button-float'} size="s" onClick={this.handleClick}>Cancel</Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = (data: any) => {
    const { id }: any = this.props;
    console.log('submit data=',  data);
    return {
      id,
      nameEn: data.nameEn.substr(0,150),
      nameRu: data.nameRu.substr(0,150),
      urlEn: data.urlEn?.url,
      urlRu: data.urlRu?.url,
    };
  }

  render() {
    const { id }: any = this.props;
    return (
      <GraphForm
        form={LAW_FILE_FORM}
        actions={this.actions}
        onSuccess={this.handleClick}
        graphId={id}
        title="Law file form"
        init={getLawFileByIdRequest}
        edit={editLawFileRequest}
        create={createNewLawFileRequest}
        submit={this.submit}
        normalize={normalize}
        validate={validate}
        type={id ? 'edit' : 'create'}
        columns={1}
        config={config(id)}
        width="12"
        reInitialize
      />
    );
  }
}
const mapStateToProps = createStructuredSelector({
  valueSetsAll: selectAllValueSets,
});

const withConnect = connect(mapStateToProps);

export default compose<any>(
  withConnect,
  withRouter,
)(LawFileForm);
