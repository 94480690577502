// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';

import GraphForm from 'components/Graphs/Form';
import { formReset } from 'components/Graphs/Form/actions';
import { tableDataSuccess } from 'components/Graphs/Table/actions';
import { getObjectTrim } from 'utils/formatters';
import {
  createNewMailingTypeRequest,
  getMailingTypeByIdRequest,
  getAllMailingTypeRequest,
  editMailingTypeRequest,
} from 'modules/MailingListSettings/redux/actions';
import normalize from './normalize';
import config from './fields';
import validate from './validate';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { MAILING_LIST_SETTINGS_FORM, PRODUCT_OPTION_FORM } from 'constants/forms';
import { MAILING_LIST_SETTINGS_TABLE } from 'constants/tables';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectAllValueSets } from '../../../../../components/ValueSet/selector';
import { getFlowFormValues } from '../../../../../utils/select';
import { makeSelectRoles } from '../../../../../app/selectors';

interface IMailingForm {
  title?: string;
  form: string;
  match: any;
  idProps: string;
}

class MailingForm extends React.Component<IMailingForm> {
  handleClick = () => {
    const { history, dispatch }: any = this.props;
    dispatch(reset(MAILING_LIST_SETTINGS_FORM));
    dispatch(
      getAllMailingTypeRequest({
        meta: {
          table: MAILING_LIST_SETTINGS_TABLE,
          callback: tableDataSuccess,
        },
      }),
    );
    dispatch(formReset({ meta: { form: MAILING_LIST_SETTINGS_FORM } }));
    history.push('/mailing-list-settings/main');
  };

  get actions() {
    const { idProps }: any = this.props;
    const action = idProps ? 'Edit this Mail' : 'Add new Mail';
    return (
      <GridRow>
        <GridCol>
          <Button
            className={'button-float'}
            view="extra"
            size="s"
            type="submit"
          >
            {action}
          </Button>
          <Button
            className={'mr20 button-float'}
            size="s"
            onClick={this.handleClick}
          >
            Cancel
          </Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = ({
    categoryId,
    productTypeId,
    footerMifidId,
    footerAccId,
    headerId,
    bodyId,
    name,
  }: any) => {
    const {
      idProps,
      match: {
        params: { id },
      },
    } = this.props;
    const currentId = id || idProps;
    return {
      id: currentId,
      categoryId,
      productTypeId,
      headerId: Array.isArray(headerId) ? headerId : [headerId],
      bodyId,
      footerMifidId,
      footerAccId,
      name,
    };
  };

  render() {
    const {
      idProps,
      match: {
        params: { id },
      },
    } = this.props;
    const { valuesFrom, valueSetsAll }: any = this.props;
    const list = valueSetsAll && valueSetsAll.productTypeId
    return (
      <GraphForm
        title="Main template form"
        form={MAILING_LIST_SETTINGS_FORM}
        actions={this.actions}
        onSuccess={this.handleClick}
        graphId={id || idProps}
        init={getMailingTypeByIdRequest}
        edit={editMailingTypeRequest}
        create={createNewMailingTypeRequest}
        submit={this.submit}
        validate={(values: object) => validate(getObjectTrim(values))}
        normalize={normalize}
        type={id || idProps ? 'edit' : 'create'}
        columns={1}
        config={config(valuesFrom, list)}
        width="12"
        reInitialize
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  valueSetsAll: selectAllValueSets,
  // @ts-ignore
  valuesFrom: getFlowFormValues(MAILING_LIST_SETTINGS_FORM),
  rolesUser: makeSelectRoles(),
});

const withConnect = connect(mapStateToProps);

export default compose<any>(
  withConnect,
  withRouter,
)(MailingForm);