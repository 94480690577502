export const GET_ALL_PRODUCTS_REQUEST = 'GET_ALL_PRODUCTS_REQUEST'
export const GET_ALL_PRODUCTS_FAST_REQUEST = 'GET_ALL_PRODUCTS_FAST_REQUEST'

export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS'
export const GET_ALL_PRODUCTS_FAILURE = 'GET_ALL_PRODUCTS_FAILURE'

export const DELETE_COMPANY_BY_ID_REQUEST = 'DELETE_COMPANY_BY_ID_REQUEST'
export const GET_PRODUCT_BY_ID_REQUEST = 'GET_PRODUCT_BY_ID_REQUEST'
export const EDIT_PRODUCT_REQUEST = 'EDIT_PRODUCT_REQUEST'
export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST'

export const GET_DELIVERY_MAILING_REQUEST = 'GET_DELIVERY_MAILING_REQUEST'
export const CREATE_DELIVERY_MAILING_REQUEST = 'CREATE_DELIVERY_MAILING_REQUEST'
export const EDIT_DELIVERY_MAILING_REQUEST = 'EDIT_DELIVERY_MAILING_REQUEST'

export const GET_DELIVERY_MAILING_BULK_REQUEST = 'GET_DELIVERY_MAILING_BULK_REQUEST'
export const GET_DELIVERY_MAILING_CYPRUS_REQUEST = 'GET_DELIVERY_MAILING_CYPRUS_REQUEST'
export const ACTIVATE_MAILING_REQUEST = 'ACTIVATE_MAILING_REQUEST'

export const CREATE_FILTER_PRODUCT_REQUEST = 'CREATE_FILTER_PRODUCT_REQUEST';
export const EMPTY_ACTION = "EMPTY_ACTION";
