// @ts-nocheck
import React from 'react';

export default (id: any) => [
  {
    type: 'file',
    id: 'currentMarketPricesXlsx',
    typeFile:'currentMarketPricesXlsx',
    label: 'Upload Current market prices',
    fieldWidth: '12',
    accept: 'application/excel',
    noFileText: 'Select and upload file',
  },
  {
    type: 'file',
    id: 'OFZ1Xlsx',
    typeFile:'OFZXlsx',
    label: 'Upload Fixed Income OFZ',
    fieldWidth: '12',
    accept: 'application/excel',
    noFileText: 'Select and upload file',
  },
  {
    type: 'file',
    id: 'OFZ2Xlsx',
    typeFile:'OFZXlsx',
    label: 'Upload Economics OFZ',
    fieldWidth: '12',
    accept: 'application/excel',
    noFileText: 'Select and upload file',
  },
];
