// @ts-nocheck
import React from 'react';
import GraphTable from 'components/Graphs/Table';
import { ICategoryTable, IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import {STATISTICS_TABLE} from 'constants/tables';
import {getAllStatisticsRequest} from '../../redux/actions';
import StringCol from 'components/Flows/Elements/StringCol'
import {STATISTICS_FILTER_FORM} from "../../../../constants/forms";
class CategoryTable extends React.Component<ICategoryTable> {

  normalizeTableData = (head: IHeadTable[], body: IBodyTable[]) : any => {
    let processedData = body && body.map((item, i) => {
      const { id, type, userId, productId, created }:any = item;
      let emptyWidth: any = '';
//      console.log('normalizeTableData item=', item)


      return {
        id,
        cells: [
          {
            element: StringCol(false,i + 1),
            id,
            type: 'id',
            width: '1'
          },
          {
            element:  type,
            id,
            type: 'type',
            width: '2'
          },
          {
            element: userId,
            id,
            type: 'user',
            width: '3'
          },

          {
            element:  productId,
            id,
            type: 'product',
            width: '3'
          },
          {
            element:  created,
            id,
            type: 'created',
            width: '2'
          },
        ]};
    }) || [];

    const normalizeData = processedData.map((val: any) => {
      let newCells = val
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells
    });

    return normalizeData;
  };

  getRowLink = (id: any) => `/categories/${id}`

  render() {
    const { handleRowClick }: any = this.props
    const head = [
      {
        id: 'id',
        label: '№',
        style: { width: '1' },
      },
      {
        id: 'type',
        label: 'Type',
        style: { width: '2' },
        type: 'valueSet',
        code2displayFunc: ( item :any) => {
          if( item ) {
            return item.name
          }
          else {
            return ''
          }

        },
      },
      {
        id: 'user',
        label: 'User Name',
        code2displayFunc: ( item :any) => {
          // console.log('code2displayFunc User item =', item)
          if( item ) {
            return item.name
          }
          else {
            return ''
          }
        },
 // подгрузка здесь возможна, но излишняя,  getAllUsersRolesRequest запускается в форме фильтра
 //       action: getAllUsersRolesRequest,
        type: 'valueSet',
        style: { width: '3' },
      },
      {
        id: 'product',
        label: 'Product Name',
        code2displayFunc: ( item :any) => {
   //       console.log('code2displayFunc Product item =', item)
          if( item ) {
            return item.name
          }
          else {
            return ''
          }

        },
 // подгрузка здесь возможна, но излишняя,  getAllProductsRequest запускается в форме фильтра
 //       action: getAllProductsRequest,
        type: 'valueSet',
        style: { width: '3' },
      },
      {
        id: 'created',
        label: 'Date',
        style: { width: '2' },
      },
    ];
    const graphId = {
      future: true,
    };


    return (

    <GraphTable
        title={'Statistics'}
        graphId={graphId}
//        type={'edit'}
        filterForm={STATISTICS_FILTER_FORM}
        table={STATISTICS_TABLE}
        normalizeTableData={this.normalizeTableData}
//        sortRequest={getFutureEventsSortRequest}
        init={getAllStatisticsRequest}
//        init={createFilterStatisticRequest}
        onRowClick={handleRowClick}
        head={head}
//        link={this.getRowLink}
        width={'12'}
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
        // activeLink
        pagination={true}
    />



  );
  }
}

export default CategoryTable;

