// @ts-nocheck
import React from 'react';
import Input from 'arui-feather/input';
import { isEmpty } from 'lodash'
import { Field } from 'redux-form';

const renderTime = ({ input, id, label, type, required, style, meta: { touched, error }, ...props }: any) => {

  if (isEmpty(input.value)) {
    input.onChange(new Date().toLocaleTimeString().slice(0,-3))
  }

  return (
    <div style={style}>
      <Input
        {...input}
        {...props}
        id={id}
        type={type}
        required={required}
        value={input.value}
        label={label}
        autocomplete={false}
        error={touched ? error : undefined}
      />
    </div>
  );
}


const RenderTimeField = ({ index, item, calculated }: any) => (
  <Field
    hasFeedback
    key={index}
    component={renderTime}
    {...item}
    {...calculated}
  />
)

export default RenderTimeField;