const validate = (values: any) => {
  const errors = {} as any;

  if (!values.declarationDate) {
    errors.declarationDate = 'Required field';
  }

  if (!values.year) {
    errors.year = 'Required field';
  }

  if (!values.periodTypeId) {
    errors.periodTypeId = 'Required field';
  }

  if (!values.amount) {
    errors.amount = 'Required field';
  }

  if (!values.recordDate) {
    errors.recordDate = 'Required field';
  }

  if (!values.recordDate) {
    errors.recordDate = 'Required field';
  }

  return errors;
};

export default validate;
