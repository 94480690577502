import { language } from 'utils/formatters'

const normalize = (data: any) => {
  if (!data) return null;

  let cormData = {
    nameEN: language(data.name, 'en'),
    nameRU: language(data.name, 'ru'),
    blastNote: data.blastNote,
    descriptionEN: language(data.description, 'en'),
    descriptionRU: language(data.description, 'ru'),
    categories: data.categories
  }

  return cormData;
};

export default normalize;
