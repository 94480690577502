// @ts-nocheck
import React from 'react';
import GraphTable from 'components/Graphs/Table';
import { ICategoryTable, IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import { MAILING_LIST_SETTINGS_TABLE } from 'constants/tables';
import {
  getAllMailingTypeRequest,
  getAllHeadRequest,
  getAllBodyRequest, getAllFooterRequest,
  deleteMailingTypeByIdRequest
} from 'modules/MailingListSettings/redux/actions';
import StringCol from 'components/Flows/Elements/StringCol';
import { language } from 'utils/formatters';
import { productTypeRequest } from '../../../../Catalog/ProductTypes/redux/actions';
import { getAllcategoriesRequest } from 'modules/Catalog/Categories/redux/actions';
import IconDelete from 'arui-feather/icon/action/delete/delete';
import { connect } from 'react-redux';

class CategoryTable extends React.Component<ICategoryTable> {

  normalizeTableData = (head: IHeadTable[], body: IBodyTable[]): any => {
    let processedData = body && body.map((item, i) => {
      const {
        id, name, productTypeId,
        categoryId, headerId, bodyId, footerAccId, footerMifidId
      }: any = item;
      let emptyWidth: any = '';
      return {
        id,
        cells: [
          {
            element: StringCol(false, i + 1),
            id,
            type: 'id',
            width: emptyWidth,
            customClass: 'mail-custom-col number-table',
          },
          {
            element: name,
            id,
            type: 'name',
            width: emptyWidth,
            customClass: 'mail-custom-col',
          },
          {
            element: productTypeId,
            id,
            type: 'productTypeId',
            width: emptyWidth,
            customClass: 'mail-custom-col',
          },
          {
            element: categoryId,
            id,
            type: 'categoryId',
            width: emptyWidth,
            customClass: 'mail-custom-col',
          },
          {
            element: headerId,
            id,
            type: 'headerId',
            width: emptyWidth,
            customClass: 'mail-custom-col',
          },
          {
            element: bodyId,
            id,
            type: 'bodyId',
            width: emptyWidth,
            customClass: 'mail-custom-col',
          },
          {
            element: footerAccId,
            id,
            type: 'footerId',
            width: emptyWidth,
            customClass: 'mail-custom-col',
          },
          {
            element: footerMifidId,
            id,
            type: 'footerMIFIDId',
            width: emptyWidth,
            customClass: 'mail-custom-col',
          },
          {
            element: <IconDelete size={'s'}/>,
            id,
            type: 'delete',
            width: emptyWidth,
            customClass: 'delete-icon mail-custom-col number-table',

          },
        ],
      };
    }) || [];

    const normalizeData = processedData.map((val: any) => {
      let newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });

    return normalizeData;
  };

  getRowLink = (id: any) => `/mailing-list-settings/main/${id}`;

  handleDelete = (id: string) => {
    const { dispatch }: any = this.props;
    dispatch(deleteMailingTypeByIdRequest({ payload: { id } }));
  };

  render() {
    const { handleRowClick }: any = this.props;
    const head = [
      {
        id: 'id',
        label: '№',
        style: { width: '1' },
        className: 'number-table',
      },
      {
        id: 'name',
        label: 'Name',
        style: { width: '' },
      },
      {
        id: 'productTypeId',
        label: 'Product Type',
        type: 'valueSet',
        action: productTypeRequest,
        style: { width: '' },
      },
      {
        id: 'categoryId',
        label: 'Category',
        type: 'valueSet',
        action: getAllcategoriesRequest,
        style: { width: '' },
      },
      {
        id: 'headerId',
        label: 'Header',
        type: 'valueSet',
        code2displayFunc: (item:any) => item &&  item.name,
        action: getAllHeadRequest,
        style: { width: '2' },
      },
      {
        id: 'bodyId',
        label: 'Body',
        type: 'valueSet',
        code2displayFunc: (item:any) => item &&  item.name,
        action: getAllBodyRequest,
        style: { width: '2' },
      },
      {
        id: 'footerId',
        label: 'Footer ACC',
        type: 'valueSet',
        code2displayFunc: (item:any) => item &&  item.name,
        action: getAllFooterRequest,
        style: { width: '' },
      },
      {
        id: 'footerMIFIDId',
        label: 'Footer',
        type: 'valueSet',
        code2displayFunc: (item:any) => item &&  item.name,
        action: getAllFooterRequest,
        style: { width: '' },
      },
      {
        id: 'delete',
        type: 'delete',
        label: '',
        style: { width: '1' },
        className: 'number-table'
      },
    ];

    return (
      <GraphTable
        title="Main templates table"
        table={MAILING_LIST_SETTINGS_TABLE}
        normalizeTableData={this.normalizeTableData}
        init={getAllMailingTypeRequest}
        onRowClick={handleRowClick}
        onDelete={(id: any) => this.handleDelete(id)}
        head={head}
        link={this.getRowLink}
        width={'12'}
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
        activeLink
      />
    );
  }
}

export default connect()(CategoryTable);

