import React from 'react';
import GridCol from 'arui-feather/grid-col';




export default class CellTable extends React.PureComponent {
  handleClick = event => {
    //const { onClick, disabled } = this.props;
    // if (disabled) {
    //   event.stopPropagation();
    //   event.preventDefault();
    //   return;
    // }
    //onClick && onClick(event);
  };

  render() {
    const { children, ...props } = this.props;
    return (
      <GridCol {...props}>
          {children}
      </GridCol>
    );
  }
}

