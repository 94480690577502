// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';

import API_STOCKTYPES from '../api';
import { GET_ALL_STOCKTYPES_REQUEST, CREATE_NEW_STOCKTYPES_REQUEST, GET_STOCKTYPES_BY_ID_REQUEST, EDIT_STOCKTYPES_REQUEST } from './constants';

function* getAllStockTypes({meta: { table, callback, form }}: any) {
  try {
    const response = yield call(API_STOCKTYPES.getAllStockTypes);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }))
      if (form) yield put(callback({ payload: { response }, meta: { form } }))
    }
  } catch (error) {
    console.error('Auth Wrong');
  }
}

function* createNewStockTypes({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_STOCKTYPES.createNewStockTypes, { data: fields });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getStockTypesById({ payload: { callback, data: { id } }, meta: { form } }: any) {
  try {
    const response = yield call(API_STOCKTYPES.getByIdStockTypes, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* editStockTypes({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_STOCKTYPES.editStockTypes, { data: fields });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    console.error(error);
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_ALL_STOCKTYPES_REQUEST, getAllStockTypes),
    takeLatest(CREATE_NEW_STOCKTYPES_REQUEST, createNewStockTypes),
    takeLatest(GET_STOCKTYPES_BY_ID_REQUEST, getStockTypesById),
    takeLatest(EDIT_STOCKTYPES_REQUEST, editStockTypes)
  ]);
}

export default Saga;
