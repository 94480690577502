import React from 'react';
import './DashBoardGraph.scss';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

class Graph extends React.Component {
    render() {
        const { data } = this.props;

        return (
               <ResponsiveContainer width="100%" height="100%">
                   <LineChart
                       width={1000}
                       height={600}
                       data={data}
                       margin={{
                           top: 50,
                           right: 30,
                           left: 10,
                           bottom: 5,
                       }}
                   >
                       <CartesianGrid strokeDasharray="3 3" />
                       <XAxis dataKey="name" />
                       <YAxis />
                       <Tooltip />
                       {/*<Legend />*/}
                       <Line type="monotone" dataKey="AUT" stroke="#FF5C5C" strokeWidth={3} activeDot={{ r: 8 }} />
                       <Line type="monotone" dataKey="DNL" stroke="#0B1F35" strokeWidth={3}/>
                       <Line type="monotone" dataKey="DNE" stroke="#999EA3" strokeWidth={3}/>
                   </LineChart>
               </ResponsiveContainer>
        )
    }
};

export default Graph;

