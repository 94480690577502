import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api';

class ClassAPI {
  getAllCompanies = () => request({
    url: 'company',
    method: 'GET',
    token: API_GLOBAL.token,
  });

  createNewCompanies = ({ data }: any) => request({
    url: 'company',
    data,
    method: 'POST',
    token: API_GLOBAL.token,
  });

  editCompanies = ({ data }: any) => request({
    url: 'company',
    data,
    method: 'PUT',
    token: API_GLOBAL.token,
  });

  getByIdCompanies = ({ id }: any) => request({
    url: `company/${id}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  deleteByIdCompanies = (id: any) => request({
    url: `company/${id}`,
    method: 'DELETE',
    token: API_GLOBAL.token,
  });

  deleteStockById = (id: any) => request({
    url: `company/stock/${id}`,
    method: 'DELETE',
    token: API_GLOBAL.token,
  });

  deleteFileById = (id: any) => request({
    url: `company/file/${id}`,
    method: 'DELETE',
    token: API_GLOBAL.token,
  });

  deleteTickerById = (id: any) => request({
    url: `company/equity-ticker/${id}`,
    method: 'DELETE',
    token: API_GLOBAL.token,
  });

  deleteDividendById = (id: any) => request({
    url: `company/dividend/${id}`,
    method: 'DELETE',
    token: API_GLOBAL.token,
  });

  getByNameClients = ({ id }: any) => request({
    url: `author/by-name/${id}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  getAllStock = () => request({
    url: `company/stock`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  getStockById = ({ id }: any) => request({
    url: `company/stock/${id}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  getStocksByIdCompany = ({ id }: any) => request({
    url: `company/stock/stocksByCompanyId/${id}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  getTickersByIdCompany = ({ id }: any) => request({
    url: `company/equity-ticker/company/${id}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  getFilesByIdCompany = ({ id }: any) => request({
    url: `company/file/company/${id}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  editStock = ({ data }: any) => request({
    url: 'company/stock',
    data,
    method: 'PUT',
    token: API_GLOBAL.token,
  });

  getAllDividends = () => request({
    url: `company/dividend`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  getDividendById = ({ id }: any) => request({
    url: `company/dividend/${id}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  getDividendByStockId = ({ id }: any) => request({
    url: `company/dividend/stock/${id}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  getRecommendationsByTickerId = ({ id }: any) => request({
    url: `company/stock-recommendation/ticker/${id}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  deleteRecommendationsByTickerId = (id : any) => request({
    url: `company/stock-recommendation/${id}`,
    method: 'DELETE',
    token: API_GLOBAL.token,
  });

  editDividend = ({ data }: any) => request({
    url: 'company/dividend',
    data,
    method: 'PUT',
    token: API_GLOBAL.token,
  });

  editTicker = ({ data }: any) => request({
    url: 'company/equity-ticker',
    data,
    method: 'PUT',
    token: API_GLOBAL.token,
  });

  getAllTickers = () => request({
    url: `company/stock-recommendation`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  getTickerById = ({ id }: any) => request({
    url: `company/equity-ticker/${id}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  getRecommendationById = ({ id }: any) => request({
    url: `company/stock-recommendation/${id}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  editRecommendationField = ({ data }: any) => request({
    url: 'company/stock-recommendation',
    data,
    method: 'PUT',
    token: API_GLOBAL.token,
  });


  getFileById = ({ id }: any) => request({
    url: `company/file/${id}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  createFileField = ({ data }: any) => request({
    url: 'company/file',
    data,
    method: 'POST',
    token: API_GLOBAL.token,
  });

  editFileField = ({ data }: any) => request({
    url: 'company/file',
    data,
    method: 'PUT',
    token: API_GLOBAL.token,
  });

  createStock = ({ data }: any) => request({
    url: 'company/stock',
    data,
    method: 'POST',
    token: API_GLOBAL.token,
  });

  createDividend = ({ data }: any) => request({
    url: 'company/dividend',
    data,
    method: 'POST',
    token: API_GLOBAL.token,
  });

  createTicker = ({ data }: any) => request({
    url: 'company/equity-ticker',
    data,
    method: 'POST',
    token: API_GLOBAL.token,
  });

  createRecommendation = ({ data }: any) => request({
    url: 'company/stock-recommendation',
    data,
    method: 'POST',
    token: API_GLOBAL.token,
  });

}

const API_COMPANIES = new ClassAPI();

export default API_COMPANIES;
