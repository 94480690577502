// @ts-nocheck
import React from 'react';
import Tabs from 'arui-feather/tabs';
import TabItem from 'arui-feather/tab-item';
import ClientsForm from './form/basic';
import ClientPage from './form/client';
import { Route, Switch, withRouter } from 'react-router';

class ClientsAdd extends React.Component<any, any>{
  handleClick = (event: any) => {
    event.preventDefault();
    const { history } = this.props
    history.push(event.target.getAttribute('href'))
  }

  render() {
    const { location: { pathname }, match: { params: { id } } } = this.props

    const baseUrl = `/clients/edit/${id}`

    return (
      <>
        <Tabs>
          <TabItem url={baseUrl} onClick={ this.handleClick } checked={ pathname === baseUrl }>
            Basic information
          </TabItem>
          <TabItem url={`${baseUrl}/config`} onClick={ this.handleClick } checked={ pathname === `${baseUrl}/config` }>
            Client page
          </TabItem>
        </Tabs>
        <Switch>
          <Route exact path={baseUrl}>
            <ClientsForm id={id} />
          </Route>
          <Route path={`${baseUrl}/config`}>
            <ClientPage id={id} />
          </Route>
        </Switch>
      </>
    );
  }
}

export default withRouter(ClientsAdd)