import {getIdEmail} from "../../../utils";

const normalize = (data: any) => {
  if (!data) return null;
  const previewEditor = data?.deliveryACC?.footer?.text?.includes(data?.body?.text) ? data?.deliveryACC?.footer?.text :  data?.body?.text + data?.deliveryACC?.footer?.text

  let ThreeValues = {} as any
  data?.deliveryACC?.categoryProductTypeSetting?.forEach((itemC: any) => {
    ThreeValues[itemC.categoryId] = {}
    if (itemC.productIdTypes) {
      itemC.productIdTypes.forEach((types: any) => {
        ThreeValues[itemC.categoryId][types] = true
      })
    }
  })
  if ( Object.keys(ThreeValues).length === 1 && Object.keys(ThreeValues)[0] === 'string') {
    ThreeValues = data?.initProduct
  }
  const fromId = getIdEmail(data?.deliveryACC?.from, 'ACC');

  return {
    ...data,
    mailingListSettingsId: data.mailingListSettingsId,
    subject: data.subject,
    date: data?.deliveryACC?.mailingDateTime?.split(' ')[0],
    time: data?.deliveryACC?.mailingDateTime?.split(' ')[1],
    checkboxes: ThreeValues,
    plate: data?.deliveryACC?.status,
    ['product-editor']: previewEditor,
    emails: fromId
  }
};

export default normalize;
