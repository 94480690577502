import * as ACTION_TYPE from './constants';

export const getAllAuthorTypesRequest = ({ meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_AUTHORTYPES_REQUEST,
  meta
});

export const createNewAuthorTypesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_AUTHORTYPES_REQUEST,
  payload,
  meta,
});

export const editAuthorTypesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_AUTHORTYPES_REQUEST,
  payload,
  meta,
})

export const getAuthorTypesByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_AUTHORTYPES_BY_ID_REQUEST,
  payload,
  meta,
})