// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import EventTable from './table';
import { Link } from 'react-router-dom';
import Button from 'arui-feather/button';

const Calendar = () => {
  return (
    <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
      <EventTable
        actions={
          <Link to={'calendar/create'}>
            <Button className={'button-float'} view="extra" size="s" type="submit">
              {'Add new event'}
            </Button>
          </Link>
        }
      />
    </GridRow>
  );
};

export default Calendar;