// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import GraphTable from 'components/Graphs/Table';
import { ICategoryTable, IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import { USERS_ROLES_TABLE } from 'constants/tables';
import { getAllUsersRolesRequest } from '../../redux/actions';
import StringCol from 'components/Flows/Elements/StringCol';

class UsersRolesTable extends React.Component<ICategoryTable> {

  normalizeTableData = (head: IHeadTable[], body: IBodyTable[]): any => {
    let processedData = body && body.map((item, i) => {
      const { id, username, email, userType, roles } = item;
      let emptyWidth: any = '';
      return {
        id,
        cells: [
          {
            element: StringCol(false, i + 1),
            id,
            type: 'id',
            width: emptyWidth,
          },
          {
            element: username,
            id,
            type: 'name',
            width: emptyWidth,
          },
          {
            element: email,
            id,
            type: 'email',
            width: emptyWidth,
          },
          {
            element: userType,
            id,
            type: 'userType',
            width: emptyWidth,
          },
          {
            element: roles.join(','),
            id,
            type: 'userRoles',
            width: emptyWidth,
          },
        ],
      };
    }) || [];

    const normalizeData = processedData.map((val: any) => {
      let newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });

    return normalizeData;
  };

  getRowLink = (id: any) => `/users-roles/edit/${id}`;

  render() {
    const { handleRowClick }: any = this.props;
    const head = [
      {
        id: 'id',
        label: '№',
        style: { width: '1' },
      },
      {
        id: 'name',
        label: 'UserName',
        style: { width: ' ' },
      },
      {
        id: 'email',
        label: 'Email',
        style: { width: '2' },
      },
      {
        id: 'userType',
        label: 'User Type',
        style: { width: '2' },
      },
      {
        id: 'userRoles',
        label: 'User Roles',
        style: { width: '2' },
      }
    ];

    return (
      <GraphTable
        table={USERS_ROLES_TABLE}
        title="Users/Roles"
        normalizeTableData={this.normalizeTableData}
        init={getAllUsersRolesRequest}
        onRowClick={handleRowClick}
        head={head}
        link={this.getRowLink}
        width={'12'}
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
      />
    );
  }
}

export default connect()(UsersRolesTable);
