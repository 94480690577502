class ClassAPI {
  token = '' ;

  setToken = (token: string) => {
    if (this.token !== token) {
      this.token = token;
    }
  };

  middleware = ({ getState }: any) => (next: any) => (action: any) => {
    const returnValue = next(action);
    const state = getState();
    const token = typeof state.auth.get === 'function' && state.auth.get('token');

    this.setToken(token);

    return returnValue;
  };
}

const API_GLOBAL = new ClassAPI();

export default API_GLOBAL;