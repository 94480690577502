// @ts-nocheck
import React from 'react';
import Label from 'arui-feather/label';

const StringCol = (link: boolean, value: any) => {
  return (
    <Label className={link ? 'border-link-bottom' : ''} size={'m'}> {value} </Label>
  );
};

export default StringCol;