import * as ACTION_TYPE from './constants';
import { EDIT_HEAD_REQUEST } from './constants';

export const getAllMailingTypeRequest = ({ meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_MAILING_TYPE_REQUEST,
  meta,
});

export const createNewMailingTypeRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_MAILING_TYPE_REQUEST,
  payload,
  meta,
});

export const editMailingTypeRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_MAILING_TYPE_REQUEST,
  payload,
  meta,
})

export const getMailingTypeByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_MAILING_TYPE_BY_ID_REQUEST,
  payload,
  meta,
});

export const deleteMailingTypeByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.DELETE_MAILING_TYPE_BY_ID_REQUEST,
  payload,
  meta,
})

export const getAllHeadRequest = ({ meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_HEAD_REQUEST,
  meta,
});

export const createNewHeadRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_HEAD_REQUEST,
  payload,
  meta,
});

export const editHeadRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_HEAD_REQUEST,
  payload,
  meta,
})

export const getHeadByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_HEAD_BY_ID_REQUEST,
  payload,
  meta,
})

export const deleteHeadByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.DELETE_HEAD_BY_ID_REQUEST,
  payload,
  meta,
})




export const getAllBodyRequest = ({ meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_BODY_REQUEST,
  meta,
});


export const createNewBodyRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_BODY_REQUEST,
  payload,
  meta,
});

export const editBodyRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_BODY_REQUEST,
  payload,
  meta,
})

export const getBodyByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_BODY_BY_ID_REQUEST,
  payload,
  meta,
})

export const deleteBodyByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.DELETE_BODY_BY_ID_REQUEST,
  payload,
  meta,
})



export const getAllFooterRequest = ({ meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_FOOTER_REQUEST,
  meta,
});

export const createNewFooterRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_FOOTER_REQUEST,
  payload,
  meta,
});

export const editFooterRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_FOOTER_REQUEST,
  payload,
  meta,
})

export const getFooterByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_FOOTER_BY_ID_REQUEST,
  payload,
  meta,
})

export const deleteFooterByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.DELETE_FOOTER_BY_ID_REQUEST,
  payload,
  meta,
})
