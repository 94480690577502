// @ts-nocheck
import React from 'react';
import { withRouter } from 'react-router-dom'
import FlowDialog from 'components/Flows/Dialog'
import GraphTable from 'components/Graphs/Table';
import { ICategoryTable, IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import { DIVIDENDS_TABLE } from 'constants/tables';
import {
  deleteDividendByIdRequest,
  getDevidendsByStockIdRequest,

} from '../../../redux/actions';
import StringCol from 'components/Flows/Elements/StringCol'
import IconDelete from 'arui-feather/icon/action/delete';
import { compose } from 'redux';
import Button from 'arui-feather/button';
import { openDialog } from '../../../../../components/Flows/Dialog/actions';
import { connect } from 'react-redux';
import DividendForm from '../../form/dividents';
import { getAllPeriodTypesRequest } from '../../../../Catalog/PeriodTypes/redux/actions';
import { DIVIDEND_FORM, TICKER_FORM } from '../../../../../constants/forms';
import IconEdit from 'arui-feather/icon/action/edit';


class DividendsTable extends React.Component<ICategoryTable> {
  state = {
    dividendId: null
  }

  normalizeTableData = (head: IHeadTable[], body: IBodyTable[]) : any => {
    let processedData = body && body.map((item, i) => {
      const { id, amount, year, recordDate, declarationDate, periodTypeId }:any = item;
      let emptyWidth: any = '';
      return {
        id,
        cells: [
          {
            element: <>{StringCol(false, i + 1)} <IconEdit size='m'/></>,
            id,
            type: 'id',
            width: emptyWidth
          },
          {
            element: periodTypeId,
            id,
            type: 'period',
            width: emptyWidth
          },
          {
            element: year,
            id,
            type: 'year',
            width: emptyWidth
          },
          {
            element: amount,
            id,
            type: 'amount',
            width: emptyWidth
          },
          {
            element: recordDate,
            id,
            type: 'recorddate',
            width: emptyWidth
          },
          {
            element: declarationDate,
            id,
            type: 'declaration',
            width: emptyWidth
          },
          {
            element: <IconDelete size={'s'}/>,
            id,
            type: 'delete',
            width: emptyWidth,
            customClass: 'delete-icon',
          },
        ]};
    }) || [];

    const normalizeData = processedData.map((val: any) => {
      let newCells = val
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells
    });

    return normalizeData;
  };

  getRowLink = (idDividend: any) => {
    const { match: { params: { id } } }: any= this.props
    return `/companies/edit/${id}/dividends/edit/${idDividend}`
  }


  handleEdit(id: any) {
    const { dispatch }: any = this.props
    this.setState({ dividendId: id })
    dispatch(openDialog({ id: 'table.dividend' }))
  }

  handleCreateNewDividend = () => {
    const { dispatch }: any = this.props
    dispatch(openDialog({ id: 'table.dividend' }))
  }

  handleDelete = (dividend: string) => {
    const { dispatch, stockId }: any = this.props;
    dispatch(deleteDividendByIdRequest({ payload: { id: dividend, stockId } }));
  };

  handleClose = () => {
    this.setState({
      dividendId: null,
    });
  };
  get createDialogs() {
    const { dividendId }: any = this.state
    const { id, stockId }: any = this.props
    return (
       // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
       // @ts-ignore
      <FlowDialog onClose={this.handleClose} form={DIVIDEND_FORM} id="table.dividend" width="big">
        <DividendForm stockId={stockId} id={id} dividendId={dividendId} />
      </FlowDialog>
    );
  }

  render() {
    const { handleRowClick, stockId }: any = this.props
    const head = [
      {
        id: 'id',
        type: 'edit',
        label: '№',
        style: { width: '1' },
      },
      {
        id: 'period',
        type: 'valueSet',
        action: getAllPeriodTypesRequest,
        label: 'Period',
        style: { width: ' ' },
      },
      {
        id: 'year',
        label: 'Year',
        style: { width: ' ' },
      },
      {
        id: 'amount',
        label: 'Amount',
        style: { width: ' ' },
      },
      {
        id: 'recorddate',
        label: 'Record date',
        style: { width: ' ' },
      },
      {
        id: 'declaration',
        label: 'Declaration Date',
        style: { width: ' ' },
      },
      {
        id: 'delete',
        type: 'delete',
        label: '',
        style: { width: '2' },
      },
    ];
    return (
      stockId ? (
        <>
          <GraphTable
            table={DIVIDENDS_TABLE}
            normalizeTableData={this.normalizeTableData}
            actions={
              <Button onClick={this.handleCreateNewDividend} className={"button-float"} view="extra" size="s">Create new dividend</Button>
            }
            title="Dividends"
            graphId={stockId}
            createDialogs={this.createDialogs}
            onDelete={(id: any) => this.handleDelete(id)}
            onEdit={(id: any) => this.handleEdit(id)}
            init={getDevidendsByStockIdRequest}
            onRowClick={handleRowClick}
            head={head}
            // link={this.getRowLink}
            width={'12'}
            gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
            activeLink
          />
        </>
      ): null
    );
  }
}

export default compose<any>(
  withRouter,
  connect()
)(DividendsTable);
