import {
  FORM_DATA_REQUEST,
  FORM_DATA_SUCCESS,
  FORM_EDIT_REQUEST,
  FORM_EDIT_SUCCESS,
  FORM_CREATE_REQUEST,
  FORM_CREATE_SUCCESS,
  FORM_RESET,
} from './constants';
import {IAction} from 'types/index'

export const formDataRequest = ({ payload, meta }: IAction<void>) => ({
  type: FORM_DATA_REQUEST,
  payload,
  meta,
});

export const formDataSuccess = ({ payload, meta }: IAction<void>) => ({
  type: FORM_DATA_SUCCESS,
  payload,
  meta,
});

export const formEditRequest = ({ payload, meta }: IAction<void>) => ({
  type: FORM_EDIT_REQUEST,
  payload,
  meta,
});

export const formEditSuccess = ({ payload, meta }: IAction<void>) => ({
  type: FORM_EDIT_SUCCESS,
  payload,
  meta,
});

export const formCreateRequest = ({ payload, meta }: IAction<void>) => ({
  type: FORM_CREATE_REQUEST, payload, meta,
});

export const formCreateSuccess = ({ payload, meta }: any) => ({
  type: FORM_CREATE_SUCCESS, payload, meta,
});

export const formReset = ({ meta }: any) => ({
  type: FORM_RESET, meta,
});
