import Auth from 'modules/Auth/routes';
import Categories from 'modules/Catalog/Categories/routes';
import ProductTypes from 'modules/Catalog/ProductTypes/routes';
import Sectors from 'modules/Catalog/Sectors/routes';
import Commodities from 'modules/Catalog/Commodities/routes';
import Countries from 'modules/Catalog/Countries/routes';
import Currencies from 'modules/Catalog/Currencies/routes';
import PeriodTypes from 'modules/Catalog/PeriodTypes/routes';
import StockTypes from 'modules/Catalog/StockTypes/routes';
import AuthorTypes from 'modules/Catalog/AuthorTypes/routes';
import EventTypes from 'modules/Catalog/EventTypes/routes';
import Languages from 'modules/Catalog/Languages/routes';

import Calendar from 'modules/Calendar/routes'

import InterfaceSettings from 'modules/InterfaceSettings/routes';
import Authors from 'modules/Authors/routes';
import WidgetsCharts from 'modules/WidgetsCharts/routes';
import Client from 'modules/Client/routes';
import Companies from 'modules/Companies/routes';
import Products from 'modules/Products/routes';
import UsersRoles from 'modules/UsersRoles/routes';
import MailingListSettings from 'modules/MailingListSettings/routes';

import LawFile from 'modules/LawFile/routes';
import Statistics from 'modules/Statistics/routes';
import Dashboard from 'modules/Dashboard/routes';
import Footer from 'modules/Footer/routes';
import CurrentMarketPrices from 'modules/CurrentMarketPricesAndOFZ/routes';
import WorkCalendar from '../modules/Catalog/WorkCalendar/routes';
import Emails from '../modules/Email/routes';

export default [
  ...Auth,
  ...Categories,
  ...ProductTypes,
  ...Sectors,
  ...Commodities,
  ...Countries,
  ...Currencies,
  ...PeriodTypes,
  ...StockTypes,
  ...AuthorTypes,
  ...EventTypes,
  ...Languages,
  ...Calendar,
  ...InterfaceSettings,
  ...Authors,
  ...WidgetsCharts,
  ...Client,
  ...Companies,
  ...UsersRoles,
  ...Products,
  ...MailingListSettings,
  ...LawFile,
  ...Footer,
  ...Statistics,
  ...Dashboard,
  ...CurrentMarketPrices,
  ...WorkCalendar,
  ...Emails
];
