// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
// 2022-10-04 add pagination component
import { Pagination } from '@alfalab/core-components/pagination';
import ProductsTable from './table';
import ProductFilterForm from "./forms/filterform";

const ProductsView = () => {
  return (
    <>
      <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
        <ProductFilterForm />
      </GridRow>

      <GridRow>
        <ProductsTable />
      </GridRow>
    </>
  );
};

export default ProductsView;
