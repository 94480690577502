import { language } from 'utils/formatters';


const normalize = (data: any) => {
  if (!data) return null;

  let cormData = {
    nameEN: language(data.name, 'en'),
    nameRU: language(data.name, 'ru'),
    commentEN: language(data.comment, 'en'),
    commentRU: language(data.comment, 'ru'),
    country:data.countryId,
    companies:data.companies,
    startDate:data.startDate,
    endDate:data.endDate,
    eventTypeId:data.eventTypeId
  }

  return cormData;
};

export default normalize;
