import * as ACTION_TYPE from './constants';

export const getAllworkCalendarRequest2 = ({ meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_WORK_CALENDAR_REQUEST2,
  meta,
});

export const createNewWorkCalendarRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_WORK_CALENDAR_REQUEST,
  payload,
  meta,
});

export const editWorkCalendarRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_WORK_CALENDAR_REQUEST,
  payload,
  meta,
})

export const getWorkCalendarByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_WORK_CALENDAR_BY_ID_REQUEST,
  payload,
  meta,
})