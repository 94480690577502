import { language } from 'utils/formatters';

const normalize = (data: any) => {
  if (!data) return null;

  let cormData = {
    nameEN: language(data.name, 'en'),
    nameRU: language(data.name, 'ru'),
    start: data.start,
    end: data.end,
  };

  return cormData;
};

export default normalize;
