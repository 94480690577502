export default [
  {
    isGroup: true,
    label: 'Sector name',
    items: [
      {
        placeholder: 'EN',
        type: 'text',
        id: 'nameEN',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU',
        type: 'text',
        id: 'nameRU',
        view: 'filled',
        width: 'available',
        size: 's',
      },
    ],
  },

  {
    isGroup: true,
    label: 'Sector short name',
    items: [
      {
        placeholder: 'EN',
        type: 'text',
        id: 'shortNameEN',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU',
        type: 'text',
        id: 'shortNameRU',
        view: 'filled',
        width: 'available',
        size: 's',
      },
    ],
  },
];
