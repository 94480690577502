import { language } from 'utils/formatters';

const normalize = (data: any) => {
  if (!data) return null;
  let cormData = {
    sector: data.sectorId,
    authors: data.authors,
    logo: {
      url: data?.logo,
      title: data?.logo?.split('/').pop()
    },
    tableInfo: {
      url: data?.tableInfo,
      title: data?.tableInfo?.split('/').pop()
    },
    equityTickerId: data.equityTickerId,
    CompanyNameEN: language(data.name, 'en'),
    CompanyNameRU: language(data.name, 'ru'),
    CompanyShortNameEN: language(data.shortName, 'en'),
    CompanyShortNameRU: language(data.shortName, 'ru'),
    CompanyDescriptionEN: language(data.description, 'en'),
    CompanyDescriptionRU: language(data.description, 'ru'),
  };


  return cormData;
};

export default normalize;
