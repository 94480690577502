import InterfaceSettings from './pages';


export default [
  {
    type: 'private',
    exact: true,
    path: '/interface-settings',
    roles: ['admin'],
    component: InterfaceSettings,
  },
  {
    type: 'private',
    exact: true,
    path: '/interface-settings/:id',
    roles: ['admin'],
    component: InterfaceSettings,
  },

];
