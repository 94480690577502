// @ts-nocheck
import React from 'react';
import { getAllMailingTypeRequest } from '../../../../MailingListSettings/redux/actions';
import { getTemplatesRequest } from '../../../../../components/GrapesJs/actions';

export const config = (curTime: any, valueSetsAll: any) => [
  {
    type: 'ValueSet',
    id: 'mailingListSettingsId',
    fieldWidth: '8',
    customPosition: {
      label: 3,
      field: 9,
    },
    placeholder: 'Mailing type',
    label: 'Mailing type',
    view: 'filled',
    width: 'available',
    size: 's',
    accept: true,
    filter: (data: any) => {
      if (valueSetsAll && valueSetsAll.ANNOTATION_OPTION_FORM) {
        const { productTypeId, categoryId } = valueSetsAll.ANNOTATION_OPTION_FORM
        if (data.productTypeId === productTypeId && data.categoryId === categoryId) {
          return data
        }
      }
    },
    handleOnChange: (props: any, dispatch: any) => dispatch(getTemplatesRequest({ payload: { mailingId: props } })),
    itemOptions: ({ name, id }: any) => ({ value: id, text: name }),
    action: getAllMailingTypeRequest,
  },
  {
    label: 'Subject',
    fieldWidth: '12',
    customPosition: {
      label: 2,
      field: 10,
    },
    placeholder: 'EN',
    type: 'text',
    id: 'subject',
    view: 'filled',
    width: 'available',
    size: 's',
    style: {
      marginBottom: '15px',
    },
  },
  {
    label: 'Editor',
    placeholder: 'EN',
    fieldWidth: '12',
    customPosition: {
      label: 2,
      field: 10,
    },
    type: 'editor',
    id: 'product-editor',
    view: 'filled',
    width: 'available',
    size: 's',
    style: {
      marginBottom: '15px',
    },
  },
  {
    label: 'Annotation',
    placeholder: 'EN',
    fieldWidth: '12',
    customPosition: {
      label: 2,
      field: 10,
    },
    type: 'editorAnnotation',
    id: 'annotation',
    view: 'filled',
    width: 'available',
    size: 's',
    style: {
      marginBottom: '15px',
    },
  },
  {
    label: 'Date publication',
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 8,
    },
    placeholder: 'EN',
    type: 'date',
    id: 'date',
    view: 'filled',
    // defaultValue: new Date().toLocaleDateString(),
    width: 'available',
    size: 's',
    style: {
      marginBottom: '15px',
    },
  },
  {
    label: 'Time publication',
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 8,
    },
    placeholder: 'DD:MM',
    type: 'timeCurrentDefault',
    // defaultValue: curTime,
    mask: '11:11',
    id: 'time',
    view: 'filled',
    width: 'available',
    size: 's',
  }
];
