// @ts-nocheck
import React from 'react';

export default [

  {
    isGroup: true,
    label: 'Name',
    fieldWidth: '12',
    customPosition: {
      label: 2,
      field: 10,
    },
    items: [
      {
        placeholder: 'Name',
        type: 'text',
        id: 'name',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
  {
    label: 'Editor',
    placeholder: 'EN',
    fieldWidth: '12',
    customPosition: {
      label: 2,
      field: 10,
    },
    type: 'editorMailing',
    id: 'text',
    view: 'filled',
    width: 'available',
    size: 's',
    style: {
      marginBottom: '15px',
    },
  }
];
