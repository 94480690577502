// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import CommoditiesForm from './form';
import CommoditiesTable from './table';

const Categories = () => {
  return (
    <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
      <CommoditiesTable />
      <CommoditiesForm />
    </GridRow>
  );
};

export default Categories;