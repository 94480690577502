// @ts-nocheck
import React from 'react';
import { withRouter } from 'react-router-dom'
import FooterForm from './form/basic';
import { Route, Switch } from 'react-router';


const FooterEdit = ({ history, location: { pathname }, match: { params: { id, stockId } }  }: any) => {
  const baseUrl = `/footerinfo/edit/${id}`
  return (
    <>
      <Switch>
        <Route exact path={baseUrl}>
          <FooterForm id={id} />
        </Route>
      </Switch>
    </>
  );
};

export default withRouter(FooterEdit);