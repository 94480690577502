export const CATALOG_CATEGORIES_TABLE = 'CATALOG_CATEGORIES_TABLE';
export const CATALOG_PRODUCT_TYPE_TABLE = 'CATALOG_PRODUCT_TYPE_TABLE';
export const CATALOG_SECTORS_TABLE = 'CATALOG_SECTORS_TABLE';
export const CATALOG_COMMODITIES_TABLE = 'CATALOG_COMMODITIES_TABLE';
export const CATALOG_COUNTRY_TABLE = 'CATALOG_COUNTRY_TABLE';
export const CATALOG_CURRENCIES_TABLE = 'CATALOG_CURRENCIES_TABLE';
export const CATALOG_PERIODTYPES_TABLE = 'CATALOG_PERIODTYPES_TABLE';
export const CATALOG_STOCKTYPES_TABLE = 'CATALOG_STOCKTYPES_TABLE';
export const CATALOG_AUTHORTYPES_TABLE = 'CATALOG_AUTHORTYPES_TABLE';
export const CATALOG_EVENTTYPES_TABLE = 'CATALOG_EVENTTYPES_TABLE';
export const CATALOG_LANGUAGES_TABLE = 'CATALOG_LANGUAGES_TABLE';
export const STOCKS_TABLE = 'STOCKS_TABLE';
export const DIVIDENDS_TABLE = 'DIVIDENDS_TABLE';
export const TICKERS_TABLE = 'TICKERS_TABLE';
export const TICKERS_RECOMENDATION_TABLE = 'TICKERS_RECOMENDATION_TABLE';

export const PRODUCTS_TABLE = 'PRODUCTS_TABLE';

export const INTERFACESETTINGS_TABLE = 'INTERFACESETTINGS_TABLE';
export const AUTHORS_TABLE = 'AUTHORS_TABLE';
export const COMPANIES_WIDGETSCHARTS_TABLE = 'COMPANIES_WIDGETSCHARTS_TABLE';
export const SECTOR_WIDGETSCHARTS_TABLE = 'SECTOR_WIDGETSCHARTS_TABLE';
export const CLIENTS_TABLE = 'CLIENTS_TABLE';
export const CALENDAR_TABLE = 'CALENDAR_TABLE';
export const COMPANIES_TABLE = 'COMPANIES_TABLE';
export const USERS_ROLES_TABLE = 'USERS_ROLES_TABLE';
export const MAILING_LIST_SETTINGS_TABLE = 'MAILING_LIST_SETTINGS_TABLE';
export const FILE_TABLE = 'FILE_TABLE';
export const HEAD_TABLE = 'HEAD_TABLE';
export const BODY_TABLE = 'BODY_TABLE';
export const INFO_TABLE = 'INFO_TABLE';

export const LAW_FILES_TABLE = 'LAW_FILES_TABLE';
export const STATISTICS_TABLE = 'STATISTICS_TABLE';
export const DASHBOARD_TABLE = 'DASHBOARD_TABLE';
export const FOOTERS_TABLE = 'FOOTERS_TABLE';

export const CATALOG_WORK_CALENDAR_TABLE = 'CATALOG_WORK_CALENDAR_TABLE';
export const EMAILS_TABLE = 'EMAILS_TABLE';
