// @ts-nocheck
import { fromJS } from 'immutable';
import {IAction} from 'types/index'
import {
  TABLE_DATA_REQUEST,
  TABLE_DATA_FAILURE,
  TABLE_DATA_SUCCESS,
  TABLE_DATA_PAGINATION_REQUEST,
  TABLE_DATA_PAGINATION_SUCCESS
} from './constants';
import {ceil, floor} from "lodash";
const initialState = fromJS({});

const PRODUCT_PAGE_SIZE = 10

// 2022-10-05 общее число записей по фильтру (сделано для продукта запрос filter2)
const getTotalRecords = (data: any): any => {
   if( data && data.length > 0 && data[0].totalRecords) {
     return data[0].totalRecords
   }
   else
     return 0;
}

const reducer = (state = initialState, { type, meta, payload }: IAction<void>) => {
  const table = meta && meta.table;
  const response = payload && payload.response;
// 2021-10-27 фвв
  const page = meta && meta.currentPage ? meta.currentPage : 0;
  const pageSize =  meta && meta.pageSize ? meta.pageSize : 10

  switch (type) {
    case TABLE_DATA_REQUEST: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state
        .setIn([table, 'data'], null)
        .setIn([table, 'loading'], true)
        .setIn([table, 'initialized'], false)
        .setIn([table, 'error'], null)
        .setIn([table, 'page'], 0)
        .setIn([table, 'pageSize'], pageSize);
    }

    case TABLE_DATA_SUCCESS: {
      const pages = {
        totalRecords:  getTotalRecords(response),
        allPages: ceil(getTotalRecords(response) / pageSize),
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state
        .setIn([table, 'data'], response)
        .setIn([table, 'loading'], false)
        .setIn([table, 'initialized'], false)
        .setIn([table, 'error'], null)
        .setIn([table, 'page'], page)
        .setIn([table, 'pageSize'], pageSize)
        .setIn([table, 'pages'], pages);
    }

    case TABLE_DATA_FAILURE: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state
        .setIn([table, 'request'], false)
        .setIn([table, 'loading'], false)
        .setIn([table, 'failureData'], payload)
        .setIn([table, 'error'], payload);
    }
// 2021-10-27 TABLE_DATA_PAGINATION_REQUEST and TABLE_DATA_PAGINATION_SUCCESS
    case TABLE_DATA_PAGINATION_REQUEST: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state
          .setIn([table, 'loading'], true)
          .setIn([table, 'page'], page)
          .setIn([table, 'initialized'], false)
          .setIn([table, 'error'], null);
    }
    case TABLE_DATA_PAGINATION_SUCCESS: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state
          .setIn([table, 'data'], response)
          .setIn([table, 'loading'], false)
          .setIn([table, 'initialized'], false)
          .setIn([table, 'error'], null);


    }

    default:
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state;
  }
};

export default reducer;
