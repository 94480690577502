export const GET_ALL_COUNTRY_REQUEST = 'GET_ALL_COUNTRY_REQUEST'
export const GET_ALL_COUNTRY_SUCCESS = 'GET_ALL_COUNTRY_SUCCESS'
export const GET_ALL_COUNTRY_FAILURE = 'GET_ALL_COUNTRY_FAILURE'

export const CREATE_NEW_COUNTRY_REQUEST = 'CREATE_NEW_COUNTRY_REQUEST'
export const CREATE_NEW_COUNTRY_SUCCESS = 'CREATE_NEW_COUNTRY_SUCCESS'
export const CREATE_NEW_COUNTRY_FAILURE = 'CREATE_NEW_COUNTRY_FAILURE'

export const GET_COUNTRY_BY_ID_REQUEST = 'GET_COUNTRY_BY_ID_REQUEST'
export const GET_COUNTRY_BY_ID_SUCCESS = 'GET_COUNTRY_BY_ID_SUCCESS'
export const GET_COUNTRY_BY_ID_FAILURE = 'GET_COUNTRY_BY_ID_FAILURE'

export const EDIT_COUNTRY_REQUEST = 'EDIT_COUNTRY_REQUEST'