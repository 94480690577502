// @ts-nocheck
import React from 'react';

export default [
  {
    isGroup: true,
    label: 'Name',
    items: [
      {
        placeholder: 'EN',
        type: 'text',
        id: 'name',
        maxLength: 3,
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
];
