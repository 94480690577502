import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {
  getAllCommodities = () => request({
    url: 'catalog/commodity',
    method: 'GET',
    token: API_GLOBAL.token
  });

  createNewCommodities = ({ data }: any) => request({
    url: 'catalog/commodity',
    data,
    method: 'POST',
    token: API_GLOBAL.token
  });

  editCommodities = ({ data }: any) => request({
    url: 'catalog/commodity',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  })

  getByIdCommodities = ({ id }: any) => request({
    url: `catalog/commodity/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })

  getByNameCommodities = ({ id }: any) => request({
    url: `catalog/commodity/by-name/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })
}

const API_COMMODITIES = new ClassAPI();

export default API_COMMODITIES;
