// @ts-nocheck
import React from 'react';
import { withRouter } from 'react-router-dom';
import GraphTable from 'components/Graphs/Table';
import { ICategoryTable, IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import { STOCKS_TABLE } from 'constants/tables';
import { openDialog } from 'components/Flows/Dialog/actions';
import { getAllCompaniesRequest, getStocksByIDCompanyRequest, deleteStocksByIdRequest } from '../../../redux/actions';
import { language, getCurrentCompany } from 'utils/formatters';
import IconDelete from 'arui-feather/icon/action/delete';
import IconEdit from 'arui-feather/icon/action/edit';
import { compose } from 'redux';
import { connect } from 'react-redux';
import FlowDialog from 'components/Flows/Dialog';
import StockForm from '../../form/stocks';
import Button from 'arui-feather/button';
import { getAllCurrenciesRequest } from '../../../../Catalog/Currencies/redux/actions';
import { getAllStockTypesRequest } from '../../../../Catalog/StockTypes/redux/actions';
import StringCol from '../../../../../components/Flows/Elements/StringCol';
import { createStructuredSelector } from 'reselect';
import { selectAllValueSets } from '../../../../../components/ValueSet/selector';
import { setValueSet } from '../../../../../components/ValueSet/actions';

class StocksTable extends React.Component<ICategoryTable> {

  state = {
    stockId: null,
  };


  normalizeTableData = (head: IHeadTable[], body: IBodyTable[]): any => {
    let processedData = body && body.map((item, i) => {
      const { id, name, companyId, currencyId, stockTypeId }: any = item;
      let emptyWidth: any = '';
      return {
        id,
        cells: [
          {
            element: <>{StringCol(false, i + 1)} <IconEdit size='m'/></>,
            id,
            type: 'edit',
            width: emptyWidth,
          },
          {
            element: language(name, 'en'),
            id,
            type: 'name',
            width: emptyWidth,
          },
          {
            element: companyId,
            id,
            type: 'company',
            width: emptyWidth,
          },
          {
            element: currencyId,
            id,
            type: 'currencyId',
            width: emptyWidth,
          },
          {
            element: stockTypeId,
            id,
            type: 'stockTypeId',
            width: emptyWidth,
          },
          {
            element: <IconDelete size={'s'}/>,
            id,
            type: 'delete',
            width: emptyWidth,
            customClass: 'delete-icon',
          },
        ],
      };
    }) || [];

    const normalizeData = processedData.map((val: any) => {
      let newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });

    return normalizeData;
  };

  getRowLink = (stockId: any) => {
    const { id }: any = this.props;
    return `/companies/edit/${id}/stock/${stockId}`;
  };

  handleDelete = (stockId: string) => {
    const { dispatch, id }: any = this.props;
    dispatch(deleteStocksByIdRequest({ payload: { id: stockId, companyId: id } }));
  };


  handleEdit(id: any) {
    const { dispatch, id: companyId }: any = this.props;
    this.setState({ stockId: id, companyId });
    dispatch(openDialog({ id: 'table.stoks' }));
  }

  handleClose = () => {
    this.setState({
      stockId: null,
    });
  };


  get createDialogs() {
    const { stockId }: any = this.state;
    const { id }: any = this.props;
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        <FlowDialog onClose={this.handleClose} id="table.stoks" width="big">
        <StockForm stockId={stockId} id={id}/>
      </FlowDialog>
    );
  }


  handleCreateNewStock = () => {
    const { dispatch }: any = this.props;
    dispatch(openDialog({ id: 'table.stoks' }));
  };

  currentCompany = () => {
    const { id, valueSetsAll }: any = this.props;
    const { company } = valueSetsAll;
    return company && <div className={'company-block'}>{getCurrentCompany(id, company)}</div>;
  };


  render() {
    const { handleRowClick, id }: any = this.props;
    const head = [
      {
        id: 'edit',
        type: 'edit',
        label: '№',
        style: { width: '1' },
      },
      {
        id: 'name',
        label: 'Name',
        style: { width: ' ' },
      },
      {
        id: 'company',
        type: 'valueSet',
        action: getAllCompaniesRequest,
        label: 'Company',
        style: { width: ' ' },
      },
      {
        id: 'currencyId',
        type: 'valueSet',
        code2displayFunc: ({ name }: any) => name,
        action: getAllCurrenciesRequest,
        label: 'Currency',
        style: { width: ' ' },
      },
      {
        id: 'stockTypeId',
        type: 'valueSet',
        action: getAllStockTypesRequest,
        label: 'Stock Type',
        style: { width: ' ' },
      },
      {
        id: 'delete',
        type: 'delete',
        label: '',
        style: { width: '2' },
      },
    ];

    return (
      <>
        {this.currentCompany()}
        <GraphTable
          table={STOCKS_TABLE}
          normalizeTableData={this.normalizeTableData}
          title="Stocks"
          graphId={id}
          actions={
            <Button onClick={this.handleCreateNewStock} className={'button-float'} view="extra" size="s">Create new
              stock</Button>
          }
          init={getStocksByIDCompanyRequest}
          onRowClick={handleRowClick}
          onDelete={(id: any) => this.handleDelete(id)}
          onEdit={(id: any) => this.handleEdit(id)}
          head={head}
          createDialogs={this.createDialogs}
          link={this.getRowLink}
          width={'12'}
          gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
          activeLink
        />
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  valueSetsAll: selectAllValueSets,
});
const withConnect = connect(mapStateToProps);


export default compose<any>(
  withConnect,
  withRouter,
  connect(),
)(StocksTable);
