// @ts-nocheck
import { fromJS } from 'immutable';
import {
  GET_ALL_CLIENTS_FAILURE,
  GET_ALL_CLIENTS_SUCCESS,
  STORE_CURRENT_CLIENT,

} from './constants';

const initialState = fromJS({
  error: null,
  token: null,
  logoutData: null,
  currentData: null
});

const clientsReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case GET_ALL_CLIENTS_FAILURE: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state
        .set('token', payload)
        .set('error', null);
    }
    case GET_ALL_CLIENTS_SUCCESS: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state.set('error', payload);
    }
    case STORE_CURRENT_CLIENT: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state.set('currentData', payload);
    }

    default:
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state;
  }
};

export default clientsReducer;
