// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import GraphTable from 'components/Graphs/Table';
import { ICategoryTable, IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import {
  CATALOG_CURRENCIES_TABLE,
  CATALOG_EVENTTYPES_TABLE,
  CATALOG_LANGUAGES_TABLE,
  CATALOG_PERIODTYPES_TABLE,
  COMPANIES_TABLE, STOCKS_TABLE
} from 'constants/tables';
import {
  getAllCompaniesRequest,
  deleteCompaniesByIdRequest,
  getAllTickersRequest,
  getAllStocksRequest
} from '../../redux/actions';
import StringCol from 'components/Flows/Elements/StringCol';
import IconDelete from 'arui-feather/icon/action/delete';
import { language } from 'utils/formatters'
import { getAllSectorsRequest } from 'modules/Catalog/Sectors/redux/actions';
import { getAllAuthorsRequest } from '../../../Authors/redux/actions';
import {getAllLanguagesRequest} from "../../../Catalog/Languages/redux/actions";
import {tableDataSuccess} from "../../../../components/Graphs/Table/actions";
import {getAllEventTypesRequest} from "../../../Catalog/EventTypes/redux/actions";
import {getAllPeriodTypesRequest} from "../../../Catalog/PeriodTypes/redux/actions";
import {getAllCurrenciesRequest} from "../../../Catalog/Currencies/redux/actions";

class CompaniesTable extends React.Component<ICategoryTable> {

  normalizeTableData = (head: IHeadTable[], body: IBodyTable[]): any => {
    let processedData = body && body.map((item, i) => {
      const { id, name, sectorId, authors, equityTickerName}:any = item;
      let emptyWidth: any = '';
      return {
        id,
        cells: [
          {
            element: StringCol(false, i + 1),
            id,
            type: 'id',
            width: emptyWidth,
          },
          {
            element: StringCol(true, language(name,'en')),
            id,
            type: 'name',
            width: emptyWidth,
          },
          {
            element: sectorId,
            id,
            type: 'sectorId',
            width: emptyWidth,
          },
          {
            element: authors,
            id,
            type: 'authors',
            width: emptyWidth,
          },
          {
            element: equityTickerName,
            id,
            type: 'equityTickerName',
            width: emptyWidth,
          },
          {
            element: <IconDelete size={'s'}/>,
            id,
            type: 'delete',
            width: emptyWidth,
            customClass: 'delete-icon',
          },
        ],
      };
    }) || [];


    const normalizeData = processedData.map((val: any) => {
      let newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });

    return normalizeData;
  };

  getRowLink = (id: any) => `/companies/edit/${id}`;

  handleDelete = (id: string) => {
    const { dispatch }: any = this.props;
    dispatch(deleteCompaniesByIdRequest({ payload: { id } }));
  };

  // 2021-09-28 Для импорта дивендов добавлена доп загрузка
  // ++ language языков
  // вообще лучше это делат глобально, но я думаю пока достаточно только здесь
  // не было заказов, а это я делаю для того чотбы вставить реальные коды языков из базы в генерируемые события после загрузки
  // дивидентов
  // ++ Загрузка типов событий
  // ++ Загрузка Периодов
  // ++ Загрузка Валют
  // ++ Загрузка Stock (Из него нужен взять код валюты, он не упоминается в самом файле импорта
  componentDidMount() {
    const { dispatch }: any = this.props;
    dispatch(getAllLanguagesRequest({ meta: { table: CATALOG_LANGUAGES_TABLE, callback: tableDataSuccess }}))
    dispatch(getAllEventTypesRequest({ meta: { table: CATALOG_EVENTTYPES_TABLE, callback: tableDataSuccess }}))
    dispatch(getAllPeriodTypesRequest({ meta: { table: CATALOG_PERIODTYPES_TABLE, callback: tableDataSuccess }}))
    dispatch(getAllCurrenciesRequest({ meta: { table: CATALOG_CURRENCIES_TABLE, callback: tableDataSuccess }}))
    dispatch(getAllStocksRequest({ meta: { table: STOCKS_TABLE, callback: tableDataSuccess }}))

  }

  render() {
    const { handleRowClick }: any = this.props;
    const head = [
      {
        id: 'id',
        label: 'ID',
        style: { width: '1' },
      },
      {
        id: 'name',
        label: 'Name',
        style: { width: ' ' },
      },
      {
        id: 'sectorId',
        type: 'valueSet',
        action: getAllSectorsRequest,
        label: 'Sector',
        style: { width: ' ' },
      },
      {
        id: 'authors',
        label: 'Authors',
        type: 'valueSet',
        action: getAllAuthorsRequest,
        style: { width: ' ' },
      },
      {
        id: 'equityTickerName',
        label: 'Ticker main',
        // type: 'valueSet',
        // action: getAllTickersRequest,
        style: { width: ' ' },
      },
      {
        id: 'delete',
        type: 'delete',
        label: '',
        style: { width: ' ' },
      },
    ];

    const { actions } = this.props
    return (
      <GraphTable
        title="Companies table"
        table={COMPANIES_TABLE}
        actions={actions}
        normalizeTableData={this.normalizeTableData}
        init={getAllCompaniesRequest}
        onRowClick={handleRowClick}
        onDelete={(id: any) => this.handleDelete(id)}
        head={head}
        link={this.getRowLink}
        width={'12'}
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
      />
    );
  }
}

export default connect()(CompaniesTable);
