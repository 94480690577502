// @ts-nocheck
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom'
import GridRow from 'arui-feather/grid-row';
import TabItem from 'arui-feather/tab-item';
import Tabs from 'arui-feather/tabs';
import CompanyForm from './form/basic';
import StocksPage from './stocksPage';
import EquityPage from './equityPage';
import FilesPage from './filesPage';
import { Route, Switch } from 'react-router';
import ClientPage from '../../Client/pages/form/client';

const TABS = [
  {
    url: '/basic-information',
    label: 'Basic information',
    tab: <CompanyForm />
  },
  {
    url: '/stocks-dividends',
    label: 'Stocks and Dividends',
    tab: <StocksPage />
  },
  {
    url: '/equity-tickers',
    label: 'Equity tickers and recommendations',
    tab: <EquityPage />
  }
];

const ClientsAdd = ({ history, location: { pathname }, match: { params: { id, stockId } }  }: any) => {
  const handleClick = (event: any) => {
    event.preventDefault();
    history.push(event.target.getAttribute('href'))
  }

  const baseUrl = `/companies/create`
  return (
    <>
      <Tabs>
        <TabItem url={baseUrl} onClick={handleClick} checked={ pathname === baseUrl }>
          Basic information
        </TabItem>
        <TabItem disabled={true} url={`${baseUrl}/stock`} onClick={handleClick} checked={ pathname === `${baseUrl}/stock` }>
          Stocks and Dividends
        </TabItem>
        <TabItem disabled={true} url={`${baseUrl}/equity`} onClick={handleClick} checked={ pathname === `${baseUrl}/equity` }>
          Equity tickers and recommendations
        </TabItem>
        <TabItem disabled={true} url={`${baseUrl}/files`} onClick={handleClick} checked={ pathname === `${baseUrl}/files` }>
          Files
        </TabItem>
      </Tabs>
      <Switch>
        <Route exact path={baseUrl}>
          <CompanyForm id={id} />
        </Route>
        <Route path={`${baseUrl}/stock`}>
          <StocksPage id={id} stockId={stockId} />
        </Route>
        <Route path={`${baseUrl}/equity`}>
          <EquityPage id={id} />
        </Route>
        <Route path={`${baseUrl}/files`}>
          <FilesPage id={id} />
        </Route>
      </Switch>
    </>
  );
};

export default withRouter(ClientsAdd);