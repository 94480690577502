// @ts-nocheck
/*  Spin
    2021-12-23  История с loadState делалась без задания со стороны зказчика, поэтому ее использваоние закомментарено сейчс
    предалагаю включить в ТЗ работу в последующих разработках время - 24 часа
*
*
* */
import React from 'react';
import {withRouter, NavLink} from 'react-router-dom';
import {compose} from 'redux';
import {isEmpty} from 'lodash';
import GridCol from 'arui-feather/grid-col';
import Label from 'arui-feather/label';
import RowTable from './rowTable';
import CellTable from './cellTable';
import cn from 'classnames';
import {IHeadTable} from 'components/Graphs/Table/interface';
import Heading from 'arui-feather/heading';
import {createStructuredSelector} from 'reselect';
import {makeSelectRoles, makeSelectToken} from '../../../app/selectors';
import {connect} from 'react-redux';
import {checkVisibleElement} from '../../../constants/roles';
import GridRow from 'arui-feather/grid-row';
import {makeSelectLoading} from "./selector";
import Spin from 'arui-feather/spin';
import {Pagination} from "@alfalab/core-components/pagination";

export function LinkComponent(props: any) {
    const {headconfig, value, clickcellhandler} = props;
    if (!headconfig.type || headconfig.type === 'valueSet') {
        return (
            <NavLink
                activeStyle={{
                    backgroundColor: '#f3f4f5',
                }}
                to={props.path || ''}
                {...props}
            >
                {props.children}
            </NavLink>
        );
    }

    return (
        <div onClick={clickcellhandler(headconfig, value)} {...props}>
            {props.children}
        </div>
    );
}

class FlowTable extends React.Component<any, any> {
    renderHead = (head: IHeadTable[]) => {
        const {gutter} = this.props;
        return (
            <RowTable gutter={gutter} className="admin-table-header catalog">
                {head.map((item, index) => {
                    // console.log('RowTable =', item, item.style && item.style.width)
                    return (
                        <CellTable
                            key={index}
                            width={item.style && item.style.width}
                            className={cn(
                                'admin-table-cell admin_table_header_cell',
                                item.className,
                            )}
                        >
                            <Label size={'m'}> {item.label} </Label>
                        </CellTable>
                    );
                })}
            </RowTable>
        );
    };

    handleRowClick = (id: any, row: any) => (event: any) => {
        console.log('!!!!! handleRowClick=', id, row)

        if (row.disabled) {
            return;
        }
        const {onRowClick} = this.props;
        if (typeof onRowClick === 'function' && id) {
            event.stopPropagation();
            onRowClick(id, row);
        }
    };

    getPathLink(id: any, row: any) {
        const {link} = this.props;
        if (typeof link === 'function' && id) {
            return link(id, row);
        }
    }

    handleDelete = (id: any) => () => {
        const {onDelete} = this.props;
        onDelete(id);
    };

    handleEditRow = (id: any, val: any) => () => {
        const {onEdit} = this.props;
        onEdit(id, val);
    };

    clickcellhandler = (head: any, val: any) => {
        switch (head?.type) {
            case 'delete':
                return this.handleDelete(val.id);
            case 'edit':
                return this.handleEditRow(val.id, val);
            default:
                return this.handleRowClick(val.id, val);
        }
    };


    clickPagination = () => {
        const {Paginate, tablePage, body, table, tableSettings} = this.props;
        console.log('clickPagination : show more', tablePage, body, table, tableSettings)
        Paginate();
    };

    clickPagination2 = (index) => {
        const {Paginate2, tablePage, body, table, tableSettings} = this.props;
        console.log('clickPagination2 : classic pagination', tablePage, body, table, tableSettings)
        Paginate2(index);
    };


    renderChart = () => {
        const {body} = this.props;
    };

    renderBody = () => {
        const {head, gutter, data, link} = this.props;
        return (
            (!isEmpty(data) &&
                data.map((val: any, p: string | number | undefined) => {
                    return (
                        <RowTable
                            id={val.id}
                            key={p}
                            gutter={gutter}
                            className="admin-table-body-data catalog"
                        >
                            {link
                                ? val.cells.map((it: any, y: number) => {
                                    return (
                                        <LinkComponent
                                            className={cn(
                                                'grid-col admin_table_data_cell break_words_cell ',
                                                {
                                                    [`grid-col_width_${it && it.width}`]:
                                                    it && it.width,
                                                    [it && it.customClass]: it && it.customClass,
                                                },
                                                {[it.className]: it.className}
                                            )}
                                            key={y}
                                            headconfig={head[y]}
                                            value={val}
                                            clickcellhandler={() =>
                                                this.clickcellhandler(head[y], val)
                                            }
                                            path={this.getPathLink(val.id, val)}
                                        >
                                            <CellTable key={y}>{it && it.element}</CellTable>
                                        </LinkComponent>
                                    );
                                })
                                : val.cells.map((it: any, y: number) => {
                                    return (
                                        <CellTable
                                            key={y}
                                            onClick={this.clickcellhandler(head[y], val)}
                                            className={cn(
                                                'grid-col admin_table_data_cell break_words_cell ',
                                                {
                                                    [`grid-col_width_${it.width}`]: it && it.width,
                                                    [it.customClass]: it && it.customClass,
                                                },
                                                {[it.className]: it.className}
                                            )}
                                        >
                                            {it && it.element}
                                        </CellTable>
                                    );
                                })}
                        </RowTable>
                    );
                })) || (
                <RowTable gutter={gutter} className="admin-table-body-data catalog">
                    Data not found
                </RowTable>
            )
        );
    };

    render() {
        const {
            head,
            customTitleHtml,
            width,
            body,
            chart,
            title,
            rolesUser,
            actions,
            pagination,
            tablePages,
            currentPage
        } = this.props;
        // console.log('Flows render pagination=', pagination, this.props)
        const header = head === undefined ? [] : head;
        const loadState = this.props.loading
        // console.log('render loadState=', loadState )
        return (
            <GridCol width={width}>
                {title && <Heading size="xs">{title}</Heading>}
                <GridRow className="buttonTable">{actions}</GridRow>
                {customTitleHtml && checkVisibleElement(rolesUser, 'addProductButton')
                    ? customTitleHtml
                    : ''}
                {chart ? chart(body) : ''}
                {this.renderHead(header)}
                {!loadState &&
                    this.renderBody()
                }
                {/*
            {loadState &&
              <div style={{ marginTop: '16px', textAlign : 'center'}}>
                  <Spin
                      size={ 'm' }
                      visible={ true }
                  />
              </div>
            }
*/}
                {pagination &&
                    <div
                        onClick={this.clickPagination}
                        className="link events__bottom-link btn-home-20 link_size_m link_theme_alfa-on-white"
                    >
                      <span className="link__text-container">
                        <span className="link__text">
                              Show more...
                        </span>
                      </span>
                        <span className="link__icon">
                        <span className="icon icon_size_m icon_name_arrow-right icon_theme_alfa-on-white"/>
                      </span>
                    </div>
                }

                {tablePages &&
                    <div style={{width: '300px', margin: "0 auto"}}>
                        <Pagination
                            currentPageIndex={(currentPage) ? currentPage : 0}
                            pagesCount={(tablePages.allPages) ? tablePages.allPages : 0}
                            onPageChange={(index) => {
                                this.clickPagination2(index);
                            }
                            }
                        />
                    </div>

                }

            </GridCol>

        );
    }
}

const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    rolesUser: makeSelectRoles(),
});

const withConnect = connect(mapStateToProps);

export default compose<any>(withRouter, withConnect)(FlowTable);
