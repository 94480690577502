// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import GraphTable from 'components/Graphs/Table';
import { ICategoryTable, IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import { CLIENTS_TABLE } from 'constants/tables';
import {
  getAllClientsRequest,
  deleteClientsByIdRequest,
  createNewClientsRequest,
  setSectorsCustom
} from '../../redux/actions';
import StringCol from 'components/Flows/Elements/StringCol';
import IconDelete from 'arui-feather/icon/action/delete';
import { language } from 'utils/formatters'
import { getAllUsersRolesRequest } from 'modules/UsersRoles/redux/actions';
import Button from "arui-feather/button";
import {CLIENTS_FORM} from "../../../../constants/forms";


class ClientsTable extends React.Component<ICategoryTable> {

  normalizeTableData = (head: IHeadTable[], body: IBodyTable[]): any => {
    let processedData = body && body.map((item, i) => {
      const { id, name, userId, type }:any = item;
      let emptyWidth: any = '';
      return {
        id,
        cells: [
          {
            element: StringCol(false, i + 1),
            id,
            type: 'id',
            width: emptyWidth,
          },
          {
            element: language(name,'en'),
            id,
            type: 'name',
            width: emptyWidth,
          },
          {
            element: type,
            id,
            type: 'authorType',
            width: emptyWidth,
          },
          {
            element: userId,
            id,
            type: 'users',
            width: emptyWidth,
          },
          {
            element: <IconDelete size={'s'}/>,
            id,
            type: 'delete',
            width: emptyWidth,
            customClass: 'delete-icon',
          },
        ],
      };
    }) || [];

    const normalizeData = processedData.map((val: any) => {
      let newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });
    console.log('Client normalizeData=', normalizeData)
    return normalizeData;
  };

  getRowLink = (id: any) => `/clients/edit/${id}`;

  handleDelete = (id: string) => {
    const { dispatch }: any = this.props;
    dispatch(deleteClientsByIdRequest({ payload: { id } }));
  };

  // очистка секторов  секторов по п.3 ТЗ 2022
  clearSectors = () => {
    const { dispatch }: any = this.props;
    dispatch(setSectorsCustom({ payload: { }, meta: {} }));
  }

  render() {
    const { handleRowClick }: any = this.props;
    const head = [
      {
        id: 'id',
        label: 'ID',
        style: { width: '1' },
      },
      {
        id: 'name',
        label: 'Name',
        style: { width: ' ' },
      },
      {
        id: 'authorType',
        label: 'Type',
        style: { width: '2' },
      },
      {
        id: 'users',
        label: 'User/Login',
        type: 'valueSet',
        code2displayFunc: (props:any) => props && `${props?.username || ''} ${props?.lastName || ''}`,
        action: getAllUsersRolesRequest,
        style: { width: '2' },
      },
      {
        id: 'delete',
        type: 'delete',
        label: '',
        style: { width: '2' },
      },
    ];

    const { actions } = this.props
    return (
    <>
      <Button
            size="s"
            onClick={this.clearSectors}
        >
          Clear Sectors by some Clients
        </Button>

    <GraphTable
        title="Clients table"
        table={CLIENTS_TABLE}
        actions={actions}
        normalizeTableData={this.normalizeTableData}
        init={getAllClientsRequest}
        onRowClick={handleRowClick}
        onDelete={(id: any) => this.handleDelete(id)}
        head={head}
        link={this.getRowLink}
        width={'12'}
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
      />
    </>
    );
  }
}

export default connect()(ClientsTable);
