// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import GraphTable from 'components/Graphs/Table';
import { ICategoryTable, IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import { AUTHORS_TABLE } from 'constants/tables';
import { getAllAuthorsRequest, deleteAuthorsByIdRequest } from '../../redux/actions';
import StringCol from 'components/Flows/Elements/StringCol';
import IconDelete from 'arui-feather/icon/action/delete';
import { language } from 'utils/formatters';
import { getAllAuthorTypesRequest } from 'modules/Catalog/AuthorTypes/redux/actions';
import { getAllCompaniesRequest } from '../../../Companies/redux/actions';
import CheckBoxCol from "../../../../components/Flows/Elements/CheckBoxCol";

class AuthorsTable extends React.Component<ICategoryTable> {

  normalizeTableData = (head: IHeadTable[], body: IBodyTable[]): any => {
    let processedData = body && body.map((item, i) => {
      const { id, name, companies, authorTypeId, blocked }: any = item;
      let emptyWidth: any = '';
      return {
        id,
        cells: [
          {
            element: StringCol(false, i + 1),
            id,
            type: 'id',
            width: emptyWidth,
          },
          {
            element: StringCol(true, language(name, 'en')),
            id,
            type: 'name',
            width: emptyWidth,
          },
          {
            element: authorTypeId,
            id,
            type: 'authorType',
            width: emptyWidth,
          },
          {
            element: companies,
            id,
            type: 'companies',
            width: emptyWidth,
          },
          {
            element: CheckBoxCol(Boolean(!blocked)),
            id,
            type: 'active',
            width: emptyWidth
          },
          {
            element: <IconDelete size={'s'}/>,
            id,
            type: 'delete',
            width: emptyWidth,
            customClass: 'delete-icon',
          },
        ],
      };
    }) || [];

    const normalizeData = processedData.map((val: any) => {
      let newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });

    return normalizeData;
  };

  getRowLink = (id: any) => `/authors/edit/${id}`;

  handleDelete = (id: string) => {
    const { dispatch }: any = this.props;
    dispatch(deleteAuthorsByIdRequest({ payload: { id } }));
  };

  render() {
    const { handleRowClick }: any = this.props;
    const head = [
      {
        id: 'id',
        label: '№',
        style: { width: '1' },
      },
      {
        id: 'name',
        label: 'Name',
        style: { width: ' ' },
      },
      {
        id: 'authorType',
        label: 'Type',
        code2displayFunc: ({ name }:any) => name,
        action: getAllAuthorTypesRequest,
        type: 'valueSet',
        style: { width: '2' },
      },
      {
        id: 'companies',
        label: 'Companies',
        type: 'valueSet',
        action: getAllCompaniesRequest,
        style: { width: '2' },
      },
      {
        id: 'active',
        label: 'Active',
        style: { width: '1' },
      },
      {
        id: 'delete',
        type: 'delete',
        label: '',
        style: { width: '2' },
      },
    ];

    const { actions }: any = this.props
    return (
      <GraphTable
        table={AUTHORS_TABLE}
        actions={actions}
        title="Authors"
        normalizeTableData={this.normalizeTableData}
        init={getAllAuthorsRequest}
        onRowClick={handleRowClick}
        onDelete={(id: any) => this.handleDelete(id)}
        head={head}
        link={this.getRowLink}
        width={'12'}
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
      />
    );
  }
}

export default connect()(AuthorsTable);
