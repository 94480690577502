// @ts-nocheck
import React from 'react';
import { getAllAuthorTypesRequest } from 'modules/Catalog/AuthorTypes/redux/actions';

export default [
  {
    isGroup: true,
    label: 'Name',
    fieldWidth: '5',
    items: [
      {
        placeholder: 'EN First name and last name',
        type: 'text',
        id: 'nameEN',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU Имя и фамилия',
        type: 'text',
        id: 'nameRU',
        view: 'filled',
        width: 'available',
        size: 's',
      },
    ],
  },
  {
    fieldWidth: '1',
    type: 'gridCol',
  },
  {
    fieldWidth: '5',
    type: 'ValueSet',
    id: 'authorType',
    placeholder: 'Type',
    label: 'Type',
    view: 'filled',
    width: 'available',
    size: 's',
    itemOptions: (item: any) => ({ value: item.id, text: item.name }),
    action: getAllAuthorTypesRequest,
  },
  {
    isGroup: true,
    label: 'Birthday',
    fieldWidth: '5',
    items: [
      {
        type: 'date',
        id: 'birthday',
        view: 'filled',
        placeholder: 'DD.MM.YYYY',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
  {
    fieldWidth: '1',
    type: 'gridCol',
  },
  {
    type: 'fileSimple',
    typeFile:'img',
    id: 'photo',
    label: 'Photo',
    fieldWidth: '5',
    accept: 'image/jpg,image/jpeg, image/png',
    noFileText: 'Select and upload file',
  },
  {
    isGroup: true,
    label: 'E-mail',
    fieldWidth: '5',
    items: [
      {
        placeholder: 'email',
        type: 'text',
        id: 'email',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
  {
    fieldWidth: '7',
    type: 'gridCol',
  },
  {
    isGroup: true,
    label: 'Phone 1',
    fieldWidth: '5',
    items: [
      {
        placeholder: '',
        type: 'text',
        id: 'phone1',
        maxLength: 40,
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
  {
    fieldWidth: '7',
    type: 'gridCol',
  },
  {
    isGroup: true,
    label: 'Phone 2',
    fieldWidth: '5',
    items: [
      {
        placeholder: '',
        type: 'text',
        id: 'phone2',
        maxLength: 40,
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
  {
    fieldWidth: '7',
    type: 'gridCol',
  },
  {
    isGroup: true,
    label: 'Position',
    fieldWidth: '5',
    items: [
      {
        placeholder: 'EN',
        type: 'text',
        id: 'positionEN',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU',
        type: 'text',
        id: 'positionRU',
        view: 'filled',
        width: 'available',
        size: 's',
      },
    ],
  },
  {
    fieldWidth: '7',
    type: 'gridCol',
  },
  {
    isGroup: true,
    label: 'Address',
    fieldWidth: '12',
    customPosition: {
      label: 1,
      field: 11,
      className:'col-11-field'
    },
    items: [
      {
        placeholder: 'EN',
        type: 'text',
        id: 'addressEN',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU',
        type: 'text',
        id: 'addressRU',
        view: 'filled',
        width: 'available',
        size: 's',
      },
    ],
  },
  {
    isGroup: true,
    label: 'Biography',
    fieldWidth: '12',
    customPosition: {
      label: 1,
      field: 11,
      className:'col-11-field'
    },
    items: [
      {
        placeholder: 'EN',
        type: 'editorAuthor',
        autosize: true,
        maxRows: 5,
        id: 'biographyEN',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU',
        type: 'editorAuthorRu',
        autosize: true,
        maxRows:5,
        id: 'biographyRU',
        view: 'filled',
        width: 'available',
        size: 's',
      },
    ],
  },

];
