import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api';

class ClassAPI {
  getAllEmails = () => request({
    url: 'delivery/sender_email',
    method: 'GET',
    token: API_GLOBAL.token,
  });

  createNewEmail = ({ data }: any) => request({
    url: 'delivery/sender_email',
    data,
    method: 'POST',
    token: API_GLOBAL.token,
  });

  editEmail = ({ data }: any) => request({
    url: 'delivery/sender_email',
    data,
    method: 'PUT',
    token: API_GLOBAL.token,
  });

  getByIdEmail = ({ id }: any) => request({
    url: `delivery/sender_email/${id}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  deleteByIdEmail = (id: any) => request({
    url: `delivery/sender_email/${id}`,
    method: 'DELETE',
    token: API_GLOBAL.token,
  });

}

const API_EMAIL = new ClassAPI();

export default API_EMAIL;
