import * as ACTION_TYPE from './constants';

export const setFilterFormValue = ({ payload, meta }: any) => ({
  type: '@@redux-form/CHANGE',
  payload,
  meta,
});


export const getAllDashboardRequest = ({ meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_DASHBOARD_REQUEST,
  meta,
});

export const createFilterDashboardRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_FILTER_DASHBOARD_REQUEST,
  payload,
  meta,
});

export const getAllWorkCalendarRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_WORK_CALENDAR_REQUEST,
  meta,
  payload
});



