// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import UsersRolesTable from './table';
import {SynchoKeyCloak} from "./SynchroKeyCloak";
import SynchroUser from "../../../components/Element/SynchroUser";


const UsersRolesView = () => {
    return (
        <>
            <GridRow>
                <SynchroUser text={"Synchronisation KeyCloak"}></SynchroUser>
            </GridRow>
            <GridRow gutter={{mobile: 0, tablet: 16, desktop: {m: 12}}}>
                <UsersRolesTable/>
            </GridRow>
        </>
    );
};

export default UsersRolesView;