// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import { Link } from 'react-router-dom';
import Button from 'arui-feather/button';

const CurrenMarketPricesView = () => {
  console.log('Client view render');
  return (
  <>
    <GridRow>
      <Link to={'current-market-prices/create'}>
           <h5 className="heading heading_size_xs heading_margins_xs heading_theme_alfa-on-white">
              Current market prices
           </h5>
           <Button className={'button-float'} view="extra" size="s" type="submit">
              Add new Current market prices
            </Button>
      </Link>
    </GridRow>
    </>
  );
};

export default CurrenMarketPricesView;