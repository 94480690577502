// @ts-nocheck
import React from 'react'
import GridRow from 'arui-feather/grid-row';
import StockTable from './table/stocks';
import Dividends from './table/dividends';
const StocksPage = ({ id, stockId }: any) => {
  return (
    <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
      <StockTable id={id} />
      <Dividends id={id} stockId={stockId} />
    </GridRow>
  )
}

export default StocksPage