// @ts-nocheck
import React from 'react';
import GraphTable from 'components/Graphs/Table';
import { round } from 'lodash';
import { connect } from 'react-redux';
import { ICategoryTable, IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import { SECTOR_WIDGETSCHARTS_TABLE } from 'constants/tables';
import { getAllSectorChartsRequest} from '../../redux/actions';
import StringCol from 'components/Flows/Elements/StringCol';
import Charts from 'components/Flows/Elements/Charts';
import { closeDialog, openDialog } from '../../../../components/Flows/Dialog/actions';
import Button from 'arui-feather/button';
import { WIDGETUPLOAD_FORM } from '../../../../constants/forms';
import WidgetUpload from '../widgetUpload';
import FlowDialog from 'components/Flows/Dialog';
import { tableDataRequest } from '../../../../components/Graphs/Table/actions';

class SectorWidgetsChartsTable extends React.Component<ICategoryTable> {

  handleClick = () => {
    const { dispatch }: any = this.props;
    //dispatch(reset(SECTOR_WIDGETSCHARTS_TABLE));
    // dispatch(formReset({ meta: { form: SECTOR_WIDGETSCHARTS_TABLE } }));
    // //dispatch(getTickersByCompanyIDRequest({ meta: { graphId: ticketId, table: TICKERS_TABLE, callback: tableDataSuccess } }));
    // dispatch(getAllRecommendationByTickerIDRequest({
    //   meta: {
    //     graphId: ticketId,
    //     table: TICKERS_RECOMENDATION_TABLE,
    //     callback: tableDataSuccess,
    //   },
    // }));
    dispatch(closeDialog({ id: 'table.widgetUploadSector' }));
  };

  normalizeTableData = (head: IHeadTable[], body: IBodyTable[]): any => {
    let processedData = body && body.map((item, i) => {
      const { id, sectorName, sectorVal } = item;
      let emptyWidth: any = '';
      const num = sectorVal ? sectorVal : 0;
      return {
        id,
        cells: [
          {
            element: StringCol(false, i + 1),
            id,
            type: 'id',
            width: '2',
          },
          {
            element: StringCol(true, sectorName),
            id,
            type: 'sectorName',
            // width: emptyWidth,
            width: '8',
          },
          {
            element: StringCol(false, round(num as number, 2) + '%'),
            id,
            type: 'sectorVal',
            // width: emptyWidth,
            width: '8',
          },
        ],
      };
    }) || [];

    const normalizeData = processedData.map((val: any) => {
      let newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });

    return normalizeData;
  };

  getRowLink = (id: any) => `/sectors/${id}`;

  get createDialogs() {
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        <FlowDialog form={WIDGETUPLOAD_FORM} id="table.widgetUploadSector" width="medium">
        <WidgetUpload id="table.widgetUploadSector" type={SECTOR_WIDGETSCHARTS_TABLE} />
      </FlowDialog>
    );
  }
  handleCreateRecommendation = () => {
    const { dispatch }: any = this.props;
    dispatch(openDialog({ id: 'table.widgetUploadSector' }));
  };

  handleChange = (value: any) => {
    const { dispatch }: any = this.props
    dispatch(tableDataRequest({type: '', meta: { table: SECTOR_WIDGETSCHARTS_TABLE, init: getAllSectorChartsRequest, graphId: value } }));
  }

  chart = (body: IBodyTable[]): any => {
    const title = 'Chart of sectors with results for the previous day';
    let button = <Button onClick={this.handleCreateRecommendation} className={'button-float'} view="extra" size="s">
      Create  new report </Button>;
    return Charts(body, title, 'sectorName', 'loadDate', button, this.handleChange);
  };

  render() {
    const { handleRowClick }: any = this.props;
    const head = [
      {
        id: 'id',
        label: '№',
        style: { width: '2' },
      },
      {
        id: 'sectorName',
        label: 'Sector',
        style: { width: '8' },
      },
      {
        id: 'sectorVal',
        label: 'Value',
        style: { width: '2' },
      },
    ];
    return (
      <GraphTable
        createDialogs={this.createDialogs}
        chart={this.chart}
        table={SECTOR_WIDGETSCHARTS_TABLE}
        normalizeTableData={this.normalizeTableData}
        init={getAllSectorChartsRequest}
        onRowClick={handleRowClick}
        head={head}
        link={this.getRowLink}
        width={'12'}
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
      />
    );
  }
}

export default connect()(SectorWidgetsChartsTable);
