const normalize = (data: any) => {
  if (!data) return null;
  let cormData = {
    main: data.main,
    name: data.name,
    currency: data.currencyId,
    gdr: data.gdr ? 'GDR' : 'ADR',
    stock: data.stockId,
    depoRatio: data.depoRatio,
    companyId: data.companyId
  };


  return cormData;
};

export default normalize;
