import * as ACTION_TYPE from './constants';

export const getAllEventsRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_EVENTS_REQUEST,
  payload,
  meta,
});

export const createNewEventRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_EVENT_REQUEST,
  payload,
  meta,
});

export const createNewEventFailure = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_EVENT_FAILURE,
  payload,
  meta,
});

export const editEventRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_EVENT_REQUEST,
  payload,
  meta,
})

export const getEventByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_EVENT_BY_ID_REQUEST,
  payload,
  meta,
})

export const deleteEventByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.DELETE_EVENT_BY_ID_REQUEST,
  payload,
  meta,
})