import ClientsPage from './pages/add';
import ClientsEdit from './pages/edit';
import ClientsView from './pages/view';
export default [
  {
    type: 'private',
    exact: true,
    path: '/clients',
    roles: ['admin'],
    component: ClientsView,
  },
  {
    type: 'private',
    exact: true,
    path: '/clients/edit/:id',
    roles: ['admin'],
    component: ClientsEdit,
  },
  {
    type: 'private',
    exact: true,
    path: '/clients/edit/:id/:tab',
    roles: ['admin'],
    component: ClientsEdit,
  },
  {
    type: 'private',
    exact: true,
    path: '/clients/create/:tab',
    roles: ['admin'],
    component: ClientsPage,
  },
  {
    type: 'private',
    exact: true,
    path: '/clients/create',
    roles: ['admin'],
    component: ClientsPage,
  },

];
