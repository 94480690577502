// @ts-nocheck
import React, { ComponentType } from 'react';
import { language } from 'utils/formatters';
import { getAllSectorsRequest } from 'modules/Catalog/Sectors/redux/actions';


export default [
  {
    isGroup: true,
    label: 'Category name',
    items: [
      {
        placeholder: 'EN',
        type: 'text',
        id: 'nameEN',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU',
        type: 'text',
        id: 'nameRU',
        view: 'filled',
        width: 'available',
        size: 's',
      },
    ],
  },
  {
    type: 'ValueSet',
    id: 'sector',
    placeholder: 'Sectors',
    label: 'Sectors',
    view: 'filled',
    width: 'available',
    size: 's',
    multiple: true,
    itemOptions: (item: any) => ({
      value: item.id,
      text: language(item.name, 'en'),
    }),
    action: getAllSectorsRequest,
  },
  {
    type: 'checkbox',
    id: 'active',
    text: 'Active',
    bigLabel: false,
  },
  {
    type: 'checkbox',
    id: 'serviceCategory',
    text: 'Service Category',
    bigLabel: false,
  },
  {
    isGroup: true,
    label: 'Files En',
    items: [
      {
        type: 'fileSimple',
        typeFile: 'pdf',
        id: 'filesEn[0]',
        label: 'FileEn #1',
        disabePreview: true,
        accept: 'application/pdf, application/excel',
        noFileText: 'Select and upload file',
      },
      {
        type: 'fileSimple',
        typeFile: 'pdf',
        id: 'filesEn[1]',
        label: 'FileEn #1',
        disabePreview: true,
        accept: 'application/pdf, application/excel',
        noFileText: 'Select and upload file',
      },
      {
        type: 'fileSimple',
        typeFile: 'pdf',
        id: 'filesEn[2]',
        label: 'FileEn #1',
        disabePreview: true,
        accept: 'application/pdf, application/excel',
        noFileText: 'Select and upload file',
      },
      {
        type: 'fileSimple',
        typeFile: 'pdf',
        id: 'filesEn[3]',
        label: 'FileEn #1',
        disabePreview: true,
        accept: 'application/pdf, application/excel',
        noFileText: 'Select and upload file',
      },
      {
        type: 'fileSimple',
        typeFile: 'pdf',
        id: 'filesEn[4]',
        label: 'FileEn #1',
        disabePreview: true,
        accept: 'application/pdf, application/excel',
        noFileText: 'Select and upload file',
      },
    ],
  },
  {
    isGroup: true,
    label: 'Files Ru',
    items: [
      {
        type: 'fileSimple',
        typeFile: 'pdf',
        id: 'filesRu[0]',
        label: 'FileEn #1',
        disabePreview: true,
        accept: 'application/pdf, application/excel',
        noFileText: 'Select and upload file',
      },
      {
        type: 'fileSimple',
        typeFile: 'pdf',
        id: 'filesRu[1]',
        label: 'FileEn #1',
        disabePreview: true,
        accept: 'application/pdf, application/excel',
        noFileText: 'Select and upload file',
      },
      {
        type: 'fileSimple',
        typeFile: 'pdf',
        id: 'filesRu[2]',
        label: 'FileEn #1',
        disabePreview: true,
        accept: 'application/pdf, application/excel',
        noFileText: 'Select and upload file',
      },
      {
        type: 'fileSimple',
        typeFile: 'pdf',
        id: 'filesRu[3]',
        label: 'FileEn #1',
        disabePreview: true,
        accept: 'application/pdf, application/excel',
        noFileText: 'Select and upload file',
      },
      {
        type: 'fileSimple',
        typeFile: 'pdf',
        id: 'filesRu[4]',
        label: 'FileEn #1',
        disabePreview: true,
        accept: 'application/pdf, application/excel',
        noFileText: 'Select and upload file',
      },
    ],
  },
];
