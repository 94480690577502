// @ts-nocheck
import {call, put, takeLatest, all, takeLeading, select} from 'redux-saga/effects';

import API_CATEGORY from '../api';
import {
    GET_ALL_DASHBOARD_REQUEST, GET_ALL_WORK_CALENDAR_REQUEST
} from './constants';
import {DASHBOARD_TABLE} from "../../../constants/tables";
import {DASHBOARD_FILTER_FORM} from "../../../constants/forms";
import {tableDataSuccess} from "../../../components/Graphs/Table/actions";
import moment from "moment";
import {setPeriodDashboard, setPrevPeriodDashboard} from '../pages/calculate'
import {dayInYear} from "../../../utils/dayInYera";
import {getAllWorkCalendarRequest} from "./actions";

const setFilterParameters = (state: any, table: any, form: any, mode: any, currentPeriod: boolean): any => {
  let graphId: any = {};
  let dayStart;
  let dayEnd;
  let now;
  let workDays = 1;
  // console.log('setFilterParameters mode=', mode )
  if( currentPeriod ) {
    switch( mode.id ) {
      case 'YTD':
        dayEnd = moment().format("DD.MM.YYYY");
        dayStart = '01.01.' + dayEnd.substr(6)
        const days =  dayInYear()
        workDays = days - Math.floor(days/ 7) * 2
        break;
      default:
          dayEnd = moment().format("DD.MM.YYYY");
          dayStart = moment().subtract(mode.len - 1, 'days').format("DD.MM.YYYY");
          workDays = mode.workDays;
    }
    setPeriodDashboard(dayStart, dayEnd, workDays )
  }
  else {
    switch( mode.id ) {
        case 'YTD':
            now = new Date()
            let year = now.getFullYear() - 1;
            dayEnd = moment().format("DD.MM.YYYY").substr(0, 6) + String(year);
            dayStart = '01.01.' + year;
            const days = dayInYear()
            workDays = days - Math.floor(days / 7) * 2
            break;
        default:
            dayStart = moment().subtract(mode.len * 2 - 1, 'days').format("DD.MM.YYYY");
            dayEnd = moment().subtract(mode.len, 'days').format("DD.MM.YYYY");
            workDays = mode.workDays;
            break;
    }
      setPrevPeriodDashboard(dayStart, dayEnd, workDays)
  }

// Это максимальное количество записей
  graphId.page = 10000000;
  graphId.dateFrom = dayStart;
  graphId.dateTo = dayEnd;
  return graphId;
}


function* getAllDashboard({ meta: { form, table, callback, mode } }: any) {
  try {
    const state = yield select();
    callback = tableDataSuccess;
    let graphId = setFilterParameters(state, DASHBOARD_TABLE, DASHBOARD_FILTER_FORM, mode, true );

    let response = yield call(API_CATEGORY.getFilterStatistics, graphId, DASHBOARD_TABLE);
    if (callback) {
      // console.log('0 - getAllStatistics table/form',  table, form, callback)
      if (table) yield put(callback({ payload: { response }, meta: { table } }))
      if (form) yield put(callback({ payload: { response }, meta: { form } }))
    }
    // предыдущий период
    graphId = setFilterParameters(state, DASHBOARD_TABLE, DASHBOARD_FILTER_FORM, mode, false);
    response = yield call(API_CATEGORY.getFilterStatistics, graphId, 'DASHBOARD_TABLE_PREV');
    if (callback) {
      // console.log('1 - getAllStatistics table/form',  table, form, callback)
      table = 'DASHBOARD_TABLE_PREV'
      if (table) yield put(callback({ payload: { response }, meta: { table } }))
      if (form) yield put(callback({ payload: { response }, meta: { form } }))
    }


  } catch (error) {
    console.error('Error getAllDashboard', error);
  }
}

function* getAllWorkCalendar({ meta: { form, table, callback, mode } }: any) {
    try {
        callback = tableDataSuccess;

        let response = yield call(API_CATEGORY.getWorkCalendar);
 //       console.log("getAllWorkCalendar work calendar =", response)
        if (callback) {
            // console.log('0 - getAllStatistics table/form',  table, form, callback)
            if (table) yield put(callback({ payload: { response }, meta: { table } }))
            if (form) yield put(callback({ payload: { response }, meta: { form } }))
        }
    } catch (error) {
        console.error('Error getAllDashboard', error);
    }
}




function* onChangeFilter({payload, meta}: any) {
}

function* onResetFilter({payload, meta}: any) {
}


function* Saga() {
  yield all([
    takeLatest(GET_ALL_DASHBOARD_REQUEST, getAllDashboard),
    takeLeading(`@@redux-form/CHANGE`, onChangeFilter),
    takeLeading(`@@redux-form/RESET`, onResetFilter),
    takeLatest(GET_ALL_WORK_CALENDAR_REQUEST, getAllWorkCalendar),


  ]);
}

export default Saga;
