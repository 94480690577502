import React from 'react';
import { Helmet } from 'react-helmet';

const MetaData = () => (
    <Helmet titleTemplate="%s - Admin Research Alfa Bank" defaultTitle="Admin Research Alfa Bank">
        <html lang="en-EN" />
        <meta charSet="utf-8" />
        <meta name="description" content="Admin Research Alfa Bank" />
    </Helmet>
);

export default MetaData;
