// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import { formReset } from 'components/Graphs/Form/actions';
import { tableDataSuccess } from 'components/Graphs/Table/actions';
import {
  createNewAuthorsRequest, getAuthorsByIdRequest,
  getAllAuthorsRequest, editAuthorsRequest,
} from '../../redux/actions';
import normalize from './normalize';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { AUTHORS_FORM } from 'constants/forms';
import { AUTHORS_TABLE } from 'constants/tables';
import { connect } from 'react-redux';

interface IAuthorsForm {
  title?: string;
  form: string;
  match: any
}

class AuthorsForm extends React.Component<IAuthorsForm> {

  handleClick = () => {
    const { history, dispatch }: any = this.props;
    dispatch(reset(AUTHORS_FORM));
    dispatch(getAllAuthorsRequest({ meta: { table: AUTHORS_TABLE, callback: tableDataSuccess } }));
    dispatch(formReset({ meta: { form: AUTHORS_FORM } }));
    history.push('/authors');
  };

  get actions() {
    const { match: { params: { id } } } = this.props;
    const action = id ? 'Edit this author' : 'Add new author';
    return (
        <GridRow>
        <GridCol>
          <Button className={'button-float'} view="extra" size="s" type="submit">
            {action}
          </Button>
          <Button className={'mr20 button-float'} size="s" onClick={this.handleClick}>Cancel</Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = ({ nameEN,
              nameRU,
              addressRU,
              addressEN,
              authorType,
              biographyRU,
              biographyEN,
              positionRU,
              positionEN,
              birthday,
              email,
              phone1,
              phone2,
              photo,
            }: any) => {
    const { match: { params: { id } } } = this.props;
    return {
      id,
      birthday,
      email,
      phone1,
      phone2,
      photo: photo?.url,
      authorTypeId: authorType,
      name: [
        {
          language: { id: '5ed9f65f396529280c953be3', name: 'RU' },
          translatedValue: nameRU,
        },
        {
          language: { id: '5ed9f684396529280c953be4', name: 'EN' },
          translatedValue: nameEN,
        },
      ],
      address: [
        {
          language: { id: '5ed9f65f396529280c953be3', name: 'RU' },
          translatedValue: addressRU,
        },
        {
          language: { id: '5ed9f684396529280c953be4', name: 'EN' },
          translatedValue: addressEN,
        },
      ],
      biography: [
        {
          language: { id: '5ed9f65f396529280c953be3', name: 'RU' },
          translatedValue: biographyRU,
        },
        {
          language: { id: '5ed9f684396529280c953be4', name: 'EN' },
          translatedValue: biographyEN,
        },
      ],
      position: [
        {
          language: { id: '5ed9f65f396529280c953be3', name: 'RU' },
          translatedValue: positionRU,
        },
        {
          language: { id: '5ed9f684396529280c953be4', name: 'EN' },
          translatedValue: positionEN,
        },
      ],
    };
  };

  render() {
    const { title, match: { params: { id } } } = this.props;
    return (
      <GraphForm
        form={AUTHORS_FORM}
        actions={this.actions}
        onSuccess={this.handleClick}
        graphId={id}
        init={getAuthorsByIdRequest}
        edit={editAuthorsRequest}
        create={createNewAuthorsRequest}
        title={ id ? 'Edit author' : 'Create author' }
        submit={this.submit}
        normalize={normalize}
        validate={() => {}}
        type={id ? 'edit' : 'create'}
        columns={1}
        config={config}
        width="12"
        reInitialize
      />
    );
  }
}

export default compose<any>(
  connect(),
  withRouter,
)(AuthorsForm);
