import * as ACTION_TYPE from './constants';

export const getAllCommoditiesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_COMMODITIES_REQUEST,
  payload,
  meta,
});

export const createNewCommoditiesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_COMMODITIES_REQUEST,
  payload,
  meta,
});

export const editCommoditiesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_COMMODITIES_REQUEST,
  payload,
  meta,
})

export const getCommoditiesByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_COMMODITIES_BY_ID_REQUEST,
  payload,
  meta,
})