// @ts-nocheck
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { change } from 'redux-form'
import { GET_TEMPLATES_REQUEST } from './constants';
import { grapesSet } from './actions'
import API_GRAPS from './api';
import { ANNOTATION_OPTION_FORM } from '../../constants/forms';

function* get({ payload: { mailingId } }: any) {
  try {
    let templateBody = []
    const { bodyId = [], footerId, headerId } = yield call(API_GRAPS.getTemplates, mailingId);
    const templateHeader = yield call(API_GRAPS.getTemplateHeader, headerId[0]);
    if (bodyId && bodyId.length) {
      templateBody = yield all(bodyId.map((id: any) => {
        return call(API_GRAPS.getTemplateBody, id)
      }));
    }
    templateBody = templateBody.map((item: any) => item.text).join('')
    const data = templateHeader.text + templateBody
    const templateFooter = yield call(API_GRAPS.getTemplateFooter, footerId);
    yield put(change(ANNOTATION_OPTION_FORM, 'product-editor', data))
    yield put(grapesSet({ payload: {templateBody, templateFooter, templateHeader } }))
  }catch (e) {}
}

function* Saga() {
  yield all([
    takeEvery(GET_TEMPLATES_REQUEST, get),
  ]);
}

export default Saga;
