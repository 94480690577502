// @ts-nocheck
import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'
import * as queryString from "querystring";

class ClassAPI {
  getAllStatistics = () => request({
    url: 'statistic',
    method: 'GET',
    token: API_GLOBAL.token
  });
  getFilterStatisticsOLD = (fields: any) => {
    console.log('Api getFilterStatistics fileds=', fields)
    return request({
      url: 'statistic?dateFrom=' + fields.dateFrom,
      method: 'GET',
      token: API_GLOBAL.token
    });
  }

  getFilterStatistics = (data: any, table: string) => {
    const {} = this;
    const req = {
      page: 1,
    };
    const sent = Object.assign(req, data);
 //   console.log('getFilterStatistics sent=', sent);
    const q = queryString.stringify(sent);
 //   console.log('getFilterStatistics q=', q);
    return request({
      //   базовый метод
      //     url: `statistic?${q}`,
      url: `statistic/filter?${q}`,
      method: 'GET',
      token: API_GLOBAL.token,
    });
  };

  getWorkCalendar = () => {
    return request({
      url: 'catalog/work-calendar',
      method: 'GET',
      token: API_GLOBAL.token,
    });
  };


}




const API_CATEGORY = new ClassAPI();

export default API_CATEGORY;
