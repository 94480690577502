// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import GraphTable from 'components/Graphs/Table';
import { ICategoryTable, IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import { LAW_FILES_TABLE } from 'constants/tables';
import { getAllLawFileRequest, deleteLawFileRequest } from '../../redux/actions';
import StringCol from 'components/Flows/Elements/StringCol';
import IconDelete from 'arui-feather/icon/action/delete';

class LawFileTable extends React.Component<ICategoryTable> {

  normalizeTableData = (head: IHeadTable[], body: IBodyTable[]): any => {

    console.log('normalizeTableData ', body );
    let processedData = body && body.map((item, i) => {
      const { id, nameEn, nameRu}:any = item;
      let emptyWidth: any = '';
      return {
        id,
        cells: [
          {
            element: StringCol(false, i + 1),
            id,
            type: 'id',
            width: emptyWidth,
          },
          {
            element: nameEn,
            id,
            type: 'nameEn',
            width: emptyWidth,
          },
          {
            element: <IconDelete size={'s'}/>,
            id,
            type: 'delete',
            width: emptyWidth,
            customClass: 'delete-icon',
          },
        ],
      };
    }) || [];

    console.log('processedData= ', processedData );

    const normalizeData = processedData.map((val: any) => {
      let newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });

    console.log('normalizeData= ', normalizeData );
    return normalizeData;
  };

  getRowLink = (id: any) => `/lawfile/edit/${id}`;

  handleDelete = (id: string) => {
    const { dispatch }: any = this.props;
    dispatch(deleteLawFileRequest({ payload: { id } }));
  };

  render() {
    const { handleRowClick }: any = this.props;
    const head = [
      {
        id: 'id',
        label: 'ID',
        style: { width: '1' },
      },
      {
        id: 'nameEn',
        label: 'Name',
        style: { width: ' ' },
      },
      {
        id: 'delete',
        type: 'delete',
        label: '',
        style: { width: ' ' },
      },
    ];

    const { actions } = this.props
    console.log('lawFile table render head=', head);
    return (
      <GraphTable
        title="Law files table"
        table={LAW_FILES_TABLE}
        actions={actions}
        normalizeTableData={this.normalizeTableData}
        init={getAllLawFileRequest}
        onRowClick={handleRowClick}
        onDelete={(id: any) => this.handleDelete(id)}
        head={head}
        link={this.getRowLink}
        width={'12'}
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
      />
    );
  }
}

export default connect()(LawFileTable);
