// @ts-nocheck
import React, { ComponentType } from 'react';
import { Field } from 'redux-form';
import { productTypeRequest } from 'modules/Catalog/ProductTypes/redux/actions';
import { getAllcategoriesRequest } from 'modules/Catalog/Categories/redux/actions';
import { getAllSectorsRequest } from 'modules/Catalog/Sectors/redux/actions'
import Input from 'arui-feather/input';
import IconButton from 'arui-feather/icon-button';
import IconDelete from 'arui-feather/icon/action/delete';
import Button from 'arui-feather/button';
import Label from 'arui-feather/label';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { store } from "../../../../../index"

const makeField = (Component: ComponentType) => ({
  input,
  meta,
  children,
  required,
  hasFeedback,
  id,
  style,
  ...rest
}: any) => {
  return (
    <div className={'input-list-mail'} style={style}>
      <Component {...input} {...rest} children={children} />
    </div>
  );
};

const AInput = makeField(Input);

const getErrorsEmail = ( values: any, touched: any  ) : any => {
  let errors = [];
    // console.log('!!!getErrorsEmail')
   if( values &&  values.emails && values.emails.length > 0) {
    for( let i = 0; i < values.emails.length; i++) {
      const email = values.emails[i];
      // console.log('emails = ', email);

      if( touched ) {
        if( !email || email=== '' ) {
          errors.push('Empty field');
        }
        else {
          const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,15})$/;
          if(reg.test(email) == false) {
            console.log('WROMG EMAIL')
            errors.push('Wrong Email');
          }
          else
            errors.push('');
        }
      }
      else
        errors.push('');
    }
  }
   return errors;
}



const renderMembers = ({ fields,  meta: { touched, error }  }: any) => {
// достаю ошибки напрямую из store, так они не передаются
  const state = store.getState();
  const anyTouched =  state?.form?.CLIENTS_FORM.anyTouched;
  let errorsEmail = [''];
  let values = null;

  if (state && state.form && state.form.CLIENTS_FORM && state.form.CLIENTS_FORM ) {
    // console.log(state.form.CLIENTS_FORM)
    const form = state.form.CLIENTS_FORM
    let err = null;
    // @ts-ignore
    if( form && form['syncErrors'])  err = form['syncErrors'];
    // @ts-ignore
    if( form && form['values'])  values = form['values'];
 //   console.log('syncsError = ', err, values, err.emails, values?.emails, values?.emails?.length);
    if( err && err.emails &&  ( !values || values?.emails==undefined
        || !values?.emails?.length))
      error = err.emails
    else
      error = ''
    touched = anyTouched

    errorsEmail = getErrorsEmail( values, touched)
    // console.log('errorsEmail = ', errorsEmail);
  }
  ////// конец изменений

  return (
      <div>
        {fields.map((member: any, index: number) => (
            <GridRow className={'magrin-mail'} key={'row_abc' + index}>
              <GridCol className={'col-email'} key={index} align="top">
                <Label className={'label-email-list'} size="m" isNoWrap={true}>
                  Email #{index + 1}
                </Label>
                <Field
                    name={`${member}`}
                    type="text"
                    component={AInput}
                    view="filled"
                    width="available"
                    size="s"
                    error={errorsEmail[index]}
                />
                <IconButton onClick={() => fields.remove(index)} size="s">
                  <IconDelete className={'delete-email'} size="s"/>
                </IconButton>
              </GridCol>
            </GridRow>
        ))}
        <Button size="s" type="button" onClick={() => fields.push('')}>
          Add Email
        </Button>
        {error && touched && <span style={{
          fontSize: 12,
          color: 'red'
        }} className="input__sub">{error}</span>}

      </div>
  )
};

export default [
  {
    isGroup: true,
    label: 'Name',
    fieldWidth: '12',
    customPosition: {
      label: 2,
      field: 10,
    },
    items: [
      {
        placeholder: 'EN',
        type: 'text',
        id: 'nameEN',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU',
        type: 'text',
        id: 'nameRU',
        view: 'filled',
        width: 'available',
        size: 's',
      },
    ],
  },
  {
    isGroup: true,
    label: 'Permitted language',
    fieldWidth: '12',
    customPosition: {
      label: 2,
      field: 10,
    },
    items: [
      {
        type: 'checkbox',
        id: 'engLang',
        text: 'EN',
        bigLabel: false,
      },
      {
        type: 'checkbox',
        id: 'rusLang',
        text: 'RU',
        bigLabel: false,
      },
    ],
  },
  {
    customPosition: {
      label: 0,
      field: 12,
      className: '',
    },
    fieldWidth: '12',
    label: '',
    id: 'emails',
    view: 'filled',
    width: 'available',
    size: 's',
    type: 'fieldArray',
    required: true,
    component: renderMembers,
  },
  {
    fieldWidth: '12',
    customPosition: {
      label: 2,
      field: 10,
    },
    type: 'ValueSet',
    id: 'type',
    placeholder: 'Type',
    label: 'Type',
    view: 'filled',
    width: 'available',
    size: 's',
    data: [ 'ACC', 'MIFID' ],
    itemOptions: (item: any) => ({ value: item, text: item }),
  },
  {
    customPosition: {
      label: 2,
      field: 10,
    },
    fieldWidth: '12',
    label: 'Clients right settings',
    id: 'clientRightSettingsThree',
    actions: {
      categories: getAllcategoriesRequest,
      productType: productTypeRequest,
      sectors: getAllSectorsRequest,
    },
    type: 'checkboxesTree',
  },
];
