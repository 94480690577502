import * as ACTION_TYPE from './constants';

export const getAllStockTypesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_STOCKTYPES_REQUEST,
  payload,
  meta,
});

export const createNewStockTypesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_STOCKTYPES_REQUEST,
  payload,
  meta,
});

export const editStockTypesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_STOCKTYPES_REQUEST,
  payload,
  meta,
})

export const getStockTypesByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_STOCKTYPES_BY_ID_REQUEST,
  payload,
  meta,
})