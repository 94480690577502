const validate = (values: any) => {
  const errors = {} as any;

  // if (!values.name) {
  //   errors.name = 'Required field';
  // }

  return errors;
};

export default validate;
