// @ts-nocheck
import React from 'react'
import GridRow from 'arui-feather/grid-row';
import FilesTable from './table/files';
const StocksPage = ({ id }: any) => {
  return (
    <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
      <FilesTable id={id} />
    </GridRow>
  )
}

export default StocksPage