import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {
  getAllAuthors = () => request({
    url: 'author',
    method: 'GET',
    token: API_GLOBAL.token
  });

  createNewAuthors = ({ data }: any) => request({
    url: 'author',
    data,
    method: 'POST',
    token: API_GLOBAL.token
  });

  editAuthors = ({ data }: any) => request({
    url: 'author',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  })

  getByIdAuthors = ({ id }: any) => request({
    url: `author/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })

  deleteByIdAuthors = (id: any) => request({
    url: `author/${id}`,
    method: 'DELETE',
    token: API_GLOBAL.token
  })

  getByNameAuthors = ({ id }: any) => request({
    url: `author/by-name/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })
// 2021-10-04
  setBlockAuthor = ({ data }: any) => request({
    url: 'author/block',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  })

}



const API_AUTHORS = new ClassAPI();

export default API_AUTHORS;
