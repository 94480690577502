// @ts-nocheck
import React from 'react';
import { getAllMailingTypeRequest } from '../../../../MailingListSettings/redux/actions';
import { getTemplatesRequest } from '../../../../../components/GrapesJs/actions';
import { getAllcategoriesRequest } from '../../../../Catalog/Categories/redux/actions';
import { productTypeRequest } from '../../../../Catalog/ProductTypes/redux/actions';
import { getAllSectorsRequest } from '../../../../Catalog/Sectors/redux/actions';
import {change} from "redux-form";
import {PRODUCT_OPTION_FORM} from "../../../../../constants/forms";
import {language} from "../../../../../utils/formatters";
import {getAllEmailRequest} from "../../../../Email/redux/actions";

export default [
  {
    label: '',
    placeholder: 'EN',
    fieldWidth: '12',
    customPosition: {
      label: 0,
      field: 10,
    },
    type: 'plate',
    id: 'plate',
    view: 'filled',
    width: 'available',
    size: 's',
    style: {
      marginBottom: '15px',
    },
  },
  {
    customPosition: {
      label: 2,
      field: 10,
    },
    fieldWidth: '12',
    label: 'List sending',
    id: 'clientRightSettingsThree',
    actions: {
      categories: getAllcategoriesRequest,
      productType: productTypeRequest,
      sectors: getAllSectorsRequest,
    },
    type: 'checkboxesTree',
    disabledIf: ({ plate }: any) => ['COMPLETED', 'READY_TO_SEND'].includes(plate)
  },
  {
    fieldWidth: '4',
    customPosition: {
      label: 2,
      field: 8,
    },
    type: 'ValueSet',
    id: 'emails',
    placeholder: 'Select email from',
    label: 'Email from',
    view: 'filled',
    width: 'available',
    size: 's',
    handleOnChange: (props: any, dispatch: any) => {
      console.log('list change', props)
    },
    itemOptions: (item: any) => ({ value: item.id, text: item.email }),
    action: getAllEmailRequest,
    disabledIf: ({ plate }: any) => ['COMPLETED', 'READY_TO_SEND'].includes(plate),
  },
  {
    label: 'Mailing date',
    fieldWidth: '4',
    customPosition: {
      label: 2,
      field: 8,
    },
    placeholder: 'EN',
    type: 'date',
    id: 'date',
    view: 'filled',
    width: 'available',
    defaultValue: new Date().toLocaleDateString('et',  { day: '2-digit', month: '2-digit', year: 'numeric' }),
    size: 's',
    style: {
      marginBottom: '15px',
    },
    disabledIf: ({ plate }: any) => ['COMPLETED', 'READY_TO_SEND'].includes(plate),
  },
  {
    label: 'Mailing time',
    fieldWidth: '4',
    customPosition: {
      label: 2,
      field: 8,
    },
    placeholder: 'DD:MM',
    type: 'text',
    mask: '11:11',
    id: 'time',
    defaultValue: new Date().toLocaleTimeString().slice(0,-3),
    view: 'filled',
    width: 'available',
    size: 's',
    disabledIf: ({ plate }: any) => ['COMPLETED', 'READY_TO_SEND'].includes(plate),
  },
  {
    label: 'Editor',
    placeholder: 'EN',
    fieldWidth: '12',
    customPosition: {
      label: 2,
      field: 10,
    },
    type: 'editorFooter',
    id: 'product-editor',
    view: 'filled',
    width: 'available',
    size: 's',
    style: {
      marginBottom: '15px',
    },
    disabledIf: ({ plate }: any) => ['COMPLETED', 'READY_TO_SEND'].includes(plate)
  },
];
