// @ts-nocheck
import React from 'react';

export default [
  {
    isGroup: true,
    label: 'Username',
    fieldWidth: '5',
    items: [
      {
        placeholder: 'Username',
        type: 'text',
        id: 'username',
        view: 'filled',
        width: 'available',
        disabled: true,
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
  {
    fieldWidth: '1',
    type: 'gridCol',
  },
  {
    isGroup: true,
    label: 'Email',
    fieldWidth: '5',
    items: [
      {
        placeholder: 'Email',
        type: 'text',
        id: 'email',
        view: 'filled',
        width: 'available',
        disabled: true,
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
  {
    isGroup: true,
    label: 'Name',
    fieldWidth: '5',
    items: [
      {
        placeholder: 'Name',
        type: 'text',
        id: 'name',
        view: 'filled',
        width: 'available',
        disabled: true,
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
  {
    fieldWidth: '1',
    type: 'gridCol',
  },
  {
    isGroup: true,
    label: 'Keycloak ID',
    fieldWidth: '5',
    items: [
      {
        placeholder: 'Keycloak ID',
        type: 'text',
        id: 'keycloakId',
        view: 'filled',
        width: 'available',
        disabled: true,
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
  {
    isGroup: true,
    label: 'Profile ID',
    fieldWidth: '5',
    items: [
      {
        placeholder: 'Profile ID',
        type: 'text',
        id: 'profileId',
        view: 'filled',
        disabled: true,
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
  {
    fieldWidth: '1',
    type: 'gridCol',
  },
  {
    isGroup: true,
    label: 'Password',
    fieldWidth: '5',
    items: [
      {
        placeholder: 'Password',
        type: 'text',
        id: 'password',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
];
