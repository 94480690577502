// @ts-nocheck
import React, { FC } from 'react';
import MetaData from 'app/metaData';
import Message from 'components/Message'
import AdminMenu from 'components/AdminMenu';
import AdminHeader from 'components/AdminHeader';
import { ILayoutProps } from 'types';
const Private: FC<ILayoutProps> = ({ children }) => {
    return (
        <div className="admin-main">
            <MetaData />
            <AdminMenu />
            <div className="admin-main__container">
                <div className="admin-main__container_header">
                    <AdminHeader />
                </div>
                <div className="admin-main__container_main">{children}</div>
            </div>
            <Message id="success" variant="success" vertical="top" horizontal="center" />
            <Message id="error" variant="error" vertical="top" horizontal="right" />
        </div>
    );
};

export default Private;
