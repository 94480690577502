import { createSelector } from 'reselect';

const selectDialog = state => state.dialog;

export const selectCurrentDialog = createSelector(
    [
        selectDialog,
        (state, ownProps) => ownProps.id,
    ],
    (dialogState, id) => dialogState.get(id),
);

export const makeSelectDialogOpen = createSelector(
    selectCurrentDialog,
    state => state && state.get('open'),
);
