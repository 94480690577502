// @ts-nocheck
import React from 'react';
import IconOk from 'arui-feather/icon/ui/ok';
import Plate from 'arui-feather/plate';
import { Field } from 'redux-form';

const PlateSome = (data: any) => {

  if (data && data.input && data.input.value === 'READY_TO_SEND') {
    return (
      <Plate
        title="Newsletter successfully created. After creating a newsletter, you cannot edit it."
        type="success"
        icon={<IconOk colored={true} />}
      />
    )
  }else if(data && data.input && data.input.value === 'COMPLETED') {
    return (
      <Plate
        title="The mailing list was successfully sent."
        type="success"
        icon={<IconOk colored={true} />}
      />
      )
  }else {
    return null
  }
};

const PlateComponent = ({
  id,
  name,
  label,
  multiple,
  data,
  showcode,
  isAutosuggest,
  async,
  valueSet,
  filter,
  sort, // exclude from ...props
  ...props
}: any) => {
  return (
    <Field
      id={id}
      name={name}
      type="text"
      loading={!valueSet}
      multiple={multiple}
      component={PlateSome}
      label={label}
      showcode={showcode}
      {...props}
    />
  );
};

export default PlateComponent;
