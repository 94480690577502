export const GET_ALL_FOOTER_INFO_REQUEST = 'GET_ALL_FOOTER_INFO_REQUEST'
export const GET_ALL_FOOTER_INFO_SUCCESS = 'GET_ALL_FOOTER_INFO_SUCCESS'
export const GET_ALL_FOOTER_INFO_FAILURE = 'GET_ALL_FOOTER_INFO_FAILURE'

export const CREATE_NEW_FOOTER_INFO_REQUEST = 'CREATE_NEW_FOOTER_INFO_REQUEST'

export const GET_FOOTER_INFO_BY_ID_REQUEST = 'GET_FOOTER_INFO_BY_ID_REQUEST'

export const DELETE_FOOTER_INFO_BY_ID_REQUEST = 'DELETE_FOOTER_INFO_BY_ID_REQUEST'

export const EDIT_FOOTER_INFO_REQUEST = 'EDIT_FOOTER_INFO_REQUEST'
export const GET_ALL_TYPE_INFO = "GET_ALL_TYPE_INFO"


