// @ts-nocheck
import React from 'react';
import { withRouter } from 'react-router-dom'
import LawFileForm from './form/basic';
import { Route, Switch } from 'react-router';


const EmailAdd = ({ history, location: { pathname }, match: { params: { id, stockId } }  }: any) => {
/*
  const handleClick = (event: any) => {
    event.preventDefault();
    history.push(event.target.getAttribute('href'))
  }
*/

  const baseUrl = `/email/create`
  return (
    <>
      <Switch>
        <Route exact path={baseUrl}>
          <LawFileForm id={id} />
        </Route>
      </Switch>
    </>
  );
};

export default withRouter(EmailAdd);