import {
  TABLE_DATA_REQUEST,
  TABLE_DATA_SUCCESS,
  GRAPH_REBUILD_DATA, TABLE_DATA_PAGINATION_REQUEST, TABLE_DATA_PAGINATION_SUCCESS,
} from './constants';
import {IAction} from 'types/index'

export const tableDataRequest = ({ meta }: IAction<void>) => ({
  type: TABLE_DATA_REQUEST,
  meta,
});

export const tableDataSuccess = ({ payload, meta }: IAction<void>) => ({
  type: TABLE_DATA_SUCCESS,
  payload,
  meta,
});

// export const graphTableRebuild = table => ({
//   type: GRAPH_REBUILD_DATA, table,
// });

// 2021-10-27 add pagination
export const tableDataPaginationRequest = ({payload, meta}: IAction<void>) => ({
  type: TABLE_DATA_PAGINATION_REQUEST,
  payload,
  meta,
});

export const tableDataPaginationSuccess = ({payload, meta}: IAction<void>) => ({
  type: TABLE_DATA_PAGINATION_SUCCESS,
  payload,
  meta,
});
