// @ts-nocheck
import { call, put, takeLatest, all, takeEvery } from 'redux-saga/effects';

import API_MAILINGTYPE from '../api';
import {
  GET_ALL_MAILING_TYPE_REQUEST,
  CREATE_NEW_HEAD_REQUEST,
  CREATE_NEW_MAILING_TYPE_REQUEST,
  GET_MAILING_TYPE_BY_ID_REQUEST,
  EDIT_MAILING_TYPE_REQUEST,
  DELETE_MAILING_TYPE_BY_ID_REQUEST,
  GET_ALL_HEAD_REQUEST,
  GET_ALL_BODY_REQUEST,
  CREATE_NEW_BODY_REQUEST,
  GET_ALL_FOOTER_REQUEST,
  EDIT_HEAD_REQUEST,
  GET_HEAD_BY_ID_REQUEST,
  EDIT_BODY_REQUEST,
  GET_BODY_BY_ID_REQUEST,
  CREATE_NEW_FOOTER_REQUEST,
  EDIT_FOOTER_REQUEST,
  GET_FOOTER_BY_ID_REQUEST,
  DELETE_HEAD_BY_ID_REQUEST,
  DELETE_BODY_BY_ID_REQUEST,
  DELETE_FOOTER_BY_ID_REQUEST,
} from './constants';
import API_AUTHORS from '../../Authors/api';
import {
  createNewAuthorsFailure,
  getAllAuthorsRequest,
} from '../../Authors/redux/actions';
import {
  MAILING_LIST_SETTINGS_TABLE,
  HEAD_TABLE,
  BODY_TABLE,
  INFO_TABLE,
} from 'constants/tables';
import { tableDataSuccess } from '../../../components/Graphs/Table/actions';
import { openSuccessMessage } from '../../../components/Message/actions';
import {
  getAllMailingTypeRequest,
  getAllHeadRequest,
  getAllBodyRequest,
  getAllFooterRequest,
} from 'modules/MailingListSettings/redux/actions';

function* getAllMailingTypeRequestY({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_MAILINGTYPE.getAllMailingTypeRequest);
    if (callback) {
      if (table) {
        yield put(callback({ payload: { response }, meta: { table } }));
      }
      if (form) {
        yield put(callback({ payload: { response }, meta: { form } }));
      }
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* createNewMailingTypeRequest({
  payload: { callback, fields },
  meta: { form, onSuccess },
}: any) {
  try {
    const response = yield call(API_MAILINGTYPE.createNewMailingTypeRequest, {
      data: fields,
    });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* getMailingTypeByIdRequest({
  payload: {
    callback,
    data: { id },
  },
  meta: { form },
}: any) {
  try {
    const response = yield call(API_MAILINGTYPE.getByIdMailingType, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* editMailingTypeRequest({
  payload: { callback, fields },
  meta: { form, onSuccess },
}: any) {
  try {
    const response = yield call(API_MAILINGTYPE.editMailingType, {
      data: fields,
    });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* deleteMailingTypeById({ payload: { id }, meta }: any) {
  try {
    yield call(API_MAILINGTYPE.deleteMailingType, id);
    yield put(
      getAllMailingTypeRequest({
        meta: {
          table: MAILING_LIST_SETTINGS_TABLE,
          callback: tableDataSuccess,
        },
      }),
    );
    yield put(openSuccessMessage('Mail successfully deleted.'));
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* getAllHeadRequestY({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_MAILINGTYPE.getAllHeadRequest);
    if (callback) {
      if (table) {
        yield put(callback({ payload: { response }, meta: { table } }));
      }
      if (form) {
        yield put(callback({ payload: { response }, meta: { form } }));
      }
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* createNewHeadRequest({
  payload: { callback, fields },
  meta: { form, onSuccess },
}: any) {
  try {
    const response = yield call(API_MAILINGTYPE.createNewHeadRequest, {
      data: fields,
    });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* editHeadRequest({
  payload: { callback, fields },
  meta: { form, onSuccess },
}: any) {
  try {
    const response = yield call(API_MAILINGTYPE.editHeadRequest, {
      data: fields,
    });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* getHeadByIdRequest({
  payload: {
    callback,
    data: { id },
  },
  meta: { form },
}: any) {
  try {
    const response = yield call(API_MAILINGTYPE.getByIdHead, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* deleteHeadById({ payload: { id }, meta }: any) {
  try {
    yield call(API_MAILINGTYPE.deleteHead, id);
    yield put(
      getAllHeadRequest({
        meta: { table: HEAD_TABLE, callback: tableDataSuccess },
      }),
    );
    yield put(openSuccessMessage('Head successfully deleted.'));
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* getAllBodyRequestY({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_MAILINGTYPE.getAllBodyRequest);
    if (callback) {
      if (table) {
        yield put(callback({ payload: { response }, meta: { table } }));
      }
      if (form) {
        yield put(callback({ payload: { response }, meta: { form } }));
      }
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* createNewBodyRequest({
  payload: { callback, fields },
  meta: { form, onSuccess },
}: any) {
  try {
    const response = yield call(API_MAILINGTYPE.createNewBodyRequest, {
      data: fields,
    });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* editBodyRequest({
  payload: { callback, fields },
  meta: { form, onSuccess },
}: any) {
  try {
    const response = yield call(API_MAILINGTYPE.editBodyRequest, {
      data: fields,
    });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* getBodyByIdRequest({
  payload: {
    callback,
    data: { id },
  },
  meta: { form },
}: any) {
  try {
    const response = yield call(API_MAILINGTYPE.getByIdBody, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* deleteBodyById({ payload: { id }, meta }: any) {
  try {
    yield call(API_MAILINGTYPE.deleteBody, id);
    yield put(
      getAllBodyRequest({
        meta: { table: BODY_TABLE, callback: tableDataSuccess },
      }),
    );
    yield put(openSuccessMessage('Body successfully deleted.'));
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* getAllFooterRequestY({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_MAILINGTYPE.getAllFooterRequest);
    if (callback) {
      if (table) {
        yield put(callback({ payload: { response }, meta: { table } }));
      }
      if (form) {
        yield put(callback({ payload: { response }, meta: { form } }));
      }
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* createNewFooterRequest({
  payload: { callback, fields },
  meta: { form, onSuccess },
}: any) {
  try {
    const response = yield call(API_MAILINGTYPE.createNewFooterRequest, {
      data: fields,
    });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* editFooterRequest({
  payload: { callback, fields },
  meta: { form, onSuccess },
}: any) {
  try {
    const response = yield call(API_MAILINGTYPE.editFooterRequest, {
      data: fields,
    });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* getFooterByIdRequest({
  payload: {
    callback,
    data: { id },
  },
  meta: { form },
}: any) {
  try {
    const response = yield call(API_MAILINGTYPE.getByIdFooter, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* deleteFooterByIdRequest({ payload: { id }, meta }: any) {
  try {
    yield call(API_MAILINGTYPE.deleteFooter, id);
    yield put(
      getAllFooterRequest({
        meta: { table: INFO_TABLE, callback: tableDataSuccess },
      }),
    );
    yield put(openSuccessMessage('Footer successfully deleted.'));
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* Saga() {
  yield all([
    takeLatest(GET_ALL_MAILING_TYPE_REQUEST, getAllMailingTypeRequestY),
    takeLatest(CREATE_NEW_MAILING_TYPE_REQUEST, createNewMailingTypeRequest),
    takeLatest(GET_MAILING_TYPE_BY_ID_REQUEST, getMailingTypeByIdRequest),
    takeLatest(EDIT_MAILING_TYPE_REQUEST, editMailingTypeRequest),
    takeLatest(DELETE_MAILING_TYPE_BY_ID_REQUEST, deleteMailingTypeById),

    takeLatest(GET_ALL_HEAD_REQUEST, getAllHeadRequestY),
    takeLatest(CREATE_NEW_HEAD_REQUEST, createNewHeadRequest),
    takeLatest(EDIT_HEAD_REQUEST, editHeadRequest),
    takeLatest(GET_HEAD_BY_ID_REQUEST, getHeadByIdRequest),
    takeLatest(DELETE_HEAD_BY_ID_REQUEST, deleteHeadById),

    takeLatest(GET_ALL_BODY_REQUEST, getAllBodyRequestY),
    takeLatest(CREATE_NEW_BODY_REQUEST, createNewBodyRequest),
    takeLatest(EDIT_BODY_REQUEST, editBodyRequest),
    takeLatest(GET_BODY_BY_ID_REQUEST, getBodyByIdRequest),
    takeLatest(DELETE_BODY_BY_ID_REQUEST, deleteBodyById),

    takeEvery(GET_ALL_FOOTER_REQUEST, getAllFooterRequestY),
    takeLatest(CREATE_NEW_FOOTER_REQUEST, createNewFooterRequest),
    takeLatest(EDIT_FOOTER_REQUEST, editFooterRequest),
    takeLatest(GET_FOOTER_BY_ID_REQUEST, getFooterByIdRequest),
    takeLatest(DELETE_FOOTER_BY_ID_REQUEST, deleteFooterByIdRequest),
  ]);
}

export default Saga;
