// @ts-nocheck
import React from 'react';
import GraphTable from 'components/Graphs/Table';
import { ICategoryTable, IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import { CALENDAR_TABLE } from 'constants/tables';
import { getAllEventsRequest,deleteEventByIdRequest } from '../../redux/actions';
import { connect } from 'react-redux';
import IconDelete from 'arui-feather/icon/action/delete';
import { getAllEventTypesRequest } from 'modules/Catalog/EventTypes/redux/actions';
import { language } from 'utils/formatters';
import  {getAllCountryRequest} from 'modules/Catalog/Countries/redux/actions';
import { getAllCompaniesRequest } from '../../../Companies/redux/actions';

class CalendarTable extends React.Component<ICategoryTable> {

  normalizeTableData = (head: IHeadTable[], body: any) : any => {
    let processedData = body && body.map((item: any, i: any) => {
      const { id, name, startDate, eventTypeId, companies,countryId } = item;

      let emptyWidth: any = '';
      return {
        id,
        cells: [
          {
            element: i + 1,
            id,
            type: 'id',
            width: emptyWidth
          },
          {
            element: startDate,
            id,
            type: 'startDate',
            width: emptyWidth
          },
          {
            element: eventTypeId,
            id,
            type: 'eventTypeId',
            width: emptyWidth
          },
          {
            element: companies,
            id,
            type: 'companiesIds',
            width: emptyWidth,
            customClass: 'break-all',
          },
          {
            element: language(name, 'en'),
            id,
            type: 'name',
            width: emptyWidth
          },
          {
            element: countryId,
            id,
            type: 'countryId',
            width: emptyWidth
          },
          {
            element: '',
            id,
            type: 'report',
            width: emptyWidth
          },
          {
            element: <IconDelete size={'s'}/>,
            id,
            type: 'delete',
            width: emptyWidth,
            customClass: 'delete-icon',
          },
        ]};
    }) || [];

    const normalizeData = processedData.map((val: any) => {
      let newCells = val
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells
    });

    return normalizeData;
  };

  getRowLink = (id: any) => `/calendar/edit/${id}`;

  handleDelete = (id: string) => {
    const { dispatch }: any = this.props;
    dispatch(deleteEventByIdRequest({ payload: { id } }));
  };

  render() {
    const { handleRowClick }: any = this.props
    const head = [
      {
        id: 'id',
        label: '№',
        style: { width: '1' },
      },
      {
        id: 'startDate',
        label: 'Date',
        style: { width: ' ' },
      },
      {
        id: 'eventTypeId',
        label: 'Event Type',
        type: 'valueSet',
        action: getAllEventTypesRequest,
        style: { width: ' ' },
      },
      {
        id: 'companiesIds',
        type: 'valueSet',
        label: 'Company',
        action: getAllCompaniesRequest,
        style: { width: ' ' },
      },
      {
        id: 'name',
        label: 'Name',
        style: { width: ' ' },
      },
      {
        id: 'countryId',
        type: 'valueSet',
        action: getAllCountryRequest,
        label: 'Country',
        style: { width: ' ' },
      },
      {
        id: 'report',
        label: 'Report',
        style: { width: ' ' },
      },
      {
        id: 'delete',
        type: 'delete',
        label: '',
        style: { width: '2' },
      },
    ];

    const { actions } = this.props
    return (
      <GraphTable
        table={CALENDAR_TABLE}
        actions={actions}
        normalizeTableData={this.normalizeTableData}
        init={getAllEventsRequest}
        onRowClick={handleRowClick}
        title="Events"
        head={head}
        onDelete={(id: any) => this.handleDelete(id)}
        link={this.getRowLink}
        width={'12'}
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
        activeLink
      />
    );
  }
}
export default connect()(CalendarTable);

