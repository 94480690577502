const normalize = (data: any) => {
  if (!data) return null;
  let cormData = {
    username: data.username,
    email: data.email,
    name: data.name,
    firstName: data.firstName,
    keycloakId: data.keycloakId,
    profileId: data.profileId,
  };

  return cormData;
};

export default normalize;
