// @ts-nocheck
import { push } from 'connected-react-router/immutable';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { getFirstPage } from 'constants/roles'

import API from '../api'
import { authSuccess, logoutSuccess } from './actions'
import { AUTH_REQUEST, LOGOUT_REQUEST } from './constants'
import { setUser } from 'modules/User/redux/actions'
import { createNewAuthorsFailure } from '../../Authors/redux/actions';

function* authorize({ payload: { fields } }: any) {
  try {
    if( fields && fields.username )
      fields.username = fields.username.trimLeft().trimRight();
    const response = yield call(API.login, { ...fields });

    const { access_token, userType, ...rest } = response;
    if (userType === 'CLIENT') {
      yield put(createNewAuthorsFailure({ payload: { customMessage: 'Auth Error' } }))
    }else {
      yield put(authSuccess(access_token))
      yield put(setUser(rest))
      yield put(push(getFirstPage(response?.roles)))
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* logout() {
  try {
    yield put(logoutSuccess())
    yield put(push("/"));

  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* Saga() {
  yield all([
    takeLatest(AUTH_REQUEST, authorize),
    takeLatest(LOGOUT_REQUEST, logout)
  ]);
}

export default Saga;