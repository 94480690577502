import FooterEdit from './pages/edit';
import FooterAdd from './pages/add';
import FooterView from './pages/view';
export default [
  {
    type: 'private',
    exact: true,
    path: '/footerinfo',
    roles: ['admin'],
    component: FooterView,
  },
  {
    type: 'private',
    exact: true,
    path: '/footerinfo/edit/:id',
    roles: ['admin'],
    component: FooterEdit,
  },
  {
    type: 'private',
    exact: true,
    path: '/footerinfo/create',
    roles: ['admin'],
    component: FooterAdd,
  },

];