
const normalize = (data: any) => {
  if (!data) return null;

  let cormData = {
    name:  data.name,
  };
  return cormData;
};

export default normalize;
