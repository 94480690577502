// @ts-nocheck
import React from 'react';
import { getAllCurrenciesRequest } from 'modules/Catalog/Currencies/redux/actions';
import { getAllCompaniesRequest } from 'modules/Companies/redux/actions'
import { getAllStockTypesRequest } from 'modules/Catalog/StockTypes/redux/actions'
import { language } from 'utils/formatters';

export default [
  {
    isGroup: true,
    label: 'Name',
    fieldWidth: '12',
    items: [
      {
        placeholder: 'EN',
        type: 'text',
        id: 'nameEN',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
      {
        placeholder: 'RU',
        type: 'text',
        id: 'nameRU',
        view: 'filled',
        width: 'available',
        size: 's',
      },
    ],
  },
  {
    type: 'ValueSet',
    id: 'stockType',
    fieldWidth: '12',
    placeholder: 'Stock Type',
    label: 'Stock Type',
    view: 'filled',
    width: 'available',
    size: 's',
    multiple: false,
    itemOptions: (item: any) => ({ value: item.id, text: language(item.name, 'en')}),
    action: getAllStockTypesRequest,
  },
  {
    type: 'ValueSet',
    id: 'currency',
    fieldWidth: '12',
    placeholder: 'Currency',
    label: 'Currency',
    view: 'filled',
    width: 'available',
    size: 's',
    multiple: false,
    itemOptions: (item: any) => ({ value: item.id, text: item.name}),
    action: getAllCurrenciesRequest,
  }
];
