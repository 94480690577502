// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';

import API_WORK_CALENDAR from '../api';
import {
  CREATE_NEW_WORK_CALENDAR_REQUEST,
  EDIT_WORK_CALENDAR_REQUEST,
  GET_WORK_CALENDAR_BY_ID_REQUEST, GET_ALL_WORK_CALENDAR_REQUEST2
} from './constants';
import {openSuccessMessage} from "../../../../components/Message/actions";

function* getAllWorkCalendar({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_WORK_CALENDAR.getAllWorkCalendar);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }))
      if (form) yield put(callback({ payload: { response }, meta: { form } }))
    }
  } catch (error) {
    console.error('Auth Wrong');
  }
}

function* createNewWorkCalendar({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_WORK_CALENDAR.createNewWorkCalendar, { data: fields });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
      yield put(openSuccessMessage('Work calendar successfully created.'));

    }
  } catch (error) {
    console.error(error);
  }
}

function* getWorkCalendarById({ payload: { callback, data: { id } }, meta: { form } }: any) {
  try {
    const response = yield call(API_WORK_CALENDAR.getByIdWorkCalendar, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* editWorkCalendar({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_WORK_CALENDAR.editWorkCalendar, { data: fields });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
      yield put(openSuccessMessage('Work calendar successfully updated.'));
    }
  } catch (error) {
    console.error(error);
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_ALL_WORK_CALENDAR_REQUEST2, getAllWorkCalendar),
    takeLatest(CREATE_NEW_WORK_CALENDAR_REQUEST, createNewWorkCalendar),
    takeLatest(GET_WORK_CALENDAR_BY_ID_REQUEST, getWorkCalendarById),
    takeLatest(EDIT_WORK_CALENDAR_REQUEST, editWorkCalendar)
  ]);
}

export default Saga;
