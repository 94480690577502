import * as ACTION_TYPE from './constants';

export const getAllPeriodTypesRequest = ({ meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_PERIODTYPES_REQUEST,
  meta
});

export const createNewPeriodTypesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_PERIODTYPES_REQUEST,
  payload,
  meta,
});

export const editPeriodTypesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_PERIODTYPES_REQUEST,
  payload,
  meta,
})

export const getPeriodTypesByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_PERIODTYPES_BY_ID_REQUEST,
  payload,
  meta,
})