// @ts-nocheck
import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api';
import queryString from 'querystring';

class ClassAPI {
  getAllProducts = () =>
    request({
      url: 'product',
      method: 'GET',
      token: API_GLOBAL.token,
    });

  getAllProductsFast = () =>
    request({
      url: 'product/read-all-admin',
      method: 'GET',
      token: API_GLOBAL.token,
    });

  deleteByIdProduct = (id: any) =>
    request({
      url: `product/${id}`,
      method: 'DELETE',
      token: API_GLOBAL.token,
    });

  getProductByID = ({ id }: any) =>
    request({
      url: `product/${id}`,
      method: 'GET',
      token: API_GLOBAL.token,
    });

  editProduct = ({ data }: any) =>
    request({
      url: `product`,
      method: 'PUT',
      data,
      token: API_GLOBAL.token,
    });

  createNewProduct = ({ data }: any) =>
    request({
      url: 'product',
      data,
      method: 'POST',
      token: API_GLOBAL.token,
    });

  getDeliveryMailing = ({ id }: any) =>
    request({
      url: `delivery/mailing/product/${id}`,
      method: 'GET',
      token: API_GLOBAL.token,
    });

  createDeliveryMailing = ({ data }: any) =>
    request({
      url: 'delivery/mailing',
      data,
      method: 'POST',
      token: API_GLOBAL.token,
    });

  editDeliveryMailing = ({ data }: any) =>
    request({
      url: `delivery/mailing`,
      method: 'PUT',
      data,
      token: API_GLOBAL.token,
    });

  activateMailing = ({ clientType, productId }: any) =>
    request({
      url: `delivery/research/product/${productId}/clientType/${clientType}`,
      method: 'GET',
      token: API_GLOBAL.token,
    });

  // 2022-10-04 быстрая выборка с использоваием критериев по диапазону дат
  getFilterProducts = (data: any) => {
    const {} = this;
    const req = {
      page: 1,
    };
    const sent = Object.assign(req, data);
    console.log('getFilterProducts sent=', sent);
    const q = queryString.stringify(sent);
    console.log('getFilterProducts q=', q);
    return request({
      url: `product/filter2?${q}`,
      method: 'GET',
      token: API_GLOBAL.token,
    });
  };
}

const API_PRODUCTS = new ClassAPI();

export default API_PRODUCTS;
