import PeriodTypes from './pages';

export default [
  {
    type: 'private',
    exact: true,
    path: '/period-types',
    roles: ['admin'],
    component: PeriodTypes,
  },
  {
    type: 'private',
    exact: true,
    path: '/period-types/:id',
    roles: ['admin'],
    component: PeriodTypes,
  },
];
