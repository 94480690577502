export const periods = [
    {
        //                               дней    среднее     в графиках
        id: '7day', name: 'Last 7 days', len: 7,  workDays: 5, dayGraph: 1
    },
    {
        id: '30day', name: 'Last 30 days', len: 30, workDays: 20, dayGraph: 1
    },
    {
        id: '90day', name: 'Last 90 days', len: 90, workDays: 60, dayGraph: 1
    },
    {
        id: '180day', name: 'Last 180 days', len: 180, workDays: 120, dayGraph: 1
    },
    {
        id: 'lastYear', name: 'Last Year', len: 360, workDays: 240, dayGraph: 1
    },
    {
        id: 'YTD', name: 'YTD',  len: 'YTD', workDays: 0, dayGraph: 1
    },

]