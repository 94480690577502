// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';

import API_EVENT from '../api';
import { GET_ALL_EVENTS_REQUEST,CREATE_NEW_EVENT_REQUEST,GET_EVENT_BY_ID_REQUEST,EDIT_EVENT_REQUEST, DELETE_EVENT_BY_ID_REQUEST} from './constants';
import { CALENDAR_TABLE } from 'constants/tables';
import { tableDataSuccess } from 'components/Graphs/Table/actions';
import {getAllEventsRequest, createNewEventFailure} from './actions'
import { openSuccessMessage } from '../../../components/Message/actions';
import { createNewAuthorsFailure } from '../../Authors/redux/actions';

function* getAllEvents({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_EVENT.getAllEvents);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }))
      if (form) yield put(callback({ payload: { response }, meta: { form } }))
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* createNewEvents({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_EVENT.createNewEvents, { data: fields });
    yield put(openSuccessMessage('Event successfully created.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewEventFailure({ payload: { error } }))
  }
}

function* getByIdEvents({ payload: { callback, data: { id } }, meta: { form } }: any) {
  try {
    const response = yield call(API_EVENT.getByIdEvents, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* editEvents({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_EVENT.editEvents, { data: fields });
    yield put(openSuccessMessage('Event successfully edited.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewEventFailure({ payload: { error } }))
  }
}

function* deleteAuthorsById({ payload: { id }, meta }: any) {
  try {
    yield call(API_EVENT.deleteByIdEvents, id);
    yield put(getAllEventsRequest({ meta: { table: CALENDAR_TABLE, callback: tableDataSuccess } }));
    yield put(openSuccessMessage('Event successfully deleted.'));
  } catch (error) {
    yield put(createNewEventFailure({ payload: { error } }))
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_ALL_EVENTS_REQUEST, getAllEvents),
    takeLatest(CREATE_NEW_EVENT_REQUEST, createNewEvents),
    takeLatest(GET_EVENT_BY_ID_REQUEST, getByIdEvents),
    takeLatest(EDIT_EVENT_REQUEST, editEvents),
    takeLatest(DELETE_EVENT_BY_ID_REQUEST, deleteAuthorsById)
  ]);
}

export default Saga;
