// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';

import API_CATEGORY from '../api';
import { GET_ALL_PERIODTYPES_REQUEST, CREATE_NEW_PERIODTYPES_REQUEST, GET_PERIODTYPES_BY_ID_REQUEST, EDIT_PERIODTYPES_REQUEST } from './constants';
import { createNewAuthorsFailure } from '../../../Authors/redux/actions';

function* getAllPeriodTypes({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_CATEGORY.getAllPeriodTypes);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }))
      if (form) yield put(callback({ payload: { response }, meta: { form } }))
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* createNewPeriodTypes({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_CATEGORY.createNewPeriodTypes, { data: fields });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getSectorById({ payload: { callback, data: { id } }, meta: { form } }: any) {
  try {
    const response = yield call(API_CATEGORY.getByIdPeriodTypes, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* editPeriodTypes({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_CATEGORY.editPeriodTypes, { data: fields });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_ALL_PERIODTYPES_REQUEST, getAllPeriodTypes),
    takeLatest(CREATE_NEW_PERIODTYPES_REQUEST, createNewPeriodTypes),
    takeLatest(GET_PERIODTYPES_BY_ID_REQUEST, getSectorById),
    takeLatest(EDIT_PERIODTYPES_REQUEST, editPeriodTypes)
  ]);
}

export default Saga;
