export const GET_UPLOAD_URL_REQUEST = 'GET_UPLOAD_URL_REQUEST';
export const GET_UPLOAD_URL_SUCCESS = 'GET_UPLOAD_URL_SUCCESS';
export const GET_UPLOAD_URL_FAILURE = 'GET_UPLOAD_URL_FAILURE';

export const FILE_URL_UPDATE_REQUEST = 'FILE_URL_UPDATE_REQUEST';
export const FILE_URL_UPDATE_SUCCESS = 'FILE_URL_UPDATE_SUCCESS';
export const FILE_URL_UPDATE_FAILURE = 'FILE_URL_UPDATE_FAILURE';

export const FILE_UPLOAD_REQUEST = 'FILE_UPLOAD_REQUEST';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';


export const FILE_GET_REQUEST = 'FILE_GET_REQUEST';
export const FILE_GET_SUCCESS = 'FILE_GET_SUCCESS';
export const FILE_GET_FAILURE = 'FILE_GET_FAILURE';


export const FILE_INPUT_INIT = 'FILE_INPUT_INIT';
