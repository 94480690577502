// @ts-nocheck
import React from 'react';
import { getAllPeriodTypesRequest } from 'modules/Catalog/PeriodTypes/redux/actions';
import { getAllCompaniesRequest } from 'modules/Companies/redux/actions'
import { language } from 'utils/formatters';

export default [
  {
    isGroup: true,
    label: 'Year',
    fieldWidth: '12',
    items: [
      {
        placeholder: 'Year',
        type: 'text',
        id: 'year',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
  {
    type: 'ValueSet',
    fieldWidth: '12',
    id: 'periodTypeId',
    placeholder: 'Period type',
    label: 'Period type',
    view: 'filled',
    width: 'available',
    size: 's',
    multiple: false,
    itemOptions: (item: any) => ({ value: item.id, text: language(item.name, 'en')}),
    action: getAllPeriodTypesRequest,
  },
  {
    isGroup: true,
    label: 'Amount of dividends',
    fieldWidth: '12',
    items: [
      {
        placeholder: 'Amount of dividends',
        type: 'text',
        id: 'amount',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
  {
    isGroup: true,
    label: 'Record date',
    fieldWidth: '12',
    items: [
      {
        type: 'date',
        id: 'recordDate',
        view: 'filled',
        placeholder: 'DD.MM.YYYY',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
  {
    isGroup: true,
    label: 'Declaration date',
    fieldWidth: '12',
    items: [
      {
        type: 'date',
        id: 'declarationDate',
        view: 'filled',
        placeholder: 'DD.MM.YYYY',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
];
