// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import StatisticFilter from './form';
import StatisticTable from './table';

const Categories = () => {
  return (
    <>
    <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
      <StatisticFilter />
    </GridRow>
        <GridRow >
            <StatisticTable />
        </GridRow>
    </>
  );
};

export default Categories;