export const GET_ALL_CURRENCIES_REQUEST = 'GET_ALL_CURRENCIES_REQUEST'
export const GET_ALL_SECTORS_SUCCESS = 'GET_ALL_SECTORS_SUCCESS'
export const GET_ALL_SECTORS_FAILURE = 'GET_ALL_SECTORS_FAILURE'

export const CREATE_NEW_CURRENCIES_REQUEST = 'CREATE_NEW_CURRENCIES_REQUEST'
export const CREATE_NEW_CURRENCIES_SUCCESS = 'CREATE_NEW_CURRENCIES_SUCCESS'
export const CREATE_NEW_CURRENCIES_FAILURE = 'CREATE_NEW_CURRENCIES_FAILURE'

export const GET_CURRENCIES_BY_ID_REQUEST = 'GET_CURRENCIES_BY_ID_REQUEST'
export const GET_CURRENCIES_BY_ID_SUCCESS = 'GET_CURRENCIES_BY_ID_SUCCESS'
export const GET_CURRENCIES_BY_ID_FAILURE = 'GET_CURRENCIES_BY_ID_FAILURE'

export const EDIT_CURRENCIES_REQUEST = 'EDIT_CURRENCIES_REQUEST'