// @ts-nocheck
import React from 'react';
import { withRouter } from 'react-router-dom';
import GraphTable from 'components/Graphs/Table';
import { ICategoryTable, IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import { TICKERS_RECOMENDATION_TABLE } from 'constants/tables';
import { openDialog } from 'components/Flows/Dialog/actions';
import { deleteRecommendationByIdRequest, getAllRecommendationByTickerIDRequest } from '../../../redux/actions';
import { language } from 'utils/formatters';
import IconDelete from 'arui-feather/icon/action/delete';
import { compose } from 'redux';
import { connect } from 'react-redux';
import FlowDialog from 'components/Flows/Dialog';
import Button from 'arui-feather/button';
import FileForm from '../../form/file';
import GridCol from 'arui-feather/grid-col';
import StringCol from '../../../../../components/Flows/Elements/StringCol';
import TickerForm from '../../form/tickers';
import RecommendationForm from '../../form/recommendation';
import IconEdit from 'arui-feather/icon/action/edit/edit';
import { RECOMENDATION_FORM } from '../../../../../constants/forms';

class RecommendationTable extends React.Component<ICategoryTable> {

  state = {
    recommendationId: null,
  };

  getRowLink = (stockId: any) => {
    const { id }: any = this.props;
    return `/companies/edit/${id}/stock/${stockId}`;
  };


  handleCreateRecommendation = () => {
    const { dispatch }: any = this.props;
    dispatch(openDialog({ id: 'table.recommendation' }));
  };

  handleEdit(id: any) {
    const { dispatch }: any = this.props;
    this.setState({ recommendationId: id });
    dispatch(openDialog({ id: 'table.recommendation' }));
  }

  handleDelete = (recommendationId: string) => {
    const { dispatch, ticketId }: any = this.props;
    dispatch(deleteRecommendationByIdRequest({ payload: { id: recommendationId, ticketId: ticketId } }));
  };

  handleClose = () => {
    this.setState({
      recommendationId: null,
    });
  };


  get createDialogs() {
    const { recommendationId }: any = this.state;
    const { id, ticketId }: any = this.props;
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        <FlowDialog onClose={this.handleClose} form={RECOMENDATION_FORM} id="table.recommendation" width="big">
        <RecommendationForm ticketId={ticketId} id={id} recommendationId={recommendationId}/>
      </FlowDialog>
    );
  }


  handleCreateNewStock = () => {
    const { dispatch }: any = this.props;
    dispatch(openDialog({ id: 'table.file' }));
  };

  normalizeTableData = (head: IHeadTable[], body: IBodyTable[]): any => {
    let processedData = body && body.map((item, i) => {
      const { id, targetPrice, recommendation, date }: any = item;
      let emptyWidth: any = '';
      return {
        id,
        cells: [
          {
            element: <>{(i + 1)} <IconEdit size='m'/></>,
            id,
            type: 'id',
            width: emptyWidth,
          },
          {
            element: language(recommendation, 'en'),
            id,
            type: 'recommendation',
            width: emptyWidth,
          },
          {
            element: targetPrice,
            id,
            type: 'targetPrice',
            width: emptyWidth,
          },
          {
            element: date,
            id,
            type: 'targetPrice',
            width: emptyWidth,
          },
          {
            element: <IconDelete size={'s'}/>,
            id,
            type: 'delete',
            width: emptyWidth,
            customClass: 'delete-icon',
          },
        ],
      };
    }) || [];


    //CheckBoxCol(Boolean(active)),
    const normalizeData = processedData.map((val: any) => {
      let newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });

    return normalizeData;
  };

  render() {
    const head = [
      {
        id: 'id',
        label: '№',
        type: 'edit',
        style: { width: 2 },
      },
      {
        id: 'recommendation',
        label: 'Recommendation',
        style: { width: '' },
      },
      {
        id: 'targetPrice',
        label: 'Target Price',
        style: { width: '' },
      },
      {
        id: 'date',
        label: 'Date',
        style: { width: '' },
      },
      {
        id: 'delete',
        type: 'delete',
        label: '',
        style: { width: '1' },
      },
    ];

    const { ticketId }: any = this.props;

    return (
      <>
        <GraphTable
          table={TICKERS_RECOMENDATION_TABLE}
          normalizeTableData={this.normalizeTableData}
          graphId={ticketId}
          actions={
            <GridCol>
              <Button onClick={() => this.handleCreateRecommendation()} className={'button-float'} view="extra" size="s">Create
                new recomendation</Button>
            </GridCol>
          }
          title="Recommendations"
          createDialogs={this.createDialogs}
          init={getAllRecommendationByTickerIDRequest}
          onDelete={(id: any) => this.handleDelete(id)}
          onEdit={(id: any) => this.handleEdit(id)}
          head={head}
          width={'12'}
          gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
          activeLink
        />
      </>
    );
  }
}

export default compose<any>(
  withRouter,
  connect(),
)(RecommendationTable);
