// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';

import GraphForm from 'components/Graphs/Form';
import { formReset } from 'components/Graphs/Form/actions'
import { tableDataSuccess } from 'components/Graphs/Table/actions'
import { LANGUAGE_EN, LANGUAGE_RU } from 'constants/languages'

import { createProductTypeRequest, categoryRequest, productTypeRequest, editProductTypeRequest } from '../../redux/actions';
import normalize from './normalize';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { CATALOG_PRODUCT_TYPE_FORM } from 'constants/forms';
import { CATALOG_PRODUCT_TYPE_TABLE } from 'constants/tables';
import { connect } from 'react-redux';


interface IProductTypesForm {
  title?: string;
  form: string;
  match: any
}

class ProductTypesForm extends React.Component<IProductTypesForm> {

  handleClick = () => {
    const {history, dispatch}: any = this.props;
    dispatch(reset(CATALOG_PRODUCT_TYPE_FORM));
    dispatch(productTypeRequest({ meta: { table: CATALOG_PRODUCT_TYPE_TABLE, callback: tableDataSuccess }}))
    dispatch(formReset({ meta: { form: CATALOG_PRODUCT_TYPE_FORM } }));
    history.push('/product-types')
  };

  get actions() {
    const { match: { params: { id } } } = this.props;
    const action = id ? 'Edit this product' : 'Add new product';
    return (
      <GridRow>
        <GridCol>
          <Button className={"button-float"} view="extra" size="s" type="submit">
            {action}
          </Button>
          <Button className={"mr20 button-float"} size="s" onClick={this.handleClick}>Cancel</Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = ({ nameEN, nameRU, descriptionEN, descriptionRU, categories, blastNote }: any) => {
    const { match: { params: { id } } } = this.props
    return {
      id,
      name: [
        {
          language: { id: LANGUAGE_RU, name: 'Рус' },
          translatedValue: nameRU,
        },
        {
          language: { id: LANGUAGE_EN, name: 'En' },
          translatedValue: nameEN,
        },
      ],
      description: [
        {
          language: { id: LANGUAGE_RU, name: 'Рус' },
          translatedValue: descriptionRU,
        },
        {
          language: { id: LANGUAGE_EN, name: 'En' },
          translatedValue: descriptionEN,
        },
      ],
      categories,
      blastNote
    };
  };

  render() {
    const {  match: { params: { id } }}  = this.props;
    return (
      <GraphForm
        title="Product type form"
        form={CATALOG_PRODUCT_TYPE_FORM}
        actions={this.actions}
        onSuccess={this.handleClick}
        graphId={id}
        init={categoryRequest}
        edit={editProductTypeRequest}
        create={createProductTypeRequest}
        submit={this.submit}
        normalize={normalize}
        validate={() => {}}
        type={id ? 'edit': 'create'}
        columns={1}
        config={config}
        width="5"
        reInitialize
      />
    );
  }
}

export default compose<any>(
  connect(),
  withRouter
)(ProductTypesForm)
