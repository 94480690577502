// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import {CURRENT_MARKET_PRICES_FORM} from 'constants/forms';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectAllValueSets } from 'components/ValueSet/selector';

interface ICompaniesForm {
  title?: string;
  form: string;
  match: any
}

class CurrentMarketPricesForm extends React.Component<ICompaniesForm> {
  handleClick = () => {
    const { history, dispatch }: any = this.props;
    dispatch(reset(CURRENT_MARKET_PRICES_FORM));
    console.log('push to list');
    history.push('/');
  };

  get actions() {
    const { id }: any = this.props;
    const action =  'Add new Current market prices';
    return (
      <GridRow>
        <GridCol>
{/*
          <Button className={'button-float'} view="extra" size="s" type="submit">
            {action}
          </Button>
          <Button className={'mr20 button-float'} size="s" onClick={this.handleClick}>Cancel</Button>
*/}
        </GridCol>
      </GridRow>
    );
  }

  submit = (data: any) => {
    const { id }: any = this.props;
    console.log('submit data=',  data);
    return {
    };
  }

  render() {
    const { id }: any = this.props;
    return (
      <GraphForm
        form={CURRENT_MARKET_PRICES_FORM}
        actions={this.actions}
        onSuccess={this.handleClick}
        graphId={id}
        title="Current market prices/OFZ upload form"
        init={()=>{}}
        edit={()=>{}}
        create={()=>{}}
        submit={this.submit}
        normalize={()=>{}}
        validate={()=>{}}
        type={id ? 'edit' : 'create'}
        columns={1}
        config={config(id)}
        width="6"
        reInitialize
      />
    );
  }
}
const mapStateToProps = createStructuredSelector({
  valueSetsAll: selectAllValueSets,
});

const withConnect = connect(mapStateToProps);

export default compose<any>(
  withConnect,
  withRouter,
)(CurrentMarketPricesForm);
