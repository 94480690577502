// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import { closeDialog } from 'components/Flows/Dialog/actions'
import { formReset } from 'components/Graphs/Form/actions';
import {
  getFileByIDRequest, editStockRequest, getAllStocksRequest, createNewStockRequest, getFilesByIDCompanyRequest,
} from '../../../redux/actions';
import normalize from './normalize';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { STOCK_FORM } from 'constants/forms';
import { LANGUAGE_EN, LANGUAGE_RU } from '../../../../../constants/languages';

import { AUTHORS_TABLE, STOCKS_TABLE } from '../../../../../constants/tables';
import { tableDataSuccess } from '../../../../../components/Graphs/Table/actions';

interface ICompaniesForm {
  title?: string;
  form: string;
  match: any
  stockId?: any
}

class FileForm extends React.Component<ICompaniesForm> {
  handleClick = () => {
    const { history, dispatch }: any = this.props;
    dispatch(reset(STOCK_FORM));
    dispatch(formReset({ meta: { form: STOCK_FORM } }));
    dispatch(getFilesByIDCompanyRequest({ meta: { table: STOCKS_TABLE, callback: tableDataSuccess } }));
    dispatch(closeDialog({ id: 'table.file' }))
  };

  submit = (data: any) => {
    const { match: { params: { idStocks } } } = this.props;
    return {
      id: data.id,
      companyId: data.company,
      currencyId: data.currency,
      stockTypeId: data.stockType,
      name: [
        {
          language: { id: LANGUAGE_RU, name: 'Рус' },
          translatedValue: data.nameRU,
        },
        {
          language: { id: LANGUAGE_EN, name: 'En' },
          translatedValue: data.nameEN,
        },
      ]
    };
  }

  render() {
    const { fileId }: any = this.props;
    return (
      <GraphForm
        form={STOCK_FORM}
        // title="File form"
        actions={() => {}}
        onSuccess={this.handleClick}
        graphId={fileId}
        init={() => {}}
        edit={() => {}}
        create={() => {}}
        submit={this.submit}
        normalize={normalize}
        validate={() => {}}
        type={fileId ? 'edit' : 'create'}
        columns={1}
        config={config}
        width="1"
        reInitialize
      />
    );
  }
}

export default compose<any>(
  withRouter,
  connect()
)(FileForm);
