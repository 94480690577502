// @ts-nocheck
import React from 'react';
import CheckBox from 'arui-feather/checkbox';

const CheckBoxCol = (value: boolean) => {
  return (
    <CheckBox className={'check-tabl'} disabled checked={value} text="" width="available"/>
  );
};

export default CheckBoxCol;