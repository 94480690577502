// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { find, isEmpty } from 'lodash';
import { compose } from 'redux';
import { getProductByIDRequest, editProductByIDRequest, createProductRequest } from '../../../redux/actions'
import { reset } from 'redux-form';
import { getFlowFormValues } from 'utils/select'
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import { formReset } from 'components/Graphs/Form/actions';
import { tableDataSuccess } from 'components/Graphs/Table/actions';
// import {
//   // createNewClientsRequest, getClientsByIdRequest,
//   // getAllClientsRequest, editClientsRequest,
// } from '../../redux/actions';
import normalize from './normalize';
import validate from './validate'
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { PRODUCT_OPTION_FORM } from 'constants/forms';
import {CATALOG_COUNTRY_TABLE, CATALOG_EVENTTYPES_TABLE, CLIENTS_TABLE} from 'constants/tables';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectAllValueSets, selectValueSet } from '../../../../../components/ValueSet/selector';
import { LANGUAGE_EN, LANGUAGE_RU } from '../../../../../constants/languages';
import { checkVisibleElement } from '../../../../../constants/roles';
import { makeSelectRoles } from '../../../../../app/selectors';
import {getAllCountryRequest} from "../../../../Catalog/Countries/redux/actions";
import {getAllEventTypesRequest} from "../../../../Catalog/EventTypes/redux/actions";

interface IClientsForm {
  title?: string;
  form: string;
  match: any
  valuesFrom?: any
  valueSetsAll: any,
}

const buildCategorySettings = ({ data, categories, productType }: any) => {
  const arrayCategories = [] as any
  Object.keys(data).forEach((category: any) => {
    if (!isEmpty(data[category])) {
      const caty = find(categories, ({ id }) =>  id === category)
      if (caty) {
        caty.productTypes = []
        Object.keys(data[category]).forEach((product: any) => {
          const productTypes = find(productType, ({ id }) =>  id === product)
          if (productTypes && data[category][product]){
            caty.productTypes.push(productTypes)
          }
        })
        arrayCategories.push(caty)
      }
    }
  })
  return arrayCategories
}

class ClientsForm extends React.Component<IClientsForm> {
  handleClick = (props: any) => {
    const { history, dispatch }: any = this.props;
    dispatch(reset(PRODUCT_OPTION_FORM));
    dispatch(formReset({ meta: { form: PRODUCT_OPTION_FORM } }));
    history.push(`/products/edit/${props.payload.response.id}/annotation`);
  };

  get actions() {
    const { id }: any = this.props;
    const action = id ? 'Edit this product' : 'Add new product';
    return (
      <GridRow>
        <GridCol>
          <Button className={'button-float'} view="extra" size="s" type="submit">
            {action}
          </Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = (data: any) => {
    return {
      annotation: data.annotation,
      authors: data.authors,
      categoryId: data.categoryId,
      commodities: data.commodities,
      companies: data.companies,
      date: data.date,
      events: data.events,
      file: data.file?.url,
      id: data.id,
      languageId: data.languageId === "EN" ? LANGUAGE_EN : LANGUAGE_RU,
      mark: data.mark,
      name: data.name,
      productTypeId: data.productTypeId,
      sectors: data.sectors
    };
  };

  // 2021-09-29  дополнительная подгрузка стран
  componentDidMount() {
    const { dispatch }: any = this.props;
    dispatch(getAllCountryRequest({ meta: { table: CATALOG_COUNTRY_TABLE, callback: tableDataSuccess }}))
    dispatch(getAllEventTypesRequest({ meta: { table: CATALOG_EVENTTYPES_TABLE, callback: tableDataSuccess }}))

  }

  render() {
    const { title, id, valuesFrom, valueSetsAll }: any = this.props;
    const list = valueSetsAll && valueSetsAll.productTypeId
    return (
        <GraphForm
          form={PRODUCT_OPTION_FORM}
          actions={this.actions}
          onSuccess={this.handleClick}
          graphId={id}
          init={getProductByIDRequest}
          edit={editProductByIDRequest}
          create={createProductRequest}
          title={title}
          submit={this.submit}
          normalize={normalize}
          validate={validate}
          type={id ? 'edit' : 'create'}
          columns={1}
          config={config(valuesFrom, list)}
          width="12"
          reInitialize
        />
    );
  }
}
const mapStateToProps = createStructuredSelector({
  valueSetsAll: selectAllValueSets,
  // @ts-ignore
  valuesFrom: getFlowFormValues(PRODUCT_OPTION_FORM),
  rolesUser: makeSelectRoles(),
});

const withConnect = connect(mapStateToProps);

export default compose<any>(
  withConnect,
  withRouter,
)(ClientsForm);
