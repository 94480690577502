export const GET_ALL_LAW_FILE_REQUEST = 'GET_ALL_LAW_FILE_REQUEST'
export const GET_ALL_LAW_FILE_SUCCESS = 'GET_ALL_LAW_FILE_SUCCESS'
export const GET_ALL_LAW_FILE_FAILURE = 'GET_ALL_LAW_FILE_FAILURE'

export const CREATE_NEW_LAW_FILE_REQUEST = 'CREATE_NEW_LAW_FILE_REQUEST'

export const GET_LAW_FILE_BY_ID_REQUEST = 'GET_LAW_FILE_BY_ID_REQUEST'

export const DELETE_LAW_FILE_BY_ID_REQUEST = 'DELETE_LAW_FILE_BY_ID_REQUEST'

export const EDIT_LAW_FILE_REQUEST = 'EDIT_LAW_FILE_REQUEST'


////////////////////////////////////////////////////////////////////////////////////////
//Старый код удалить позднее
export const GET_ALL_COMPANIES_REQUEST = 'GET_ALL_COMPANIES_REQUEST'
export const GET_ALL_COMPANIES_SUCCESS = 'GET_ALL_COMPANIES_SUCCESS'
export const GET_ALL_COMPANIES_FAILURE = 'GET_ALL_COMPANIES_FAILURE'

export const CREATE_NEW_COMPANIES_REQUEST = 'CREATE_NEW_COMPANIES_REQUEST'

export const GET_COMPANIES_BY_ID_REQUEST = 'GET_COMPANIES_BY_ID_REQUEST'

export const DELETE_COMPANIES_BY_ID_REQUEST = 'DELETE_COMPANIES_BY_ID_REQUEST'

export const EDIT_COMPANIES_REQUEST = 'EDIT_COMPANIES_REQUEST'

export const GET_ALL_STOCKS_REQUEST = 'GET_ALL_STOCKS_REQUEST'
export const GET_ALL_DIVIDENDS_REQUEST = 'GET_ALL_DIVIDENDS_REQUEST'
export const GET_DIVIDEND_BY_ID = 'GET_DIVIDEND_BY_ID'
export const EDIT_DIVIDEND_REQUEST = 'EDIT_DIVIDEND_REQUEST'
export const GET_STOCKS_BY_ID_REQUEST = 'GET_STOCKS_BY_ID_REQUEST'
export const EDIT_STOCK_REQUEST = 'EDIT_STOCK_REQUEST'

export const GET_ALL_TICKERS_REQUEST = 'GET_ALL_TICKERS_REQUEST'

export const GET_TICKER_BY_ID_REQUEST = 'GET_TICKER_BY_ID_REQUEST'
export const GET_STOCKS_BY_ID_COMPANY = 'GET_STOCKS_BY_ID_COMPANY'
export const CREATE_NEW_STOCK_REQUEST = 'CREATE_NEW_STOCK_REQUEST'

export const GET_DIVIDEND_BY_STOCK_ID = 'GET_DIVIDEND_BY_STOCK_ID'
export const CREATE_NEW_DIVIDEND_REQUEST = 'CREATE_NEW_DIVIDEND_REQUEST'
export const GET_FILES_BY_ID_COMPANY = 'GET_FILES_BY_ID_COMPANY'
export const CREATE_NEW_FILE_FIELD_REQUEST = 'CREATE_NEW_FILE_FIELD_REQUEST'
export const EDIT_FILE_FIELD_REQUEST = 'EDIT_FILE_FIELD_REQUEST'



export const GET_FILE_BY_ID_REQUEST = 'GET_FILE_BY_ID_REQUEST'
export const DELETE_STOCKS_BY_ID_REQUEST = 'DELETE_STOCKS_BY_ID_REQUEST'
export const DELETE_DIVIDEND_BY_ID_REQUEST = 'DELETE_DIVIDEND_BY_ID_REQUEST'
export const GET_TICKERS_BY_COMPANY_ID_REQUEST = 'GET_TICKERS_BY_COMPANY_ID_REQUEST'
export const CREATE_NEW_TICKER_REQUEST = 'CREATE_NEW_TICKER_REQUEST'
export const DELETE_TICKER_BY_ID_REQUEST = 'DELETE_TICKER_BY_ID_REQUEST'
export const EDIT_TICKER_REQUEST = 'EDIT_TICKER_REQUEST'
export const CREATE_RECOMMENDATION_REQUEST = 'CREATE_RECOMMENDATION_REQUEST'
export const GET_RECOMMENDATION_BY_TICKER_ID_REQUEST = 'GET_RECOMMENDATION_BY_TICKER_ID_REQUEST'
export const GET_RECOMMENDATION_BY_ID_REQUEST = 'GET_RECOMMENDATION_BY_ID_REQUEST'
export const EDIT_RECOMMENDATION_REQUEST = 'EDIT_RECOMMENDATION_REQUEST'
export const DELETE_RECOMMENDATION_BY_ID_REQUEST = 'DELETE_RECOMMENDATION_BY_ID_REQUEST'
export const DELETE_FILE_BY_ID_REQUEST = 'DELETE_FILE_BY_ID_REQUEST'

