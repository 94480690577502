// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import CurrenciesForm from './form';
import CurrenciesTable from './table';

const Currencies = () => {
  return (
    <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
      <CurrenciesTable />
      <CurrenciesForm />
    </GridRow>
  );
};

export default Currencies;