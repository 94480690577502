import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {
  getAllEvents = () => request({
    url: 'event',
    method: 'GET',
    token: API_GLOBAL.token
  });

  createNewEvents = ({ data }: any) => request({
    url: 'event',
    data,
    method: 'POST',
    token: API_GLOBAL.token
  })

  editEvents = ({ data }: any) => request({
    url: 'event',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  })

  getByIdEvents = ({ id }: any) => request({
    url: `event/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  });

  deleteByIdEvents = (id: any) => request({
    url: `event/${id}`,
    method: 'DELETE',
    token: API_GLOBAL.token
  })

}

const API_EVENT = new ClassAPI();

export default API_EVENT;
