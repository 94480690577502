export const roles = {
  ['ADMINISTRATOR']: {
    loginPage: '/categories',
  },
  ['ADMINISTRATOR_CYPRUS']: {
    routes: [ '/users-roles', '/clients', '/products' ],
    routesExcludet: [ '/products/add' ],
    loginPage: '/users-roles',
    menu: [ '/users-roles', '/clients', '/products' ],
    hideElement: [ 'addProductButton', 'save_mailing' ]
  },
  ['EDITOR']: {
    routes: [ '/products' ],
    loginPage: '/products',
    menu: ['/products']
  }
} as any

export const checkPermisions = (rolesUser?: Array<string>, element?: any, type?: string, excludeType?: any) => {
  if (!rolesUser && !element && !type) return false
  const isAccesed = rolesUser?.some((role: any) => {
    if (role === 'ADMINISTRATOR') return true
    if (role === 'CLIENT') return false

    const oo = roles[role] && roles[role]
    // @ts-ignore
    const aa = oo && oo[type]?.some((path: any) => {
      return element?.includes(path)
    })
    return aa
  })
  const ResuldAccess = isAccesed && excludeType && rolesUser?.some((role: any) => {
    if (role === 'ADMINISTRATOR') return true
    if (role === 'CLIENT') return false
    const oo = roles[role]
    // @ts-ignore
    const aa = oo && !oo[excludeType]?.includes(element)
    return aa
  })

  return excludeType ? ResuldAccess : isAccesed
}

export const getFirstPage = (rolesUser: Array<string>) => {
  const role = rolesUser[0]
  return roles[role].loginPage
}

export const checkVisibleElement = (rolesUser?: Array<string>, element?: any,) => {
  if (!rolesUser && !element) return true
  const isVisible = rolesUser?.some((role: any) => {
    if (role === 'ADMINISTRATOR') return true
    const oo = roles[role] && roles[role]
    // @ts-ignore
    const aa = oo && !oo['hideElement']?.includes(element)
    return aa
  })
  return isVisible
}