import { validateEmail } from 'components/Inputs/Text/validate';

interface IValidate {
  email?: string;
  lastName?: string;
  firstName?: string;
  roles?: string;
}

const validate = (values: any) => {
  const errors = {} as IValidate;
  return errors;
};

export default validate;
