export const GET_ALL_COMMODITIES_REQUEST = 'GET_ALL_COMMODITIES_REQUEST'
export const GET_ALL_COMMODITIES__SUCCESS = 'GET_ALL_COMMODITIES__SUCCESS'
export const GET_ALL_COMMODITIES__FAILURE = 'GET_ALL_COMMODITIES__FAILURE'

export const CREATE_NEW_COMMODITIES_REQUEST = 'CREATE_NEW_COMMODITIES_REQUEST'
export const CREATE_NEW_COMMODITIES_SUCCESS = 'CREATE_NEW_COMMODITIES_SUCCESS'
export const CREATE_NEW_COMMODITIES_FAILURE = 'CREATE_NEW_COMMODITIES_FAILURE'

export const GET_COMMODITIES_BY_ID_REQUEST = 'GET_COMMODITIES_BY_ID_REQUEST'
export const GET_COMMODITIES_BY_ID_SUCCESS = 'GET_COMMODITIES_BY_ID_SUCCESS'
export const GET_COMMODITIES_BY_ID_FAILURE = 'GET_COMMODITIES_BY_ID_FAILURE'

export const EDIT_COMMODITIES_REQUEST = 'EDIT_COMMODITIES_REQUEST'