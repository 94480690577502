// @ts-nocheck
import React from 'react'
import GridRow from 'arui-feather/grid-row';
import EquityTable from './table/equity';
const StocksPage = ({ id, stockId }: any) => {

  return (
    <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
      <EquityTable id={id} stockId={stockId} />
    </GridRow>
  )
}

export default StocksPage