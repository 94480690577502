interface IValidate {
  footerMifidId?: string;
  footerAccId?: string;
}

const validate = (values: any) => {
  const errors = {} as IValidate;

  if (!values.footerMifidId) {
    errors.footerMifidId = "Required";
  }

  if (!values?.footerAccId) {
    errors.footerAccId = "Required";
  }

  return errors;
};

export default validate;
