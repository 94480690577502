// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';

import API_EMAILS from '../api';
import {
  GET_ALL_EMAILS_REQUEST,
  CREATE_NEW_EMAILS_REQUEST,
  GET_EMAILS_BY_ID_REQUEST,
  EDIT_EMAILS_REQUEST, DELETE_EMAILS_BY_ID_REQUEST

} from './constants';
import {
  getAllEmailRequest
} from './actions';
import {
  EMAILS_TABLE
} from '../../../constants/tables';

import { tableDataSuccess } from '../../../components/Graphs/Table/actions';
import { openSuccessMessage } from '../../../components/Message/actions';
import { createNewAuthorsFailure } from '../../Authors/redux/actions';
import { set_global_id } from '../../../app';

function* getAllEmail({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_EMAILS.getAllEmails);
    console.log('Получены Email', response);
    if (callback) {
      console.log('table = ', table);
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* createNewLawFile({
  payload: { callback, fields },
  meta: { form, onSuccess },
}: any) {
  try {
    const response = yield call(API_EMAILS.createNewEmail, { data: fields });
    // 2021-08-05 сохраним id для дальней шего перехода на эту страницу для редактирования
    set_global_id(response.id);

    yield put(openSuccessMessage('Company successfully created.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* getByIdEmail({
  payload: {
    callback,
    data: { id },
  },
  meta: { form },
}: any) {
  try {
    console.log('==getByIdEmail id=', id )
    const response = yield call(API_EMAILS.getByIdEmail, { id });
    console.log('==response =', response )

    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}

function* editEmail({
  payload: { callback, fields },
  meta: { form, onSuccess },
}: any) {
  try {
    const response = yield call(API_EMAILS.editEmail, { data: fields });
    yield put(openSuccessMessage('Email successfully edited.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }));
  }
}
function* deleteByIdEmail({ payload: { id }, meta }: any) {
  try {
    yield call(API_EMAILS.deleteByIdEmail, id);
    yield put(
      getAllEmailRequest({
        meta: { table: EMAILS_TABLE, callback: tableDataSuccess },
      }),
    );
    yield put(openSuccessMessage('Email successfully deleted.'));
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_ALL_EMAILS_REQUEST, getAllEmail),
    takeLatest(CREATE_NEW_EMAILS_REQUEST, createNewLawFile),
    takeLatest(GET_EMAILS_BY_ID_REQUEST, getByIdEmail),
    takeLatest(EDIT_EMAILS_REQUEST, editEmail),
    takeLatest(DELETE_EMAILS_BY_ID_REQUEST, deleteByIdEmail),

  ]);
}

export default Saga;
