import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {
  getAllCountry = () => request({
    url: 'catalog/country',
    method: 'GET',
    token: API_GLOBAL.token
  });

  createNewCountry = ({ data }: any) => request({
    url: 'catalog/country',
    data,
    method: 'POST',
    token: API_GLOBAL.token
  });

  editCountry = ({ data }: any) => request({
    url: 'catalog/country',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  })

  getByIdCountry = ({ id }: any) => request({
    url: `catalog/country/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })

  getByNameCountry = ({ id }: any) => request({
    url: `catalog/sector/by-name/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })
}

const API_SECTOR = new ClassAPI();

export default API_SECTOR;
