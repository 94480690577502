// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import {
  imageUploadRequest,
  dividendsUploadRequest,
  sectorUploadRequest,
  pdfUploadRequest,
  currenMarketPricesUploadRequest, oFZUploadRequest, oFZDeleteRequest
} from './actions';
import GridCol from 'arui-feather/grid-col';
import IconButton from 'arui-feather/icon-button'
import IconDelete from 'arui-feather/icon/action/delete'
import Attach from 'arui-feather/attach';
import GridRow from 'arui-feather/grid-row';

class FileInput extends React.Component {

  isImage = (value: string) => {
    if (!value) return false
    const getExtension = (filename: string) => {
      var parts = filename.split('.');
      return parts[parts.length - 1];
    }

    const isImageData = (filename: string) => {
      var ext = getExtension(filename);
      switch (ext.toLowerCase()) {
        case 'jpg':
        case 'gif':
        case 'bmp':
        case 'png':
        case 'jpeg':
          //etc
          return true;
      }
      return false;
    }

    return isImageData(value)
  }

  uploadFile = (file: any) => {
    const { dispatch, input, id, typeFile, meta: { form } }: any = this.props;
    switch (typeFile) {
      case 'img':
        dispatch(imageUploadRequest({ file, id, form }));
        break;
      case 'pdf':
        dispatch(pdfUploadRequest({ file, id, form }));
        break;
      case 'companyXlsx':
       // dispatch(companyUploadRequest({ file, id, form }));
        input.onChange({file:file})
        break;
      case 'sectorXlsx':
       // dispatch(sectorUploadRequest({ file, id, form }));
        input.onChange({file:file})
        break;
      case 'dividendsXlsx':
        dispatch(dividendsUploadRequest({ file, id, form }));
        break;
      case 'currentMarketPricesXlsx':
        console.log("Input File file/id/form", file, id, form)
        dispatch(currenMarketPricesUploadRequest({ file, id, form }));
        break;
      case 'OFZXlsx':
        console.log("Input File OFZ file/id/form", file, id, form)
        dispatch(oFZUploadRequest({ file, id, form }));
        break;
      default:
        return null;
    }

  };

// add 2023-10-12
  uploadDelete= (file: any) => {
    const { dispatch, input, id, typeFile, meta: { form } }: any = this.props;
    switch (typeFile) {
      case 'OFZXlsx':
        console.log("Delete OFZ", file, id, form)
        dispatch(oFZDeleteRequest({ id }));
        break;
      default:
        return null;
    }

  };




  renderInput = (accept: string, value: string, typeFile: string, disabePreview: boolean, disabled:boolean) => {
    switch (typeFile) {
      case 'img':
        return (<GridRow>
          <GridCol width='8'>
            <input
              type="file"
              name="f"
              value={value}
            />
            {/*<Attach*/}
            {/*  accept={accept}*/}
            {/*  onChange={this.uploadFile}*/}
            {/*  noFileText={value ? 'File is saved' : 'File is empty'}*/}
            {/*  buttonContent={ value ? 'Edit file' : 'Upload file' }*/}
            {/*  size='s'*/}
            {/*/>*/}
          </GridCol>
          {
            !disabePreview && this.isImage(value) && (
              <GridCol width='4' className='admin-image'>
                <img src={value}/>
                <IconButton
                  className="image-button"
                  size="s"
                  onClick={this.uploadFile}
                >
                  <IconDelete colored />
                </IconButton>
              </GridCol>
            )
          }
        </GridRow>);
        break;
      case 'pdf':
        return (<GridRow>
          <GridCol width='8'>
            <Attach
              accept={accept}
              onChange={this.uploadFile}
              noFileText={value ? 'File is saved' : 'File is empty'}
              buttonContent={ value ? 'Edit file' : 'Upload file' }
              size='s'
            />
          </GridCol>
          {
            !disabePreview && (
              <GridCol width='4' className='admin-image'>
                <img src={value}/>
              </GridCol>
            )
          }
        </GridRow>);
        break;
      case 'companyXlsx':
        return <Attach
          disabled={disabled}
          accept={accept}
          onChange={this.uploadFile}
          noFileText=''
          buttonContent='Upload xlsx'
          size='s'
        />;
        break;
      case 'sectorXlsx':
        return <Attach
          disabled={disabled}
          accept={accept}
          onChange={this.uploadFile}
          noFileText=''
          buttonContent='Upload xlsx'
          size='s'
        />;

      case 'dividendsXlsx':
        return <Attach
          disabled={disabled}
          accept={accept}
          onChange={this.uploadFile}
          noFileText=''
          buttonContent='Upload xlsx'
          size='s'
        />;
        break;

      case 'currentMarketPricesXlsx':
        return <Attach
            disabled={disabled}
            accept={accept}
            onChange={this.uploadFile}
            noFileText=''
            buttonContent='Upload xlsx'
            size='s'
          />
        break;
      case 'OFZXlsx':
        return (
        <>
          <div style= {{ display: "inline-block"}}>
          <Attach
            accept={accept}
              onChange={this.uploadFile}
              noFileText=''
              buttonContent='Upload xlsx'
              size='s'
          />
          </div>

          <div style= {{ display: "inline-block", marginLeft:'20px'}}>
          <IconButton size="s" onClick={this.uploadDelete }>
            <IconDelete size="s" />
          </IconButton>
          </div>

        </>
        )
        break;

      default:
        return null;
    }
  };


  render() {
    const { accept, input, typeFile, disabePreview, disabled }: any = this.props;
    const value = input?.value;
    return this.renderInput(accept, value, typeFile, disabePreview, disabled);
  }
}

export default connect()(FileInput);
