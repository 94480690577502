// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import EmailTable from './table';
import { Link } from 'react-router-dom';
import Button from 'arui-feather/button';

const EmailsView = () => {
  console.log('Client view render');
  return (
  <>
    <GridRow>
      <EmailTable
        actions={
          <Link to={'email/create'}>
            <Button className={'button-float'} view="extra" size="s" type="submit">
              Add new email
            </Button>
          </Link>
        }
      />
    </GridRow>
    </>
  );
};

export default EmailsView;