import * as ACTION_TYPE from './constants';

export const getAllLanguagesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_LANGUAGES_REQUEST,
  payload,
  meta,
});

export const createNewLanguagesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_LANGUAGES_REQUEST,
  payload,
  meta,
});

export const editLanguagesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_LANGUAGES_REQUEST,
  payload,
  meta,
})

export const getLanguagesByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_LANGUAGES_BY_ID_REQUEST,
  payload,
  meta,
})