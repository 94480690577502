export const GET_ALL_EVENTS_REQUEST = 'GET_ALL_EVENTS_REQUEST'
export const EVENTS_SUCCESS = 'EVENTS_SUCCESS'
export const EVENTS_FAILURE = 'EVENTS_FAILURE'

export const CREATE_NEW_EVENT_REQUEST = 'CREATE_NEW_EVENT_REQUEST'
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS'
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE'

export const GET_EVENT_BY_ID_REQUEST = 'GET_EVENT_BY_ID_REQUEST'
export const EVENT_SUCCESS = 'EVENT_SUCCESS'
export const EVENT_FAILURE = 'EVENT_FAILURE'

export const EDIT_EVENT_REQUEST = 'EDIT_EVENT_REQUEST'
export const DELETE_EVENT_BY_ID_REQUEST = 'DELETE_EVENT_BY_ID_REQUEST'
export const CREATE_NEW_EVENT_FAILURE = 'CREATE_NEW_EVENT_FAILURE'

