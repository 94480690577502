import WidgetsCharts from './pages';


export default [
  {
    type: 'private',
    exact: true,
    path: '/widgets-charts',
    roles: ['admin'],
    component: WidgetsCharts,
  },

];
