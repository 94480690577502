// @ts-nocheck
import {all, takeEvery, put, takeLeading} from 'redux-saga/effects';
import {tableDataPaginationSuccess, tableDataSuccess} from './actions';
import {TABLE_DATA_PAGINATION_REQUEST, TABLE_DATA_REQUEST} from './constants';
import {IAction} from 'types/index'

function* get({ meta: { table, init, graphId } }: IAction<void>) {
  yield put(init({ meta: { table, graphId, callback: tableDataSuccess } }))
}

function* getPagination({payload, meta: {table, init, currentPage}}: IAction<void>) {
//  console.log('getPagination ', payload, table, currentPage)
  yield put(init({payload, meta: {table, currentPage, callback: tableDataPaginationSuccess, payload}}))
}

function* Saga() {
  yield all([
    takeEvery(TABLE_DATA_REQUEST, get),
    takeLeading(TABLE_DATA_PAGINATION_REQUEST, getPagination),
  ]);
}

export default Saga;
