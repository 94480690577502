import CurrenMarketPrices from './pages/view';
import HotNewsAdd from './pages/form/basic/index';
export default [
  {
    type: 'private',
    exact: true,
    path: '/current-market-prices',
    roles: ['admin'],
    component: CurrenMarketPrices,
  },
  {
    type: 'private',
    exact: true,
    path: '/current-market-prices/create',
    roles: ['admin'],
    component: HotNewsAdd,
  },

];