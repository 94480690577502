// @ts-nocheck
import { fromJS } from 'immutable';
import {
  GET_ALL_INTERFACESETTINGS_SUCCESS,
} from './constants';

const initialState = fromJS({});

const interfaceSettingsReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {

    case GET_ALL_INTERFACESETTINGS_SUCCESS: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state
        .set('data', payload)
    }

    default:
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state;
  }
};

export default interfaceSettingsReducer;
