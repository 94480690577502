
import { language } from 'utils/formatters'

const normalize = (data: any) => {
  if (!data) return null;

  let cormData = {
    nameEN:  language(data.name, 'en'),
    nameRU: language(data.name, 'ru'),
    shortNameEN: language(data.shortName, 'en'),
    shortNameRU: language(data.shortName, 'ru'),
  };

  return cormData;
};

export default normalize;
