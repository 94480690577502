import { createSelector } from 'reselect';

const selectAuth = (state: any) => state.auth;
const selectUser = (state: any) => state.user;

export const makeSelectToken = () => createSelector(
    selectAuth,
    authState => authState && authState.get('token'),
);

export const makeSelectRoles = () => createSelector(
    selectUser,
    userState => userState?.roles
);
