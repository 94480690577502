// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';
import { logoutRequest } from 'modules/Auth/redux/actions'

import API_CATEGORY from '../api';
import { GET_ALL_AUTHORTYPES_REQUEST, CREATE_NEW_AUTHORTYPES_REQUEST, GET_AUTHORTYPES_BY_ID_REQUEST, EDIT_AUTHORTYPES_REQUEST } from './constants';

function* getAllAuthorTypes({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_CATEGORY.getAllAuthorTypes);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }))
      if (form) yield put(callback({ payload: { response }, meta: { form } }))
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(logoutRequest({}))
    }
    console.log(error.response.status === 401)
    console.log('error', error)
    console.log('error', error)
    console.error('Auth Wrong');
  }
}

function* createNewAuthorTypes({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_CATEGORY.createNewAuthorTypes, { data: fields });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getAuthorTypesById({ payload: { callback, data: { id } }, meta: { form } }: any) {
  try {
    const response = yield call(API_CATEGORY.getByIdAuthorTypes, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* editAuthorTypes({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_CATEGORY.editAuthorTypes, { data: fields });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    console.error(error);
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_ALL_AUTHORTYPES_REQUEST, getAllAuthorTypes),
    takeLatest(CREATE_NEW_AUTHORTYPES_REQUEST, createNewAuthorTypes),
    takeLatest(GET_AUTHORTYPES_BY_ID_REQUEST, getAuthorTypesById),
    takeLatest(EDIT_AUTHORTYPES_REQUEST, editAuthorTypes)
  ]);
}

export default Saga;
