// @ts-nocheck
import React from 'react';
import { change } from 'redux-form'
import { getAllLanguagesRequest } from 'modules/Catalog/Languages/redux/actions';
import Radio from 'components/Inputs/Radio';
import { RadioComponent } from 'components/CheckboxesThree';
import { language } from '../../../../../utils/formatters';
import { getAllcategoriesRequest } from '../../../../Catalog/Categories/redux/actions';
import { getAllAuthorsRequest } from '../../../../Authors/redux/actions';
import { getAllSectorsRequest } from '../../../../Catalog/Sectors/redux/actions';
import { productTypeRequest } from '../../../../Catalog/ProductTypes/redux/actions';
import { getAllCompaniesRequest } from '../../../../Companies/redux/actions';
import { getAllEventsRequest } from '../../../../Calendar/redux/actions';
import { getAllCommoditiesRequest } from '../../../../Catalog/Commodities/redux/actions';
import { getTemplatesRequest } from '../../../../../components/GrapesJs/actions';
import { PRODUCT_OPTION_FORM } from '../../../../../constants/forms';

import { store } from "../../../../../index"
import {CATALOG_COUNTRY_TABLE, CATALOG_EVENTTYPES_TABLE} from "../../../../../constants/tables";

export default (values: any, list: any) => [
  {
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 10,
      className:'col-6-selector'
    },
    type: 'ValueSet',
    id: 'languageId',
    placeholder: 'Select language',
    label: 'Language',
    view: 'filled',
    width: 'available',
    size: 's',
    data: [ 'EN', 'RU' ]
  },
  {
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 10,
      className:'col-6-selector'
    },
    type: 'ValueSet',
    id: 'productTypeId',
    placeholder: 'Select product type',
    label: 'Product type',
    view: 'filled',
    width: 'available',
    size: 's',
    handleOnChange: (props: any, dispatch: any) => {
      if (list && list.length) {
        const categoriesList = list?.find((productType: any) => productType.id === props)
        dispatch(change(PRODUCT_OPTION_FORM, 'categoryId', categoriesList?.categories?.[0]))
      }
    },
    itemOptions: (item: any) => ({ value: item.id, text: language(item.name, 'en') }),
    action: productTypeRequest,
  },
  {
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 10,
      className:'col-6-selector'
    },
    type: 'ValueSet',
    id: 'categoryId',
    placeholder: 'Category',
    label: 'Category',
    view: 'filled',
    width: 'available',
    size: 's',
    filter: (data: any) => {
      if (values && values.productTypeId) {
        const { categories } = list?.find(({ id }: any) => id === values.productTypeId)
        return categories.includes(data.id)
      }
    },
    itemOptions: (item: any) => !item.serviceCategory && item.active && ({ value: item.id, text: language(item.name,'en') }),
    action: getAllcategoriesRequest,
  },
  {
    label: 'Product name',
    fieldWidth: '12',
    customPosition: {
      label: 1,
      field: 11,
      className:'col-11-calendar'
    },
    placeholder: 'Product name',
    type: 'text',
    id: 'name',
    view: 'filled',
    width: 'available',
    size: 's',
    style: {
      marginBottom: '15px',
    },
  },
  {
    type: 'fileSimple',

    typeFile:'pdf',
    id: 'file',
    fieldWidth: '12',
    customPosition: {
      label: 1,
      field: 11,
      className:'col-11-calendar'
    },
    label: 'File',
    disabePreview: true,
    accept: 'application/pdf',
    noFileText: 'Select and upload file',
  },
  {

    fieldWidth: '12',
    customPosition: {
      label: 1,
      field: 11,
      className:'col-11-calendar'
    },
    type: 'checkbox',
    id: 'mark',
    text: 'Mark if link to this product should be displayed in the block of important announcements in the right sidebar',
    label: 'Product mark',
  },
  {
    isGroup: true,
    label: 'Authors',
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 10,
      className:'col-6-selector'
    },
    items: [
      {
        placeholder: 'Authors',
        type: 'ValueSet',
        id: 'authors',
        view: 'filled',
        width: 'available',
        size: 's',
        multiple: true,
        itemOptions: (item: any) => ({ value: item.id, text:language(item.name, 'en')}),
        action: getAllAuthorsRequest,
      },
    ],
  },
  {
    fieldWidth: '6',
    type: 'gridCol',
  },
  {
    type: 'ValueSet',
    id: 'sectors',
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 10,
      className:'col-6-selector'
    },
    placeholder: 'Sector',
    label: 'Sector',
    view: 'filled',
    width: 'available',
    size: 's',
    multiple: true,
    itemOptions: (item: any) => ({ value: item.id, text:language(item.name, 'en')}),
    action: getAllSectorsRequest,
  },
  {
    fieldWidth: '6',
    type: 'gridCol',
  },
  {
    type: 'ValueSet',
    id: 'companies',
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 10,
      className:'col-6-selector'
    },
    placeholder: 'Companies',
    label: 'Companies',
    view: 'filled',
    width: 'available',
    multiple: true,
    size: 's',
    itemOptions: (item: any) => ({ value: item.id, text: language(item.name, 'en') }),
    action: getAllCompaniesRequest,
  },
  {
    fieldWidth: '6',
    type: 'gridCol',
  },
  {
    type: 'ValueSet',
    id: 'commodities',
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 10,
      className:'col-6-selector'
    },
    placeholder: 'Commodity',
    label: 'Commodity',
    view: 'filled',
    multiple: true,
    width: 'available',
    size: 's',
    itemOptions: (item: any) => ({ value: item.id, text: language(item.name, 'en') }),
    action: getAllCommoditiesRequest,
  },
/*
  {
    fieldWidth: '6',
    type: 'gridCol',
  },
  {
    type: 'ValueSet',
    id: 'events',
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 10,
      className:'col-6-selector'
    },
    placeholder: 'Events',
    label: 'Events',
    view: 'filled',
    width: 'available',
    multiple: true,
    size: 's',
    itemOptions: (item: any) => ({ value: item.id, text: language(item.name, 'en') }),
    action: getAllEventsRequest,
  },

 */
  {
    fieldWidth: '6',
    type: 'gridCol',
  },
  {
    type: 'ValueSet',
    id: 'events',
    fieldWidth: '12',
    customPosition: {
      label: 1,
      field: 11,
      className:'col-11-calendar'
    },
    placeholder: 'Event',
    label: 'Event',
    view: 'filled',
    width: 'available',
    multiple: true,
    size: 's',
/*
    itemOptions: (item: any) => ({ value: item.id, text: item.startDate + " " + language(item.name, 'en') + " " +
                                   item.companies[0]}),

 */
    itemOptions: eventOption,

    action: getAllEventsRequest,
  },
];

const eventOption =  (item: any) => {
   const state = store.getState();
//  console.log('eventOption state=', state )

  const valueSets = state.valueSets;
  // console.log('eventOption data=', item.companies[0], valueSets);
  const companies = valueSets.get('companies');
  // console.log('eventOption companies=', companies);

  const graphTable = state.graphTable;
  const countriesMap = graphTable.get(CATALOG_COUNTRY_TABLE);
  const countries = countriesMap?.get('data');
  // console.log('eventOption graphTable=', graphTable, countries);
  const eventTypesMap = graphTable.get(CATALOG_EVENTTYPES_TABLE);
  const eventTypes = eventTypesMap?.get('data');
  // console.log('eventTypes =', eventTypes);
  // console.log('item =', item);

  let companyName = getCompanyName( item.companies,  companies);
  if( companyName &&  item.companies.length > 1 )
    companyName += ',...'

  const companyNameOrCountry = (companyName) ? companyName: getCountryName( item.countryId,  countries);
//  console.log('eventOption companies=', companies);

  return  { value: item.id, text: item.startDate + " " + language(item.name, 'en') + " " +
        companyNameOrCountry + ' ' + getEventTypeName(item.eventTypeId,  eventTypes)}

}

const getCompanyName =  (companyIds: any, companies: any) => {
  const company = companies?.find((company: any) => company.id === companyIds[0])
  if( company ) {
    const companyName = language(company.name, 'en')
    return (companyName) ? companyName : null
  }
  return null

}

const getCountryName =  (countryId: any, countries: any) => {
  if(!countryId) return 'Not present'
  const country = countries?.find((country: any) => country.id === countryId)
  if( country ) {
    const countryName = language(country.name, 'en')
    return (countryName) ? countryName : 'Not present'
  }
  return 'Not present'

}

const getEventTypeName =  (eventTypeId: any, eventTypes: any) => {
  if(!eventTypeId) return 'Not present'
  const eventType = eventTypes?.find((eventType: any) => eventType.id === eventTypeId)
  if( eventType ) {
    const eventTypeName = language(eventType.name, 'en')
    return (eventTypeName) ? eventTypeName : 'Not present'
  }
  return 'Not present'

}

