// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import {compose} from 'redux';
import {reset} from 'redux-form';
import {withRouter} from 'react-router';

import GraphForm from 'components/Graphs/Form';
import {formReset} from 'components/Graphs/Form/actions';
import {tableDataSuccess} from 'components/Graphs/Table/actions';
import {getObjectTrim} from 'utils/formatters';
import {
    getWorkCalendarByIdRequest,
    editWorkCalendarRequest,
    createNewWorkCalendarRequest,
    getAllworkCalendarRequest2,
} from '../../redux/actions';
import normalize from './normalize';
import config from './fields';
import validate from './validate';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import {CATALOG_WORK_CALENDAR_FORM} from 'constants/forms';
import {CATALOG_WORK_CALENDAR_TABLE} from 'constants/tables';
import {connect} from 'react-redux';

interface ICategoryForm {
    title?: string;
    form: string;
    match: any;
}

class WorkCalendarForm extends React.Component<ICategoryForm> {
    // eslint-disable-next-line react/sort-comp
    handleClick = () => {
        const {history, dispatch}: any = this.props;
        dispatch(reset(CATALOG_WORK_CALENDAR_FORM));
        dispatch(
            getAllworkCalendarRequest2({
                meta: {table: CATALOG_WORK_CALENDAR_TABLE, callback: tableDataSuccess},
            }),
        );
        dispatch(formReset({meta: {form: CATALOG_WORK_CALENDAR_FORM}}));
        history.push('/work-calendar');
    };

    get actions() {
        const {
            match: {
                params: {id},
            },
        } = this.props;
        const action = id ? 'Edit this work calendar' : 'Add new work calendar';
        return (
            <GridRow>
                <GridCol>
                    <Button
                        className={'button-float'}
                        view="extra"
                        size="s"
                        type="submit"
                    >
                        {action}
                    </Button>
                    <Button
                        className={'mr20 button-float'}
                        size="s"
                        onClick={this.handleClick}
                    >
                        Cancel
                    </Button>
                </GridCol>
            </GridRow>
        );
    }

    validateBeforeSubmit(year, holiday, movedWorkDay) {
        let data: any = {}
        let errorMessage = ''

        const validDate = (itemIn) => {
            const item = itemIn.trim();
            const index = item.indexOf(".")
            if (index == -1) {
                return {date: null, error: 'Invalid date:' + item}
            }
            let month = item.substr(index + 1)
            if (month.length == 1) month = "0" + month
            let day = item.substr(0, index)
            if (day.length == 1) day = "0" + day
            // console.log(year + "-" + month + "-" + day)
            const date = new Date(year + "-" + month + "-" + day)
            if (date == 'Invalid Date') {
                return {date: null, error: 'Invalid date:' + item, weekend: false}

            }

            return {
                date: year + "-" + month + "-" + day,
                error: '',
                weekend: (date.getDay() == 0 || date.getDay() == 6) ? true : false
            }
        }

        data.holiday = []
        if (holiday) {
            const harr = holiday.split(',')
            for (let i = 0; i < harr.length; i++) {
                const res = validDate(harr[i])
                if (res.error.length > 0) {
                    errorMessage = res.error
                    break
                }
                // выходные субботу/воскресенье не добавляем
                if (res.weekend == false)
                    data.holiday.push(res.date)
            }
        }
        data.movedWorkDay = []
        if (movedWorkDay) {
            const harr = movedWorkDay.split(',')
            for (let i = 0; i < harr.length; i++) {
                const res = validDate(harr[i])
                if (res.error.length > 0) {
                    errorMessage = res.error
                    break
                }
                if (res.weekend == true)
                    data.movedWorkDay.push(res.date)
            }
        }
        if (!year) errorMessage = 'field Year required'
        return {
            data: data,
            validateForm: {
                valid: (errorMessage) ? false : true,
                message: errorMessage
            }
        }

    }

    submit = ({
                  year,
                  holiday,
                  movedWorkDay,
              }: any) => {
        const {
            match: {
                params: {id},
            },
        } = this.props;


        const res = this.validateBeforeSubmit(year, holiday, movedWorkDay)
        if (res.validateForm && res.validateForm.valid == false)
            return res
        else    // в успешном случае надо заполнить возвращаемый объект полями
            return {
                id: id,
                year: year,
                holiday: res.data.holiday,
                movedWorkDay: res.data.movedWorkDay
            }

    };

    render() {
        const {
            match: {
                params: {id},
            },
        } = this.props;
        return (
            <GraphForm
                title="Work calendar form"
                form={CATALOG_WORK_CALENDAR_FORM}
                actions={this.actions}
                onSuccess={this.handleClick}
                graphId={id}
                init={getWorkCalendarByIdRequest}
                edit={editWorkCalendarRequest}
                create={createNewWorkCalendarRequest}
                submit={this.submit}
                validate={(values: object) => validate(getObjectTrim(values))}
                normalize={normalize}
                type={id ? 'edit' : 'create'}
                columns={1}
                config={config}
                width="5"
                reInitialize
            />
        );
    }
}

export default compose<any>(connect(), withRouter)(WorkCalendarForm);
