import {getIdEmail} from "../../../utils";

const normalize = (data: any) => {

  if (!data) return null;
  const previewEditor = data?.deliveryMIFID?.footer?.text?.includes(data?.body?.text) ? data?.deliveryMIFID?.footer?.text :  data?.body?.text + data?.deliveryMIFID?.footer?.text

  let ThreeValues = {} as any
  console.log('normalize data=', data)
  data?.deliveryMIFID?.categoryProductTypeSetting?.forEach((itemC: any) => {
    ThreeValues[itemC.categoryId] = {}
    if (itemC.productIdTypes) {
      itemC.productIdTypes.forEach((types: any) => {
        ThreeValues[itemC.categoryId][types] = true
      })
    }
  })
  if ( Object.keys(ThreeValues).length === 1 && Object.keys(ThreeValues)[0] === 'string') {
    ThreeValues = data?.initProduct
  }


  const fromId = getIdEmail(data?.deliveryMIFID?.from, 'MIFID');

  return {
    ...data,
    mailingListSettingsId: data.mailingListSettingsId,
    subject: data.subject,
    date: data?.deliveryMIFID?.mailingDateTime?.split(' ')[0],
    time: data?.deliveryMIFID?.mailingDateTime?.split(' ')[1],
    checkboxes: ThreeValues,
    plate: data?.deliveryMIFID?.status,
    ['product-editor']: previewEditor,
    emails: fromId
  }
};

export default normalize;
