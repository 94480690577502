import * as ACTION_TYPE from './constants';

export const getAllStatisticsRequest = ({ meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_STATISTICS_REQUEST,
  meta,
});

export const createFilterStatisticRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_FILTER_STATISTIC_REQUEST,
  payload,
  meta,
});


export const getAllTypeStatistic = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_TYPE_STATISTIC,
  meta,
  payload
});


