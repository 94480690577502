// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';

import GraphForm from 'components/Graphs/Form';
import { authRequest } from '../../../redux/actions';
import { CATALOG_CATEGORIES_FORM } from 'constants/forms';

import normalize from './normalize';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';

interface ICategoryForm {
  title?: string;
  type?: string;
}

class LoginForm extends React.Component<ICategoryForm> {
  get actions() {
    return (
      <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
        <GridCol width={12}>
          <Button view="extra" type="submit" width="available">
            Sign in
          </Button>
        </GridCol>
      </GridRow>
    );
  }

  render() {
    const { type, title } = this.props;
    return (
      <GraphForm
        form={CATALOG_CATEGORIES_FORM}
        actions={this.actions}
        create={authRequest}
        title={title}
        normalize={normalize}
        validate={() => {}}
        type={type}
        columns={1}
        width='12'
        config={config}
      />
    );
  }
}

export default LoginForm;
