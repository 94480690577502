import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {
  getAllUsersRoles = () => request({
    url: 'user',
    method: 'GET',
    token: API_GLOBAL.token
  });

  editPasswordUsers = ({ data: { keycloakId, password } }: any) => {
    return request({
      url: `user/change-user-password/id/${keycloakId}?password=${password}`,
      method: 'GET',
      token: API_GLOBAL.token
    })
  }
  //
  getByIdUser = ({ id }: any) => request({
    url: `user/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })

// 2021-09-07 Synchoronization KeyCloak
  getSynchroKeyCloak = () => request({
    url: `user/synchrokeycloak`,
    method: 'GET',
    token: API_GLOBAL.token
  })

}

const API_USERS = new ClassAPI();

export default API_USERS;
