// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';

import GraphForm from 'components/Graphs/Form';
import { formReset } from 'components/Graphs/Form/actions';
import { tableDataSuccess } from 'components/Graphs/Table/actions';
import { getObjectTrim } from 'utils/formatters';

import { getEventByIdRequest, getAllEventsRequest, createNewEventRequest, editEventRequest } from '../../redux/actions';
import normalize from './normalize';
import config from './fields';
import validate from './validate';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { CALENDAR_FORM } from 'constants/forms';
import { CALENDAR_TABLE } from 'constants/tables';
import { LANGUAGE_EN, LANGUAGE_RU } from 'constants/languages';
import { connect } from 'react-redux';

interface ICategoryForm {
  title?: string;
  form: string;
  match: any
}

class CalendarForm extends React.Component<ICategoryForm> {

  handleClick = () => {
    const { history, dispatch }: any = this.props;
    dispatch(reset(CALENDAR_FORM));
    dispatch(getAllEventsRequest({ meta: { table: CALENDAR_TABLE, callback: tableDataSuccess } }));
    dispatch(formReset({ meta: { form: CALENDAR_FORM } }));
    history.push('/calendar');
  };

  get actions() {
    const { match: { params: { id } } } = this.props;
    const action = id ? 'Edit this calendar' : 'Add new calendar';
    return (
      <GridRow>
        <GridCol>
          <Button className={'button-float'} view="extra" size="s" type="submit">
            {action}
          </Button>
          <Button className={'mr20 button-float'} size="s" onClick={this.handleClick}>Cancel</Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = ({ commentRU, commentEN, nameEN, nameRU, country, companies, startDate, endDate, eventTypeId }: any) => {
    const { match: { params: { id } } } = this.props;
    return {
      id,
      countryId: country,
      companies: companies ? companies : [],
      startDate,
      endDate,
      eventTypeId: eventTypeId,
      name: [
        {
          language: { id: LANGUAGE_RU, name: 'Рус' },
          translatedValue: nameRU,
        },
        {
          language: { id: LANGUAGE_EN, name: 'En' },
          translatedValue: nameEN,
        },
      ],
      comment: [
        {
          language: { id: LANGUAGE_RU, name: 'Рус' },
          translatedValue: commentRU,
        },
        {
          language: { id: LANGUAGE_EN, name: 'En' },
          translatedValue: commentEN,
        },
      ],

    };
  };

  render() {
    const { match: { params: { id } } } = this.props;
    return (
      <GraphForm
        form={CALENDAR_FORM}
        actions={this.actions}
        onSuccess={this.handleClick}
        graphId={id}
        init={getEventByIdRequest}
        edit={editEventRequest}
        create={createNewEventRequest}
        title={id ? "Edit event" : "Create event"}
        submit={this.submit}
        validate={(values: object) => validate(getObjectTrim(values))}
        normalize={normalize}
        type={id ? 'edit' : 'create'}
        columns={1}
        config={config}
        // width=""
        reInitialize
      />
    );
  }
}

export default compose<any>(
  connect(),
  withRouter,
)(CalendarForm);
