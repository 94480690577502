import * as ACTION_TYPE from './constants';

export const getAllWidgetsChartsRequest = ({ meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_COMPANIES_WIDGETSCHARTS_REQUEST,
  meta
});

export const getAllSectorChartsRequest = ({ meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_SECTOR_WIDGETSCHARTS_REQUEST,
  meta
});

export const createCompaniesWidgetsChartsRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_COMPANIES_WIDGETSCHARTS_REQUEST,
  payload,
  meta,
});

export const createSectorsWidgetsChartsRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_SECTORS_WIDGETSCHARTS_REQUEST,
  payload,
  meta,
});