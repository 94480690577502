import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {
  //
  getByIdTask = (id: any) => request({
    url: `task/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })
}
const API_TASK = new ClassAPI();

export default API_TASK;
