// @ts-nocheck
import React from 'react';
import {getAllcategoriesRequest} from "../../../../Catalog/Categories/redux/actions";
import {productTypeRequest} from "../../../../Catalog/ProductTypes/redux/actions";
import {language} from "../../../../../utils/formatters";
import {getAllAuthorsRequest} from "../../../../Authors/redux/actions";

export default  [
  {
    type: 'date',
    id: 'dateFrom',
    label: 'From Date',
    view: 'filled',
    width: 'available',
    size: 's',
    fieldWidth: '4',

  },

  {
    type: 'date',
    id: 'dateTo',
    label: 'To Date',
    view: 'filled',
    width: 'available',
    size: 's',
    fieldWidth: '4',
    defaultValue: new Date().toLocaleDateString('et', {day: '2-digit', month: '2-digit', year: 'numeric'}),
  },
  {
    type: 'text',
    id: 'name',
    label: 'Name',
    view: 'filled',
    width: 'available',
    size: 's',
    fieldWidth: '4',

  },
  {
    type: 'ValueSet',
    id: 'productTypeId',
    placeholder: 'Product Type',
    label: 'Product Type',
    view: 'filled',
    width: 'available',
    size: 's',
    // multiple: true,
//    itemOptions: (item: any) => ( { value: item.id, text: item.name}),
    itemOptions: (item: any) => {
      console.log('Product type option', item)
      return  { value: item.id, text: item.name[1].translatedValue}
    },
    action: productTypeRequest,
    fieldWidth: '4',
  },
  {
    type: 'ValueSet',
    id: 'category',
    placeholder: 'Category',
    label: 'Category',
    view: 'filled',
    width: 'available',
    size: 's',
    // multiple: true,
//    itemOptions: (item: any) => ( { value: item.id, text: item.name}),
    itemOptions: (item: any) => {
      return  { value: item.id, text: item.name[1].translatedValue}
    },
    action: getAllcategoriesRequest,
    fieldWidth: '4',
  },
  {
    type: 'ValueSet',
    id: 'analyst',
    placeholder: 'Authors',
    label: 'Authors',
    view: 'filled',
    width: 'available',
    size: 's',
    // multiple: true,
//    itemOptions: (item: any) => ( { value: item.id, text: item.name}),
    itemOptions: (item: any) => {
      return  { value: item.id, text: item.name[1].translatedValue}
    },
    action: getAllAuthorsRequest,
    fieldWidth: '4',
  },
];