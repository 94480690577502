import React from 'react';
import {connect} from 'react-redux';
import {getAllProductsFastRequest} from '../../Products/redux/actions';
import {
    AUTHORS_TABLE,
    CATALOG_PRODUCT_TYPE_TABLE,
    CLIENTS_TABLE,
    COMPANIES_TABLE,
    PRODUCTS_TABLE, CATALOG_WORK_CALENDAR_TABLE,
} from '../../../constants/tables';
import {tableDataSuccess} from '../../../components/Graphs/Table/actions';
import DashboardFilterForm from './form/index';
import DashboardTotal from './dashboardTotal';
import DashboardGraph from './dashboardGraph';
import DashboardBestIndicators from './dashboardBestIndicators';

import {createStructuredSelector} from 'reselect';
import {
    makeSelectAuthor,
    makeSelectClient,
    makeSelectCompany,
    makeSelectDataDashBoard,
    makeSelectDataDashBoardPrev,
    makeSelectProduct,
    makeSelectProductType, makeWorkCalendar,
} from '../redux/selectors';
import {compose} from 'redux';
import {calculateDashboard} from './calculate';
import {productTypeRequest} from '../../Catalog/ProductTypes/redux/actions';
import {getAllAuthorsRequest} from '../../Authors/redux/actions';
import {getAllCompaniesRequest} from '../../Companies/redux/actions';
import {getAllClientsRequest} from '../../Client/redux/actions';
import {getAllWorkCalendarRequest} from "../redux/actions";

class Dashboard extends React.Component {
    componentDidMount() {
        const {dispatch} = this.props;
        // console.log('Dashboard componentDidMount')
        dispatch(
            getAllProductsFastRequest({
                meta: {table: PRODUCTS_TABLE, callback: tableDataSuccess},
            }),
        );
        dispatch(
            productTypeRequest({
                meta: {table: CATALOG_PRODUCT_TYPE_TABLE, callback: tableDataSuccess},
            }),
        );
        dispatch(
            getAllAuthorsRequest({
                meta: {table: AUTHORS_TABLE, callback: tableDataSuccess},
            }),
        );
        dispatch(
            getAllCompaniesRequest({
                meta: {table: COMPANIES_TABLE, callback: tableDataSuccess},
            }),
        );
        dispatch(
            getAllClientsRequest({
                meta: {table: CLIENTS_TABLE, callback: tableDataSuccess},
            }),
        );
// 2022-11-08 add
        dispatch(
            getAllWorkCalendarRequest({
                meta: {table: CATALOG_WORK_CALENDAR_TABLE, callback: tableDataSuccess},
            }),
        );


    }

    render() {
        const {
            graphData,
            graphDataPrev,
            products,
            productTypes,
            authors,
            companies,
            clients,
            workCalendars
        } = this.props;

        // const loading = (graphData) ? graphData.get('loading') : true;

        const dataCur = graphData ? graphData.get('data') : null;
        const dataPrev = graphDataPrev ? graphDataPrev.get('data') : null;
        // console.log('loading = ', loading);

        // console.log('dataCur = ', dataCur);
        // console.log('dataPrev = ', dataPrev);
        const dataPruducts = new Map();

        if (products) {
            products.get('data').forEach((product) => {
                dataPruducts.set(product.id, product);
            });
        }
        const dataPruductTypes = new Map();
        if (productTypes) {
            productTypes.get('data').forEach((productType) => {
                dataPruductTypes.set(productType.id, productType);
            });
        }
        const dataAuthors = new Map();
        if (authors) {
            authors.get('data').forEach((author) => {
                dataAuthors.set(author.id, author);
            });
        }
        // console.log('dataAuthors=', dataAuthors)
        const dataCompanies = new Map();

        if (companies) {
            companies.get('data').forEach((company) => {
                dataCompanies.set(company.id, company);
            });
        }
        // console.log('dataCompanies=', dataCompanies)
        const dataClients = new Map();

        if (clients) {
            clients.get('data').forEach((client) => {
                dataClients.set(client.id, client);
            });
        }
        // console.log('dataClients=', dataClients)
        const dataCatalog = {
            products: dataPruducts,
            productTypes: dataPruductTypes,
            authors: dataAuthors,
            companies: dataCompanies,
            clients: dataClients,
        };

        const workCalendarArr = (workCalendars) ? workCalendars.get('data') : null;

        let data = null;
        if (dataCur != null && dataPrev != null) {
            data = calculateDashboard(workCalendarArr, dataCur, dataPrev, dataCatalog );
        }
        return (
            <>
                <DashboardFilterForm/>
                <div>
                    <DashboardTotal data={data ? data.total : {}}/>
                    <DashboardGraph data={data ? data.range : null}/>
                    <DashboardBestIndicators
                        data={
                            data && dataCatalog
                                ? {
                                    data,
                                    dataCatalog,
                                }
                                : null
                        }
                    />
                </div>
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    graphData: makeSelectDataDashBoard(),
    graphDataPrev: makeSelectDataDashBoardPrev(),
    products: makeSelectProduct(),
    productTypes: makeSelectProductType(),
    authors: makeSelectAuthor(),
    companies: makeSelectCompany(),
    clients: makeSelectClient(),
    workCalendars: makeWorkCalendar(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(Dashboard);
