// @ts-nocheck
import { createSelector } from 'reselect';

const selectGraphFormState = (state: any) => state.graphTable;

export const selectCurrentForm = createSelector(
    [
        selectGraphFormState,
        (state: any, ownProps: any) => ownProps.table,
    ],
    (graphForm, table) => graphForm.get(table),
);

export const makeSelectData = () => createSelector(
    selectCurrentForm,
    state => state && state.get('data'),
);
//--------------------------------------------------
// 2021-10-27 Добавлено из приложения клиент
export const tablePage = () => createSelector(
    selectCurrentForm,
    state => state && state.get('page'),
);

const selectFilterState = (state: any) => state.form;
export const selectCurrentFilter = createSelector(
    [
        selectFilterState,
        (state: any, ownProps: any) => {
            return ownProps.filterForm
        },
    ],
    (graphForm, table) => {
        return graphForm
    },
);
export const filterPage = () => createSelector(
    selectCurrentFilter,
    state => state,
);
//--------------------------------------------------
// 2022-10-05 Добавлено
export const tablePages = () => createSelector(
    selectCurrentForm,
    state => state && state.get('pages'),
);
export const tablePageSize = () => createSelector(
    selectCurrentForm,
    state => state && state.get('pageSize'),
);
