
import Mail from './pages/mail';
export default [
  {
    type: 'private',
    exact: true,
    path: '/mailing-list-settings/main',
    roles: ['admin'],
    component: Mail,
  },
  {
    type: 'private',
    exact: true,
    path: '/mailing-list-settings/main/:id',
    roles: ['admin'],
    component: Mail,
  },
  {
    type: 'private',
    exact: true,
    path: '/mailing-list-settings/header',
    roles: ['admin'],
    component: Mail,
  },
  {
    type: 'private',
    exact: true,
    path: '/mailing-list-settings/header/:id',
    roles: ['admin'],
    component: Mail,
  },
  {
    type: 'private',
    exact: true,
    path: '/mailing-list-settings/body',
    roles: ['admin'],
    component: Mail,
  },
  {
    type: 'private',
    exact: true,
    path: '/mailing-list-settings/body/:id',
    roles: ['admin'],
    component: Mail,
  },
  {
    type: 'private',
    exact: true,
    path: '/mailing-list-settings/footer',
    roles: ['admin'],
    component: Mail,
  },
  {
    type: 'private',
    exact: true,
    path: '/mailing-list-settings/footer/:id',
    roles: ['admin'],
    component: Mail,
  },

];
