// @ts-nocheck
import React from 'react';


const fields = () => {

  const data = [
    {
      isGroup: true,
      label: 'Target price',
      fieldWidth: '12',
      items: [
        {
          placeholder: '',
          type: 'text',
          id: 'targetPrice',
          view: 'filled',
          width: 'available',
          size: 's',
          style: {
            marginBottom: '15px',
          },
        },
      ],
    },
    {
      isGroup: true,
      label: 'Recommendation',
      fieldWidth: '12',
      items: [
        {
          placeholder: 'EN',
          type: 'text',
          id: 'recommendationEN',
          view: 'filled',
          width: 'available',
          size: 's',
          style: {
            marginBottom: '15px',
          },
        },
        {
          placeholder: 'RU',
          type: 'text',
          id: 'recommendationRU',
          view: 'filled',
          width: 'available',
          size: 's',
        },
      ],
    },
    {
      type: 'date',
      id: 'date',
      fieldWidth: '12',
      placeholder: 'DD.MM.YYYY',
      label: 'Date',
      view: 'filled',
      width: 'available',
      size: 's',
    },
  ]

  return data
}

export default fields