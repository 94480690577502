// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';

import API_PRODUCT_TYPE from '../api';
import { GET_PRODUCT_TYPE_REQUEST, CREATE_PRODUCT_TYPE_REQUEST, PRODUCT_TYPE_REQUEST_ID, EDIT_PRODUCT_TYPE } from './constants';
import { createNewAuthorsFailure } from '../../../Authors/redux/actions';
import API_CLIENTS from "../../../Client/api";
import {openSuccessMessage} from "../../../../components/Message/actions";

function* getProductType({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_PRODUCT_TYPE.getProductType);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }))
      if (form) yield put(callback({ payload: { response }, meta: { form } }))
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* createProductType({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_PRODUCT_TYPE.createProductType, { data: fields });
    console.log('create new type response = ', response)
    const newProductTypeId = response.id
    const сategories = response.categories
    console.log('newProductTypeId/сategories = ', response.id, сategories )


// 2024-01-29 Установить доступ и рассылку для клиентов у которых есть доступ к группе
// вынести функциональность в отдельную функцию хотелось бы, но используются генераторы,
// ранее это не получалось так просто сделать
    const response1 = yield call(API_CLIENTS.getAllClients);
    const clients = response1
    // console.log('response clients = ', clients)

    let countChangeProfile = 0

    for( let i=0; i<clients.length; i++ ) {
      yield put(openSuccessMessage('Processed ' + i + " of " + clients.length + " clients"));
      const client = clients[i]
      console.log("Client =", client)

// Добавляем доступ к новой типу, если он есть в категории для этого клиента
      const changedClient = addAccessClient(client, сategories, newProductTypeId)
      if (changedClient) {
        const clientUpdate = yield call(API_CLIENTS.editClientsForSettingDelivery, {data: client});
        console.log("Client update =", clientUpdate)
      }


// работаем с профилем пользователя
      const clientProfile = yield call(API_CLIENTS.getProfileClient, {id:  client.id});
      let changed2 = false

      if (clientProfile && clientProfile.deliveryData) {
        // Добавляем доступ к новой типу, если он есть в категории для этого клиента
        changed2 = addAccessProfile(clientProfile.accessItems, сategories, newProductTypeId)

        clientProfile.deliveryData.forEach((item: any) => {
          addProfileDelivery(item.categories, сategories, newProductTypeId)
        })
      }
      if (changed2 ) {
        yield call(API_CLIENTS.editProfileClient, {data: clientProfile});
        countChangeProfile++
      }
    }

    yield put(openSuccessMessage('Changed ' + countChangeProfile + " from " + clients.length + " clients"));
    console.log("Success change profile " + countChangeProfile + " clients")
// конец Установить доступ и рассылку для клиентов у которых есть доступ к группе

    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function addAccessClient(client: any, categories: any, newProductTypeId: any ) {
  let changed = false
  categories.forEach( (category: any) => {
    const r = client.clientRightSettings
    const categ = r.categories;
    const foundCategory = categ.find( item => item.id === category)
    if( foundCategory ) {
      foundCategory.productTypes.push( newProductTypeId )
      changed = true
    }
  })
  return changed
}

function addAccessProfile(items: any, categories: any, newProductTypeId: any ) {
  let changed = false
  items.forEach( (accessItem: any) => {
    const foundCategory = categories.find( item => item === accessItem.categoryId)
    if( foundCategory ) {
      accessItem.productTypes.push( newProductTypeId )
      changed = true
    }
  })
  return changed
}

function addProfileDelivery(items: any, categories: any, newProductTypeId: any ) {
  let changed = false
  items.forEach( (accessItem: any) => {
    const foundCategory = categories.find( item => item === accessItem.id)
    if( foundCategory ) {
      accessItem.productTypes.push( newProductTypeId )
      changed = true
    }
  })
  return changed
}

function* getProductTypeId({ payload: { callback, data: { id } }, meta: { form } }: any) {
  try {
    const response = yield call(API_PRODUCT_TYPE.getProductTypeId, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* editProductType({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_PRODUCT_TYPE.editProductType, { data: fields });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_PRODUCT_TYPE_REQUEST, getProductType),
    takeLatest(CREATE_PRODUCT_TYPE_REQUEST, createProductType),
    takeLatest(PRODUCT_TYPE_REQUEST_ID, getProductTypeId),
    takeLatest(EDIT_PRODUCT_TYPE, editProductType)
  ]);
}

export default Saga;
