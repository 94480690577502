// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { find, isEmpty } from 'lodash';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import {
  createDeliveryMailingRequest,
  getDeliveryMailingRequest,
  editDeliveryMailingRequest, getProductByIDRequest,
} from '../../../redux/actions';
import normalize from './normalize';
import validate from './validate';
import { config } from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { ANNOTATION_OPTION_FORM } from 'constants/forms';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectAllValueSets,
  selectValueSet,
} from '../../../../../components/ValueSet/selector';
import { Link } from 'react-router-dom';
import { makeSelectRoles } from '../../../../../app/selectors';
import { checkVisibleElement } from '../../../../../constants/roles';
import { setValueSet } from '../../../../../components/ValueSet/actions';

import { blastNote } from '../../../redux/saga';

interface IClientsForm {
  title?: string;
  form: string;
  match: any;
  id: string;
  valueSetsAll: any;
  rolesUser: any;
}

const buildCategorySettings = ({ data, categories, productType }: any) => {
  const arrayCategories = [] as any;
  Object.keys(data).forEach((category: any) => {
    if (!isEmpty(data[category])) {
      const caty = find(categories, ({ id }) => id === category);
      if (caty) {
        caty.productTypes = [];
        Object.keys(data[category]).forEach((product: any) => {
          const productTypes = find(productType, ({ id }) => id === product);
          if (productTypes && data[category][product]) {
            caty.productTypes.push(productTypes);
          }
        });
        arrayCategories.push(caty);
      }
    }
  });
  return arrayCategories;
};

class ClientsForm extends React.Component<IClientsForm> {
  componentDidMount() {
    const { history, dispatch, id }: any = this.props;
    dispatch(getProductByIDRequest({ payload: { callback: setValueSet, data: { id } }, meta: { form: ANNOTATION_OPTION_FORM }}))

    // dispatch(getProductByIDRequest({ meta: { form: ANNOTATION_OPTION_FORM, graphId: id, callback: setValueSet } }));
    //dispatch(getProductByIDRequest({ payload: { callback, data: { id } }, meta: { form }}))
  }

  state = {
    curTime: new Date().toLocaleTimeString().slice(0, -3),
  };
  // handleClick = () => {
  //   const { history, dispatch }: any = this.props;
  //   dispatch(reset(CLIENTS_FORM));
  //   // dispatch(getAllClientsRequest({ meta: { table: CLIENTS_TABLE, callback: tableDataSuccess } }));
  //   dispatch(formReset({ meta: { form: CLIENTS_FORM } }));
  //   history.push('/clients');
  // };

  get actions() {
    const { id } = this.props;
    return (
      <GridRow>
        <GridCol>
          <Link to={`/products/edit/${id}/bulk`}>
            <Button className={'button-float'} view="extra" size="s">
              Go to mailing bulk
            </Button>
          </Link>
          <Button
            className={'mr20 button-float'}
            view="extra"
            size="s"
            type="submit"
          >
            Save mailing
          </Button>
        </GridCol>
      </GridRow>
    );
  }




  submit = (data: any) => {
    const { id } = this.props;
    // blastNore заполняется в action при загрузке продукта и сюда импортитруется
    console.log("submit annotation", this.props, data, blastNote );
    return {
      id: data.id,
      body: {
        id: 'string',
        language: 'string',
        name: 'string',
        text: data['product-editor'],
      },
      createdDateTime: `${data.date} ${data.time}`,
      deliveryACC: {
        categoryProductTypeSetting: [
          {
            categoryId: 'string',
            productIdTypes: ['string'],
          },
        ],
        footer: {
          id: 'string',
          language: 'string',
          name: 'string',
          text: 'string',
        },
        mailingDateTime: `${new Date().toLocaleDateString('et',  { day: '2-digit', month: '2-digit', year: 'numeric' })} ${new Date().toLocaleTimeString()}`,
        recipients: ['string'],
        status: 'DRAFT',
// 2020-01-15 добавление поля отправителя письма
        from: 'test_acc@tecman.ru'
      },
      deliveryMIFID: {
        categoryProductTypeSetting: [
          {
            categoryId: 'string',
            productIdTypes: ['string'],
          },
        ],
        footer: {
          id: 'string',
          language: 'string',
          name: 'string',
          text: 'string',
        },
        mailingDateTime: `${new Date().toLocaleDateString('et',  { day: '2-digit', month: '2-digit', year: 'numeric' })} ${new Date().toLocaleTimeString()}`,
        recipients: ['string'],
        status: 'DRAFT',
        from: ''

      },
      header: {
        id: 'string',
        language: 'string',
        name: 'string',
        text: 'string',
      },
      mailingListSettingsId: data.mailingListSettingsId,
      productId: id,
      annotation: data.annotation,
      subject: data.subject,
      blastNote
    };
  };

  render() {
    const { title, id, rolesUser, valueSetsAll } = this.props;
    const { curTime } = this.state;
    return (
      <GraphForm
        form={ANNOTATION_OPTION_FORM}
        actions={this.actions}
        onSuccess={() => {}}
        graphId={id}
        init={getDeliveryMailingRequest}
        edit={editDeliveryMailingRequest}
        create={createDeliveryMailingRequest}
        title={title}
        submit={this.submit}
        normalize={normalize}
        validate={validate}
        type={'edit'}
        columns={1}
        config={config(curTime, valueSetsAll)}
        width="12"
        reInitialize
      />
    );
  }
}
const mapStateToProps = createStructuredSelector({
  valueSetsAll: selectAllValueSets,
  values: formValueSelector,
  rolesUser: makeSelectRoles(),
});

const withConnect = connect(mapStateToProps);

export default compose<any>(withConnect, withRouter)(ClientsForm);
