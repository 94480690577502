import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {
  getAllPeriodTypes = () => request({
    url: 'catalog/period-type',
    method: 'GET',
    token: API_GLOBAL.token
  });

  createNewPeriodTypes = ({ data }: any) => request({
    url: 'catalog/period-type',
    data,
    method: 'POST',
    token: API_GLOBAL.token
  });

  editPeriodTypes = ({ data }: any) => request({
    url: 'catalog/period-type',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  })

  getByIdPeriodTypes = ({ id }: any) => request({
    url: `catalog/period-type/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })

  getByNamePeriodTypes = ({ id }: any) => request({
    url: `catalog/period-type/by-name/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })
}

const API_SECTOR = new ClassAPI();

export default API_SECTOR;
