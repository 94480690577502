// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import {compose} from 'redux';
import {reset} from 'redux-form';
import {withRouter} from 'react-router';
import GraphForm from 'components/Graphs/Form';
import {formReset} from 'components/Graphs/Form/actions';
import {tableDataSuccess} from 'components/Graphs/Table/actions';
import {
    getDividendByIDRequest,
    editDividendRequest,
    createDividendRequest,
    getAllStocksRequest,
    getAllDividendsRequest,
    getDevidendsByStockIdRequest,
} from '../../../redux/actions';
import normalize from './normalize';
import config from './fields';
import validate from './validate'
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import {DIVIDEND_FORM, RECOMENDATION_FORM} from 'constants/forms';
// import { COMPANIES_TABLE } from 'constants/tables';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectAllValueSets} from 'components/ValueSet/selector';
import {DIVIDENDS_TABLE, STOCKS_TABLE} from '../../../../../constants/tables';
import {closeDialog} from '../../../../../components/Flows/Dialog/actions';

interface ICompaniesForm {
    title?: string;
    form: string;
    match: any
}

class DividendsForm extends React.Component<ICompaniesForm> {
    componentWillUnmount() {
        const {dispatch, id, form}: any = this.props;
        dispatch(reset(DIVIDEND_FORM));
        dispatch(formReset({meta: {form: DIVIDEND_FORM}}));
    }

    handleClick = () => {
        const {dispatch, stockId}: any = this.props;
        dispatch(reset(DIVIDEND_FORM));
        dispatch(formReset({meta: {form: DIVIDEND_FORM}}));
        dispatch(getDevidendsByStockIdRequest({
            meta: {
                table: DIVIDENDS_TABLE,
                graphId: stockId,
                callback: tableDataSuccess
            }
        }));
        dispatch(closeDialog({id: 'table.dividend'}))
    };

    get actions() {
        const {dividendId}: any = this.props;
        const action = dividendId ? 'Edit this dividend' : 'Add new dividend';
        return (
            <GridRow>
                <GridCol>
                    <Button className={'button-float'} view="extra" size="s" type="submit">
                        {action}
                    </Button>
                    <Button className={'mr20 button-float'} size="s" onClick={this.handleClick}>Cancel</Button>
                </GridCol>
            </GridRow>
        );
    }

    submit = (data: any) => {
        const {stockId, id}: any = this.props;
        return {
            id: data.id,
            amount: `${data.amount}`,
            comment: "string",
            companyId: id,
            declarationDate: data.declarationDate,
            periodTypeId: data.periodTypeId,
            recordDate: data.recordDate,
            stockId,
            year: `${data.year}`
        };
    }

    render() {
        const {dividendId}: any = this.props;
        return (
            <div style={{padding: '20px'}}>
                <GraphForm
                    form={DIVIDEND_FORM}
                    title="Dividend form"
                    actions={this.actions}
                    onSuccess={this.handleClick}
                    graphId={dividendId}
                    init={getDividendByIDRequest}
                    create={createDividendRequest}
                    edit={editDividendRequest}
                    submit={this.submit}
                    normalize={normalize}
                    validate={validate}
                    type={dividendId ? 'edit' : 'create'}
                    columns={1}
                    config={config}
                    width="12"
                    reInitialize
                />
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    valueSetsAll: selectAllValueSets,
});

const withConnect = connect(mapStateToProps);

export default compose<any>(
    withConnect,
    withRouter,
)(DividendsForm);
