// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';

import API_CLIENTS from '../api';
import {
  GET_ALL_CLIENTS_REQUEST, CREATE_NEW_CLIENTS_REQUEST, GET_CLIENTS_BY_ID_REQUEST, EDIT_CLIENTS_REQUEST,
  DELETE_CLIENTS_BY_ID_REQUEST, GET_PROFILE_CLIENT, EDIT_PROFILE_CLIENT, SET_SECTORS_CLIENTS_CUSTOM
} from './constants';
import {getAllClientsRequest, editClientsFailure } from './actions';
import { CLIENTS_TABLE } from 'constants/tables';
import { tableDataSuccess } from 'components/Graphs/Table/actions';
import { openErrorMessage, openSuccessMessage } from 'components/Message/actions';

///---------------------------------------------
import { set_global_id } from 'app/index';
import {createNewAuthorsFailure} from "../../Authors/redux/actions";
import API_SECTOR from "../../Catalog/Sectors/api";
import { findIndex } from "lodash";

function* getAllClients({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_CLIENTS.getAllClients);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    openErrorMessage(error)
  }
}

function* createNewClients({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_CLIENTS.createNewClients, { data: fields });
    set_global_id(response.id);
    yield put(openSuccessMessage('Client successfully created.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {

//    console.log('ERROR message=', error)
    // 2021-10-04 Пользователь используется сделать неактивным
    if(  error.response && error.response.data.message ) {
      console.log(error.response.data.message);
//      openErrorMessage(error.response.data.message)
      yield put(createNewAuthorsFailure({ payload: { error } }))
    }
  //  openErrorMessage(error)
  }
}

function* getClientsById({ payload: { callback, data: { id } }, meta: { form } }: any) {
  try {
    const response = yield call(API_CLIENTS.getByIdClients, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    openErrorMessage(error)
  }
}

function* deleteClientsById({ payload: { id }, meta }: any) {
  try {
    yield call(API_CLIENTS.deleteByIdClients, id);
    yield put(openSuccessMessage('Client successfully deleted.'));
    yield put(getAllClientsRequest({ meta: { table: CLIENTS_TABLE, callback: tableDataSuccess } }));
  } catch (error) {
    openErrorMessage(error)
  }
}

function* editClients({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_CLIENTS.editClients, { data: fields });
    yield put(openSuccessMessage('Client successfully edited.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(editClientsFailure({ error }));
    openErrorMessage(error)
  }
}

function* editClientsForSettingDelivery({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_CLIENTS.editClientsForSettingDelivery, { data: fields });
    yield put(openSuccessMessage('Client successfully edited.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(editClientsFailure({ error }));
    openErrorMessage(error)
  }
}


function* getProfileClient({ payload: { callback, data }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_CLIENTS.getProfileClient, data);
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    openErrorMessage(error)
  }
}

function* editProfileClient({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_CLIENTS.editProfileClient, { data: fields });
    yield put(openSuccessMessage('Client successfully changed.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    openErrorMessage(error)
    yield put(editClientsFailure({ error }));
  }
}
/*
* Заказная установка секторов для клиентов по ТЗ 2022
* Сбросить в профиле клиента все сектора, если у клиента установлены все сектора кроме выбранного (SELECTED_SECTOR=Forest)
*
* */
function* setSectorsCustom({ payload: {}, meta: {} }: any) {
  try {
    const SELECTED_SECTOR = 'Forest'

    console.log('setSectorsCustom starts...')
    let response = yield call(API_SECTOR.getAllSectors);
    const sectors = response
    const selectedSectorIdx = findSectorIdByName(sectors, SELECTED_SECTOR)
    if( selectedSectorIdx == -1) {
      console.error('Not found sector ', SELECTED_SECTOR)

    }
    const selectedSectorId = sectors[selectedSectorIdx].id

    // console.log('selectedSectorId = ', selectedSectorId)

    response = yield call(API_CLIENTS.getAllClients);
    const clients = response
    // console.log('response clients = ', clients)

    let countChangeProfile = 0

    for( let i=0; i<clients.length; i++ ) {
      yield put(openSuccessMessage('Calculated ' + i + " from " + clients.length + " clients"));
      console.log("Client #" + i + " from " + clients.length)
      const client = clients[i]
      const clientProfile = yield call(API_CLIENTS.getProfileClient, {id:  client.id});
      let changed = false
      if( clientProfile && clientProfile.deliveryData) {
        clientProfile.deliveryData.forEach( (item: any) => {
          // console.log('item=', item)
          let foundSelected = false
          let sectorCount = 0

          for(let j=0; j<item.sectors.length; j++ ) {
             if( item.sectors[j] == selectedSectorId ) {
               // console.log("!!! sector FOUND")
               foundSelected = true
             }
             else if( item.sectors[j] != 'false') {
               sectorCount++
             }
          }
          if( !foundSelected && sectorCount == sectors.length -1) {
            // !!!очистка списка по заданию!!!
            item.sectors = []
            changed = true
          }
        })
        if( changed ) {
          // console.log("!!! Now will save profile ", clientProfile)
          const result = yield call(API_CLIENTS.editProfileClient, { data: clientProfile });
          // console.log("!!! RESULT save= ", result)
          countChangeProfile++

        }
      }
    }
    yield put(openSuccessMessage('Changed ' + countChangeProfile + " from " + clients.length + " clients"));
    console.log("Success change profile " + countChangeProfile + " clients")


  } catch (error) {
    console.error(error)
    openErrorMessage(error)
  }
}




function* Saga() {
  yield all([
    takeLatest(GET_ALL_CLIENTS_REQUEST, getAllClients),
    takeLatest(CREATE_NEW_CLIENTS_REQUEST, createNewClients),
    takeLatest(GET_CLIENTS_BY_ID_REQUEST, getClientsById),
    takeLatest(EDIT_CLIENTS_REQUEST, editClients),
    takeLatest(DELETE_CLIENTS_BY_ID_REQUEST, deleteClientsById),
    takeLatest(GET_PROFILE_CLIENT, getProfileClient),
    takeLatest(EDIT_PROFILE_CLIENT, editProfileClient),

    takeLatest(SET_SECTORS_CLIENTS_CUSTOM, setSectorsCustom)


  ]);
}

export default Saga;

function findSectorIdByName( sectors: any, nameSector: any) {
  console.log('findSectorByName=', sectors, nameSector )

  return findIndex(sectors, ( obj: any ) => obj.name[0].translatedValue === nameSector
                                             || obj.name[1].translatedValue === nameSector )
}