// @ts-nocheck
import React from 'react';
import Tabs from 'arui-feather/tabs';
import TabItem from 'arui-feather/tab-item';
import { Route, Switch, withRouter } from 'react-router';
import Mail from 'modules/MailingListSettings/pages/mail/index';
import Head from 'modules/MailingListSettings/pages/head/index';
import Body from 'modules/MailingListSettings/pages/body/index';
import Footer from 'modules/MailingListSettings/pages/footer/index';


class MailingListSettingsView extends React.Component<any, any> {
  handleClick = (event: any) => {
    event.preventDefault();
    const { history } = this.props;
    history.push(event.target.getAttribute('href'));
  };

  render() {
    const { location: { pathname }, match: { params: { id} } } = this.props;


    const baseUrl = `/mailing-list-settings`;


    return (
      <>
        <Tabs className={'general-form__tabs'}>
          <TabItem url={`${baseUrl}/main`} onClick={this.handleClick}
                   checked={pathname === `${baseUrl}/main` || pathname === `${baseUrl}/main/${id}`}>
            Main
          </TabItem>
          <TabItem url={`${baseUrl}/header`}
                   onClick={this.handleClick}
                   checked={pathname === `${baseUrl}/header` || pathname === `${baseUrl}/header/${id}`}>
            Header
          </TabItem>

          <TabItem url={`${baseUrl}/body`} onClick={this.handleClick} checked={pathname === `${baseUrl}/body`}>
            Body
          </TabItem>

          <TabItem url={`${baseUrl}/footer`} onClick={this.handleClick} checked={pathname === `${baseUrl}/footer`}>
            Footer
          </TabItem>
        </Tabs>
        <Switch>
          <Route exact path={`${baseUrl}/main`}>
            <Mail id={id}/>
          </Route>

          <Route exact path={`${baseUrl}/main/${id}`}>
            <Mail id={id}/>
          </Route>

          <Route path={`${baseUrl}/header`}>
            <Head id={id}/>
          </Route>
          <Route exact path={`${baseUrl}/header/${id}`}>
            <Head id={id}/>
          </Route>

          <Route path={`${baseUrl}/body`}>
            <Body id={id}/>
          </Route>
          <Route exact path={`${baseUrl}/body/${id}`}>
            <Body id={id}/>
          </Route>

          <Route path={`${baseUrl}/footer`}>
            <Footer id={id}/>
          </Route>

          <Route exact path={`${baseUrl}/footer/${id}`}>
            <Footer id={id}/>
          </Route>
        </Switch>
      </>
    );
  }
}

export default withRouter(MailingListSettingsView);
