const validate = (values: any) => {
  const errors = {} as any

  if (!values.languageId) {
    errors.languageId = 'Required';
  }

  if (!values.productTypeId) {
    errors.productTypeId = 'Required';
  }

  if (!values.categoryId) {
    errors.categoryId = 'Required';
  }

  if (!values.name) {
    errors.name = 'Required';
  }

  // if (!values.published) {
  //   errors.published = 'Required';
  // }

  if (!values.authors) {
    errors.authors = 'Required';
  }


  // if (!values.commodities) {
  //   errors.commodities = 'Required';
  // }

  return errors;
};

export default validate;