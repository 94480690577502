import {
  IMAGE_UPLOAD_REQUEST,
  COMPANIES_UPLOAD_REQUEST,
  SECTOR_UPLOAD_REQUEST,
  PDF_UPLOAD_REQUEST,
  DIVIDENDS_UPLOAD_REQUEST, CURRENT_MARKET_PRICE_UPLOAD_REQUEST, OFZ_UPLOAD_REQUEST, OFZ_DELETE_REQUEST
} from './constants';

export const imageUploadRequest = (data: any) => ({
  type: IMAGE_UPLOAD_REQUEST,
  payload: data,
});

export const pdfUploadRequest = (data: any) => ({
  type: PDF_UPLOAD_REQUEST,
  payload: data,
});

export const companyUploadRequest = (data: any) => ({
  type: COMPANIES_UPLOAD_REQUEST,
  payload: data,
});
export const sectorUploadRequest = (data: any) => ({
  type: SECTOR_UPLOAD_REQUEST,
  payload: data,
});

export const dividendsUploadRequest = (data: any) => ({
  type: DIVIDENDS_UPLOAD_REQUEST,
  payload: data,
});

export const currenMarketPricesUploadRequest = (data: any) => ({
  type: CURRENT_MARKET_PRICE_UPLOAD_REQUEST,
  payload: data,
});

export const oFZUploadRequest = (data: any) => ({
  type: OFZ_UPLOAD_REQUEST,
  payload: data,
});

export const oFZDeleteRequest = (data: any) => ({
  type: OFZ_DELETE_REQUEST,
  payload: data,
});
