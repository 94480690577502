import * as ACTION_TYPE from './constants';

export const getAllUsersRolesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_USERS_ROLES_REQUEST,
  meta,
  payload
});

export const editPasswordUserRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_PASSWORD_USERS_REQUEST,
  payload,
  meta,
})

export const getUsersRolesByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_USERS_ROLES_BY_ID_REQUEST,
  payload,
  meta,
})