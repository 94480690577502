import Categories from './pages/index';

export default [
  {
    type: 'private',
    exact: true,
    path: '/dashboard',
    roles: ['admin'],
    component: Categories,
  },
];
