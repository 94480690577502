import React from 'react';

import GridRow from 'arui-feather/grid-row';
import GridCol from 'arui-feather/grid-col';
import Label from 'arui-feather/label';
import Input from 'arui-feather/input';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { makeSelectInterfaceData } from '../../redux/selector';
import { Field } from 'redux-form';
import { uniqueId } from 'lodash';

const headerRow = [
  { name: '№' },
  { name: 'Name page' },
  { name: 'Table option quality' },
];

class InterfaceSettings extends React.Component {
  makeField = (Component) => ({
    input,
    meta,
    children,
    required,
    hasFeedback,
    id,
    style,
    ...rest
  }) => {
    return (
      <div style={style}>
        <Component
          {...input}
          error={meta.error}
          autocomplete={false}
          {...rest}
          children={children}
        />
      </div>
    );
  };

  render() {
    function colWidth(index) {
      return index === 0 ? '1' : index === 1 ? '' : '2';
    }

    const { values } = this.props;
    return (
      <GridCol width="12">
        <GridRow
          gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
          className="admin-table-header"
        >
          {headerRow.map((categoryHead, index) => (
            <GridCol
              key={index}
              width={colWidth(index)}
              className="admin_table_header_cell"
            >
              <Label size={'m'}> {categoryHead.name} </Label>
            </GridCol>
          ))}
        </GridRow>
        <span className="admin-spacer" />
        {values &&
          Object.keys(values).map((key, index) => (
            <GridRow
              gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
              key={index}
              className="admin-table-row"
            >
              <GridCol width={'1'} className="admin-table-cell">
                {index + 1}
              </GridCol>
              <GridCol className="admin-table-cell">
                {values[key].aliasName}
              </GridCol>
              <GridCol width={'2'} className="admin-table-cell">
                <Field
                  key={uniqueId()}
                  name={`${key}.tableOptions.size`}
                  type="number"
                  component={this.makeField(Input)}
                  view="filled"
                  width="available"
                  size="s"
                />
              </GridCol>
            </GridRow>
          ))}
      </GridCol>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  values: makeSelectInterfaceData(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, withRouter)(InterfaceSettings);
