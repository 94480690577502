// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';

import API_LAWFILES from '../api';
import {
  GET_ALL_LAW_FILE_REQUEST,
  CREATE_NEW_LAW_FILE_REQUEST,
  GET_LAW_FILE_BY_ID_REQUEST,
  EDIT_LAW_FILE_REQUEST,
  DELETE_LAW_FILE_BY_ID_REQUEST

} from './constants';
import {
  getAllLawFileRequest
} from './actions';
import {
  LAW_FILES_TABLE
} from '../../../constants/tables';

import { tableDataSuccess } from '../../../components/Graphs/Table/actions';
import { openSuccessMessage } from '../../../components/Message/actions';
import { createNewAuthorsFailure } from '../../Authors/redux/actions';
import {set_global_id} from "../../../app";


function* getAllLawFile({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_LAWFILES.getAllLawFile);
    console.log('Получены LawFile', response);
    if (callback) {
      console.log('table = ', table);
      if (table) yield put(callback({ payload: { response }, meta: { table } }));
      if (form) yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* createNewLawFile({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_LAWFILES.createNewLawFile, { data: fields });
    // 2021-08-05 сохраним id для дальней шего перехода на эту страницу для редактирования
    set_global_id(response.id);

    yield put(openSuccessMessage('Company successfully created.'));
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getByIdLawFile({ payload: { callback, data: { id } }, meta: { form } }: any) {
  try {
    const response = yield call(API_LAWFILES.getByIdLawFile, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* editLawFile({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_LAWFILES.editLawFile, {data: fields});
    yield put(openSuccessMessage('LawFile successfully edited.'));
    if (callback) {
      yield put(callback({payload: {response}, meta: {form, onSuccess}}));
    }
  } catch (error) {
    yield put(createNewAuthorsFailure({payload: {error}}))
  }
}
function* deleteByIdLawFile({ payload: { id }, meta }: any) {
  try {
    yield call(API_LAWFILES.deleteByIdLawFile, id);
    yield put(getAllLawFileRequest({ meta: { table: LAW_FILES_TABLE, callback: tableDataSuccess } }));
    yield put(openSuccessMessage('Law File successfully deleted.'));
  } catch (error) {
    yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_ALL_LAW_FILE_REQUEST, getAllLawFile),
    takeLatest(CREATE_NEW_LAW_FILE_REQUEST, createNewLawFile),
    takeLatest(GET_LAW_FILE_BY_ID_REQUEST, getByIdLawFile),
    takeLatest(EDIT_LAW_FILE_REQUEST, editLawFile),
    takeLatest(DELETE_LAW_FILE_BY_ID_REQUEST, deleteByIdLawFile),

  ]);
}

export default Saga;
