import { makeRequest, request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api';
import  {getFormattedDate} from 'utils/formatters'
export const API_HOST = process.env.REACT_APP_STORAGE_HOST;


//import queryString from 'query-string'
class ClassAPI {
  UploadImage = (file: any) => {
    var bodyFormData = new FormData();
    bodyFormData.append('file', file, file.name);
    return makeRequest({
      url: `${API_HOST ? API_HOST : ''}/minio/upload/alfaresearch/avatars`,
      method: 'POST',
      data: bodyFormData,
      headers: {
        'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${API_GLOBAL.token}`,
      },
    });
  };

  UploadPdf = (file: any) => {
    var bodyFormData = new FormData();
    bodyFormData.append('file', file[0], file[0].name);
    const CURRENT_YEAR = new Date().getFullYear();
    return makeRequest({
      url: `${API_HOST ? API_HOST : ''}/minio/upload/alfaresearch/${CURRENT_YEAR}`,
      method: 'POST',
      data: bodyFormData,
      headers: {
        'Content-type': 'multipart/form-data',
        Authorization: `Bearer ${API_GLOBAL.token}`,
      },
    });
  };

  uploadCompanyXlsx = (file: any) => {
    var bodyFormData = new FormData();
    bodyFormData.append('fileLoad', file[0], file[0].name);
    bodyFormData.append('loadDate', getFormattedDate(new Date()));
    return request({
      data: bodyFormData,
      url: 'upload/companyGraphLoad',
      method: 'POST',
      token: API_GLOBAL.token,
    });
  };

  UploadSectorXlsx = (file: any) => {
    var bodyFormData = new FormData();
    bodyFormData.append('fileLoad', file[0], file[0].name);
    bodyFormData.append('loadDate', getFormattedDate(new Date()));
    return request({
      data: bodyFormData,
      url: 'upload/sectorGraphLoad',
      method: 'POST',
      token: API_GLOBAL.token,
    });
  };

  UploadDividendsXlsx = (file: any) => {
    var bodyFormData = new FormData();
    bodyFormData.append('fileLoad', file[0], file[0].name);
    bodyFormData.append('loadDate', getFormattedDate(new Date()));
    return request({
      data: bodyFormData,
      url: 'upload/dividendsLoad',
      method: 'POST',
      token: API_GLOBAL.token,
    });
  };

  UploadCurrentMarketPrices = (news: any) => {
    var bodyFormData = new FormData();
    bodyFormData.append('currentMarketPrices', news);
    return request({
      data: bodyFormData,
      url: 'upload/currentMarketPricesLoad',
      method: 'POST',
      token: API_GLOBAL.token,
    });
  };

  UploadOFZ = (data: any, label: any) => request({
    url: 'upload/OFZLoad/' + label,
    data: data,
    method: 'POST',
    token: API_GLOBAL.token
  });

  DeleteOFZ = (label: any) => request({
    url: 'upload/OFZDelete/' + label,
    data: null,
    method: 'DELETE',
    token: API_GLOBAL.token
  });

}

const API_FILES = new ClassAPI();

export default API_FILES;
