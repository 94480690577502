// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import CompanyWidgetsChartsForm from './form/company';
import SectorWidgetsChartsForm from './form/sector';
import CompanyWidgetsChartsTable from './table/company';
import SectorWidgetsChartsTable from './table/sector';
const WidgetsCharts = () => {
  return (
    <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
      <CompanyWidgetsChartsTable />
      {/*<CompanyWidgetsChartsForm />*/}
      <SectorWidgetsChartsTable />
      {/*<SectorWidgetsChartsForm />*/}
    </GridRow>
  );
};

export default WidgetsCharts;