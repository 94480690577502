// @ts-nocheck
import React from 'react';
import Editor from './index'

const renderEditorField = ({ input, id, label, type, required, style, meta: { touched, error }, ...props }: any) => (
  <div style={style}>
    <Editor
      {...input}
      {...props}
      id={id}
      type={type}
      required={required}
      label={label}

      autocomplete={false}
      error={touched ? error : undefined}
    />
  </div>
);

export default renderEditorField;