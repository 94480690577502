// @ts-nocheck
import React from 'react';
import Tabs from 'arui-feather/tabs';
import TabItem from 'arui-feather/tab-item';
import ClientsForm from './form/basic';
import ClientPage from './form/client';
import { Route, Switch, withRouter } from 'react-router';

class ClientsAdd extends React.Component<any, any>{
  handleClick = (event: any) => {
    console.log('Client handle click event=', event)
    event.preventDefault();
    const { history } = this.props
    history.push(event.target.getAttribute('href'))
  }

  render() {
    const { location: { pathname } } = this.props
    const baseUrl = `/clients/create`

    return (
      <>
        <Tabs>
          <TabItem url={baseUrl} onClick={ this.handleClick } checked={ pathname === baseUrl }>
            Basic information
          </TabItem>
          <TabItem disabled={true} url={`${baseUrl}/config`} onClick={ this.handleClick } checked={ pathname === `${baseUrl}/config` }>
            Client page
          </TabItem>
        </Tabs>
        <Switch>
          <Route exact path={baseUrl}>
            <ClientsForm />
          </Route>
          <Route path={`${baseUrl}/config`}>
            <ClientPage />
          </Route>
        </Switch>
      </>
    );
  }
}

export default withRouter(ClientsAdd)