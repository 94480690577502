import { request } from 'api/restClient';

class ClassAPI {
  login = (data: any) => request({
    url: 'keycloak/token',
    method: 'POST',
    data,
  });
}

const API = new ClassAPI();
export default API;
