import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api';

class ClassAPI {
  getAllStaticInfo = () => request({
    url: 'staticinfo',
    method: 'GET',
    token: API_GLOBAL.token,
  });

  createNewStaticInfo = ({ data }: any) => request({
    url: 'staticinfo',
    data,
    method: 'POST',
    token: API_GLOBAL.token,
  });

  editStaticInfo = ({ data }: any) => request({
    url: 'staticinfo',
    data,
    method: 'PUT',
    token: API_GLOBAL.token,
  });

  getByIdStaticInfo = ({ id }: any) => request({
    url: `staticinfo/${id}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  deleteByIdStaticInfo = (id: any) => request({
    url: `staticinfo/${id}`,
    method: 'DELETE',
    token: API_GLOBAL.token,
  });
/////////////////////////////////////////////////////////////////////////////////////
    getAllInfoTypes= () =>   request({
                                 url: 'catalog/info-type',
                                 method: 'GET',
                                 token: API_GLOBAL.token,
   });
}

const API_STATIC_INFO = new ClassAPI();

export default API_STATIC_INFO;
