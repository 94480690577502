import { fromJS } from 'immutable';
import { SET_GRAPES_SET } from './constants';

const initialState = fromJS({});


const grapesReducer = (state = initialState, { type, payload, meta }: any) => {
  switch (type) {
    case SET_GRAPES_SET: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state.set('product-editor', payload);
    }

    default:
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state;
  }
};

export default grapesReducer;
