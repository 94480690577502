// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { find, isEmpty} from 'lodash';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import { formReset } from 'components/Graphs/Form/actions';
import { tableDataSuccess } from 'components/Graphs/Table/actions';
import {
  createNewClientsRequest, getClientsByIdRequest,
  getAllClientsRequest, editClientsRequest,
} from '../../../redux/actions';
import validate from './validate'
import normalize from './normalize';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { CLIENTS_FORM } from 'constants/forms';
import { CLIENTS_TABLE } from 'constants/tables';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectAllValueSets } from 'components/ValueSet/selector';
import { LANGUAGE_EN, LANGUAGE_RU } from 'constants/languages';

///---------------------------------------------
import { get_global_id } from 'app/index';


interface IClientsForm {
  title?: string;
  form: string;
  match: any
  valueSetsAll: any,
  id: string
}

const buildCategorySettings = ({ data, categories, productType }: any) => {
  const arrayCategories = [] as any
  if(data) {
    Object.keys(data).forEach((category: any) => {
      if (!isEmpty(data[category])) {
        const caty = find(categories, ({id}) => id === category)
        let catyCopy = Object.assign({}, caty)
        if (catyCopy) {
          let productTypesObj = [] as any
          Object.keys(data[category]).forEach((product: any) => {
            const productTypes = find(productType, ({id}) => id === product)
            if (productTypes && data[category][product]) {
              productTypesObj.push(productTypes.id)
            }
          })
          arrayCategories.push(Object.assign(catyCopy, {productTypes: productTypesObj}))
        }
      }
    })
  }
  return arrayCategories
}

class ClientsForm extends React.Component<IClientsForm> {
  handleClick = () => {
    const { history, dispatch }: any = this.props;
    console.log('id new record = ', get_global_id());
    dispatch(reset(CLIENTS_FORM));
    dispatch(getAllClientsRequest({ meta: { table: CLIENTS_TABLE, callback: tableDataSuccess } }));
    dispatch(formReset({ meta: { form: CLIENTS_FORM } }));
// c версии 4.1 переходим на вновь всталенную запись
    const id = get_global_id();
    if( id )
        history.push('/clients/edit/' + id);
    else
        history.push('/clients');


/*  Так было до версии 4.1
    dispatch(reset(CLIENTS_FORM));
    dispatch(getAllClientsRequest({ meta: { table: CLIENTS_TABLE, callback: tableDataSuccess } }));
    dispatch(formReset({ meta: { form: CLIENTS_FORM } }));
    history.push('/clients');
*/
  };

  get actions() {
    const { match: { params: { id } }, id: idProps } = this.props;
    const action = id || idProps ? 'Edit this client' : 'Add new client';
    return (
      <GridRow>
        <GridCol>
          <Button className={'button-float'} view="extra" size="s" type="submit">
            {action}
          </Button>
          <Button className={'mr20 button-float'} size="s" onClick={this.handleClick}>Cancel</Button>
        </GridCol>
      </GridRow>
    );
  }
/*
  submit = (data: any) => {
    console.log('client submit data=', data)
    const { valueSetsAll: { categories, productType } } = this.props;
    console.log('client submit categories=', categories, productType)
    return {
      clientRightSettings: {
        categories: buildCategorySettings({ data: data.checkboxes, categories, productType }),
      },
      emails: data.emails,
      id: data.id,
      rusLang: data.rusLang,
      type: data.type,
      engLang: data.engLang,
      name: [
        {
          language: { id: LANGUAGE_RU, name: 'Рус' },
          translatedValue: data.nameRU,
        },
        {
          language: { id: LANGUAGE_EN, name: 'En' },
          translatedValue: data.nameEN,
        },
      ],
    };
  };
*/
  submit = (data: any) => {
    console.log('client submit data=', data)
    const  res = validate(data);
    console.log('result validate=', res)

    const { valueSetsAll: { categories, productType } } = this.props;
    const ret = {
      clientRightSettings: {
        categories: buildCategorySettings({ data: data.checkboxes, categories, productType }),
      },
      emails: data.emails,
      id: data.id,
      rusLang: data.rusLang,
      type: data.type,
      engLang: data.engLang,
      name: [
        {
          language: { id: LANGUAGE_RU, name: 'Рус' },
          translatedValue: data.nameRU,
        },
        {
          language: { id: LANGUAGE_EN, name: 'En' },
          translatedValue: data.nameEN,
        },
      ],
    };
    console.log('client submit ret=', ret)
    return ret;
  };


  render() {
    const { match: { params: { id } }, id: idProps } = this.props;
    return (
        <GraphForm
          form={CLIENTS_FORM}
          actions={this.actions}
          onSuccess={this.handleClick}
          graphId={id || idProps}
          init={getClientsByIdRequest}
          edit={editClientsRequest}
          create={createNewClientsRequest}
          title={'Basic information form'}
          submit={this.submit}
          normalize={normalize}
          validate={validate}
          type={id || idProps ? 'edit' : 'create'}
          columns={1}
          config={config}
          width="12"
          reInitialize
        />
    );
  }
}
const mapStateToProps = createStructuredSelector({
  valueSetsAll: selectAllValueSets,
});

const withConnect = connect(mapStateToProps);

export default compose<any>(
  withConnect,
  withRouter,
)(ClientsForm);
