import * as ACTION_TYPE from './constants';

export const getAllCurrenciesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_CURRENCIES_REQUEST,
  payload,
  meta,
});

export const createNewCurrenciesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_CURRENCIES_REQUEST,
  payload,
  meta,
});

export const editCurrenciesRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_CURRENCIES_REQUEST,
  payload,
  meta,
})

export const getCurrenciesByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_CURRENCIES_BY_ID_REQUEST,
  payload,
  meta,
})