// @ts-nocheck
import React, {Component} from 'react';
import GraphForm from 'components/Graphs/Form';
import GridRow from 'arui-feather/grid-row';
import GridCol from 'arui-feather/grid-col';
import Button from 'arui-feather/button';
import {compose} from 'redux';
import {withRouter} from 'react-router';
import {getProfileClient, editProfileClient, getAllClientsRequest} from '../../../redux/actions';
import {createStructuredSelector} from 'reselect';
import {selectAllValueSets} from '../../../../../components/ValueSet/selector';
import {connect} from 'react-redux';
import {CLIENTS_CONFIG_FORM, CLIENTS_FORM} from '../../../../../constants/forms';
import normalize from './normalize';
import config from './fields';
import {find, isEmpty} from 'lodash';
import {reset} from 'redux-form';
import {CLIENTS_TABLE} from '../../../../../constants/tables';
import {tableDataSuccess} from '../../../../../components/Graphs/Table/actions';
import {formReset} from '../../../../../components/Graphs/Form/actions';

export const buildCategorySettingss = ({data = {}, categories, productType}: any) => {
    const arrayCategories = [] as any
    Object.keys(data).forEach((category: any) => {
        if (!isEmpty(data[category])) {
            let caty = find(categories, ({id}) => id === category)
            let catyCopy = Object.assign({}, caty)
            if (catyCopy) {
                let productTypesObj = [] as any
                Object.keys(data[category]).forEach((product: any) => {
                    const productTypes = find(productType, ({id}) => id === product)
                    if (productTypes && data[category][product]) {
                        productTypesObj.push(productTypes.id)
                    }
                })
                arrayCategories.push(Object.assign(catyCopy, {productTypes: productTypesObj}))
            }
        }
    })
    return arrayCategories
}


class ClientPageConfig extends Component<any, any> {

    handleClick = () => {
        const {history, dispatch}: any = this.props;
        dispatch(reset(CLIENTS_FORM));
        dispatch(getAllClientsRequest({meta: {table: CLIENTS_TABLE, callback: tableDataSuccess}}));
        dispatch(formReset({meta: {form: CLIENTS_FORM}}));
        history.push('/clients');
    };


    get actions() {
        const {match: {params: {id}}, id: idProps} = this.props;
        const action = id || idProps ? 'Edit this client' : 'Add new client';
        return (
            <GridRow>
                <GridCol>
                    <Button className={'button-float'} view="extra" size="s" type="submit">
                        {action}
                    </Button>
                    <Button className={'mr20 button-float'} size="s" onClick={() => {
                    }}>Cancel</Button>
                </GridCol>
            </GridRow>
        );
    }

    submit = (data: any) => {
        const {valueSetsAll: {categories, productType}} = this.props;

        let deliveryData = [] as any
        Object.keys(data.deliveryData).forEach((key) => {
            let sectors = [] as any
            data.deliveryData[key].sectors && Object.keys(data.deliveryData[key].sectors).map((s) => {
                if (data.deliveryData[key].sectors[s] && s != 'true' && s != 'false')
                    sectors.push(s)
            })
            deliveryData.push({
                email: data.deliveryData[key].email,
                engLang: data.deliveryData[key].engLang,
                rusLang: data.deliveryData[key].rusLang,
                attachProduct: data.deliveryData[key].attachProduct,
                categories: buildCategorySettingss({data: data.deliveryData[key].checkboxes, categories, productType}),
//  2022-11-17 изменено, чтобы в результирующем списке списке не было никаких false
//        sectors: data.deliveryData[key].sectors && Object.keys(data.deliveryData[key].sectors).map((sector) => data.deliveryData[key].sectors[sector] && sector),
                sectors
            })
        })

        return {
            clientId: data.clientId,
            id: data.id,
            portalDefaultLanguageId: data.portalDefaultLanguageId,
            deliveryData: deliveryData
        }
    };

    render() {
        const {match: {params: {id}}, id: idProps} = this.props;
        return (
            <GraphForm
                form={CLIENTS_CONFIG_FORM}
                actions={this.actions}
                onSuccess={this.handleClick}
                graphId={id || idProps}
                init={getProfileClient}
                edit={editProfileClient}
                create={() => {
                }}
                title={'Client config form'}
                submit={this.submit}
                normalize={normalize}
                validate={() => {
                }}
                type={id || idProps ? 'edit' : 'create'}
                columns={1}
                config={config}
                width="12"
                reInitialize
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    valueSetsAll: selectAllValueSets,
});

const withConnect = connect(mapStateToProps);

export default compose<any>(
    withConnect,
    withRouter,
)(ClientPageConfig);