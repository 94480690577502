import { GET_TEMPLATES_REQUEST, SET_GRAPES_SET } from './constants';

export const getTemplatesRequest = ({ payload, meta }: any) => ({
  type: GET_TEMPLATES_REQUEST,
  payload,
  meta
});

export const grapesSet = ({ payload, meta }: any) => ({
  type: SET_GRAPES_SET,
  payload,
  meta
});
