import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {

  getAllMailingTypeRequest = () => request({
    url: 'delivery/mailingType',
    method: 'GET',
    token: API_GLOBAL.token
  });

  createNewMailingTypeRequest = ({ data }: any) => request({
    url: 'delivery/mailingType',
    data,
    method: 'POST',
    token: API_GLOBAL.token
  })

  getByIdMailingType = ({ id }: any) => request({
    url: `delivery/mailingType/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })

  editMailingType = ({ data }: any) => request({
    url: 'delivery/mailingType',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  });

  deleteMailingType = (id: any) => {
    return request({
      url: `/delivery/mailingType/${id}`,
      method: 'DELETE',
      token: API_GLOBAL.token
    })
  }



  getAllHeadRequest = () => request({
    url: 'delivery/header',
    method: 'GET',
    token: API_GLOBAL.token
  });

  createNewHeadRequest = ({ data }: any) => request({
    url: 'delivery/header',
    data,
    method: 'POST',
    token: API_GLOBAL.token
  });

  editHeadRequest = ({ data }: any) => request({
    url: 'delivery/header',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  });

  getByIdHead = ({ id }: any) => request({
    url: `delivery/header/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  });

  deleteHead = (id: any) => {
    return request({
      url: `delivery/header/${id}`,
      method: 'DELETE',
      token: API_GLOBAL.token
    })
  }


  getAllBodyRequest = () => request({
    url: 'delivery/body',
    method: 'GET',
    token: API_GLOBAL.token
  });

  createNewBodyRequest = ({ data }: any) => request({
    url: 'delivery/body',
    data,
    method: 'POST',
    token: API_GLOBAL.token
  });

  editBodyRequest = ({ data }: any) => request({
    url: 'delivery/body',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  })

  getByIdBody = ({ id }: any) => request({
    url: `delivery/body/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })

  deleteBody = (id: any) => {
    return request({
      url: `delivery/body/${id}`,
      method: 'DELETE',
      token: API_GLOBAL.token
    })
  }

  getAllFooterRequest = () => request({
    url: 'delivery/footer',
    method: 'GET',
    token: API_GLOBAL.token
  });

  createNewFooterRequest = ({ data }: any) => request({
    url: 'delivery/footer',
    data,
    method: 'POST',
    token: API_GLOBAL.token
  })

  editFooterRequest = ({ data }: any) => request({
    url: 'delivery/footer',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  })

  getByIdFooter = ({ id }: any) => request({
    url: `delivery/footer/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })

  deleteFooter = (id: any) => {
    return request({
      url: `delivery/footer/${id}`,
      method: 'DELETE',
      token: API_GLOBAL.token
    })
  }


  createNewCategory = ({ data }: any) => request({
    url: 'catalog/category',
    data,
    method: 'POST',
    token: API_GLOBAL.token
  })

  editCategory = ({ data }: any) => request({
    url: 'catalog/category',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  })

  getByIdCategory = ({ id }: any) => request({
    url: `catalog/category/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })
}

const API_MAILINGTYPE = new ClassAPI();

export default API_MAILINGTYPE;
