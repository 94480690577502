// @ts-nocheck
import React from 'react';
import { withRouter } from 'react-router-dom';
import GraphTable from 'components/Graphs/Table';
import { ICategoryTable, IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import { TICKERS_TABLE } from 'constants/tables';
import FlowDialog from 'components/Flows/Dialog';
import {
  deleteTickerByIdRequest,
  getAllCompaniesRequest,
  getAllStocksRequest,
  getTickersByCompanyIDRequest,
} from '../../../redux/actions';
import CheckBoxCol from 'components/Flows/Elements/CheckBoxCol';
import StringCol from 'components/Flows/Elements/StringCol';
import { getCurrentCompany, language } from 'utils/formatters';
import IconDelete from 'arui-feather/icon/action/delete';
import { compose } from 'redux';
import Button from 'arui-feather/button';
import { openDialog } from '../../../../../components/Flows/Dialog/actions';
import TickerForm from '../../form/tickers';
import { connect } from 'react-redux';
import IconEdit from 'arui-feather/icon/action/edit/edit';
import { TICKER_FORM } from '../../../../../constants/forms';
import { createStructuredSelector } from 'reselect';
import { selectAllValueSets } from '../../../../../components/ValueSet/selector';
import { getAllStockTypesRequest } from '../../../../Catalog/StockTypes/redux/actions';
import { setValueSet } from 'components/ValueSet/actions';
import { getAllAuthorTypesRequest } from '../../../../Catalog/AuthorTypes/redux/actions';
import { getAllCurrenciesRequest } from '../../../../Catalog/Currencies/redux/actions';


class EquityTable extends React.Component<ICategoryTable> {

  state = {
    ticketId: null,
  };

  componentDidMount() {
    const { dispatch }: any = this.props;
    const actions: any = {
      company: getAllCompaniesRequest,
    }
    if (actions) {
      Object.keys(actions).forEach((key) => {
        dispatch(actions[key]({ meta: { form: key, callback: setValueSet } }));
      });
    }
  }

  normalizeTableData = (head: IHeadTable[], body: IBodyTable[]): any => {
    let processedData = body && body.map((item, i) => {
      const { id, stockId, depoRatio, recommendation, currencyId, name }: any = item;
      let emptyWidth: any = '';

      return {
        id,
        cells: [
          {
            element: <>{StringCol(false, i + 1)} <IconEdit size='m'/></>,
            id,
            type: 'id',
            width: emptyWidth,
          },
          {
            element: name,
            id,
            type: 'name',
            width: emptyWidth,
          },
          {
            element: stockId,
            id,
            type: 'stockId',
            width: emptyWidth,
          },
          {
            element: currencyId,
            id,
            type: 'currencyId',
            width: emptyWidth,
          },
          {
            element: depoRatio,
            id,
            type: 'depoRatio',
            width: emptyWidth,
          },
          {
            element: <IconDelete size={'s'}/>,
            id,
            type: 'delete',
            width: emptyWidth,
            customClass: 'delete-icon',
          }
        ],
      };
    }) || [];


    const normalizeData = processedData.map((val: any) => {
      let newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });

    return normalizeData;
  };

  getRowLink = (idTicker: any) => {
    const { id }: any = this.props;
    return `/companies/edit/${id}/equity/${idTicker}`;
  };

  handleCreateNewTicker = () => {
    const { dispatch }: any = this.props;
    dispatch(openDialog({ id: 'table.ticker' }));
  };

  handleClose = () => {
    this.setState({
      ticketId: null,
    });
  };

  get createDialogs() {
    const { ticketId }: any = this.state;
    const { id, stockId }: any = this.props;
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
      <FlowDialog onClose={this.handleClose} form={TICKER_FORM} id="table.ticker" width="big">
        <TickerForm stockId={stockId} id={id} ticketId={ticketId}/>
      </FlowDialog>
    );
  }

  handleEdit(id: any) {
    const { dispatch }: any = this.props;
    this.setState({ ticketId: id });
    dispatch(openDialog({ id: 'table.ticker' }));
  }

  handleDelete = (dividend: string) => {
    const { dispatch, id }: any = this.props;
    dispatch(deleteTickerByIdRequest({ payload: { id: dividend, stockId: id } }));
  };

  currentCompany = () => {
    const { id, valueSetsAll }: any = this.props;
    const {company} = valueSetsAll
    return company && <div className={'company-block'}>{getCurrentCompany(id, company)}</div>;
  };

  render() {
    const { handleRowClick, id }: any = this.props;
    const head = [
      {
        id: 'id',
        label: '№',
        type: 'edit',
        style: { width: '1' },
      },
      {
        id: 'name',
        label: 'Name',
        style: { width: ' ' },
      },
      {
        id: 'stockId',
        label: 'Stock',
        action: getAllStocksRequest,
        type: 'valueSet',
        style: { width: '' },
      },
      {
        id: 'currencyId',
        label: 'Cur',
        code2displayFunc: ({ name }: any) => name,
        action: getAllCurrenciesRequest,
        type: 'valueSet',
        style: { width: '' },
      },
      {
        id: 'depoRatio',
        label: 'Depo ratio',
        style: { width: ' ' },
      },
      {
        id: 'delete',
        type: 'delete',
        label: '',
        style: { width: '2' },
      },
    ];

    return (
      <>
        {this.currentCompany()}
        <GraphTable
          table={TICKERS_TABLE}
          normalizeTableData={this.normalizeTableData}
          title="Equity tickers"
          graphId={id}
          actions={
            <Button onClick={this.handleCreateNewTicker} className={'button-float'} view="extra" size="s">Create new
              ticker</Button>
          }
          createDialogs={this.createDialogs}
          onDelete={(id: any) => this.handleDelete(id)}
          onEdit={(id: any) => this.handleEdit(id)}
          init={getTickersByCompanyIDRequest}
          onRowClick={handleRowClick}
          head={head}
          width={'12'}
          gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
          activeLink
        />
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  valueSetsAll: selectAllValueSets,
});
const withConnect = connect(mapStateToProps);


export default compose<any>(
  withConnect,
  withRouter,
  connect(),
)(EquityTable);
