// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import ClientsTable from './table';
import { Link } from 'react-router-dom';
import Button from 'arui-feather/button';
import {createNewClientsRequest} from "../redux/actions";

const ClientsView = () => {
  return (
    <GridRow>
        <ClientsTable actions={
        <Link to={'clients/create'}>
          <Button className={'button-float'} view="extra" size="s" type="submit">
            {'Add new client'}
          </Button>
        </Link>
      } />
    </GridRow>
  );
};

export default ClientsView;