export const GET_ALL_USERS_ROLES_REQUEST = 'GET_ALL_USERS_ROLES_REQUEST'
export const GET_ALL_USERS_ROLES_SUCCESS = 'GET_ALL_USERS_ROLES_SUCCESS'
export const GET_ALL_USERS_ROLES_FAILURE = 'GET_ALL_USERS_ROLES_FAILURE'

export const CREATE_NEW_AUTHORS_REQUEST = 'CREATE_NEW_AUTHORS_REQUEST'

export const GET_USERS_ROLES_BY_ID_REQUEST = 'GET_USERS_ROLES_BY_ID_REQUEST'


export const DELETE_AUTHORS_BY_ID_REQUEST = 'DELETE_AUTHORS_BY_ID_REQUEST'

export const EDIT_PASSWORD_USERS_REQUEST = 'EDIT_PASSWORD_USERS_REQUEST'