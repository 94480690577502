// @ts-nocheck
import {call, put, takeLatest, all, takeLeading} from 'redux-saga/effects';

import API_STATIC_INFO from '../api';
import {
    GET_ALL_FOOTER_INFO_REQUEST,
    CREATE_NEW_FOOTER_INFO_REQUEST,
    GET_FOOTER_INFO_BY_ID_REQUEST, EDIT_FOOTER_INFO_REQUEST, DELETE_FOOTER_INFO_BY_ID_REQUEST, GET_ALL_TYPE_INFO

} from './constants';
import {
    getAllFooterInfoRequest
} from './actions';
import {
    FOOTERS_TABLE
} from '../../../constants/tables';

import {tableDataSuccess} from '../../../components/Graphs/Table/actions';
import {openSuccessMessage} from '../../../components/Message/actions';
import {createNewAuthorsFailure} from '../../Authors/redux/actions';
import {set_global_id} from "../../../app";
import {GET_ALL_TYPE_STATISTIC} from "../../Statistics/redux/constants";
import API_CATEGORY from "../../Catalog/AuthorTypes/api";
import {logoutRequest} from "../../Auth/redux/actions";

function* getAllFooterInfo({meta: {form, table, callback}}: any) {
    try {
        console.log('Запрос на получение  Footer', form, table);
        const response = yield call(API_STATIC_INFO.getAllStaticInfo);
        console.log('Получены Footer', response);
        // отфильтруем ненужные строки которые относятся к футеру или контактам
        // сейчас это бегущая строка/ графики ОФЗ
        const responseWithoutRunningStr = response.filter((item: any) => item.type != null && item.type != "hot-news" && item.type.indexOf("ofz") == -1)
        console.log('Отфильтрованы responseWithoutRunningStr', responseWithoutRunningStr);
        if (callback) {
            console.log('table = ', table);
            if (table) yield put(callback({payload: {response: responseWithoutRunningStr}, meta: {table}}));
            if (form)  yield put(callback({payload: {response: responseWithoutRunningStr}, meta: {form}}));
        }
    } catch (error) {
        yield put(createNewAuthorsFailure({payload: {error}}))
    }
}

// сейчас тип Info  - список фиксированных значений -
function* getAllTypeInfo({payload, meta: {form, table, callback}}: any) {
    try {
        // const response = yield call(API_FOOTER.getAllInfoTypes);
        const response = [
             {id: 'footer', name: 'Footer'},
             {id: 'contact-desk', name: 'Contact Desktop'},
             {id: 'contact-mob', name: 'Contact Mobile'},
        ]
        if (callback) {
            if (table) yield put(callback({ payload: { response }, meta: { table } }))
            if (form) yield put(callback({ payload: { response }, meta: { form } }))
        }
    } catch (error) {
        if (error.response.status === 401) {
            yield put(logoutRequest({}))
        }
        console.log(error.response.status === 401)
        console.log('error', error)
        console.log('error', error)
        console.error('Auth Wrong');
    }
}


function* createNewFooterInfo({payload: {callback, fields}, meta: {form, onSuccess}}: any) {
    try {
        if( fields.type == null)  fields.type = 'contact-mob';  // незаполненная запись

        const response = yield call(API_STATIC_INFO.createNewStaticInfo, {data: fields});
        // 2021-08-05 сохраним id для дальней шего перехода на эту страницу для редактирования
        set_global_id(response.id);

        yield put(openSuccessMessage('Footer successfully created.'));
        if (callback) {
            yield put(callback({payload: {response}, meta: {form, onSuccess}}));
        }
    } catch (error) {
        yield put(createNewAuthorsFailure({payload: {error}}))
    }
}

function* getByIdFooterInfo({payload: {callback, data: {id}}, meta: {form}}: any) {
    try {
        console.log('SAGA getByIdFooter');
        const response = yield call(API_STATIC_INFO.getByIdStaticInfo, {id});
        if (callback) {
            return yield put(callback({payload: {response}, meta: {form}}));
        }
    } catch (error) {
        yield put(createNewAuthorsFailure({payload: {error}}))
    }
}

function* editFooterInfo({payload: {callback, fields}, meta: {form, onSuccess}}: any) {
    try {
        const response = yield call(API_STATIC_INFO.editStaticInfo, {data: fields});
        yield put(openSuccessMessage('Footer successfully edited.'));
        if (callback) {
            yield put(callback({payload: {response}, meta: {form, onSuccess}}));
        }
    } catch (error) {
        yield put(createNewAuthorsFailure({payload: {error}}))
    }
}

function* deleteByIdFooterInfo({payload: {id}, meta}: any) {
    try {
        yield call(API_STATIC_INFO.deleteByIdStaticInfo, id);
        yield put(getAllFooterInfoRequest({meta: {table: FOOTERS_TABLE, callback: tableDataSuccess}}));
        yield put(openSuccessMessage('Footer successfully deleted.'));
    } catch (error) {
        yield put(createNewAuthorsFailure({payload: {error}}))
    }
}


function* Saga() {
    yield all([
        takeLatest(GET_ALL_FOOTER_INFO_REQUEST, getAllFooterInfo),
        takeLatest(CREATE_NEW_FOOTER_INFO_REQUEST, createNewFooterInfo),
        takeLatest(GET_FOOTER_INFO_BY_ID_REQUEST, getByIdFooterInfo),
        takeLatest(EDIT_FOOTER_INFO_REQUEST, editFooterInfo),
        takeLatest(DELETE_FOOTER_INFO_BY_ID_REQUEST, deleteByIdFooterInfo),
        takeLeading(GET_ALL_TYPE_INFO, getAllTypeInfo),
    ]);
}

export default Saga;
