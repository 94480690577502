import * as ACTION_TYPE from './constants';

export const getAllInterfaceSettingsRequest = ({ payload, meta }: any) => {
  return ({
    type: ACTION_TYPE.GET_ALL_INTERFACESETTINGS_REQUEST,
    payload,
    meta,
  });
}

export const getAllInterfaceSettingsSuccess = ({ payload, meta }: any) => {
  return ({
    type: ACTION_TYPE.GET_ALL_INTERFACESETTINGS_SUCCESS,
    payload,
    meta,
  });
}

export const updateInterfaceRequest = ({ payload, meta }: any) => {
  return ({
    type: ACTION_TYPE.UPDATE_INTERFACESETTINGS_REQUEST,
    payload,
    meta,
  });
}

export const createNewInterfaceSettingsRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_INTERFACESETTINGS_REQUEST,
  payload,
  meta,
});

export const editInterfaceSettingsRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_INTERFACESETTINGS_REQUEST,
  payload,
  meta,
})