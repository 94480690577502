// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import { formReset } from 'components/Graphs/Form/actions'
import { tableDataSuccess } from 'components/Graphs/Table/actions'
import { getAllInterfaceSettingsRequest, updateInterfaceRequest } from '../../redux/actions';
import normalize from './normalize';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { INTERFACESETTINGS_FORM } from 'constants/forms';
import { INTERFACESETTINGS_TABLE } from 'constants/tables';
import { connect } from 'react-redux';

interface IInterfaceSettingsForm {
  title?: string;
  form: string;
  match: any
}

class InterfaceSettingsForm extends React.Component<IInterfaceSettingsForm> {

  get actions() {
    return (
      <GridRow>
        <GridCol>
          <Button className={"button-float"} view="extra" size="s" type="submit">
            Save
          </Button>
        </GridCol>
      </GridRow>
    )
  }

  submit = (data: any) => {
    if (!data) return null
    const resArray = Object.keys(data).map((key) => {
      data[key].tableOptions.size = Number(data[key].tableOptions.size)
      return data[key]
    })

    return resArray
  };

  render() {
    return (
      <GraphForm
        form={INTERFACESETTINGS_FORM}
        actions={this.actions}
        init={getAllInterfaceSettingsRequest}
        edit={updateInterfaceRequest}
        title="Interface settings"
        submit={this.submit}
        normalize={normalize}
        validate={() => {}}
        type={'edit'}
        columns={1}
        config={config}
        width="12"
        reInitialize
      />
    );
  }
}

export default compose<any>(
  connect(),
  withRouter
)(InterfaceSettingsForm)
