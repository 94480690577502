// @ts-nocheck
import {getStylesForButton} from './styles'
import {IJodit} from "jodit/src/types";
import {URL_DEVIDER} from "../../../../index";

const bodyButton = {
    name: 'Body text',
    icon: 'ol',
    mode: 1,
    list: {
        btb: 'body text bold',
        bt: 'body text',
    },
    template: (editor: any, key: any, value: any) => value,
    exec(editor: any) {
        editor.s.applyStyle(getStylesForButton({ key: this.name }))
    },
};

const titleButton = {
    name: 'Titles',
    icon: 'ol',
    mode: 1,
    list: {
        flr: 'first level red',
        flb: 'first level black',
        slr: 'second level red',
        slb: 'second level black',
    },
    template: (editor: any, key: any, value: any) => value,
    exec(editor: any, control: any, ...args: any) {
        // @ts-ignore
        let valoa = this
        // editor.execCommand('selectall');
        editor.s.applyStyle(getStylesForButton({ key: this.name }))
    },
};

const deviderButton = {
    name: 'Divider',
    tooltip: 'Example2',
    exec: (editor: IJodit) => {
//    editor.s.insertHTML('<img width="100%" height="19" src="http://storage.alfabank.tecman.ru/minio/download/divider/client/divider.png">');
//        editor.s.insertHTML('<img width="100%" height="19" src= "' + URL_DEVIDER + '">');
        editor.s.insertHTML('<hr id="null" color="#eeeeee" size="1px">');
    }
}


export { bodyButton, titleButton, deviderButton};
