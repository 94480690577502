// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import AuthorsForm from './form';

const AuthorsEdit = () => {
  return (
    <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
      <AuthorsForm />
    </GridRow>
  );
};

export default AuthorsEdit;