// @ts-nocheck
import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {change} from 'redux-form/immutable';
import API_FILES from './api';
import {
    COMPANIES_UPLOAD_REQUEST,
    DIVIDENDS_UPLOAD_REQUEST, CURRENT_MARKET_PRICE_UPLOAD_REQUEST,
    IMAGE_UPLOAD_REQUEST, OFZ_UPLOAD_REQUEST,
    PDF_UPLOAD_REQUEST,
    SECTOR_UPLOAD_REQUEST, OFZ_DELETE_REQUEST
} from './constants';
import {getAllSectorChartsRequest, getAllWidgetsChartsRequest} from '../../modules/WidgetsCharts/redux/actions';
import {
    CATALOG_CURRENCIES_TABLE,
    CATALOG_EVENTTYPES_TABLE,
    CATALOG_LANGUAGES_TABLE,
    CATALOG_PERIODTYPES_TABLE,
    COMPANIES_WIDGETSCHARTS_TABLE,
    SECTOR_WIDGETSCHARTS_TABLE, STOCKS_TABLE
} from 'constants/tables';
import {tableDataSuccess} from '../Graphs/Table/actions';
import {openErrorMessage, openSuccessMessage} from '../Message/actions';
import {createNewAuthorsFailure} from '../../modules/Authors/redux/actions';
import API_EVENT from "../../modules/Calendar/api";
import {language} from "../../utils/formatters";
import {readXLSX2} from "../../utils/xlsx";

const MAX_SIZE_FILE_MB = 10
function* uploadImage({payload: {file, id, form}}: any) {
    try {

        if (file.size < (MAX_SIZE_FILE_MB * 1024 * 1024)) {
            const {createdDateTime, fullName, size} = yield call(API_FILES.UploadImage, file);
            yield put(change(form, `${id}.url`, fullName));
            yield put(change(form, `${id}.title`, fullName.split('/').pop()));
            yield put(change(form, `${id}.size`, size));
            yield put(change(form, `${id}.createdDateTime`, createdDateTime));
            yield put(openSuccessMessage('File successfully uploaded.'));
        } else {
            yield put(openErrorMessage('File size must be less than ' + MAX_SIZE_FILE_MB + 'Mb'));
        }

    } catch (e) {
        console.log('upload error', e);
    }
}

function* uploadPdf({payload: {file, id, form}}: any) {
    try {
        const {createdDateTime, fullName, size} = yield call(API_FILES.UploadPdf, file);
        yield put(change(form, id, fullName));
        yield put(change(form, 'size', size));
        yield put(change(form, 'createdDateTime', createdDateTime));
        yield put(openSuccessMessage('File successfully uploaded.'));
    } catch (e) {
        console.log('upload error', e);
    }
}


function* companyUpload({payload: {file, id, form}}: any) {
    try {
        const {createdDateTime, fullName, size} = yield call(API_FILES.uploadCompanyXlsx, file);
        yield put(change(form, id, fullName));
        yield put(change(form, 'size', size));
        yield put(change(form, 'createdDateTime', createdDateTime));
        yield put(getAllWidgetsChartsRequest({
            meta: {
                table: COMPANIES_WIDGETSCHARTS_TABLE,
                callback: tableDataSuccess,
            },
        }));
        yield put(openSuccessMessage('File successfully uploaded.'));
    } catch (error) {
        yield put(createNewAuthorsFailure({payload: {error}}))
    }
}

function* sectorUpload({payload: {file, id, form}}: any) {
    try {
        const data = yield call(API_FILES.UploadSectorXlsx, file);
        yield put(change(form, id, data));
        yield put(getAllSectorChartsRequest({
            meta: {
                table: SECTOR_WIDGETSCHARTS_TABLE,
                callback: tableDataSuccess,
            },
        }));
        yield put(openSuccessMessage('File successfully uploaded.'));
    } catch (error) {
        yield put(createNewAuthorsFailure({payload: {error}}))
    }
}

function* dividendsUpload({payload: {file, id, form}}: any) {
    try {
        console.log('dividendsUpload start', form, id);

 /* пример использхования веншнего пакета, обернутого в промис
        const linesXLS = readXLSX2(file).then(res=> {
            console.log('result=', res)
        }) ;
        const linesXLS = yield call( readXLSX2, file);
        console.log('Lines XLS=', linesXLS);

 */

        const data = yield call(API_FILES.UploadDividendsXlsx, file);

        yield put(change(form, id, data));

        console.log('dividendsUpload success form/id/data=', form, id, data);
        yield put(openSuccessMessage('File successfully uploaded.'));

// 2021-09-28 После загрузки дивидентов создать соотвествующее событие
        if ( 1 ) {
            const state = yield select();
            const { engLanguageId, rusLanguageId, eventDividendsId, periods, currencies, stocks } = getDataFromState( state );

            console.log("!!!!!RESULT =",  engLanguageId, rusLanguageId, eventDividendsId, periods, currencies, stocks)


            if (engLanguageId && rusLanguageId && eventDividendsId && periods.length > 0 && currencies.length > 0 &&
                stocks.length > 0  ) {
                console.log("!!!!! ADD event")
                for(let i=0; i< data.length; i++) {
                    const dividend = data[i]
                    console.log('Dividend = ', dividend)
                    // const comment = buildComment(periods, stocks, currencies, dividend.periodTypeId, dividend.year, dividend.stockId, dividend.amount)
                    // console.log('Comment = ', comment)
                    // два события на каждуй запись дивидедов
                    let eventFields = {
                        companies: [dividend.companyId],
                        startDate: dividend.recordDate,
                        endDate: null,
                        eventTypeId: eventDividendsId,
                        name: [{
                            language: {id: rusLanguageId, name: "Рус"},
                            translatedValue: "Дата закрытия реестра"
                        },
                            {
                                language: {id: engLanguageId, name: "En"},
                                translatedValue: "Record date"}],
                        comment: [{
                            language: {id: rusLanguageId, name: "Рус"},
                            translatedValue: buildComment(periods, stocks, currencies, dividend.periodTypeId, dividend.year, dividend.stockId, dividend.amount)
                        }, {
                            language: {id: engLanguageId, name: "En"},
                            translatedValue: buildComment(periods, stocks, currencies, dividend.periodTypeId, dividend.year, dividend.stockId, dividend.amount)
                        }]
                    }
                    console.log("before create event 1", eventFields)
                    let response = yield call(API_EVENT.createNewEvents, {data: eventFields});
                    yield put(openSuccessMessage('Event successfully created.'));

                    eventFields = {
                        companies: [dividend.companyId],
                        startDate: dividend.declarationDate,
                        endDate: null,
                        eventTypeId: eventDividendsId,
                        name: [{
                            language: {id: rusLanguageId, name: "Рус"},
                            translatedValue: "Дата объявления"
                        },
                            {
                                language: {id: engLanguageId, name: "En"},
                                translatedValue: "Declaration date"}],
                        comment: [{
                            language: {id: rusLanguageId, name: "Рус"},
                            translatedValue: buildComment(periods, stocks, currencies, dividend.periodTypeId, dividend.year, dividend.stockId, dividend.amount)
                        }, {
                            language: {id: engLanguageId, name: "En"},
                            translatedValue: buildComment(periods, stocks, currencies, dividend.periodTypeId, dividend.year, dividend.stockId, dividend.amount)
                        }]
                    }
                    console.log("before create event 2", eventFields)
                    response = yield call(API_EVENT.createNewEvents, {data: eventFields});
                    yield put(openSuccessMessage('Event successfully created.'));


                }
            } else {

            }
        }

    } catch (error) {
        yield put(createNewAuthorsFailure({payload: {error}}))
    }
}

// 2022-10-19 Загрузка Current market prices
// Для разнообразия, разберу XLS файл здесь, на фронте
function* currenMarketPricesUpload({payload: {file, id, form}}: any) {
        if(!file) return
        try {
            console.log('currenMarketPricesUpload start', form, id);


            /* пример использования внешнего пакета, обернутого в промис
                   const linesXLS = readXLSX2(file).then(res=> {
                       console.log('result=', res)
                   }) ;
            */
           const linesXLS = yield call( readXLSX2, file);
           console.log('Lines XLS=', linesXLS);
           const line = mergeHotNews(linesXLS)
            console.log('Line result =', line);
            const data = yield call(API_FILES.UploadCurrentMarketPrices, line);
            yield put(openSuccessMessage('Data successfully uploaded.'));
        } catch (error) {
            yield put(createNewAuthorsFailure({payload: {error}}))
        }
}

// 2022-11-01 Обработка массива строк XLS ОФЗ перед отправкой на сервер
function normalizeOFZ(lines: any) {
    let counter = 0
    let items: any = []
    let nameEn: any = ''
    let nameRu: any = ''
    lines.forEach( (line: any) => {
        if(counter === 0) {
            nameRu = line[0]
            nameEn = line[1]
        }
        else if( counter > 1) {
            let elem = null
            for( let i = 0; i < 3; i++) {
                elem = line[i];
                switch(i) {
                    case 2:
                        line[i]= String(elem)
                        break;
                    default:
                }
            }
            const obj = {
                x: line[0],
                y: line[1],
                text: line[2]
            }
            items.push(obj)
        }
        counter++;
    })
    return {
        nameEn,
        nameRu,
        ofzPoints: items,
    }

}

// разберу XLS файл здесь, на фронте
function* oFZUpload({payload: {file, id, form}}: any) {
    if(!file) return
    try {
        console.log('oFZUpload start', form, id);
        let label: any = ""

        switch (id) {
            case "OFZ1Xlsx":
                label = "ofz1"
                break
            case "OFZ2Xlsx":
                label = "ofz2"
                break
            default:
                break
        }
        const linesXLS = yield call( readXLSX2, file);
        const ofzUploadObject = normalizeOFZ(linesXLS)
        console.log('ofzUploadObject =', ofzUploadObject);
// Отладка
        const data = yield call(API_FILES.UploadOFZ, ofzUploadObject, label);
        yield put(openSuccessMessage('Data successfully uploaded.'));
    } catch (error) {
        yield put(createNewAuthorsFailure({payload: {error}}))
    }
}

// 2023-10-12 Очистка графиков - (удаление соответствующей записи в staticinfo )
function* oFZDelete({payload: { id }}: any) {
    try {
        console.log('oFZUpload delete', id);
        let label: any = ""

        switch (id) {
            case "OFZ1Xlsx":
                label = "ofz1"
                break
            case "OFZ2Xlsx":
                label = "ofz2"
                break
            default:
                break
        }
        const data = yield call(API_FILES.DeleteOFZ, label);
        yield put(openSuccessMessage('Data successfully deleted.'));
    } catch (error) {
        yield put(createNewAuthorsFailure({payload: {error}}))
    }
}






function* Saga() {
    yield all([takeEvery(IMAGE_UPLOAD_REQUEST, uploadImage)]);
    yield all([takeEvery(PDF_UPLOAD_REQUEST, uploadPdf)]);
    yield all([takeEvery(COMPANIES_UPLOAD_REQUEST, companyUpload)]);
    yield all([takeEvery(SECTOR_UPLOAD_REQUEST, sectorUpload)]);
    yield all([takeEvery(DIVIDENDS_UPLOAD_REQUEST, dividendsUpload)]);
    yield all([takeEvery(CURRENT_MARKET_PRICE_UPLOAD_REQUEST, currenMarketPricesUpload)]);
    yield all([takeEvery(OFZ_UPLOAD_REQUEST, oFZUpload)]);
    yield all([takeEvery(OFZ_DELETE_REQUEST, oFZDelete)]);
}
/*
  2021-09-30 Дополнительные функции для автогенерации событий после загрузки дивидендов
 */
function buildComment(periods: any, stocks: any, currencies: any,  periodId: any, year: any, stockId: any, amount:any) {
    const stock = stocks.find((stockItem: any) => stockItem.id === stockId)
    if( !stock ) {
        console.error("Stock id=" + stockId + " not found" )
        return null;
    }
    const currency= currencies.find((currencyItem: any) => currencyItem.id === stock.currencyId)
    if( !currency ) {
        console.error("Currency id=" + stock.currencyId + " not found" )
        return null;
    }

    const period = periods.find((periodItem: any) => periodItem.id === periodId)
    if( !period ) {
        console.error("Period id=" + periodId + " not found" )
        return null;
    }
    return language(period.name, 'en') + String(year).substr(2) + ' ' + currency.name + ' ' + amount;
}
/* Возврат из хранилища необходимых таблиц и кодов
* */
function getDataFromState(state: any) {
    const graphTableDate = state.graphTable &&
        state.graphTable.get(CATALOG_LANGUAGES_TABLE)
    // console.log("Language graphTableDate=", graphTableDate)
    const languages = graphTableDate?.get('data')
    // console.log("Language languages=", languages)
    let engLanguageId = '';
    let rusLanguageId = '';
    languages.forEach((language: any) => {
        // console.log("language ", language)
        if (language.name.toUpperCase() == 'EN')
            engLanguageId = language.id
        else if (language.name.toUpperCase() == 'RU')
            rusLanguageId = language.id
    })
    // console.log("Language languages=", engLanguageId, rusLanguageId)

    let eventDividendsId = '';
    const graphTableDateEventTypes = state.graphTable &&
        state.graphTable.get(CATALOG_EVENTTYPES_TABLE)
    const eventTypes = graphTableDateEventTypes?.get('data')
    eventTypes.forEach((eventType: any) => {
        // console.log("eventType ", eventType)
        eventType.name.forEach((name: any) => {
            // console.log("name ", name)
            if (name.translatedValue.toUpperCase() == 'DIVIDENDS')
                eventDividendsId = eventType.id
        })
    })
    const graphTablePeriods = state.graphTable &&
        state.graphTable.get(CATALOG_PERIODTYPES_TABLE)
    // console.log("PeriodType graphTableDate=", graphTablePeriods)
    const periods = graphTablePeriods?.get('data')
    // console.log("Periods=", periods)

    const graphTableCurrencies = state.graphTable &&
        state.graphTable.get(CATALOG_CURRENCIES_TABLE)
    // console.log("Currency graphTableDate=", graphTableCurrencies)
    const currencies = graphTableCurrencies?.get('data')
    // console.log("currencies=", currencies)

    const graphTableStocks = state.graphTable &&
        state.graphTable.get(STOCKS_TABLE)
    // console.log("Stocks graphTableDate=", graphTableStocks)
    const stocks = graphTableStocks?.get('data')
    // console.log("stocks=", stocks)

    // console.log("RESULT =", engLanguageId, rusLanguageId, eventDividendsId, periods, currencies)
    return { engLanguageId, rusLanguageId, eventDividendsId, periods, currencies, stocks }
};

/*
 * Склейка новостей в одну строку
 */
function mergeHotNews_Base( lines:any) {
    let counter = 0
    let res = ''
    lines.forEach( (line: any) => {
       if( counter++ != 0) {
           line.forEach( (elem: any) => {
              console.log('elem = ', elem)
              res += (elem + " ")
           })
       }
       res += "\u2000\u2000\u2000"
    })
    return res;
}

function mergeHotNews( lines:any) {
    let counter = 0
    let res = ''
    lines.forEach( (line: any) => {
        if( counter++ != 0) {
            for( let i = 0; i < 4; i++) {
                let elem = line[i];
                switch(i) {
                    case 3:
                        res += ((elem * 100).toFixed(2) + "% ")
                        break;
                    default:
                        res += (elem + " ")
                }

            }
        }
        res += "\u2000\u2000\u2000"
    })
    return res;
}



export default Saga;
