export default [
  {
    type: 'group',
    opened: false,
    content: 'Catalog',
    url: 'catalog',
    submenu: [
      {
        type: 'item',
        content: 'Categories',
        value: '0',
        props: {
          url: '/categories'
        }
      },
      {
        type: 'item',
        content: 'Product types',
        value: '1',
        props: {
          url: '/product-types'
        }
      },
      {
        type: 'item',
        content: 'Sectors',
        value: '2',
        props: {
          url: '/sectors'
        }
      },
      {
        type: 'item',
        content: 'Commodities',
        value: '3',
        props: {
          url: '/commodities'
        }
      },
      {
        type: 'item',
        content: 'Countries',
        value: '4',
        props: {
          url: '/countries'
        }
      },
      {
        type: 'item',
        content: 'Currencies',
        value: '5',
        props: {
          url: '/currencies'
        }
      },
      {
        type: 'item',
        content: 'Period types',
        value: '6',
        props: {
          url: '/period-types'
        }
      },
      {
        type: 'item',
        content: 'Stock types',
        value: '7',
        props: {
          url: '/stock-types'
        }
      },
      {
        type: 'item',
        content: 'Author types',
        value: '8',
        props: {
          url: '/author-types'
        }
      },
      {
        type: 'item',
        content: 'Event types',
        value: '9',
        props: {
          url: '/event-types'
        }
      },
      {
        type: 'item',
        content: 'Languages',
        value: '10',
        props: {
          url: '/languages'
        }
      },
      {
        type: 'item',
        content: 'Work calendar',
        value: '61',
        props: {
          url: '/work-calendar'
        }
      },

    ]
  },
  {
    type: 'item',
    content: 'Law files',
    value: '20',
    props: {
      url: '/lawfile'
    }
  },
  {
    type: 'item',
    content: 'Emails',
    value: '71',
    props: {
      url: '/email'
    }
  },
  {
    type: 'item',
    content: 'Footer/Contacts',
    value: '21',
    props: {
      url: '/footerinfo'
    }
  },
  {
    type: 'item',
    content: 'Interface settings',
    value: '11',
    props: {
      url: '/interface-settings'
    }
  },
  {
    type: 'item',
    content: 'Mailing list settings',
    value: '12',
    props: {
      url: '/mailing-list-settings/main'
    }
  },
  {
    type: 'item',
    content: 'Users/Roles',
    value: '13',
    props: {
      url: '/users-roles'
    }
  },
  {
    type: 'item',
    content: 'Clients',
    value: '14',
    props: {
      url: '/clients'
    }
  },
  {
    type: 'item',
    content: 'Widgets/Charts',
    value: '15',
    props: {
      url: '/widgets-charts'
    }
  },
  {
    type: 'item',
    content: 'Current market prices/OFZ',
    value: '51',
    props: {
      url: '/current-market-prices/create'
    }
  },
  {
    type: 'item',
    content: 'Authors',
    value: '16',
    props: {
      url: '/authors'
    }
  },
  {
    type: 'item',
    content: 'Companies',
    value: '17',
    props: {
      url: '/companies'
    }
  },
  {
    type: 'item',
    content: 'Products',
    value: '18',
    props: {
      url: '/products'
    }
  },
  {
    type: 'item',
    content: 'Calendar',
    value: '19',
    props: {
      url: '/calendar'
    }
  },
  {
    type: 'group',
    opened: false,
    content: 'Analytics',
    url: 'analytics',
    submenu: [
      {
        type: 'item',
        content: 'Statistics',
        value: '21',
        props: {
          url: '/statistics'
        }
      },
      {
        type: 'item',
        content: 'Dashboard',
        value: '22',
        props: {
          url: '/dashboard'
        }
      },
    ]
  },
];
