import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {
  getAllClients = () => request({
    url: 'client',
    method: 'GET',
    token: API_GLOBAL.token
  });

  createNewClients = ({ data }: any) => request({
    url: 'client',
    data,
    method: 'POST',
    token: API_GLOBAL.token
  });

  editClients = ({ data }: any) => request({
    url: 'client',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  })

  editClientsForSettingDelivery = ({ data }: any) => request({
    url: 'client/nokeycloak',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  })



  getByIdClients = ({ id }: any) => request({
    url: `client/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })

  deleteByIdClients = (id: any) => request({
    url: `client/${id}`,
    method: 'DELETE',
    token: API_GLOBAL.token
  })

  getByNameClients = ({ id }: any) => request({
    url: `author/by-name/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })

  getProfileClient = ({ id }: any) => request({
    url: `client/profile/client/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })

  editProfileClient = ({ data }: any) => request({
    url: `client/profile`,
    method: 'PUT',
    data,
    token: API_GLOBAL.token
  })

/*
  editClientsNoKeyCloak = ({ data }: any) => request({
    url: 'client/nokeycloak',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  })
*/
}




const API_CLIENTS = new ClassAPI();

export default API_CLIENTS;
