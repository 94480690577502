import * as ACTION_TYPE from './constants';
import {CREATE_FILTER_PRODUCT_REQUEST} from "./constants";

export const getAllProductsRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_PRODUCTS_REQUEST,
  meta,
  payload
});

// add 2021-11-01
export const getAllProductsFastRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_PRODUCTS_FAST_REQUEST,
  meta,
  payload
});


export const deleteCompanyByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.DELETE_COMPANY_BY_ID_REQUEST,
  payload,
  meta,
})

export const getProductByIDRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_PRODUCT_BY_ID_REQUEST,
  payload,
  meta,
})

export const editProductByIDRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_PRODUCT_REQUEST,
  payload,
  meta,
})

export const createProductRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_PRODUCT_REQUEST,
  payload,
  meta,
});

export const getDeliveryMailingRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_DELIVERY_MAILING_REQUEST,
  payload,
  meta,
});

export const getDeliveryMailingBulkRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_DELIVERY_MAILING_BULK_REQUEST,
  payload,
  meta,
});

export const getDeliveryMailingCyprusRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_DELIVERY_MAILING_CYPRUS_REQUEST,
  payload,
  meta,
});

export const createDeliveryMailingRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_DELIVERY_MAILING_REQUEST,
  payload,
  meta,
});

export const editDeliveryMailingRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_DELIVERY_MAILING_REQUEST,
  payload,
  meta,
});

export const activateMailingRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.ACTIVATE_MAILING_REQUEST,
  payload,
  meta,
});

// 2022-10-04
export const createFilterProductRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_FILTER_PRODUCT_REQUEST,
  payload,
  meta,
});
// add 2022-10-05
export const emptyAction = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EMPTY_ACTION,
  meta,
  payload
});

