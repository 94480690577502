// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import MailForm from 'modules/MailingListSettings/pages/mail/form';
import MailTable from 'modules/MailingListSettings/pages/mail/table';

const Mail = ({id}: any) => {
  return (
    <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
      <MailTable />
      <MailForm idProps={id}/>
    </GridRow>
  );
};

export default Mail;