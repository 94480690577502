// @ts-nocheck
import React from 'react'
import { withRouter, Redirect, Route, Switch } from 'react-router';
import TabItem from 'arui-feather/tab-item';
import Options from './forms/options'
import Annotation from './forms/annotation'
import Tabs from 'arui-feather/tabs';

class CreatePage extends React.Component<any, any>{
  handleClick = (event: any) => {
    event.preventDefault();
    const { history } = this.props
    history.push(event.target.getAttribute('href'))
  }
  render() {
    const { location: { pathname }, match: { params: { id } } } = this.props
    const baseUrl = `/products/add`
    return (
      <>
        <Tabs className={'general-form__tabs'}>
          <TabItem url={baseUrl} onClick={ this.handleClick } checked={ pathname === baseUrl }>
            Product options
          </TabItem>
          <TabItem disabled={true} url={`${baseUrl}/annotation`} onClick={ this.handleClick } checked={ pathname === `${baseUrl}/annotation` }>
            Annotation and mailing options
          </TabItem>
          <TabItem disabled={true} url={`${baseUrl}/bulk`} onClick={ this.handleClick } checked={ pathname === `${baseUrl}/bulk` }>
            Bulk mailing
          </TabItem>
          <TabItem disabled={true} url={`${baseUrl}/cyprus`} onClick={ this.handleClick } checked={ pathname === `${baseUrl}/cyprus` }>
            CYPRUS mailing
          </TabItem>
        </Tabs>
        <Switch>
          <Route exact path={baseUrl}>
            <Options />
          </Route>
          <Route path={`${baseUrl}/annotation`}>
            <Annotation />
          </Route>
          <Route path={`${baseUrl}/bulk`}>
            <div>false</div>
          </Route>
          <Route path={`${baseUrl}/cyprus`}>
            <div>falfddfgdfgse</div>
          </Route>
        </Switch>
      </>
    );
  }
}

export default withRouter(CreatePage)