// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import BodyForm from 'modules/MailingListSettings/pages/body/form';
import BodyTable from 'modules/MailingListSettings/pages/body/table';

const Body = ({id}: any) => {
  return (
    <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
      <BodyTable/>
      <BodyForm idProps={id}/>
    </GridRow>
  );
};

export default Body;