// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { find, isEmpty } from 'lodash';
import { compose } from 'redux';

import { selectAllValueSets } from 'components/ValueSet/selector';

import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import {
  createDeliveryMailingRequest,
  editDeliveryMailingRequest,
  activateMailingRequest,
  getDeliveryMailingCyprusRequest,
} from '../../../redux/actions';
import normalize from './normalize';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { BULK_OPTION_FORM } from 'constants/forms';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { checkVisibleElement } from '../../../../../constants/roles';
import { makeSelectRoles } from '../../../../../app/selectors';

// 2021-31-05
import { blastNote } from '../../../redux/saga';
import {getEmailById} from "../../../utils";

interface IClientsForm {
  title?: string;
  form: string;
  match: any;
  id: string;
  valueSetsAll: any;
  rolesUser: any;
}

const buildCategorySettings = ({ data, categories, productType }: any) => {
  const arrayCategories = [] as any;
  Object.keys(data).forEach((category: any) => {
    if (!isEmpty(data[category])) {
      const caty = find(categories, ({ id }) => id === category);
      const catyCopy = Object.assign({}, caty);
      if (catyCopy) {
        const productTypesObj = [] as any;
        Object.keys(data[category]).forEach((product: any) => {
          const productTypes = find(productType, ({ id }) => id === product);
          if (productTypes && data[category][product]) {
            productTypesObj.push(productTypes.id);
          }
        });
        arrayCategories.push(
          Object.assign(
            { categoryId: catyCopy.id },
            { productIdTypes: productTypesObj },
          ),
        );
      }
    }
  });
  return arrayCategories;
};

class BulkForm extends React.Component<IClientsForm> {
  handleSuccess = () => {
    const { dispatch, id }: any = this.props;
    dispatch(
      activateMailingRequest({
        payload: { clientType: 'MIFID', productId: id },
      }),
    );
  };

  get actions() {
    const { id } = this.props;
    return (
      <GridRow>
        <GridCol>
          <Link to={`/products/edit/${id}/cyprus`}>
            <Button className={'button-float'} view="extra" size="s">
              Go to mailing Cyprus
            </Button>
          </Link>
          <Button
            className={'mr20 button-float'}
            view="extra"
            size="s"
            type="submit"
          >
            Make newsletter Bulk
          </Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = (data: any) => {
    const {
      valueSetsAll: { categories, productType },
    } = this.props;
    console.log('submit bulk', this.props, data, blastNote);

    const {
      id,
      body,
      createdDateTime,
      deliveryACC,
      deliveryMIFID,
      header,
      mailingListSettingsId,
      productId,
      subject,
    }: any = data;
    const currentData = {
      id,
      body,
      createdDateTime,
      deliveryACC,
      deliveryMIFID,
      header,
      mailingListSettingsId,
      productId,
      subject,
      blastNote,
    };

    currentData.deliveryMIFID = {
      categoryProductTypeSetting: buildCategorySettings({
        data: data.checkboxes,
        categories,
        productType,
      }),
      footer: {
        id: 'string',
        language: 'string',
        name: 'string',
        text: data['product-editor'],
      },
//      mailingDateTime: `${data.date} ${data.time}`,
//    2024-01-25 время в этот момент меняем
      mailingDateTime: `${new Date().toLocaleDateString('et',  { day: '2-digit', month: '2-digit', year: 'numeric' })} ${new Date().toLocaleTimeString()}`,
      status: 'READY_TO_SEND',
      // 2024-01-15 добавление поля отправителя письма
      from: getEmailById(data['emails'])

    };
    return currentData;
  };

  render() {
   const { title, id, rolesUser } = this.props;
    return (
      <GraphForm
        form={BULK_OPTION_FORM}
        actions={this.actions}
        graphId={id}
        onSuccess={this.handleSuccess}
        init={getDeliveryMailingCyprusRequest}
        edit={editDeliveryMailingRequest}
        create={createDeliveryMailingRequest}
        title={title}
        submit={this.submit}
        normalize={normalize}
        validate={() => {}}
        type={checkVisibleElement(rolesUser, 'save_mailing') ? 'edit' : 'view'}
        columns={1}
        config={config}
        width="12"
        reInitialize
      />
    );
  }
}
const mapStateToProps = createStructuredSelector({
  valueSetsAll: selectAllValueSets,
  rolesUser: makeSelectRoles(),
  // values: formValueSelector
});

const withConnect = connect(mapStateToProps);

export default compose<any>(withConnect, withRouter)(BulkForm);
