// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import { formReset } from 'components/Graphs/Form/actions'
import { tableDataSuccess } from 'components/Graphs/Table/actions'
import { createNewPeriodTypesRequest, getPeriodTypesByIdRequest,
  getAllPeriodTypesRequest, editPeriodTypesRequest} from '../../redux/actions';
import normalize from './normalize';
import config from './fields';
import { LANGUAGE_EN, LANGUAGE_RU } from 'constants/languages'
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { CATALOG_PERIODTYPESS_FORM } from 'constants/forms';
import { CATALOG_PERIODTYPES_TABLE } from 'constants/tables';
import { connect } from 'react-redux';

interface IPeriodTypesForm {
  title?: string;
  form: string;
  match: any
}

class PeriodTypesForm extends React.Component<IPeriodTypesForm> {

  handleClick = () => {
    const {history, dispatch}: any = this.props;
    dispatch(reset(CATALOG_PERIODTYPESS_FORM))
    dispatch(getAllPeriodTypesRequest({ meta: { table: CATALOG_PERIODTYPES_TABLE, callback: tableDataSuccess }}))
    dispatch(formReset({ meta: { form: CATALOG_PERIODTYPESS_FORM } }))
    history.push('/period-types')
  }

  get actions() {
    const { match: { params: { id } } } = this.props;
    const action = id ? 'Edit this Period type' : 'Add new Period type';
    return (
      <GridRow>
        <GridCol>
          <Button className={"button-float"} view="extra" size="s" type="submit">
            {action}
          </Button>
          <Button className={"mr20 button-float"} size="s" onClick={this.handleClick}>Cancel</Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = ({ start, nameEN, nameRU, end }: any) => {
    const { match: { params: { id } } } = this.props
    return {
      id,
      name: [
        {
          language: { id: LANGUAGE_RU, name: 'Рус' },
          translatedValue: nameRU,
        },
        {
          language: { id: LANGUAGE_EN, name: 'En' },
          translatedValue: nameEN,
        },
      ],
      end,
      start
    };
  };

  render() {
    const {  match: { params: { id } }}  = this.props;
    return (
      <GraphForm
        title="Period types form"
        form={CATALOG_PERIODTYPESS_FORM}
        actions={this.actions}
        onSuccess={this.handleClick}
        graphId={id}
        init={getPeriodTypesByIdRequest}
        edit={editPeriodTypesRequest}
        create={createNewPeriodTypesRequest}
        submit={this.submit}
        normalize={normalize}
        validate={() => {}}
        type={id ? 'edit': 'create'}
        columns={1}
        config={config}
        width="5"
        reInitialize
      />
    );
  }
}

export default compose<any>(
  connect(),
  withRouter
)(PeriodTypesForm)
