import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api';
import { getFormattedDate } from '../../../utils/formatters';

class ClassAPI {
  getAllCompanyWidgetsCharts = (date: string) => request({
    url: `upload/readAllCompanyGraph${date ? `?loadDate=${date}` : ''}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  getAllSectorWidgetsCharts = (date: string) => request({
    url: `upload/readAllSectorGraph${date ? `?loadDate=${date}` : ''}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  createCompaniesWidgetsChartsRequest = ({ data: { fileLoad, loadDate } }: any) => {

    var bodyFormData: any = new FormData();
    bodyFormData.append('fileLoad', fileLoad[0], fileLoad[0].name);
    bodyFormData.append('loadDate', loadDate);
    return request({
      data: bodyFormData,
      url: 'upload/companyGraphLoad',
      method: 'POST',
      token: API_GLOBAL.token,
    });
  };

  createSectorsWidgetsChartsRequest = ({ data: { fileLoad, loadDate } }: any) => {
    var bodyFormData: any = new FormData();
    bodyFormData.append('fileLoad', fileLoad[0], fileLoad[0].name);
    bodyFormData.append('loadDate', loadDate);
    return request({
      data: bodyFormData,
      url: 'upload/sectorGraphLoad',
      method: 'POST',
      token: API_GLOBAL.token,
    });
  };

  createNewWidgetsCharts = ({ data }: any) => request({
    url: 'catalog/sector',
    data,
    method: 'POST',
    token: API_GLOBAL.token,
  });

  editWidgetsCharts = ({ data }: any) => request({
    url: 'catalog/sector',
    data,
    method: 'PUT',
    token: API_GLOBAL.token,
  });

  getByIdWidgetsCharts = ({ id }: any) => request({
    url: `catalog/sector/${id}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });

  getByNameWidgetsCharts = ({ id }: any) => request({
    url: `catalog/sector/by-name/${id}`,
    method: 'GET',
    token: API_GLOBAL.token,
  });
}

const API_WIDGETSCHARTS = new ClassAPI();

export default API_WIDGETSCHARTS;
