// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import { formReset } from 'components/Graphs/Form/actions';
import { tableDataSuccess } from 'components/Graphs/Table/actions';
import {
  createNewCompaniesRequest, getCompaniesByIdRequest,
  getAllCompaniesRequest, editCompaniesRequest,
} from '../../../redux/actions';
import normalize from './normalize';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { COMPANIES_FORM } from 'constants/forms';
import { COMPANIES_TABLE } from 'constants/tables';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectAllValueSets } from 'components/ValueSet/selector';
import {get_global_id} from "../../../../../app";

interface ICompaniesForm {
  title?: string;
  form: string;
  match: any
}

class ClientsForm extends React.Component<ICompaniesForm> {
  handleClick = () => {
    const { history, dispatch }: any = this.props;
    dispatch(reset(COMPANIES_FORM));
    dispatch(getAllCompaniesRequest({ meta: { table: COMPANIES_TABLE, callback: tableDataSuccess } }));
    dispatch(formReset({ meta: { form: COMPANIES_FORM } }));
    // c версии 4.1 переходим на вновь всталенную запись
    // history.push('/companies');
    history.push('/companies/edit/' + get_global_id());
  };

  get actions() {
    const { id }: any = this.props;
    const action = id ? 'Edit this company' : 'Add new company';
    return (
      <GridRow>
        <GridCol>
          <Button className={'button-float'} view="extra" size="s" type="submit">
            {action}
          </Button>
          <Button className={'mr20 button-float'} size="s" onClick={this.handleClick}>Cancel</Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = (data: any) => {
    const { id }: any = this.props;
    return {
      id,
      sectorId: data.sector,
      authors: data.authors,
      logo: data.logo?.url,
      tableInfo: data.tableInfo?.url,
      equityTickerId: data.equityTickerId,
      name: [
        {
          language: { id: '5ed9f65f396529280c953be3', name: 'RU' },
          translatedValue: data.CompanyNameRU,
        },
        {
          language: { id: '5ed9f684396529280c953be4', name: 'EN' },
          translatedValue: data.CompanyNameEN,
        },
      ],
      shortName: [
        {
          language: { id: '5ed9f65f396529280c953be3', name: 'RU' },
          translatedValue: data.CompanyShortNameRU,
        },
        {
          language: { id: '5ed9f684396529280c953be4', name: 'EN' },
          translatedValue: data.CompanyShortNameEN,
        },
      ],
      description: [
        {
          language: { id: '5ed9f65f396529280c953be3', name: 'RU' },
          translatedValue: data.CompanyDescriptionRU,
        },
        {
          language: { id: '5ed9f684396529280c953be4', name: 'EN' },
          translatedValue: data.CompanyDescriptionEN,
        },
      ],
    };
  }

  render() {
    const { id }: any = this.props;
    return (
      <GraphForm
        form={COMPANIES_FORM}
        actions={this.actions}
        onSuccess={this.handleClick}
        graphId={id}
        title="Basic company form"
        init={getCompaniesByIdRequest}
        edit={editCompaniesRequest}
        create={createNewCompaniesRequest}
        submit={this.submit}
        normalize={normalize}
        validate={() => {}}
        type={id ? 'edit' : 'create'}
        columns={1}
        config={config(id)}
        width="12"
        reInitialize
      />
    );
  }
}
const mapStateToProps = createStructuredSelector({
  valueSetsAll: selectAllValueSets,
});

const withConnect = connect(mapStateToProps);

export default compose<any>(
  withConnect,
  withRouter,
)(ClientsForm);
