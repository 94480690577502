import {DateTime} from 'luxon';
import {getNumberWorkedDays, isWorkday, isWorkdayNew} from '../../../utils/dashboardUtils';

export function setPeriodDashboard(startDate, endDate, workDays) {
  dashData.startDate = startDate;
  dashData.endDate = endDate;
  dashData.workDays = workDays;
}

export function getPeriodDashboard() {
  return {
    startDate: dashData.startDate,
    endDate: dashData.endDate,
    workDays: dashData.workDays,
  };
}

export function setPrevPeriodDashboard(startDate, endDate, workDays) {
  dashData.startPrevDate = startDate;
  dashData.endPrevDate = endDate;
  dashData.workPrevDays = workDays;
}

export function getPrevPeriodDashboard() {
  return {
    startDate: dashData.startPrevDate,
    endDate: dashData.endPrevDate,
    workDays: dashData.workDays,
  };
}

const dashData = {
  // settings
  startDate: '',
  endDate: '',
  workDays: 0,
  // 2022-09-19 добавлены явные даты предыдущего периода для вычисления рабочих дней
  startPrevDate: '',
  endPrevDate: '',
  workPrevDays: 0,

  // total
  curAUT: 0,
  curDNL: 0,
  curDNE: 0,
  prevAUT: 0,
  prevDNL: 0,
  prevDNE: 0,
  // graph
  rangeAUT: 0,
  rangeDNE: 0,
  rangeDNL: 0,
  range: [],
  nextDate: null,
  rangeName: null,
  // секция bestIndicators
  products: new Map(),
  productsCatalog: null,
  productTypes: new Map(),
  clients: new Map(),
  authors: new Map(),
  companies: new Map(),
};
const resetTotal = () => {
  dashData.curDate = null;
  dashData.curAUT = 0;
  dashData.curDNL = 0;
  dashData.curDNE = 0;
  dashData.prevAUT = 0;
  dashData.prevDNL = 0;
  dashData.prevDNE = 0;
  dashData.range = [];
  dashData.nextDate = null;
  dashData.rangeName = null;

  dashData.products = new Map();
  dashData.productTypes = new Map();
  dashData.clients = new Map();
  dashData.authors = new Map();
  dashData.companies = new Map();
};
const startRange = (rangeName) => {
  console.log("startRange ", rangeName)
  dashData.rangeAUT = 0;
  dashData.rangeDNE = 0;
  dashData.rangeDNL = 0;
  dashData.rangeName = rangeName;
};
const storeRange = () => {
  console.log("storeRange Name/value ", dashData.rangeName, dashData.rangeAUT)

  dashData.range.push({
    name: dashData.rangeName,
    AUT: dashData.rangeAUT,
    DNE: dashData.rangeDNE,
    DNL: dashData.rangeDNL,
  });
};

const calcAUT = (item) => {
  dashData.curAUT++;
  dashData.rangeAUT++;
};

const calcDownloadOther = (item) => {
  if (item.productId) {
    let numProduct = dashData.products.get(item.productId);
    if (numProduct) {
      dashData.products.set(item.productId, ++numProduct);
    } else {
      dashData.products.set(item.productId, 1);
    }

    const product = dashData.productsCatalog.get(item.productId);
    if (product) {
      product.authors &&
      product.authors.forEach((authorId) => {
        let numAuthor = dashData.authors.get(authorId);
        if (numAuthor) {
          dashData.authors.set(authorId, ++numAuthor);
        } else {
          dashData.authors.set(authorId, 1);
        }
      });
      product.companies &&
      product.companies.forEach((companyId) => {
        let numCompany = dashData.companies.get(companyId);
        if (numCompany) {
          dashData.companies.set(companyId, ++numCompany);
        } else {
          dashData.companies.set(companyId, 1);
        }
      });
    }
  }

  if (item.productTypeId) {
    let numProductType = dashData.productTypes.get(item.productTypeId);
    if (numProductType) {
      dashData.productTypes.set(item.productTypeId, ++numProductType);
    } else {
      dashData.productTypes.set(item.productTypeId, 1);
    }
  }

  if (item.clientId) {
    let numClient = dashData.clients.get(item.clientId);
    if (numClient) {
      dashData.clients.set(item.clientId, ++numClient);
    } else {
      dashData.clients.set(item.clientId, 1);
    }
  }
};
const calcDNL = (item) => {
  dashData.curDNL++;
  dashData.rangeDNL++;
  calcDownloadOther(item);
  // console.log('calcDNL', dashData )
};

const calcDNE = (item) => {
  dashData.curDNE++;
  dashData.rangeDNE++;
  calcDownloadOther(item);
};

const calEndRange = (endDate, startDate) => {
  controlEndDate(endDate, startDate);
};

/* Заполнение верхнего диапазона пустыми значениями, если нет данных по нему */
const controlEndDate = (requiredDate, itemDate) => {
  while (itemDate.ts < requiredDate.ts) {
    console.log("!!! actionChangeDate !!!!", itemDate.ts, requiredDate.ts)
    const day = (requiredDate.c.day < 10) ? '0' + String(requiredDate.c.day) : requiredDate.c.day
    const month = (requiredDate.c.month < 10) ? '0' + String(requiredDate.c.month) : requiredDate.c.month
    startRange(
        `${day}.${month}.${requiredDate.c.year}`,
    );
    storeRange();
    requiredDate = requiredDate.minus({days: 1});
  }
};

const actionChangeDate = (item) => {
  const today = DateTime.fromFormat(item.created.substr(0, 10), 'dd.MM.yyyy');
  if (dashData.nextDate) {
    if (today.ts <= dashData.nextDate.ts) {
      storeRange();
      controlEndDate(dashData.nextDate, today);

      console.log("before startRange actionChangeDate ", item)
      startRange(item.created.substr(0, 10));
      dashData.nextDate = today.minus({days: 1});
    }
  } else {
    // первая Item
    const endDate = DateTime.fromFormat(dashData.endDate, 'dd.MM.yyyy');
    controlEndDate(endDate, today);
    console.log("before startRange actionChangeDate first", item)
    startRange(item.created.substr(0, 10));
    dashData.nextDate = today.minus({days: 1});
  }
};

///////////////////////////////////////////////////////////////
function convertCalendarArrayToObject(calendar) {
   let holidays = {}
   let movedWorkDays = {}
   for( let i=0; i< calendar.length; i++) {
      const calItem = calendar[i]
      const year = calItem.year
      const holiday = calItem.holiday
      holidays[year] = []
      for( let j=0; j< holiday.length; j++) {
        const item = holiday[j];
        let date = new Date(item);
        date.setHours(0)
        holidays[year].push(date)
      }
     // console.log("convertCalendarArrayToObject=", calendar)
     const movedWorkDay = calItem.movedWorkDay
     movedWorkDays[year] = []
     for( let j=0; j< movedWorkDay.length; j++) {
       const item = movedWorkDay[j];
       let date = new Date(item);
       date.setHours(0)
       movedWorkDays[year].push(date)
     }

   }
   return {
     holidays,
     movedWorkDays
   }
}

export const calculateDashboard = (
    workCalendars,
    dataCur,
    dataPrev,
    {products, productTypes}
) => {

  dashData.productsCatalog = products;
  // преобразование массива календарей в объект, с которым умеют работать  функционал нижнего уровня
  const workCalendarAsObj = convertCalendarArrayToObject(workCalendars)

  resetTotal();
  startRange();

  // 2022 в расчеты попадают только рабочие дни. Остальные дни (выходные празники) будут 0-ми даже если там что то содержалось

  dataPrev.forEach((item) => {
    // 2022 добавлено условие рабочих дней
    if (isWorkday(item.created.substr(0, 10))) {
      switch (item.type) {
        case 'AUT':
          dashData.prevAUT++;
          break;
        case 'DNL':
          dashData.prevDNL++;
          break;
        case 'DNE':
          dashData.prevDNE++;
          break;
      }
    }
  });
  let oldDay = null;
  let lastItem = null;
  dataCur.forEach((item) => {
    const today = DateTime.fromFormat(item.created.substr(0, 10), 'dd.MM.yyyy')
        .c.day;
    console.log("WORKLOOP today/item", today,item)

    if (!oldDay || today != oldDay) {
      console.log("CHANGE date ")
      actionChangeDate(item);
    }
    // 2022 добавлено условие рабочих дней
    if (isWorkday(workCalendarAsObj, item.created.substr(0, 10))) {
      switch (item.type) {
        case 'AUT':
          calcAUT(item);
          break;
        case 'DNL':
          calcDNL(item);
          break;
        case 'DNE':
          calcDNE(item);
          break;
      }
      oldDay = today;
      lastItem = item;
    }
  });
  storeRange();
  console.log("----------------------------------------------------------------------------------")
  console.log("Last item/startDate=", lastItem, dashData.startDate)
  // if (lastItem) {
  //   const last = DateTime.fromFormat(
  //       lastItem.created.substr(0, 10),
  //       'dd.MM.yyyy',
  //   ).minus({days: 1});
  //   const start = DateTime.fromFormat(dashData.startDate, 'dd.MM.yyyy').minus({
  //     days: 1,
  //   });
  //
  //   calEndRange(last, start);
  // }
  normalizeTotalByWorkDays(workCalendarAsObj, dashData.startDate, dashData.endDate)

  return {
    range: dashData.range,
    total: {
      AUT: {cur: dashData.curAUT, prev: dashData.prevAUT},
      DNL: {cur: dashData.curDNL, prev: dashData.prevDNL},
      DNE: {cur: dashData.curDNE, prev: dashData.prevDNE},
    },
    products: dashData.products,
    productTypes: dashData.productTypes,
    clients: dashData.clients,
    authors: dashData.authors,
    companies: dashData.companies,
  };
};




function normalizeTotalByWorkDays(workCalendars, startDate, endDate) {
  const workDays = getNumberWorkedDays(workCalendars, startDate, endDate);
  const prevWorkDays = getNumberWorkedDays(workCalendars, startDate, endDate);
  if(!workDays || !prevWorkDays ) {
    return
  }
  if (dashData) {
    console.log("normalizeTotalByWorkDays AUT calculate dashData.curAUT / workDays", dashData.curAUT, workDays )
    dashData.curAUT = Math.round(dashData.curAUT / workDays);
    dashData.prevAUT = Math.round(dashData.prevAUT / prevWorkDays);
  }
  if (dashData) {
    dashData.curDNL = Math.round(dashData.curDNL / workDays);
    dashData.prevDNL = Math.round(dashData.prevDNL / prevWorkDays);
  }
  if (dashData) {
    dashData.curDNE = Math.round(dashData.curDNE / workDays);
    dashData.prevDNE = Math.round(dashData.prevDNE / prevWorkDays);
  }
}
