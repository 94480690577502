export const GET_ALL_EMAILS_REQUEST = 'GET_ALL_EMAILS_REQUEST'
export const GET_ALL_EMAILS_SUCCESS = 'GET_ALL_EMAILS_SUCCESS'
export const GET_ALL_EMAILS_FAILURE = 'GET_ALL_EMAILS_FAILURE'

export const CREATE_NEW_EMAILS_REQUEST = 'CREATE_NEW_EMAILS_REQUEST'

export const GET_EMAILS_BY_ID_REQUEST = 'GET_EMAILS_BY_ID_REQUEST'

export const DELETE_EMAILS_BY_ID_REQUEST = 'DELETE_EMAILS_BY_ID_REQUEST'

export const EDIT_EMAILS_REQUEST = 'EDIT_EMAILS_REQUEST'


