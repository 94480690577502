// @ts-nocheck
import React from 'react';
import './DashBoardBestIndicators.scss';
import Tabs from "arui-feather/tabs";
import TabItem from "arui-feather/tab-item";
import IndicatorPanel from "./IndicatorPanel";
import  { orderBy } from 'lodash';

class DashboardBestIndicators extends React.Component {
    constructor() {
        super();
        this.state = {
            page: 'Topics'
        }
    }


    handleClick = (event: any) => {
        event.preventDefault();
        //console.log('click event=', event)
        this.setState({ page: event.target.getAttribute('href') });
    }

    calculateAvgValue(item, mapIdNumProduct) {
        if( !item ) return 0;
        const numProduct = mapIdNumProduct.get(item[0])
        if(numProduct) {
           return  Math.round(item[1] / numProduct)
        }
        else {
          return 0;
        }
    }

    getValueFromMap(item, mapIdNumProduct) {
        if( !item ) return 0;
        const numProduct = mapIdNumProduct.get(item[0])
        if(numProduct) {
            return numProduct
        }
        else {
            return 0;
        }
    }


    normalize = (data: any) => {
        // console.log('bestindicator normalize=', data)
        if(!data || !data.data || !data.dataCatalog) return null
        const dash = data.data;
        const catalog = data.dataCatalog;
        // console.log('bestindicator normalize dash/dataCatalog =', dash, catalog )
// Установка name вместо id в результирующие наборы
        let products = [];
//  множество продуктов для авторов
        let authorProduct = new Set();
//  множество компаний для авторов
        let companyProduct = new Set();

        for (let entry of  dash.products) {
            const product = catalog.products.get(entry[0])
            if( product ) {
                product.authors && product.authors.forEach( authorId => {
                    authorProduct.add({authorId: authorId, productId:product.id})
                })
                product.companies && product.companies.forEach( companyId => {
                    // console.log('!!!!!!!!COMPANY PRODUCT0', companyProduct)
                    companyProduct.add({companyId: companyId, productId:product.id})
                })
                products.push({ name: product.name, value: entry[1]})

            }
        }
        let authorsNumProduct = new Map();
        for (let obj of authorProduct) {
            let numProduct = authorsNumProduct.get(obj.authorId);
            if (numProduct)
                authorsNumProduct.set(obj.authorId, ++numProduct)
            else
                authorsNumProduct.set(obj.authorId, 1)

        };

        let companyNumProduct = new Map();
        for (let obj of companyProduct) {
            let numProduct = companyNumProduct.get(obj.companyId);
            if (numProduct)
                companyNumProduct.set(obj.companyId, ++numProduct)
            else
                companyNumProduct.set(obj.companyId, 1)

        };


        products = orderBy(products, ['value'], ['desc']).splice(0,20);

        let authors = [];
        for (let entry of  dash.authors) {
            const author = catalog.authors.get(entry[0])
            if( author ) {
                // Для авторов вычисляем среднее на всего загрузок / число продуктов,
                // добавляем число продуктов и logo
                    authors.push({ name: author.name[1].translatedValue, value: this.calculateAvgValue(entry, authorsNumProduct),
                        numProducts: this.getValueFromMap(entry, authorsNumProduct) , img :author.photo })
            }
        }
        authors = orderBy(authors, ['value'], ['desc']).splice(0,20);

        let companies = [];
        for (let entry of  dash.companies) {
            const company = catalog.companies.get(entry[0])
            if( company ) {
                // Для компаний вычисляем среднее на всего загрузок / число продуктов,
                // добавляем абсолютное число закачек и logo
                companies.push({ name: company.name[1].translatedValue, value: this.calculateAvgValue(entry, companyNumProduct),
                    numLoads: entry[1], img :company.logo })

            }
        }
        companies = orderBy(companies, ['value'], ['desc']).splice(0,20);

        let clients = [];
        for (let entry of  dash.clients) {
            const client = catalog.clients.get(entry[0])
            if( client ) {
                clients.push({ name: client.name[1].translatedValue, value: entry[1]})
            }
        }
        clients = orderBy(clients, ['value'], ['desc']).splice(0,20);

        let productTypes = [];
        for (let entry of  dash.productTypes) {
            const productType = catalog.productTypes.get(entry[0])
            if( productType ) {
                productTypes.push({ name: productType.name[1].translatedValue, value: entry[1]})
            }
        }
        productTypes = orderBy(productTypes, ['value'], ['desc']).splice(0,20);
        //console.log('TEST SORT normalize', products, authors, authorsNumProduct, companies, clients, productTypes)
        return { products, authors, authorsNumProduct, companies, companyNumProduct, clients, productTypes  }

    }

    render() {
        const {data}: any = this.props;
        //console.log('DashboardBestIndicators render=', data)
        const dataNormalize = this.normalize(data)
        //console.log( 'Best Indicators = ', dataNormalize )
        return (
            <div style={{width:"1092px"}}>
            <div className={'dashboard-title'}>
                Key metrics
            </div>
            <Tabs>
                <TabItem url='Topics' onClick={ this.handleClick } checked={ this.state.page === 'Topics' }>
                    Topics
                </TabItem>
                <TabItem url='Analysts' onClick={ this.handleClick } checked={ this.state.page === 'Analysts' }>
                    Analysts
                </TabItem>
                <TabItem url='Companies' onClick={ this.handleClick } checked={this.state.page === 'Companies' }>
                    Companies
                </TabItem>
                <TabItem url='Readers' onClick={ this.handleClick } checked={this.state.page === 'Readers' }>
                    Readers
                </TabItem>
                <TabItem url='Products type' onClick={ this.handleClick } checked={this.state.page === 'Products type' }>
                    Products type
                </TabItem>
                <TabItem disabled url='Geograhy' onClick={ this.handleClick } checked={this.state.page === 'Geograhy' }>
                    Geograhy
                </TabItem>
                <TabItem disabled url='Accounts' onClick={ this.handleClick } checked={this.state.page === 'Accounts' }>
                    Accounts
                </TabItem>
            </Tabs>
            { (this.state.page === 'Topics'  || this.state.page === 'Analysts' || this.state.page === 'Companies'
                || this.state.page === 'Readers' || this.state.page === 'Products type') &&
               <IndicatorPanel data={dataNormalize} title={this.state.page}/>
            }
            </div>
        );
    }
}

export default DashboardBestIndicators;

