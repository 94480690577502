// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import { formReset } from 'components/Graphs/Form/actions'
import { tableDataSuccess } from 'components/Graphs/Table/actions'
import { createNewCurrenciesRequest, getCurrenciesByIdRequest,
  getAllCurrenciesRequest, editCurrenciesRequest} from '../../redux/actions';
import normalize from './normalize';
import validate from './validate';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { CATALOG_CURRENCIES_FORM } from 'constants/forms';
import { CATALOG_CURRENCIES_TABLE } from 'constants/tables';
import { connect } from 'react-redux';

interface ISectorForm {
  title?: string;
  form: string;
  match: any
}

class CurrenciesForm extends React.Component<ISectorForm> {

  handleClick = () => {
    const {history, dispatch}: any = this.props;
    dispatch(reset(CATALOG_CURRENCIES_FORM))
    dispatch(getAllCurrenciesRequest({ meta: { table: CATALOG_CURRENCIES_TABLE, callback: tableDataSuccess }}))
    dispatch(formReset({ meta: { form: CATALOG_CURRENCIES_FORM } }))
    history.push('/currencies')
  }

  get actions() {
    const { match: { params: { id } } } = this.props;
    const action = id ? 'Edit this currency' : 'Add new currency';
    return (
      <GridRow>
        <GridCol>
          <Button className={"button-float"} view="extra" size="s" type="submit">
            {action}
          </Button>
          <Button className={"mr20 button-float"} size="s" onClick={this.handleClick}>Cancel</Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = ({ name }: any) => {
    const { match: { params: { id } } } = this.props
    return {
      id,
      name
    };
  };

  render() {
    const {  match: { params: { id } }}  = this.props;
    return (
      <GraphForm
        title="Currencies form"
        form={CATALOG_CURRENCIES_FORM}
        actions={this.actions}
        onSuccess={this.handleClick}
        graphId={id}
        init={getCurrenciesByIdRequest}
        edit={editCurrenciesRequest}
        create={createNewCurrenciesRequest}
        submit={this.submit}
        normalize={normalize}
        validate={validate}
        type={id ? 'edit': 'create'}
        columns={1}
        config={config}
        width="5"
        reInitialize
      />
    );
  }
}

export default compose<any>(
  connect(),
  withRouter
)(CurrenciesForm)
