const validate = (values: any) => {
  const errors = {} as any;

  if (values.email === '' && Object.keys(values.email).length === 0) {
    errors.email = 'Required field';
  }

  const email = values.email;
  const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,15})$/;
  if(reg.test(email) == false) {
      errors.email = 'Wrong Email';
  }
  return errors;
};

export default validate