import { language } from 'utils/formatters';

const normalize = (data: any) => {
  if (!data) return null;
  let cormData = {
    email: data.email,
    defaultMFID: data.defaultMFID,
    defaultACC: data.defaultACC,
  };


  return cormData;
};

export default normalize;
