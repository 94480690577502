// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';

import API_CATEGORY from '../api';
import { GET_ALL_LANGUAGES_REQUEST, CREATE_NEW_LANGUAGES_REQUEST, GET_LANGUAGES_BY_ID_REQUEST, EDIT_LANGUAGES_REQUEST } from './constants';

function* getAllLanguages({ meta: { form, table, callback } }: any) {
  try {
    const response = yield call(API_CATEGORY.getAllLanguages);
    if (callback) {
      if (table) yield put(callback({ payload: { response }, meta: { table } }))
      if (form) yield put(callback({ payload: { response }, meta: { form } }))
    }
  } catch (error) {
    console.error('Auth Wrong');
  }
}

function* createNewLanguages({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_CATEGORY.createNewLanguages, { data: fields });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getLanguagesById({ payload: { callback, data: { id } }, meta: { form } }: any) {
  try {
    const response = yield call(API_CATEGORY.getByIdLanguages, { id });
    if (callback) {
      return yield put(callback({ payload: { response }, meta: { form } }));
    }
  } catch (error) {
    console.error(error);
  }
}

function* editLanguages({ payload: { callback, fields }, meta: { form, onSuccess } }: any) {
  try {
    const response = yield call(API_CATEGORY.editLanguages, { data: fields });
    if (callback) {
      yield put(callback({ payload: { response }, meta: { form, onSuccess } }));
    }
  } catch (error) {
    console.error(error);
  }
}


function* Saga() {
  yield all([
    takeLatest(GET_ALL_LANGUAGES_REQUEST, getAllLanguages),
    takeLatest(CREATE_NEW_LANGUAGES_REQUEST, createNewLanguages),
    takeLatest(GET_LANGUAGES_BY_ID_REQUEST, getLanguagesById),
    takeLatest(EDIT_LANGUAGES_REQUEST, editLanguages)
  ]);
}

export default Saga;
