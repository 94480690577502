import * as ACTION_TYPE from './constants';

export const getAllcategoriesRequest = ({ meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_CATEGORIES_REQUEST,
  meta,
});

export const createNewCategoryRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_CATEGORY_REQUEST,
  payload,
  meta,
});

export const editCategoryRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_CATEGORY_REQUEST,
  payload,
  meta,
})

export const getCategoryByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_CATEGORY_BY_ID_REQUEST,
  payload,
  meta,
})