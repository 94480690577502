import * as ACTION_TYPE from './constants';

export const getAllAuthorsRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_ALL_AUTHORS_REQUEST,
  meta,
  payload
});

export const createNewAuthorsRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_AUTHORS_REQUEST,
  payload,
  meta,
});

export const createNewAuthorsFailure = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.CREATE_NEW_AUTHORS_FAILURE,
  payload,
  meta,
});


export const editAuthorsRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.EDIT_AUTHORS_REQUEST,
  payload,
  meta,
})

export const getAuthorsByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.GET_AUTHORS_BY_ID_REQUEST,
  payload,
  meta,
})

export const deleteAuthorsByIdRequest = ({ payload, meta }: any) => ({
  type: ACTION_TYPE.DELETE_AUTHORS_BY_ID_REQUEST,
  payload,
  meta,
})