import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {
  getTemplates = (mailingId: any) => request({
    url: `delivery/mailingType/${mailingId}`,
    method: 'GET',
    token: API_GLOBAL.token
  });

  getTemplateBody = (mailingId: any) => request({
    url: `delivery/body/${mailingId}`,
    method: 'GET',
    token: API_GLOBAL.token
  });

  getTemplateFooter = (mailingId: any) => request({
    url: `delivery/footer/${mailingId}`,
    method: 'GET',
    token: API_GLOBAL.token
  });

  getTemplateHeader = (mailingId: any) => request({
    url: `delivery/header/${mailingId}`,
    method: 'GET',
    token: API_GLOBAL.token
  });
}

const API_GRAPES = new ClassAPI();

export default API_GRAPES;
