import { language } from 'utils/formatters'


const normalize = (data: any) => {
  if (!data) return null;

  let cormData = {
    nameEN: language(data.name, 'en'),
    nameRU: language(data.name, 'ru'),
  }

  return cormData;
};

export default normalize;
