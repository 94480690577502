// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { find, isEmpty } from 'lodash';
import { compose } from 'redux';
import { selectAllValueSets } from 'components/ValueSet/selector';
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import {
  activateMailingRequest,
  createDeliveryMailingRequest,
  editDeliveryMailingRequest, getDeliveryMailingBulkRequest,
  getDeliveryMailingCyprusRequest,
} from '../../../redux/actions';
import normalize from './normalize';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { CYPRUS_OPTION_FORM } from 'constants/forms';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { checkVisibleElement } from '../../../../../constants/roles';
import { makeSelectRoles } from '../../../../../app/selectors';
import {getEmailById} from "../../../utils";


interface IClientsForm {
  title?: string;
  form: string;
  match: any;
  id: string;
  valueSetsAll: any;
  rolesUser: any
}

const buildCategorySettings = ({ data, categories, productType }: any) => {
  const arrayCategories = [] as any;
  Object.keys(data).forEach((category: any) => {
    if (!isEmpty(data[category])) {
      const caty = find(categories, ({ id }) =>  id === category)
      let catyCopy = Object.assign({}, caty)
      if (catyCopy) {
        let productTypesObj = [] as any
        Object.keys(data[category]).forEach((product: any) => {
          const productTypes = find(productType, ({ id }) =>  id === product)
          if (productTypes && data[category][product]){
            productTypesObj.push(productTypes.id)
          }
        })
        arrayCategories.push(Object.assign({ categoryId: catyCopy.id }, { productIdTypes: productTypesObj }))
      }
    }
  })
  return arrayCategories;
};

class CyprusForm extends React.Component<IClientsForm> {
  handleSuccess = () => {
    const { dispatch, id }: any = this.props;
    dispatch(
      activateMailingRequest({ payload: { clientType: 'ACC', productId: id } }),
    );
  };

  get actions() {
    return (
      <GridRow>
        <GridCol>
          <Button
            className={'mr20 button-float'}
            view="extra"
            size="s"
            type="submit"
          >
            Make newsletter Cyprus
          </Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = (data: any) => {
    const { valueSetsAll: { categories, productType } } = this.props;
    const { id, body, createdDateTime, deliveryACC, deliveryMIFID, header, mailingListSettingsId, productId, subject, blastNote }: any = data;
    const currentData = { id, body, createdDateTime, deliveryACC, deliveryMIFID, header, mailingListSettingsId, productId, subject, blastNote }

    currentData.deliveryACC = {
      categoryProductTypeSetting: buildCategorySettings({ data: data.checkboxes, categories, productType }),
      footer: {
        id: 'string',
        language: 'string',
        name: 'string',
        text: data['product-editor'],
      },
//      mailingDateTime: `${data.date} ${data.time}`,
//    2024-01-25 время в этот момент меняем
      mailingDateTime: `${new Date().toLocaleDateString('et',  { day: '2-digit', month: '2-digit', year: 'numeric' })} ${new Date().toLocaleTimeString()}`,
      status: 'READY_TO_SEND',

      from: getEmailById(data['emails'])
    }
    return currentData;
  };

  render() {
    const { title, id, rolesUser } = this.props;
    return (
      <GraphForm
        form={CYPRUS_OPTION_FORM}
        actions={this.actions}
        graphId={id}
        onSuccess={this.handleSuccess}
        init={getDeliveryMailingBulkRequest}
        edit={editDeliveryMailingRequest}
        create={createDeliveryMailingRequest}
        title={title}
        submit={this.submit}
        normalize={normalize}
        validate={() => {}}
        type={checkVisibleElement(rolesUser, 'save_mailing') ? 'edit' :  'view'}
        columns={1}
        config={config}
        width="12"
        reInitialize
      />
    );
  }
}
const mapStateToProps = createStructuredSelector({
  valueSetsAll: selectAllValueSets,
  rolesUser: makeSelectRoles(),
  // values: formValueSelector
});

const withConnect = connect(mapStateToProps);

export default compose<any>(withConnect, withRouter)(CyprusForm);
