// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';

import GraphForm from 'components/Graphs/Form';
import { formReset } from 'components/Graphs/Form/actions';
import { tableDataSuccess } from 'components/Graphs/Table/actions';
import { getObjectTrim } from 'utils/formatters';
import {
  createNewFooterRequest,
  getFooterByIdRequest, getAllFooterRequest, editFooterRequest,
}
  from 'modules/MailingListSettings/redux/actions';
import normalize from './normalize';
import config from './fields';
import validate from './validate';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { INFO_FORM } from 'constants/forms';
import { INFO_TABLE } from 'constants/tables';
import { LANGUAGE_EN, LANGUAGE_RU } from 'constants/languages';
import { connect } from 'react-redux';

interface IMailingForm {
  title?: string;
  form: string;
  match: any
  idProps: string
}

class FooterForm extends React.Component<IMailingForm> {

  handleClick = () => {
    const { history, dispatch }: any = this.props;
    dispatch(reset(INFO_FORM));
    dispatch(getAllFooterRequest({ meta: { table: INFO_TABLE, callback: tableDataSuccess } }));
    dispatch(formReset({ meta: { form: INFO_FORM } }));
    history.push('/mailing-list-settings/footer');
  };

  get actions() {
    const {idProps, match: { params: { id } } } = this.props;
    const action = id || idProps ? 'Edit this Footer/Contacts' : 'Add new Footer/Contacts';
    return (
      <GridRow>
        <GridCol>
          <Button className={'button-float'} view="extra" size="s" type="submit">
            {action}
          </Button>
          <Button className={'mr20 button-float'} size="s" onClick={this.handleClick}>Cancel</Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = ({ name, text }: any) => {
    const { idProps, match: { params: { id } } } = this.props;
    const currentId = id || idProps;
    return {
      id: currentId,
      name,
      text,
    };
  };


  render() {
    const { idProps, match: { params: { id } } } = this.props;
    return (
      <GraphForm
        title="Footer template form"
        form={INFO_FORM}
        actions={this.actions}
        onSuccess={this.handleClick}
        graphId={id || idProps}
        init={getFooterByIdRequest}
        edit={editFooterRequest}
        create={createNewFooterRequest}
        submit={this.submit}
        validate={(values: object) => validate(getObjectTrim(values))}
        normalize={normalize}
        type={id || idProps ? 'edit' : 'create'}
        columns={1}
        config={config}
        width="12"
        reInitialize
      />
    );
  }
}

export default compose<any>(
  connect(),
  withRouter,
)(FooterForm);
