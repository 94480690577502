// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import AuthorsTable from './table';
import { Link } from 'react-router-dom';
import Button from 'arui-feather/button';
const AuthorsView = () => {
  return (
    <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
      <AuthorsTable actions={
        <Link to={'authors/create'}>
          <Button className={'button-float'} view="extra" size="s" type="submit">
            {'Add new author'}
          </Button>
        </Link>
      } />
    </GridRow>
  );
};

export default AuthorsView;