// @ts-nocheck
import { getStylesForButton } from './styles'
import { IJodit, IPlugin, IControlType } from 'jodit/src/types';

const bodyButton = {
  name: 'Body text',
  icon: 'ol',
  mode: 1,
  list: {
    btb: 'body text bold',
    bt: 'body text',
  },
  template: (editor: any, key: any, value: any) => value,
  exec(editor: any) {
    editor.s.applyStyle(getStylesForButton({ key: this.name }))
  },
};

const titleButton = {
  name: 'Titles',
  icon: 'ol',
  mode: 1,
  list: {
    flr: 'first level red',
    flb: 'first level black',
    slr: 'second level red',
    slb: 'second level black',
  },
  template: (editor: any, key: any, value: any) => value,
  exec(editor: any, control: any, ...args: any) {
    // @ts-ignore
    let valoa = this
    // editor.execCommand('selectall');
    editor.s.applyStyle(getStylesForButton({ key: this.name }))
    // font-size:14.0pt;font-family:"Arial","sans-serif";color:red
    // editor.s.applyStyle({ color: 'red' })
    // editor.selection.save()
    // debugger
    // // @ts-ignore
    // this.execCommand('fontSize', 4)
    // // editor.selection.execCommand('fontSize', 4);
    // editor.selection.restore();
    // if (control && control.data) {
    //   const textData = getTitleTemplate(this.name, control.data);
    //   editor.selection.insertHTML(textData);
    // }
  },
};

const resetStyleButton = {
  name: 'Reset',
  tooltip: 'Example2',
  exec: (editor: IJodit) => {
    editor.s.applyStyle({})
  }
}

const deviderButton = {
  name: 'Divider',
  tooltip: 'Example2',
  exec: (editor: IJodit) => {
    editor.s.insertHTML('<img border="0" width="728" height="19" id="_x0000_i1027_mr_css_attr" src="//af12.mail.ru/cgi-bin/readmsg?id=15922311710443746545;0;4&amp;mode=attachment&amp;email=inerc@mail.ru">');
  }
}

export { titleButton, deviderButton, bodyButton, resetStyleButton };
