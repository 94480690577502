// @ts-nocheck
import React from 'react';

export default (id: any) => [
  {
    isGroup: true,
    label: 'Email',
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 10,
    },

    items: [
      {
        placeholder: 'Email',
        type: 'text',
        id: 'email',
        view: 'filled',
        width: 'available',
        size: 's',
        style: {
          marginBottom: '15px',
        },
      },
    ],
  },
  {
    isGroup: true,
    label: 'Default',
    fieldWidth: '6',
    customPosition: {
      label: 2,
      field: 10,
    },
    items: [
      {
        type: 'checkbox',
        id: 'defaultMFID',
        text: 'MIFID',
        bigLabel: false,
      },
      {
        type: 'checkbox',
        id: 'defaultACC',
        text: 'ACC',
        bigLabel: false,
      },
    ],
  },


];
