// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import AuthorTypesForm from './form';
import AuthorTypesTable from './table';

const AuthorTypes = () => {
  return (
    <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
      <AuthorTypesTable />
      <AuthorTypesForm />
    </GridRow>
  );
};

export default AuthorTypes;