interface IValidate {
  companies?: string;
  country?: string;
}

const validate = (values: any) => {
  const errors = {} as IValidate;
  const errorMessage = 'Required company or country';
  if (values) {
    if (values.companies || values.country) {
      if ((values.companies && !values.companies.length) &&
          !values.country) {
        errors.companies = errorMessage;
        errors.country = errorMessage;
      }
    } else {
      errors.companies = errorMessage;
      errors.country = errorMessage;
    }
  }

  return errors;
};

export default validate;
