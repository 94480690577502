import { language } from 'utils/formatters';

const normalize = (data: any) => {
  if (!data) return null;
//  console.log('normalize data=', data);
  let cormData = {
    id: data.id,
    company: data.companyId,
    currency: data.currencyId,
    stockType: data.stockTypeId,
    name: data.name,
    descriptionEN: language(data.description, 'en'),
    descriptionRU: language(data.description, 'ru'),
    photo: {
      url: data?.photo,
      title: data?.photo?.split('/').pop()
    },
  };


  return cormData;
};

export default normalize;
