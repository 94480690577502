// @ts-nocheck
import { language } from 'utils/formatters';
import {isArray} from "lodash";

export const NUM_SLOTS = 5

const normalize = (data: any) => {
  if (!data) {
    return null;
  }

  function getFilesObj(files: any, lang: string) {
    let res: any[];
    res = [];
    if(files && isArray(files[lang])) {
      files[lang].forEach((item: any) => {
        res.push({
          url: item ? item.url : null,
          title: item ? item.url.split('/').pop() : null,
          size: item ? item.size : 0,
          createdDateTime: item ? item.createdDateTime : null,
        });
      });
    }

    const curLength = res.length;
    for( let i=curLength; i<NUM_SLOTS; i++)  {
      res.push({});
    }
    return res;
  }

  const cormData = {
    nameEN: language(data.name, 'en'),
    nameRU: language(data.name, 'ru'),
    sector: data.sectors,
    active: data.active,
    serviceCategory: data.serviceCategory,
    filesEn: getFilesObj(data.files, 'en'),
    filesRu: getFilesObj(data.files, 'ru'),
  };
  return cormData;
};

export default normalize;
