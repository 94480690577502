import { fromJS } from 'immutable';
import {
    OPEN_SUCCESS_MESSAGE,
    CLOSE_SUCCESS_MESSAGE,

    OPEN_ERROR_MESSAGE,
    CLOSE_ERROR_MESSAGE,
} from './constants';

const initialState = fromJS({
    success: null,
    error: null,
});


const messageReducer = (state = initialState, { type, payload }) => {
    switch (type) {
    case OPEN_SUCCESS_MESSAGE: {
        return state
            .set('success', payload);
    }

    case CLOSE_SUCCESS_MESSAGE: {
        return state
            .set('success', null);
    }


    case OPEN_ERROR_MESSAGE: {
        return state
            .set('error', payload);
    }

    case CLOSE_ERROR_MESSAGE: {
        return state
            .set('error', null);
    }


    default:
        return state;
    }
};

export default messageReducer;
