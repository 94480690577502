// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';
import GraphForm from 'components/Graphs/Form';
import { formReset } from 'components/Graphs/Form/actions'
import { tableDataSuccess } from 'components/Graphs/Table/actions'
import { createNewAuthorTypesRequest, getAuthorTypesByIdRequest,
  getAllAuthorTypesRequest, editAuthorTypesRequest} from '../../redux/actions';
import normalize from './normalize';
import config from './fields';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { CATALOG_AUTHORTYPES_FORM } from 'constants/forms';
import { CATALOG_AUTHORTYPES_TABLE } from 'constants/tables';
import { connect } from 'react-redux';

interface IAuthorTypesForm {
  title?: string;
  form: string;
  match: any
}

class AuthorTypesForm extends React.Component<IAuthorTypesForm> {

  handleClick = () => {
    const {history, dispatch}: any = this.props;
    dispatch(reset(CATALOG_AUTHORTYPES_FORM))
    dispatch(getAllAuthorTypesRequest({ meta: { table: CATALOG_AUTHORTYPES_TABLE, callback: tableDataSuccess }}))
    dispatch(formReset({ meta: { form: CATALOG_AUTHORTYPES_FORM } }))
    history.push('/author-types')
  }

  get actions() {
    const { match: { params: { id } } } = this.props;
    const action = id ? 'Edit this author type' : 'Add new author type';
    return (
      <GridRow>
        <GridCol>
          <Button className={"button-float"} view="extra" size="s" type="submit">
            {action}
          </Button>
          <Button className={"mr20 button-float"} size="s" onClick={this.handleClick}>Cancel</Button>
        </GridCol>
      </GridRow>
    );
  }

  submit = ({ name }: any) => {
    const { match: { params: { id } } } = this.props
    return {
      id,
      name
    };
  };

  render() {
    const { match: { params: { id } }}  = this.props;
    return (
      <GraphForm
        title="Author types form"
        form={CATALOG_AUTHORTYPES_FORM}
        actions={this.actions}
        onSuccess={this.handleClick}
        graphId={id}
        init={getAuthorTypesByIdRequest}
        edit={editAuthorTypesRequest}
        create={createNewAuthorTypesRequest}
        submit={this.submit}
        normalize={normalize}
        validate={() => {}}
        type={id ? 'edit': 'create'}
        columns={1}
        config={config}
        width="5"
        reInitialize
      />
    );
  }
}

export default compose<any>(
  connect(),
  withRouter
)(AuthorTypesForm)
