// @ts-nocheck
import Checkbox from 'arui-feather/checkbox';
import React from 'react';

const renderDateField = ({ input, id, label, type, required, style, meta: { touched, error }, ...props }: any) => (
  <div style={style}>
    <Checkbox
      {...input}
      {...props}
      id={id}
      type={type}
      required={required}
      label={label}
    />
    {error && touched && <span style={{
      fontSize: 12,
      color: 'red'
    }} className="input__sub">Required</span>}
  </div>
);

export default renderDateField;