// @ts-nocheck
import { find, isArray, isEmpty } from 'lodash';

import axios from "axios";
import API_GLOBAL from "../api/restClient/api";
import { LANGUAGE_EN, LANGUAGE_RU } from 'constants/languages';

export const getObjectTrim = (object: any) => {
  if (typeof object !== 'object' || object === null) {
    return object;
  }
  return Object.keys(object).reduce(
    (memo, key) => {
      const typeKey = typeof object[key];
      let value = object[key];
      switch (typeKey) {
        case 'string':
          value = object[key].trim();
          break;
        case 'object':
          value = getObjectTrim(object[key]);
          break;
        default:
          value = object[key];
      }

      if (Array.isArray(memo)) {
        return [...memo, value];
      }

      return { ...memo, [key]: value };
    },
    Array.isArray(object) ? [] : {},
  );
};

export const language = (data: object, language: string) => {
  switch (language) {
    case 'en':
      // @ts-ignore
      return find(data, { language: { id: LANGUAGE_EN } }) && find(data, { language: { id: LANGUAGE_EN } }).translatedValue;
    case 'ru':
      // @ts-ignore
      return find(data, { language: { id: LANGUAGE_RU } }) && find(data, { language: { id: LANGUAGE_RU } }).translatedValue;
  }
};

export const getFormattedDate = (date: Date) => {
  let year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return day + '.' + month + '.' + year;
};

export const exten = (fileName: string) => {
  return fileName.substr(fileName.lastIndexOf('.') + 1);
};

export const hashCode = (s: string) => {
  var h = 0, l = s.length, i = 0;
  if (l > 0)
    while (i < l)
      h = (h << 5) - h + s.charCodeAt(i++) | 0;
  return h;
};


export const getCurrentCompany = (id: string, company: any) => {
  return company && isArray(company) && company
    .filter((v: any) => v.id === id)
    .map((v: any) => language(v.name, 'en'));
};


export const isValidUrl = (string: any) => {
  // try {
  //   new URL(string);
  // } catch (error) {
  //   return false;
  // }
  return true;
};

export const getFilename = (url: any) => {
  if (url) {
    var m = url.toString().match(/.*\/(.+?)\./);
    if (m && m.length > 1) {
      return m[1];
    }
  }
  return "";
}

export const dowloadFile = (url: any, callback?: any) => {
  if (isValidUrl(url)) {
    const name = getFilename(url);
    const extension = url.split('.').pop();
    axios({
      url: url,
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${API_GLOBAL.token}`,
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute("target", "_blank");
      link.setAttribute('download', `${name}.${extension}`);
      document.body.appendChild(link);
      link.click();
      if (callback) {
        window.location = callback
      }
    }).catch((error) => {
      console.error(error)
    })
  }
};

export const checkTouch = (payload: any, meta: any): any => {
  return meta.field === "dateTo" || meta.field === "dateFrom" ||
         meta.field === "user" || meta.field === "product" || meta.field === "type" ||
         meta.field === "productTypeSimple" || meta.field === "language" ||
         meta.field === "categoryType" ||  meta.field === "client" || meta.field === "company" ||
         meta.field === "name" ||  meta.field === "productTypeId" || meta.field === "category" || meta.field === "analyst" ||
  isArray(payload?.defaultSelect) && !isEmpty(payload?.defaultSelect);
};
