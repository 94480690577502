import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {
  getAllEventTypes = () => request({
    url: 'catalog/event-type',
    method: 'GET',
    token: API_GLOBAL.token
  });

  createNewEventTypes = ({ data }: any) => request({
    url: 'catalog/event-type',
    data,
    method: 'POST',
    token: API_GLOBAL.token
  });

  editEventTypes = ({ data }: any) => request({
    url: 'catalog/event-type',
    data,
    method: 'PUT',
    token: API_GLOBAL.token
  })

  getByIdEventTypes = ({ id }: any) => request({
    url: `catalog/event-type/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })

  getByNameEventTypes = ({ id }: any) => request({
    url: `catalog/event-type/by-name/${id}`,
    method: 'GET',
    token: API_GLOBAL.token
  })
}

const API_EVENTTYPES = new ClassAPI();

export default API_EVENTTYPES;
