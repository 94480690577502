import { store } from "../../index";

export function getIdEmail(from: any, type: string) {
  const state = store.getState();
  const valueSets = state.valueSets;
  const emails = valueSets.get('emails');
  console.log('normalize getIdEmail state=', state.valueSets, emails)

  let foundRec = emails.find((item: any) => item.email === from )
  console.log('normalize foundRec=', foundRec)
  if (!foundRec) {
    if (type === 'MIFID') {
      foundRec = emails.find((item: any) => item.defaultMFID === true)
    } else {
      foundRec = emails.find((item: any) => item.defaultACC === true)
    }
  }

  return foundRec ? foundRec.id : null;
}

export function getEmailById(id: any) {
  const state = store.getState();
  const valueSets = state.valueSets;
  const emails = valueSets.get('emails');
  console.log('normalize getIdEmail state=', state.valueSets, emails)

  let foundRec = emails.find((item: any) => item.id === id )
  console.log('normalize foundRec=', foundRec)
  if (!foundRec) {
     return '';
  }
  return foundRec.email;
}


