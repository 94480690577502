export const GET_ALL_AUTHORS_REQUEST = 'GET_ALL_AUTHORS_REQUEST'
export const GET_ALL_AUTHORS_SUCCESS = 'GET_ALL_AUTHORS_SUCCESS'
export const GET_ALL_AUTHORS_FAILURE = 'GET_ALL_AUTHORS_FAILURE'

export const CREATE_NEW_AUTHORS_REQUEST = 'CREATE_NEW_AUTHORS_REQUEST'

export const GET_AUTHORS_BY_ID_REQUEST = 'GET_AUTHORS_BY_ID_REQUEST'

export const DELETE_AUTHORS_BY_ID_REQUEST = 'DELETE_AUTHORS_BY_ID_REQUEST'

export const EDIT_AUTHORS_REQUEST = 'EDIT_AUTHORS_REQUEST'
export const CREATE_NEW_AUTHORS_FAILURE = 'CREATE_NEW_AUTHORS_FAILURE'