const normalize = ({ date, ...args }: any) => {
  if (!args) return null;

  return {
    ...args,
    mailingListSettingsId: args.mailingListSettingsId,
    subject: args.subject,
    date: args.createdDateTime ? args.createdDateTime.split(' ')[0] : new Date().toLocaleDateString('et',  { day: '2-digit', month: '2-digit', year: 'numeric' }),
    time: args.createdDateTime && args.createdDateTime.split(' ')[1],
    ['product-editor']: args.body && args.body.text,
  }
};

export default normalize;
