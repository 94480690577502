// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';

import GraphForm from 'components/Graphs/Form';
import { formReset } from 'components/Graphs/Form/actions'
import { getObjectTrim } from 'utils/formatters'
import {createFilterStatisticRequest, getAllStatisticsRequest} from '../../redux/actions';
import normalize from './normalize';
import config from './fields';
import validate from './validate';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { STATISTICS_FILTER_FORM } from 'constants/forms';
import { STATISTICS_TABLE } from 'constants/tables';
import { connect } from 'react-redux';

interface ICategoryForm {
  title?: string;
  form: string;
  match: any
}

class CategoryForm extends React.Component<ICategoryForm> {

  handleClick = () => {
    const {history, dispatch}: any = this.props;
    dispatch(reset(STATISTICS_FILTER_FORM))
    dispatch(formReset({ meta: { form: STATISTICS_FILTER_FORM } }))
    history.push('/statistics')
  };

  get actions() {
//    const { match: { params: { id } } } = this.props;
    const { dispatch}: any = this.props;
    const action = 'Reset filters';
    return (
      <GridRow>
        <GridCol>
          <Button className={"button-float"} view="extra" size="s" onClick={()=>  dispatch(reset(STATISTICS_FILTER_FORM)) }>
            {action}
          </Button>
          <Button className={"mr20 button-float"} view="extra" size="s" onClick={()=>
               dispatch(getAllStatisticsRequest({ meta: { table: STATISTICS_TABLE, exportToXLSX: true} })) }>
            Download XLSX
          </Button>
        </GridCol>
      </GridRow>
    );
  }
  render() {
//    const { match: { params: { id } }}  = this.props;
    const graphId = {
      future: true,
    };

    return (
      <GraphForm
        title="Statistic filter"
        form={STATISTICS_FILTER_FORM}
        graphId={graphId}
        type={'create'}
        actions={this.actions}
        onSuccess={this.handleClick}
        create={createFilterStatisticRequest}
        validate={(values: object) => validate(getObjectTrim(values))}
        normalize={normalize}
        columns={1}
        config={config}
        width="10"
        reInitialize
      />
    );
  }
}

export default compose<any>(
  connect(),
  withRouter
)(CategoryForm)
